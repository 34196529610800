import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import debounce from "lodash/debounce";
import moment from "moment-timezone";
import qs from "qs";
// Assets and Styles

// SLDS components
import Popover from "@salesforce/design-system-react/components/popover";
import Icon from "@salesforce/design-system-react/components/icon";
import Button from "@salesforce/design-system-react/components/button";
import logoLarge from "@assets/icons/logo.png";

// Component Helpers
import { setToastMessage, clearToastMessage } from "@containers/App/actions";
import { SocketContext } from "@context/socket";

import { getMessageRequest } from "@utils/messageRequest";
import NotificationList from "./NotificationList";
import CustomTooltip from "@components/customTooltip";

class NotificationContainer extends Component {
	static contextType = SocketContext;

	constructor(props) {
		super(props);
		this.state = {
			notifications: [],
			allRead: false,
			showLoading: false,
			allSeen: true,
			isOpen: false,
			tabOnFocus: true,
		};
	}

	onFocus = () => {
		this.setState({ tabOnFocus: true });
		document.title = "Pepper Cloud CRM";
	};

	// User has switched away from the tab (AKA tab is hidden)
	onBlur = () => {
		this.setState({ tabOnFocus: false });
	};

	/**
	 * Update the Notification
	 * @param data
	 */
	handleUpdateNotification = data => {
		this.setState(() => data);
	};

	/**
	 * Update the Notification after remove
	 * @param notifications
	 */
	handleRemoveNotification = notifications => {
		this.setState(prevState => {
			this.loadMore(prevState.notifications.length);
			return { notifications };
		});
	};

	/**
	 * Remove all notifications
	 * @param idList
	 */
	removeAll = idList => {
		this.context.socketIo?.post(
			`/notification/removeAll/`,
			{ idList },
			async () => {
				this.handleRemoveNotification([]);
			},
		);
	};

	/**
	 * Load more notifications
	 * @param addOne
	 */
	loadMore = addOne => {
		this.setState({ showLoading: true });
		const url = `/notification/loadMore/${addOne}`;
		this.context.socketIo?.get(url, (body, JWR) => {
			if (JWR.statusCode === 200) {
				let allRead = true;
				const notifications = body.data.notifications.map(data => {
					if (
						!(data.readBy && data.readBy.indexOf(this.props.userInfo.id) > -1)
					) {
						allRead = false;
					}
					return {
						...data,
						fileUrl: data.meta.errorFile || data.meta.file,
						timestamp: moment(data.createdAt)
							.tz(this.props.userInfo.locale.timeZone)
							.format("llll"),
					};
				});

				this.setState(
					{
						notifications,
						allRead,
						count: body.data.count,
						showLoading: false,
					},
					() => this.markSeen(),
				);
			}
		});
	};

	/**
	 * Mark Read  all notifications
	 * @param idList
	 */
	markAllAsRead = idList => {
		let { notifications } = this.state;
		if (!notifications || !notifications.length) return;
		this.context.socketIo?.post(
			`/notification/markAllRead/`,
			{ idList },
			async () => {
				notifications = this.state.notifications.map(notif => {
					if (idList.indexOf(notif._id) > -1) {
						return {
							...notif,
							readBy: [...notif.readBy, this.props.userInfo.id],
						};
					}
					return notif;
				});
				this.setState({ notifications, allRead: true });
			},
		);
	};

	/**
	 * Mark Seen  notifications
	 * @param idList
	 */
	markSeen = () => {
		const { notifications } = this.state;
		if (!notifications || !notifications.length) return;
		const idList = this.state.notifications.map(
			notification => notification._id,
		);
		this.context.socketIo?.post(
			`/notification/markSeen/`,
			{ idList },
			async () => {
				this.setState({ allSeen: true });
			},
		);
	};

	/**
	 * Scroll to Load more notification
	 * @type {*}
	 */
	onScrollLoadMore = debounce(scrollElementId => {
		const scrollElememt = document.getElementById(scrollElementId);
		if (!scrollElememt) return;
		if (
			scrollElememt.clientHeight + scrollElememt.scrollTop ===
			scrollElememt.scrollHeight
		) {
			const { notifications } = this.state;
			const { count } = this.state;
			if (
				notifications.length &&
				notifications.length < count &&
				!this.state.showLoading
			) {
				this.loadMore(this.state.notifications.length + 25);
			}
		}
	}, 100);

	isSupportedNotification = () =>
		"Notification" in window &&
		"serviceWorker" in navigator &&
		"PushManager" in window;

	getUnreadMessageCount = async () => {
		try {
			const {
				data: {
					data: { allNewMessageCount = 0 },
				},
			} = await getMessageRequest(
				`/api/v2/conversation/unread-count?${qs.stringify({
					type: "allConversation",
				})}`,
			);
			if (allNewMessageCount) this.props.handleNewMessage();
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	componentDidMount() {
		if (this.props.activeRoute !== "MSG") this.getUnreadMessageCount();
		window.addEventListener("focus", this.onFocus);
		window.addEventListener("blur", this.onBlur);
		this.context.socketIo?.get("/connect", (body, JWR) => {
			if (JWR.statusCode === 200) {
				let allRead = true;
				const notifications = body.data.notifications.map(data => {
					if (
						!(data.readBy && data.readBy.indexOf(this.props.userInfo.id) > -1)
					) {
						allRead = false;
					}
					return {
						...data,
						fileUrl: data.meta.errorFile || data.meta.file,
						timestamp:
							this.props.userInfo && this.props.userInfo.locale
								? moment(data.createdAt)
										.tz(this.props.userInfo.locale.timeZone)
										.format("llll")
								: moment(moment(data.createdAt).toArray()).fromNow(),
					};
				});

				let { allSeen } = this.state;
				const { id } = this.props.userInfo;
				for (let i = 0; i < notifications.length; i += 1) {
					if (notifications[i].seenBy.indexOf(id) === -1) {
						allSeen = false;
						break;
					}
				}

				this.setState({
					notifications,
					count: body.data.count,
					allRead,
					allSeen,
				});
			}
		});

		this.context.socketIo?.on("messages", async data => {
			const { fullDocument, operationType } = data;
			if (
				operationType !== "update" &&
				(fullDocument.status === "RECEIVED" ||
					fullDocument.reactionStatus === "RECEIVED") &&
				(this.props.activeRoute !== "MSG" || !this.state.tabOnFocus)
			) {
				if (this.props.activeRoute !== "MSG") {
					this.props.handleNewMessage();
				}

				try {
					if (
						this.props.userInfo &&
						this.props.userInfo.messageSetting &&
						this.props.userInfo.messageSetting.isAllowNotificationSound &&
						!fullDocument.archivedByUser.includes(this.props.userInfo.id)
					) {
						const element = document.getElementById("newMessageSoundPlay");
						if (element) {
							element.click();
						}
					}
				} catch (e) {
					/* empty */
				}

				if (
					this.isSupportedNotification() &&
					Notification.permission === "granted" &&
					!fullDocument.archivedByUser.includes(this.props.userInfo.id)
				) {
					document.title = "Message* | Pepper Cloud CRM";
					let title = "You have new unread messages";
					let body =
						fullDocument.message.type === "text"
							? fullDocument.message.text
							: "New Message received";
					if (fullDocument?.activityType === "EMOJI_REACTION") {
						title = "New reaction to message";
						body = `Someone has reacted ${fullDocument?.message?.emoji} to your message`;
					}
					const options = {
						body,
						icon: `${logoLarge}?auto=compress&cs=tinysrgb&dpr=1&w=500`,
					};
					const userAgent = navigator.userAgent.toLowerCase();
					const isAndroid = /android/.test(userAgent);
					let notification = null;
					if (isAndroid) {
						const registration = await navigator.serviceWorker.ready;
						notification = await registration.showNotification(title, options);
					} else {
						notification = new Notification(title, options);
					}

					// close the notification after 20 seconds
					setTimeout(() => {
						if (notification) notification.close();
					}, 20 * 1000);

					if (notification) {
						// navigate to a URL
						notification.addEventListener("click", () => {
							window.open(
								`https://${window.location.host}/messages#${fullDocument.conversationId}`,
								"_blank",
							);
						});
					}
				}
			}
		});

		this.context.socketIo?.on("notification", async data => {
			if (
				data.object === "CONVERSATION" &&
				data.title === "ASSIGN" &&
				this.isSupportedNotification() &&
				Notification.permission === "granted"
			) {
				const options = {
					icon: `${logoLarge}?auto=compress&cs=tinysrgb&dpr=1&w=500`,
				};

				const title = `${data?.meta?.actorName} has assigned a conversation to you`;

				const userAgent = navigator.userAgent.toLowerCase();
				const isAndroid = /android/.test(userAgent);
				let notification = null;
				if (isAndroid) {
					const registration = await navigator.serviceWorker.ready;
					notification = await registration.showNotification(title, options);
				} else {
					notification = new Notification(title, options);
				}

				// close the notification after 20 seconds
				setTimeout(() => {
					if (notification) notification.close();
				}, 20 * 1000);

				if (notification) {
					// navigate to a URL
					notification.addEventListener("click", () => {
						window.open(
							`https://${window.location.host}/messages#${data.objectId}`,
							"_blank",
						);
					});
				}
			}

			// if (
			// 	data.title === "CREATE" &&
			// 	(data.object === "NOTE" ||
			// 		data.object === "TASK" ||
			// 		data.object === "LOG" ||
			// 		data.object === "EVENT")
			// ) {
			// 	const message = {
			// 		type: "success",
			// 		body: {
			// 			title: "Records have been updated: ",
			// 			details: "",
			// 			headingLink: "Refresh",
			// 			duration: 1000000,
			// 		},
			// 	};
			// 	this.props.dispatch(setToastMessage(message));
			// }

			const isRefresh = ["BULK ACTION", "ACCOUNT TRANSFER"].includes(
				data.title,
			);
			if (
				data &&
				isRefresh &&
				this.props.activeRoute === data.meta.moduleId &&
				data.meta.isSelectedAll
			) {
				const message = {
					type: "success",
					body: {
						title: "Records have been updated: ",
						details: "",
						headingLink: "Refresh",
						duration: 500000,
					},
				};
				this.props.dispatch(clearToastMessage());
				this.props.dispatch(setToastMessage(message));
			}
			this.setState(prevState => {
				const notifications = [...prevState.notifications];
				if (notifications.length % 25 === 0) {
					notifications.pop();
				}
				notifications.unshift({
					...data,
					fileUrl: data.meta.errorFile || data.meta.file,
					timestamp: moment(data.createdAt)
						.tz(this.props.userInfo.locale.timeZone)
						.format("llll"),
				});
				if (prevState.isOpen) this.markSeen();
				return {
					allRead: false,
					notifications,
					allSeen: prevState.isOpen,
				};
			});
		});
		this.context.socketIo?.on("reconnect", () => {
			this.context.socketIo?.get("/connect", () => {});
		});
	}

	componentWillUnmount() {
		window.removeEventListener("blur", this.onBlur);

		window.removeEventListener("focus", this.onFocus);
		this.context.socketIo?.off("notification");
	}

	// toggle Notification container
	handleToggle = () => {
		this.props.closeNav();
		if (!this.state.isOpen && !this.state.allSeen) {
			this.markSeen();
			this.setState(state => ({
				isOpen: !state.isOpen,
			}));
		} else this.setState(state => ({ isOpen: !state.isOpen }));
	};

	// Close Notification container
	handleRequestClose = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const { isOpen, allSeen } = this.state;
		return (
			<li
				onScroll={() => this.onScrollLoadMore("notificationContainer")}
				className="pc-navigation-bar-dropdown-list pc-notification-dropdown"
			>
				<Popover
					className="pc-notification-dropdown-popover"
					isOpen={isOpen}
					onClose={this.handleRequestClose}
					onRequestClose={this.handleRequestClose}
					body={
						<div id="notificationContainer">
							{this.state.notifications.length ? (
								<div className="pc-notification-dropdown-list">
									<ul>
										{this.state.notifications.map(notification => (
											<NotificationList
												key={notification._id}
												notification={notification}
												intl={this.props.intl}
												io={this.context}
												userId={this.props.userInfo.id}
												notifications={this.state.notifications}
												handleUpdateNotification={this.handleUpdateNotification}
												handleRemoveNotification={this.handleRemoveNotification}
											/>
										))}
										{this.state.showLoading ? (
											<li className="pc-notifications-load">
												<div className="pc-load-more" />
											</li>
										) : null}
									</ul>
								</div>
							) : (
								<React.Fragment>
									{this.state.showLoading ? (
										<li className="pc-notifications-load">
											<div className="pc-load-more" />
										</li>
									) : (
										<div className="pc-notification-dropdown-popover_no-data">
											<Icon
												className="pc-no-notifications-icon"
												category="custom"
												name="pc-no-notifications"
												size="large"
											/>
											<br />
											<br />
											<h2>No notifications right now</h2>
											{/* <p>No notifications right now</p> */}
										</div>
									)}
								</React.Fragment>
							)}
						</div>
					}
					heading={
						<div className="pc-notification-header">
							<span className="pc-notification-header-title">
								Notifications
							</span>
							{this.state.notifications.length ? (
								<Button
									id={this.state.allRead ? "CLEAR" : "MARK"} // Do not alter this, Used in Cypress
									onClick={() => {
										const idList = this.state.notifications.map(
											notification => notification._id,
										);
										if (this.state.allRead) {
											this.removeAll(idList);
										}
										this.markAllAsRead(idList);
									}}
									className="pc-modal-primary-btn"
								>
									{this.state.allRead ? "Clear all" : "Mark all as read"}
								</Button>
							) : (
								<React.Fragment></React.Fragment>
							)}
						</div>
					}
					align="bottom right"
				>
					<CustomTooltip desc="Notifications">
						<Button
							variant="base"
							iconName="pc-notification"
							iconCategory="custom"
							iconVariant="border-filled"
							onClick={this.handleToggle}
							className="pc-navigation-bar-dropdown-btn-icon"
							id="notification-dropdown"
						/>
					</CustomTooltip>
				</Popover>
				{!allSeen ? <span className="pc-notification-count" /> : null}
			</li>
		);
	}
}
NotificationContainer.propTypes = {
	intl: PropTypes.object,
	userInfo: PropTypes.object,
	activeRoute: PropTypes.string,
	dispatch: PropTypes.func,
	closeNav: PropTypes.func,
	handleNewMessage: PropTypes.func,
};
export default injectIntl(NotificationContainer);
