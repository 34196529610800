/**
 * The global state selectors
 */

import { createSelector } from "reselect";
import { Iterable } from "immutable";

const selectGlobal = state => state.get("global");

const makeSelectLoading = () =>
	createSelector(selectGlobal, globalState => globalState.get("loading"));

const makeSelectError = () =>
	createSelector(selectGlobal, globalState => globalState.get("error"));

const makeSelectNotifications = () =>
	createSelector(selectGlobal, globalState => globalState.get("notifications"));

const makeSelectNavbar = () =>
	createSelector(selectGlobal, globalState =>
		globalState.get("menusState").toJS(),
	);

// ! not used anywhere
const makeSelectLocationState = () => {
	let prevRoutingState;
	let prevRoutingStateJS;

	return state => {
		const routingState = state.get("route"); // or state.route

		if (!routingState.equals(prevRoutingState)) {
			prevRoutingState = routingState;
			prevRoutingStateJS = routingState.toJS();
		}

		return prevRoutingStateJS;
	};
};

const makeSelectUserInfo = () =>
	createSelector(selectGlobal, globalState =>
		globalState.get("userInfo").toJS(),
	);

const makeSelectMessage = () =>
	createSelector(selectGlobal, globalState => {
		const messageObj = globalState.get("message");
		if (Iterable.isIterable(messageObj)) {
			return globalState.get("message").toJS();
		}
		return messageObj;
	});
const makeSelectResponseCode = () =>
	createSelector(selectGlobal, globalState => globalState.get("responseCode"));

const makeSelectAuthRequest = () =>
	createSelector(selectGlobal, globalState => globalState.get("secretFlag"));

const makeSelectSecretUrl = () =>
	createSelector(selectGlobal, globalState => globalState.get("secretURL"));

const makeSelectLoginData = () =>
	createSelector(selectGlobal, globalState =>
		globalState.get("loginData").toJS(),
	);

const makeSelect2FAFlag = () =>
	createSelector(selectGlobal, globalState => globalState.get("is2FA"));

const makeSelectAnimation = () =>
	createSelector(selectGlobal, globalState => globalState.get("animation"));

export {
	selectGlobal,
	makeSelectLoading,
	makeSelectError,
	makeSelectNavbar,
	makeSelectLocationState,
	makeSelectNotifications,
	makeSelectUserInfo,
	makeSelectMessage,
	makeSelectAuthRequest,
	makeSelectSecretUrl,
	makeSelectLoginData,
	makeSelect2FAFlag,
	makeSelectResponseCode,
	makeSelectAnimation,
};
