import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { injectIntl } from "react-intl";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/dateStyles.scss";

function NewDateTimeInput(props) {
	const { timeZone } = props.userInfo.locale;

	const weekDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

	const [dateFormat, timeFormat] = get(
		props,
		"userInfo.locale.dateTimeFormat",
		"DD/MM/YYYY HH:mm aa",
	).split(" ");

	const dateTime = props.value ? moment(props.value).tz(timeZone) : null;

	// const time = dateTime ? dateTime.format(timeFormat) : "";

	const handleDateChange = e => {
		if (e && e.isValid) {
			let value = moment(e.toDate()).tz(timeZone).startOf("day").toISOString();
			if (
				(props.fieldId === "F109" || props.fieldId === "F110") &&
				props.value
			) {
				const timeString = moment.utc(props.value).format("HH:mm:ss.SSS[Z]");
				const dateString = moment(e.toDate()).format("YYYY-MM-DD");
				value = moment(`${dateString}T${timeString}`)
					.tz(timeZone)
					.toISOString();
			}
			if (props.parentValue && moment(value).isBefore(props.parentValue)) {
				// this check make sures that selected date is after the parentdate if not adds one hour to the parent date
				value = moment(props.parentValue).add(1, "hours").toISOString();
			}
			props.handleChange({
				fieldName: props.fieldName,
				fieldId: props.fieldId,
				typeCode: props.typeCode,
				value,
				isRequired: props.isRequired,
			});
		}
	};

	const handleTimeChange = date => {
		try {
			let inputVal = new Date(date).toString();
			if (inputVal === "Invalid Date") {
				inputVal = "00:00";
			} else {
				inputVal = moment(date).format("HH:mm");
			}
			const [hrs, mns] = inputVal.split(":");
			let value = dateTime
				.set("hours", hrs)
				.set("minutes", mns)
				.set("second", 0)
				.set("milliseconds", 0)
				.toISOString();
			if (props.parentValue && moment(value).isBefore(props.parentValue)) {
				value = moment(props.parentValue).add(1, "hours").toISOString();
			}
			if (props.fieldId === "F109") {
				props.handleChange({
					fieldName: props.fieldName,
					typeCode: props.typeCode,
					fieldId: "F110",
					value: moment(value).add(1, "hours").toISOString(),
					isRequired: props.isRequired,
				});
			}

			props.handleChange({
				fieldName: props.fieldName,
				typeCode: props.typeCode,
				fieldId: props.fieldId,
				value,
				isRequired: props.isRequired,
			});
		} catch (error) {
			props.handleChange({
				fieldName: props.fieldName,
				typeCode: props.typeCode,
				fieldId: props.fieldId,
				value: "",
				isRequired: props.isRequired,
			});
		}
	};

	return (
		<div className="pc-event-time-date-box">
			<div className={`${props.className} pc-single-date-picker`}>
				<p className="inner-text label-title">Date</p>
				<DatePicker
					id={props.fieldId}
					placeholder={`${dateFormat}`}
					weekDays={weekDays}
					value={dateTime && dateTime.format(dateFormat)}
					onChange={handleDateChange}
					format={dateFormat}
					arrow={false}
					numberOfMonths={props.numberOfMonths}
					minDate={props.minDate ? props.minDate : ""}
				/>
			</div>
			<div className={`${props.className} time`}>
				<p>Time</p>
				<TimePicker
					className="pc-date-time-box"
					selected={
						dateTime ? new Date(dateTime.format("YYYY-MM-DDTHH:mm:ss")) : ""
					}
					placeholderText="Time"
					onChange={handleTimeChange}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption="Time"
					dateFormat={timeFormat}
					timeFormat="HH:mm"
				/>
			</div>
		</div>
	);
}

NewDateTimeInput.propTypes = {
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	userInfo: PropTypes.object,
	value: PropTypes.object,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleChange: PropTypes.func,
	numberOfMonths: PropTypes.number,
	className: PropTypes.string,
	parentValue: PropTypes.string,
	minDate: PropTypes.string,
};

NewDateTimeInput.defaultProps = {
	numberOfMonths: 1,
};

export default injectIntl(NewDateTimeInput);
