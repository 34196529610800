import * as React from "react";
import Button from "@salesforce/design-system-react/components/button";
import { getRequest } from "@utils/request";

import "./style.scss";

const Banner: React.FC = (): JSX.Element => {
	const [state, setState] = React.useState({
		isOpenBanner: false,
		bannerHtmlData: `<div />`,
	});

	const getBanner = async (): Promise<void> => {
		const {
			data: {
				data: { banner },
			},
		} = await getRequest("/api/v2/banner?type=FEATURE");

		if (banner?.html) {
			setState({ isOpenBanner: true, bannerHtmlData: banner.html });
		}
	};

	React.useEffect(() => {
		getBanner();
	}, []);

	return (
		<React.Fragment>
			{state.isOpenBanner && (
				<div className="pc-banner">
					<Button
						iconCategory="custom"
						iconName="pc-banner-close-white"
						variant="base"
						title="Close"
						className="banner-close"
						onClick={(): void =>
							setState({ isOpenBanner: false, bannerHtmlData: `<div />` })
						}
						iconSize="medium"
					/>
					<div
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: `${state.bannerHtmlData}`,
						}}
					/>
				</div>
			)}
		</React.Fragment>
	);
};

export default Banner;
