import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import "@assets/styles/chat-bot.scss";
import PropTypes from "prop-types";
import localStorage from "@services/localStorage";
import LottieIcon from "@components/LottieIcon";
import Glare from "../../assets/lottie-animation/Glare.json";

const DraggableChatButton = ({ handleChatBotToggle }) => {
	const [currentPosition, setCurrentPosition] = useState({
		xRate: -1,
		yRate: -1,
	});

	useEffect(() => {
		const iconButtonPositionObj = localStorage.getItem(
			"chatBotButtonPosition",
			"currentPosition",
		);
		if (iconButtonPositionObj) setCurrentPosition(iconButtonPositionObj);
	}, []);

	const handleDrag = (e, data) => {
		const { clientWidth, clientHeight } = document.documentElement;
		const newXPercent = (data.x / clientWidth) * 100;
		const newYPercent = (data.y / clientHeight) * 100;

		if (
			currentPosition.xRate - newXPercent < 0.3 &&
			currentPosition.yRate - newYPercent < 0.3 &&
			currentPosition.xRate - newXPercent > -0.3 &&
			currentPosition.yRate - newYPercent > -0.3
		) {
			handleChatBotToggle();
			return;
		}
		setCurrentPosition({ xRate: newXPercent, yRate: newYPercent });
		localStorage.setItem("chatBotButtonPosition", "currentPosition", {
			xRate: newXPercent,
			yRate: newYPercent,
		});
	};

	const calculatePositionFromPercent = (percent, isX) => {
		if (isX) {
			const { clientWidth } = document.documentElement;
			const initialXValue = (percent / 100) * clientWidth;
			const position = clientWidth - initialXValue;
			if (
				position < clientWidth ||
				(position > clientWidth && position > clientWidth * 2)
			) {
				return -1;
			}

			return Math.floor(initialXValue);
		}
		const { clientHeight } = document.documentElement;
		const initialYValue = (percent / 100) * clientHeight;
		const position = clientHeight - initialYValue;

		if (
			position < clientHeight ||
			(position > clientHeight && position > clientHeight * 2)
		) {
			return -1;
		}
		return Math.floor(initialYValue);
	};

	const initialX = calculatePositionFromPercent(currentPosition.xRate, true);
	const initialY = calculatePositionFromPercent(currentPosition.yRate, false);

	return (
		<Draggable
			bounds="body"
			position={{
				x: initialX || -1,
				y: initialY || -1,
			}}
			onStop={handleDrag}
		>
			<div className="pc-chat-bot-button">
				<LottieIcon
					autoplay={false}
					loop
					animationData={Glare}
					style={{
						height: 42,
						width: 42,
					}}
					// eslint-disable-next-line react/jsx-boolean-value
					hover={true}
				/>
			</div>
		</Draggable>
	);
};

export default DraggableChatButton;

DraggableChatButton.propTypes = {
	handleChatBotToggle: PropTypes.func,
};
