import * as React from "react";
import cx from "classnames";
import debounce from "lodash/debounce";
import Button from "@salesforce/design-system-react/components/button";
import searchIcon from "@assets/icons/search-white.svg";
import { GlobalSearchContext } from "@context/globalSearch";
import GlobalSearchResults from "@components/GlobalSearchResults";
import ClickAwayListener from "@components/Layout/ClickAwayListener";
import { MAX_SEARCH_LENGTH } from "@containers/Messages/ChatScreen/constant";

import "./index.scss";

const GlobalSearch: React.FC = (): JSX.Element => {
	const [search, setSearch] = React.useState("");
	const searchRef = React.useRef<HTMLInputElement>(null);
	const [showSearchInput, setShowSearchInput] = React.useState(false);

	const searchCtx = React.useContext(GlobalSearchContext);

	const delayedQuery = React.useCallback(
		debounce(q => setGlobalSearch(q), 500),
		[],
	);

	React.useEffect(() => {
		delayedQuery(search);
	}, [search]);

	React.useEffect(() => {
		if (showSearchInput) searchRef.current?.focus();
	}, [showSearchInput]);

	if (!searchCtx) return <React.Fragment />;

	const { showGlobalSearchPanel, setGlobalSearchShowPanel, setGlobalSearch } =
		searchCtx;

	return (
		<div>
			<div
				role="presentation"
				className={cx(
					"pc-global-search-container",
					showSearchInput && "focussed",
				)}
				onClick={(): void => {
					if (!showSearchInput) {
						setShowSearchInput(true);
					}
				}}
			>
				<ClickAwayListener
					onClickAway={(): void => {
						setSearch("");
						setShowSearchInput(false);
						setGlobalSearchShowPanel(false);
					}}
				>
					<div
						role="presentation"
						className="slds-hide_large"
						onClick={(): void => {
							setGlobalSearchShowPanel(true);
						}}
					>
						<img
							src={searchIcon}
							className="mb-search-icon"
							alt="global-search-icon"
							title="Global Search"
						/>
					</div>
					<div className="slds-show_large">
						<input
							ref={searchRef}
							type="text"
							value={search}
							onChange={(e): void => {
								if (!showGlobalSearchPanel) setGlobalSearchShowPanel(true);
								setSearch(e.target.value);
							}}
							onFocus={(): void => {
								setGlobalSearchShowPanel(true);
							}}
							placeholder="Search"
							onKeyDown={(e): void => {
								if (e.key === "Enter" && !showGlobalSearchPanel) {
									setGlobalSearchShowPanel(true);
								}
							}}
							maxLength={MAX_SEARCH_LENGTH}
						/>

						<img
							src={searchIcon}
							className="search-icon"
							alt="global-search-icon"
							title="Global Search"
						/>

						<Button
							iconName="clear"
							iconCategory="utility"
							onClick={(): void => {
								setSearch("");
								searchRef.current?.focus();
							}}
							className="clear-search"
							variant="icon"
						/>
					</div>
					<GlobalSearchResults />
				</ClickAwayListener>
			</div>
		</div>
	);
};
export default GlobalSearch;
