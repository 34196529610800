import * as React from "react";

import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";

import ClickAwayListener from "@components/Layout/ClickAwayListener";

import { AircallContext } from "@context/aircallProvider";
import "./style.scss";
import { getWarningMessage } from "@utils/common";
import { getMsgProvider } from "@utils/constants";

const Aircall: React.FC = (): JSX.Element => {
	const aircallContext = React.useContext(AircallContext);

	const escFunction = React.useCallback((event: KeyboardEvent) => {
		if (event.keyCode === 27) {
			aircallClose();
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener("keydown", escFunction);

		return () => {
			document.removeEventListener("keydown", escFunction);
		};
	}, []);

	if (!aircallContext) return <React.Fragment />;

	const {
		isAircallShown,
		toggleAircall,
		aircallClose,
		checkProceed,
		isOptOut,
		isExpired,
	} = aircallContext;

	const handleClick = (): void => {
		const proceed = checkProceed("", false);
		if (proceed) toggleAircall();
	};

	return (
		<li className="pc-navigation-bar-dropdown-list pc-settings-popover aircall-popover">
			<ClickAwayListener onClickAway={(): void => aircallClose()}>
				<Button
					iconCategory="custom"
					iconName="pc-aircall"
					variant="base"
					title="Aircall"
					onClick={handleClick}
					iconSize="large"
					className="aircall-button"
				>
					Aircall
				</Button>
				<div
					className={`float-div ${isAircallShown ? "show" : ""}`}
					id="aircallPopup"
				>
					{isOptOut || isExpired ? (
						<div className="pc-alter-info">
							<Icon
								className="pc-alter-icon"
								name="pc-warning-icon"
								category="custom"
								size="x-small"
							/>
							<span>
								{getWarningMessage(
									isOptOut,
									isExpired,
									getMsgProvider.AIRCALL,
									true,
								)}
							</span>
						</div>
					) : null}
					<div className="pc-float-aircall-header">
						<div className="aircall-header-content">
							<Icon category="utility" name="call" size="small" />
							<p>Aircall</p>
						</div>
						<Button
							iconCategory="custom"
							iconName="pc-aircall-close"
							variant="base"
							title="Close"
							onClick={aircallClose}
							iconSize="large"
						/>
					</div>
					<div id="aircall" />
				</div>
			</ClickAwayListener>
		</li>
	);
};

export default Aircall;
