import React from "react";
// eslint-disable-next-line import/no-named-as-default
import Checkbox from "@salesforce/design-system-react/components/checkbox";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import FormElement from "@components/FormElement";
import {
	TYP_CURRENCY,
	TYP_DATE,
	TYP_DATE_TIME,
	TYP_SYSTEM_SELECT,
	TYP_RICHTEXT,
	TYP_CHECKBOX,
	TYP_MULTI_SELECT,
	TYP_SELECT,
	TYP_SUGGESTION,
	TYP_PERCENT,
	TYP_NOTIFICATION_CONTROL,
} from "@components/FormElement/ElementTypes";
import tagFields from "@utils/constants/tags";
import ListTags from "@components/TinyComponents/ListTags";

const escapeRegExp = str => {
	if (str) return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
	return str;
};

const currencyFormat = n =>
	// format number 1000000 to 1,234,567
	String(n)
		.replace(/[^0-9.]+/g, "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const amountFormat = amount => {
	amount = String(amount);
	// appends $ to value, validates decimal side
	// and puts cursor back in right position.

	// don't validate empty input
	if (amount === "") {
		return "";
	}

	// original length
	// const originalLen = amount.length;

	// initial caret position
	// let caret_pos = input.prop("selectionStart");

	// check for decimal
	if (amount.indexOf(".") >= 0) {
		// get position of first decimal
		// this prevents multiple decimals from
		// being entered
		const decimalPos = amount.indexOf(".");

		// split number by decimal point
		let leftSide = amount.substring(0, decimalPos);
		let rightSide = amount.substring(decimalPos + 1);

		// add commas to left side of number
		leftSide = currencyFormat(leftSide);

		// validate right side
		rightSide = currencyFormat(rightSide);

		// On blur make sure 2 numbers after decimal
		// if (blur === "blur") {
		// 	rightSide += "00";
		// }

		// Limit decimal to only 2 digits
		rightSide = rightSide.substring(0, 2);

		// join number by .
		amount = `${leftSide}.${rightSide}`;
	} else {
		// no decimal entered
		// add commas to number
		// remove all non-digits
		amount = currencyFormat(amount);
		amount = `${amount}`;

		// final formatting
		// if (blur === "blur") {
		// 	amount += ".00";
		// }
	}

	return amount;

	// send updated string to input

	// put caret back in the right position
	// const updated_len = input_val.length;
	// caret_pos = updated_len - original_len + caret_pos;
	// input[0].setSelectionRange(caret_pos, caret_pos);
	// };
};

const getFieldValue = (c, filters = [], userInfo, intl, limited) => {
	const locale = userInfo.locale || {};
	let field;

	if (c.fieldId) field = filters.find(filter => filter.fieldId === c.fieldId);
	else field = filters.find(filter => filter.fieldName === c.fieldName);

	let typeCode = field && field.type.typeCode ? field.type.typeCode : "";
	typeCode = typeCode || (c && c.typeCode ? c.typeCode : "");
	if (!field && c.fieldName === "tag") return "";
	switch (typeCode) {
		case TYP_CURRENCY: {
			return c.value &&
				c.value.currencyType &&
				typeof c.value.amount === "number"
				? `${c.value.currencyType} - ${amountFormat(c.value.amount)}`
				: "-";
		}
		case TYP_DATE: {
			if (typeof c.value !== "string" && c.value) {
				const startDate = moment(c.value.startDate)
					.tz(locale.timeZone)
					.startOf("day")
					.format(locale.dateTimeFormat.split(" ")[0] || "YYYY-MM-DD");

				const endDate = moment(c.value.endDate)
					.tz(locale.timeZone)
					.startOf("day")
					.format(locale.dateTimeFormat.split(" ")[0] || "YYYY-MM-DD");
				return `${startDate} to ${endDate}`;
			}
			return c.value
				? locale.dateTimeFormat &&
						moment(c.value)
							.tz(locale.timeZone)
							.endOf("day")
							.format(locale.dateTimeFormat.split(" ")[0] || "YYYY-MM-DD")
				: "-";
		}
		case TYP_DATE_TIME: {
			if (typeof c.value !== "string" && c.value) {
				const startDate = moment(c.value.startDate)
					.tz(locale.timeZone)
					.startOf("day")
					.format(locale.dateTimeFormat.split(" ")[0] || "YYYY-MM-DD");

				const endDate = moment(c.value.endDate)
					.tz(locale.timeZone)
					.startOf("day")
					.format(locale.dateTimeFormat.split(" ")[0] || "YYYY-MM-DD");
				return `${startDate} to ${endDate}`;
			}
			return c.value
				? moment(c.value)
						.tz(locale.timeZone)
						.format(locale.dateTimeFormat || "MM/DD/YYYY HH:mm:ss")
				: "-";
		}
		case TYP_SYSTEM_SELECT: {
			if (c.valueLabel) {
				// return Array.isArray(c.valueLabel)
				// 	? c.valueLabel.map(v => v.label).join(", ")
				return c.valueLabel;
			}
			const options = field.params.find(param => param.value === c.value);
			return options ? options.label : c.value;
		}
		case TYP_SUGGESTION: {
			if (c.valueLabel) {
				return c.value ? c.valueLabel : "";
			}
			if (c.value) {
				return c.value;
			}
			return "-";
		}
		case TYP_RICHTEXT: {
			let htmlValue;
			if (c.value.html) {
				htmlValue = c.value.html;
				if (c.value.attachments) {
					c.value.attachments.map(attachment => {
						const safeContentId = escapeRegExp(attachment.contentId);
						const cidRegexp = new RegExp(
							`cid:${safeContentId}(@[^'"]+)?`,
							"gi",
						);
						htmlValue = htmlValue.replace(
							cidRegexp,
							attachment.s3AttachmentUrl,
						);
					});
				}
			}
			return (
				<FormElement
					typeCode={TYP_RICHTEXT}
					value={{ html: htmlValue || c.value.text || c.value }}
					readOnly
					toolBar={[]}
					disable
					inline
				/>
			);
		}
		case TYP_CHECKBOX: {
			return <Checkbox checked={!!c.value} />;
		}
		case TYP_MULTI_SELECT: {
			if (
				(field.fieldId === "F139" && ["LM", "CM"].includes(c.moduleId)) ||
				(field && tagFields[c.moduleId] === field.fieldId)
			) {
				return <ListTags filters={filters} field={c} detailTags={limited} />;
			}
			if (c.valueLabel) {
				// return Array.isArray(c.valueLabel)
				// 	? c.valueLabel.map(v => v.label).join(", ")
				return c.valueLabel;
			}
			const str = [];
			if (c.value && c.value.length) {
				c.value.forEach(v => {
					str.push(v);
				});
				const sorted = orderBy(str, [opt => opt.toLowerCase()], "asc");
				return sorted.join(", ");
			}
			return "-";
		}
		case TYP_SELECT: {
			if (c.valueLabel) {
				// return Array.isArray(c.valueLabel)
				// 	? c.valueLabel.map(v => v.label).join(", ")
				return c.valueLabel;
			}

			if (intl) {
				if (field.fieldName === "leadStatus") {
					const valueObj = field.params.find(e => e.value === c.value) || {};
					return !isEmpty(valueObj)
						? intl.formatMessage({ id: valueObj.key })
						: c.value;
				}
				if (field.fieldName === "opportunityStatus") {
					const valueObj = field.params.find(e => e.value === c.value) || {};
					return !isEmpty(valueObj)
						? intl.formatMessage({ id: valueObj.key })
						: c.value;
				}
			}
			return c.value;
		}
		case TYP_PERCENT: {
			return c.value === 0 ? 0 : c.value || "-";
		}
		case TYP_NOTIFICATION_CONTROL: {
			return c.value && c.value.interval && typeof c.value.value === "number"
				? `${c.value.value} - ${c.value.interval}`
				: "-";
		}
		default: {
			return c.valueLabel || c.value || "-";
		}
	}
};

export default getFieldValue;
export { amountFormat, currencyFormat };
