/*
 * Local Import
 */

// Priorities
import Spoil, { patternSpoil } from "./components/Piece/Spoil";
import Blockquote, { patternBlockquote } from "./components/Piece/Blockquote";
import Link, {
	patternLink,
	patternLinkMarkdown,
} from "./components/Piece/Link";
import Mention, { patternMention } from "./components/Piece/Mention";

// Text
import TextFormat, { patternTextFormat } from "./components/Piece/TextFormat";

// Character
import Character, { patternCharacter } from "./components/Piece/Character";

/*
 * Export
 */
export default [
	// Priorities
	{
		pattern: patternSpoil,
		Component: Spoil,
	},
	{
		pattern: patternBlockquote,
		Component: Blockquote,
	},
	{
		pattern: patternLinkMarkdown,
		Component: Link,
	},
	{
		pattern: patternLink,
		Component: Link,
	},
	{
		pattern: patternMention,
		Component: Mention,
		check: (props, value) => props.isMention(value) || props.isMentionMe(value),
	},

	// Text
	{
		pattern: patternTextFormat,
		Component: TextFormat,
	},

	// Character
	{
		pattern: patternCharacter,
		Component: Character,
	},
];
