// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import capitalize from "lodash/capitalize";
import { components } from "react-select";

import Icon from "@salesforce/design-system-react/components/icon";
import "./style.scss";

const dropdownClose = require("../../assets/icons/react-select-dropdown-close.svg");
const dropdownArrow = require("../../assets/icons/react-select-dropdown-arrow.svg");

export function CustomClearText() {
	return <img className="pc-select-close" src={dropdownClose} alt="Close" />;
}

export const MaxLengthInput = props => (
	<components.Input {...props} maxLength={25} />
);

export function SingleValue({ children, ...props }) {
	const { isNew, accountType = "" } = props?.selectProps?.selectProps ?? {};
	return (
		<components.SingleValue {...props}>
			{accountType && (
				<Icon
					name={`pc-${accountType.toLowerCase()}-account`}
					category="custom"
					size="small"
					style={{ "margin-right": "1rem" }}
					title={`${capitalize(accountType)} Account`}
				/>
			)}
			{children}
			{isNew && (
				<span data-cy="new-suggestion" className="pc-suggestion-new label">
					<Icon
						category="custom"
						name="pc-stars"
						size="x-small"
						style={{ "margin-right": "0.2rem" }}
					/>
					New
				</span>
			)}
		</components.SingleValue>
	);
}

export function Option({ children, ...props }) {
	const { data: { accountType = "", color, isMetaTag = false } = {} } = props;
	return (
		<components.Option {...props}>
			<div className="drop-down-tag-align">
				<div className="tag-icon">
					{accountType && (
						<Icon
							name={`pc-${accountType.toLowerCase()}-account`}
							category="custom"
							size="small"
							style={{ "margin-right": "1rem" }}
							title={`${capitalize(accountType)} Account`}
						/>
					)}
					{isMetaTag && <Icon name="pc-meta" category="custom" size="small" />}
					{color && !isMetaTag && (
						<span
							className="dd-tag-icon"
							style={{
								backgroundColor: `${color}`,
							}}
						></span>
					)}
				</div>
				<div>{children}</div>
			</div>
		</components.Option>
	);
}

export function MultiValueContainer({ children, ...props }) {
	if (props?.data?.data?.image_url) {
		return (
			<span className="multivalue-image-container">
				<div className="image-container">
					<img src={props?.data?.data?.image_url} alt="Arrow" />
				</div>
				{children}
			</span>
		);
	}
	if (props?.data.email) {
		return props?.data.isAdmin ? (
			<components.MultiValueContainer {...props}>
				<span title="You cannot remove admin user" className="valueContainer">
					{children}
				</span>
			</components.MultiValueContainer>
		) : (
			<components.MultiValueContainer {...props}>
				{children}
			</components.MultiValueContainer>
		);
		// eslint-disable-next-line no-else-return
	} else if (props.data.color && props.data.uuid) {
		return (
			<components.MultiValueContainer {...props}>
				{props?.data?.isMetaTag ? (
					<span
						title={
							props.data?.moduleId === "MSG"
								? "You cannot remove meta tags"
								: ""
						}
						className={
							props.data?.moduleId === "MSG"
								? "disable-ad-tag"
								: " marketing-tags"
						}
					>
						<Icon name="pc-meta" category="custom" size="small" />
						{children}
					</span>
				) : (
					<React.Fragment>
						<span
							className="multivalue-tags"
							style={{
								backgroundColor: props.data.color,
							}}
						/>
						{children}
					</React.Fragment>
				)}
			</components.MultiValueContainer>
		);
	}
	return (
		<components.MultiValueContainer {...props}>
			{children}
		</components.MultiValueContainer>
	);
}

export const MultiValue = ({ data, removeProps }) => {
	return (
		<div
			className={`${
				data.value?.F153 === "Opt out" || data.value?.isRetentionExpired
					? "recipient recipient-opt-out"
					: "recipient"
			}`}
		>
			{data.label}
			<span
				{...removeProps}
				style={{
					marginLeft: "8px",
					cursor: "pointer",
				}}
			>
				&#10005;
			</span>
		</div>
	);
};

export function ClearIndicator(props) {
	const {
		children = <CustomClearText />,
		getStyles,
		innerProps: { ref, ...restInnerProps },
	} = props;
	return (
		<div
			{...restInnerProps}
			ref={ref}
			style={getStyles("clearIndicator", props)}
		>
			<div>{children}</div>
		</div>
	);
}

export function DropdownIndicator(props) {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<img className="pc-select-arrow" src={dropdownArrow} alt="Arrow" />
			</components.DropdownIndicator>
		)
	);
}

export function ValueContainer({ children, ...props }) {
	return (
		<components.ValueContainer {...props} className="messageBody">
			{children}
		</components.ValueContainer>
	);
}

export const themes = theme => ({
	...theme,
	colors: {
		...theme.colors,
		boxShadow: "none",
		text: "#2e3f51",
		primary25: "#e6f4f7",
		primary: "#81c8d7",
	},
});

// With border on hover
export const dropdownStyles = {
	menu: base => ({ ...base, zIndex: 3 }),
	menuPortal: base => ({ ...base, zIndex: 9999 }),
	control: (styles, state) => ({
		...styles,
		"&:hover": { borderColor: "#d1dbeb" }, // ? border style on hover
		borderColor: state.isFocused ? "#0291ae !important" : "#d1dbeb",
		boxShadow: "none",
		minHeight: "42px",
	}),
	option: (styles, state) => ({
		...styles,
		color: state.isSelected ? "#2e3f51" : "",
		opacity: state.isDisabled ? 0.5 : 1,
		wordBreak: "break-word",
		":active": {
			...styles[":active"],
			backgroundColor: "#01829c",
			color: "#fff",
		},
		".option-help-text": {
			color: "#616161",
			fontSize: "10px",
		},
	}),
	singleValue: style => ({
		...style,
		position: "absolute",
		".pc-suggestion-new": {
			color: "#fff",
			padding: "0 0.5rem",
			borderRadius: "1.25rem",
			textTransform: "capitalize !important",
			background: "#0dc563",
			whiteSpace: "nowrap",
			height: "23px",
			display: "inline-block",
			marginLeft: "0.5rem",
		},
	}),
	multiValue: style => ({
		...style,
		backgroundColor: "#e3e3e3",
		color: "#878787",
	}),
	placeholder: defaultStyles => {
		return {
			...defaultStyles,
			color: "#cdcdd6",
			display: "flex",
			fontSize: "14px",
			position: "relative",
			"@media(max-width:1300px)": {
				fontSize: "12px",
			},
		};
	},
	group: defaultStyles => {
		return {
			...defaultStyles,
			padding: 0,
		};
	},
	valueContainer: styles => ({
		...styles,
		maxHeight: "150px",
		overflowY: "auto !important",
		overflowX: "hidden !important",
	}),
};
export const inlineDropdownStyles = {
	...dropdownStyles,
	valueContainer: styles => ({
		...styles,
		width: "200px !important",
		maxWidth: "200px !important",
	}),
};

// Transparent border on hover
export const transparentStyle = {
	control: (styles, state) => ({
		...styles,
		"&:hover": { borderColor: "transparent" }, // ? border style on hover
		borderColor: state.isFocused ? "#0291ae !important" : "transparent",
		boxShadow: state.isFocused ? "none" : "none",
		minHeight: "42px",
	}),
	option: (styles, state) => ({
		...styles,
		color: state.isSelected ? "#2e3f51" : "",
		wordBreak: "break-word",
		":active": {
			...styles[":active"],
			backgroundColor: "#01829c",
			color: "#fff",
		},
	}),
};
