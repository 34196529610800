// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import { amountFormat } from "@services/getFieldValue";
import { getRequest } from "@utils/request";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

export const CurrencyInput = props => {
	const {
		disable,
		value,
		userInfo: {
			locale: { currency, supportedCurrencies },
		},
	} = props;
	const { fieldId, moduleId } = props.field || "";

	const [getState, setState] = useState({
		options: [],
	});

	const [amount, setAmount] = useState(
		!Number.isNaN(value?.amount) ? amountFormat(value?.amount) : 0,
	);

	const resp = async () => {
		let options = [];
		let currencies = [];

		if (fieldId !== "amount") {
			currencies = supportedCurrencies || [];
		} else if (moduleId === "QTM") {
			try {
				const {
					data: { currencies: resCurr },
				} = await getRequest("/api/v2/integration/xero/form-details");
				resCurr.forEach(el => {
					currencies.push(el.code);
				});
			} catch (err) {
				currencies = [];
			}
		} else if (moduleId === "EMM") {
			try {
				const {
					data: { currencyCodes: resCurr },
				} = await getRequest("/api/v2/integration/quickbooks/form-details");
				resCurr.forEach(el => {
					currencies.push(el.Code);
				});
			} catch (err) {
				currencies = [];
			}
		}

		currencies.forEach(c => {
			options.push({
				label: c,
				value: c,
			});
		});
		options =
			orderBy(options, [label => label.value.toLowerCase()], "asc") || [];

		setState(st => ({ ...st, options }));
	};

	useEffect(() => {
		resp();
	}, []);

	const handleChangeSelect = record => {
		const values = {
			currencyType: record.value || "",
			amount: (value && value.amount) || 0,
		};

		props.handleChange({
			typeCode: props.typeCode,
			fieldName: props.fieldName,
			fieldId: props.fieldId,
			isRequired: props.isRequired,
			value: values,
		});
	};

	const handleChange = event => {
		event.value = Number.parseFloat(event.value.replace(/,/g, ""));

		const values = {
			currencyType: value.currencyType,
			amount: event.value || 0,
		};

		props.handleChange({
			typeCode: props.typeCode,
			fieldName: props.fieldName,
			fieldId: props.fieldId,
			isRequired: props.isRequired,
			value: values,
		});
		setAmount(!Number.isNaN(event.value) ? event.value : 0);
	};

	const handleFocus = event => {
		event.value = Number.parseFloat(event.value.replace(/,/g, ""));

		const values = {
			currencyType: value.currencyType,
			amount: event.value || 0,
		};

		props.handleChange({
			typeCode: props.typeCode,
			fieldName: props.fieldName,
			fieldId: props.fieldId,
			isRequired: props.isRequired,
			value: values,
		});
		setAmount(Number(event.value) || "");
	};

	const handleBlur = event => {
		const checkAmt = Number.parseFloat(event.value.replace(/,/g, ""));
		event.value = Number(checkAmt) || 0;

		const values = {
			currencyType: (props.value && props.value.currencyType) || "",
			amount: !Number.isNaN(event.value) ? event.value : 0,
		};

		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value: values,
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};

		props.handleBlur(record);
		setAmount(amountFormat(checkAmt || 0));
	};

	const { options } = getState;

	return (
		<div className="pc-curreny-box-counter">
			<div className="">
				<SelectInput
					inline={props.inline}
					typeCode="TYP_SYSTEM_SELECT"
					options={options}
					handleChange={handleChangeSelect}
					handleBlur={handleChangeSelect}
					value={value?.currencyType}
					disable={disable}
					defaultValue={find(options, option => option.value === currency)}
					menuPosition={props.menuPosition}
				/>
			</div>
			<div className="pc-curreny-box-counter-input">
				<TextInput
					value={amount}
					handleChange={handleChange}
					handleBlur={handleBlur}
					handleFocus={handleFocus}
					disable={disable}
					className={props.className || props.customClass} // ! couldnt find where customClass was used. Please advice on removal
					maxLength={16}
					placeholder={props.placeholder}
				/>
			</div>
		</div>
	);
};
// export class CurrencyInput1 extends React.PureComponent {}

CurrencyInput.propTypes = {
	handleBlur: PropTypes.func,
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	sectionId: PropTypes.string,
	customClass: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.object,
	userInfo: PropTypes.object,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleChange: PropTypes.func,
	disable: PropTypes.bool,
	inline: PropTypes.bool,
};

CurrencyInput.defaultProps = { disable: false };

export default injectIntl(CurrencyInput);
