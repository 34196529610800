// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Select, { components as comps } from "react-select";
import CreatableSelect from "react-select/creatable";
import Icon from "@salesforce/design-system-react/components/icon";

import {
	themes,
	dropdownStyles,
	ClearIndicator,
	DropdownIndicator,
	MultiValueContainer,
} from "@components/TinyComponents/Dropdown";

const MultiValueRemove = props => {
	if (props.data.isAdmin) {
		return (
			<span
				style={{ color: "#878787", padding: "0 5px 0 0" }}
				title="You cannot remove admin user"
			>
				<Icon category="custom" name="pc-user-close" />
			</span>
		);
	}
	return <comps.MultiValueRemove {...props} />;
};

class MultiSelectInput extends React.PureComponent {
	handleChange = event => {
		this.props.handleChange({
			fieldName: this.props.fieldName,
			typeCode: this.props.typeCode,
			fieldId: this.props.fieldId,
			isRequired: this.props.isRequired,
			isMultiSelect: true,
			label: event ? event.map(e => e.label) : [],
			value: event ? event.map(e => e.value) : [],
			data: event ? event.map(e => e.data) : [],
		});
	};

	handleBlur = () => {
		const record = {
			fieldId: this.props.fieldId,
			fieldName: this.props.fieldName,
			sectionId: this.props.sectionId,
			typeCode: this.props.typeCode,
			value: this.props.value,
			isRequired: this.props.isRequired,
			isMultiSelect: true,
		};
		this.props.handleBlur(record);
	};

	render() {
		const {
			props: {
				value,
				valueLabel,
				data = [],
				options: Sortedoptions = [],
				isCreatable,
				components,
			},
			props,
		} = this;
		let valueObj = null;
		// added the check "-" to solve the filter issue.
		if (valueLabel && value) {
			valueObj = valueLabel
				? valueLabel.map((l, i) => ({
						label: l,
						value: value[i],
						data: data[i],
					}))
				: [];
		} else {
			valueObj =
				props.value &&
				props.value.map(val => Sortedoptions.find(opt => opt.value === val));
		}
		// let value;
		// props.options.forEach(option => {
		// 	if (option.label.props) {
		// 		const childOptions = option.options;
		// 		const opt = childOptions.find(o => o.value === props.value);
		// 		if (opt) value = opt;
		// 	}
		// 	if (option.value === props.value) value = option;
		// });
		if (isCreatable) {
			return (
				<div>
					<div className="pc-form-field-with-tooltip">
						<CreatableSelect
							components={{
								ClearIndicator,
								DropdownIndicator,
								MultiValueRemove,
							}}
							isMulti
							isDisabled={props.disable}
							menuShouldBlockScroll={!props.inline}
							menuPosition="absolute"
							menuPlacement="auto"
							menuPortalTarget={!props.inline && document.body}
							options={props.options}
							defaultValue={props.defaultValue}
							value={valueObj}
							onChange={this.handleChange}
							onBlur={e => this.handleBlur(e)}
							sectionId={props.sectionId}
							// className={`${props.className} pc-modal-select-box`}
							className={`${props.className} ${
								!props.disable
									? "pc-modal-select-box"
									: "pc-modal-select-box pc-modal-select-box-disabled"
							}`}
							theme={themes}
							styles={props.dropdownStyles || dropdownStyles}
							placeholder={props.placeholder}
						/>
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className="pc-form-field-with-tooltip">
					<Select
						isMulti
						isClearable={props.isClearable}
						isDisabled={props.disable}
						options={Sortedoptions}
						defaultValue={props.defaultValue}
						components={{
							ClearIndicator,
							DropdownIndicator,
							MultiValueRemove,
							MultiValueContainer,
							...components,
						}}
						value={valueObj}
						menuShouldBlockScroll={!props.inline}
						menuPosition="absolute"
						menuPlacement="auto"
						menuPortalTarget={!props.inline && document.body}
						onChange={this.handleChange}
						closeMenuOnSelect={false}
						sectionid={props.sectionId}
						className={`${props.className} ${
							!props.disable
								? "pc-modal-select-box"
								: "pc-modal-select-box pc-modal-select-box-disabled"
						}`}
						theme={themes}
						styles={props.dropdownStyles || dropdownStyles}
						placeholder={props.placeholder}
					/>
				</div>
			</div>
		);
	}
}

MultiSelectInput.propTypes = {
	value: PropTypes.string,
	valueLabel: PropTypes.string,
	options: PropTypes.string,
	isCreatable: PropTypes.bool,
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	sectionId: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	components: PropTypes.object,
};

MultiSelectInput.defaultProps = {
	components: {},
};

export default MultiSelectInput;
