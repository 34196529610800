import * as React from "react";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import tagFields from "@utils/constants/tags";

import "./index.scss";
import Tag from "./Tag";
import PopOverTag from "./PopOverTag";

const ListTags = ({ filters, field, detailTags, parentRef }) => {
	const tagRef = React.useRef(null);
	const [visibleTags, setVisibleTags] = React.useState([]);

	const tagValues = React.useMemo(() => {
		const tempTags = [];
		const tags = orderBy(
			field.value || [],
			[param => param.toLowerCase()],
			["asc"],
		);

		if (tags && tags.length) {
			let fie;
			if (field.fieldId === "F139" && ["LM", "CM"].includes(field.moduleId))
				fie = filters.find(pf => pf.fieldId === "F139") || {
					params: [],
				};
			else
				fie = filters.find(pf => pf.fieldId === tagFields[field.moduleId]) || {
					params: [],
				};
			tags.forEach(va => {
				const obj = {
					key: va,
					value: va,
					color: "#0291ae",
					order: 0,
				};
				const tagDefinition = fie.params.find(pa => pa.value === va);
				if (tagDefinition) {
					obj.color = tagDefinition.color;
					obj.isMetaTag = tagDefinition.isMetaTag || false;
					obj.link = tagDefinition.link || "";
					tempTags.push(obj);
				}
			});
		}
		return tempTags;
	}, [field?.value]);

	React.useEffect(() => {
		if (tagRef && tagRef.current) {
			const containerWidth = tagRef.current.offsetWidth;
			let tempWidth = 0;
			const extraLength = 58;
			const vRecord = [];
			for (let i = 0; i < tagValues.length; i++) {
				let tag = tagValues[i].value;

				if (tempWidth + tag.length * 6.25 + extraLength > containerWidth) {
					let divisor = 9;
					if (tag.length * 6.25 + extraLength > 100) divisor = 18;
					tag = `${tag.slice(0, (containerWidth - tempWidth) / divisor)}...`;
					if (tempWidth + tag.length * 6.25 + extraLength > containerWidth) {
						break;
					}
				}
				tempWidth += tag.length * 6.25 + extraLength;

				vRecord.push({ ...tagValues[i], value: tag, key: tag });
			}
			setVisibleTags(vRecord);
		}
	}, [tagValues.length, parentRef?.current?.offsetWidth]);

	if (!tagValues.length && !detailTags) return "-";

	const remainingTags = tagValues.length - visibleTags.length;

	return (
		<div className="list-tags" ref={!detailTags ? tagRef : null}>
			{detailTags
				? tagValues.map(v => <Tag tag={v} />)
				: visibleTags.slice(0, visibleTags.length).map(v => <Tag tag={v} />)}
			{remainingTags > 0 && !detailTags ? (
				<PopOverTag
					tagValues={tagValues}
					remainingTags={remainingTags}
					fieldId={field.fieldId}
					className="tag-popover"
				/>
			) : null}
		</div>
	);
};

ListTags.propTypes = {
	filters: PropTypes.array,
	field: PropTypes.object,
	detailTags: PropTypes.bool,
	parentRef: PropTypes.node,
};

export default ListTags;
