import React from "react";
import PropTypes from "prop-types";
import Popover from "@salesforce/design-system-react/components/popover";
import Tag from "./Tag";

import "./index.scss";

const PopOverTag = ({ tagValues, remainingTags, fieldId }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const handleToggle = () => setIsOpen(st => !st);

	return (
		<Popover
			body={
				<React.Fragment>
					<p className="tag-popover-head">
						{fieldId === "F139" ? "Marketing Tags" : "Tags"}
					</p>
					<div className="tag-container">
						{tagValues.map(v => (
							<Tag tag={v} />
						))}
					</div>
				</React.Fragment>
			}
			id="popover-heading"
			isOpen={isOpen}
			onRequestClose={handleToggle}
			className="popover-container"
		>
			<div>
				<span
					className={`pc-extra-TAGS-numbers ${isOpen ? "selected" : ""}`}
					role="button"
					tabIndex={0}
					onClick={handleToggle}
					title=""
					onKeyDown={() => undefined}
				>
					+{remainingTags}
				</span>
			</div>
		</Popover>
	);
};

PopOverTag.propTypes = {
	tagValues: PropTypes.array,
	remainingTags: PropTypes.number,
	fieldId: PropTypes.string,
};

export default PopOverTag;
