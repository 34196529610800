import Axios from "axios";

import { getCookie, deleteCookies } from "@services/getSetCookie";

const X_APP_ID = "x_app_id";

const metaReqHeaders = {
	"Cache-Control": "no-store",
	Pragma: "no-cache",
	Expires: "0",
};
const mainAxios = Axios.create({
	baseURL: process.env.BACKEND_URI,
	withCredentials: true,
});

mainAxios.interceptors.request.use(req => {
	const csrfToken = getCookie("_csrf");
	if (csrfToken) req.headers["X-CSRF-Token"] = csrfToken;

	return req;
});

mainAxios.interceptors.response.use(
	response => {
		if (process.env.NODE_ENV === "production") {
			(async () => {
				try {
					const {
						data: { version },
					} = await Axios.get("/version.json", {
						headers: metaReqHeaders,
					});
					let xAppId = "";
					xAppId = version;
					const currentXAppId = await localStorage.getItem(X_APP_ID);
					if (currentXAppId !== xAppId) {
						await localStorage.setItem(X_APP_ID, xAppId);
						try {
							const files = await caches.keys();
							await Promise.all(
								files.map(name => {
									caches.delete(name);
								}),
							);
						} catch (e) {
							// No error handling for this.
						}

						/**
						 * if the currentXAppId is there we i.e user has already visited
						 * there is a newer version so reloads to get new chunks
						 * if the currentXAppId is not present i.e user has visited first time
						 * no need to reload the page
						 */
						if (currentXAppId) {
							window.location.reload();
						}
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error(error);
				}
			})();
		}
		// Any status code that lie within the range of 2xx cause this function to trigger
		return response;
	},
	error => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		if (
			error.response &&
			error.response.status &&
			error.response.status === 401
		) {
			const { errorCode = null } = error.response.data || {};
			if (errorCode && errorCode === "GEN412") {
				deleteCookies();
				window.location.href = "/login";
			}
		}
		return Promise.reject(error);
	},
);

export function getRequest(url) {
	return mainAxios({
		method: "GET",
		url,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
	});
}

export async function postRequest(url, data, type) {
	return mainAxios({
		method: "POST",
		url,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		responseType: type || "json",
		data: JSON.stringify(data),
	});
}

export async function patchRequest(url, data, type) {
	return mainAxios({
		method: "PATCH",
		url,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		responseType: type || "json",
		data: JSON.stringify(data),
	});
}

export async function deleteRequest(url, data, type) {
	return mainAxios({
		method: "DELETE",
		url,
		responseType: type || "json",
		data: JSON.stringify(data),
	});
}

export async function multipartRequest(url, data) {
	return mainAxios({
		method: "POST",
		url,
		headers: {
			Accept: "application/json",
			"Content-Type": "multipart/form-data",
		},
		data,
	});
}

export default {
	getRequest,
	postRequest,
	patchRequest,
	deleteRequest,
	multipartRequest,
};
