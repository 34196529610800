export const individualMessageActions = {
	TELEGRAM: { delete: true, replyTo: true, reaction: false, editMessage: true },
	"TWILIO-WHATSAPP": {
		delete: false,
		replyTo: false,
		reaction: false,
		editMessage: false,
	},
	MESSENGER: {
		delete: false,
		replyTo: false,
		reaction: false,
		editMessage: false,
	},
	"WHATSAPP-WEB": {
		delete: true,
		replyTo: true,
		reaction: true,
		editMessage: true,
	},
	"WHATSAPP-CLOUD-API": {
		delete: false,
		replyTo: true,
		reaction: true,
		editMessage: false,
	},
	INSTAGRAM: {
		delete: false,
		replyTo: false,
		reaction: true,
		editMessage: false,
	},
};

export const messagaeReceivedActions = [
	{
		label: "Reply",
		action: "REPLY_MESSAGE",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "pc-replyTo-icon",
			category: "custom",
		},
	},
];

export const cloudApIvalidMediaSizes = {
	audio: 16 * 1024 * 1024,
	text: 20 * 1024 * 1024,
	application: 20 * 1024 * 1024,
	image: 5 * 1024 * 1024,
	video: 16 * 1024 * 1024,
	sticker: 100000,
};
export const whatsappWebValidMediaSizes = {
	audio: 5 * 1024 * 1024,
	text: 5 * 1024 * 1024,
	application: 5 * 1024 * 1024,
	image: 5 * 1024 * 1024,
	video: 5 * 1024 * 1024,
};

export const MAX_SEARCH_LENGTH = 500;

export const fileSizeLimit = 5 * 1024 * 1024;

export const bytesToSize = (bytes: number): string => {
	const kilobyte = 1024;
	const megabyte = kilobyte * 1024;

	if (bytes < kilobyte) {
		return `${bytes} B`;
	}
	if (bytes < megabyte) {
		return `${(bytes / kilobyte).toFixed(0)} KB`;
	}
	return `${(bytes / megabyte).toFixed(0)} MB`;
};

export const assistAIOptions = [
	{
		label: "Reply via AssistAI knowledge base",
		action: "QUICK_KNOWLEDGE_REPLY",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "pc-msg-kb-qr-option",
			category: "custom",
		},
		rightIcon: {
			name: "pc-replyTo-icon",
			category: "custom",
		},
	},
	{
		label: "Reply via selected AssistAI knowledge base group",
		action: "REPLY_FROM_KNOWLEDGE_BASE_GROUP",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "pc-msg-kb-grp-option",
			category: "custom",
		},
		rightIcon: {
			name: "pc-replyTo-icon",
			category: "custom",
		},
	},
];

export const messagaeSentActions = [
	...messagaeReceivedActions,
	{
		label: "Delete",
		action: "DELETE_MESSAGE",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "pc-replyTo-delete-icon",
			category: "custom",
		},
	},
];

export const multipleFileReceiveActions = [
	...messagaeReceivedActions,
	{
		label: "Reaction",
		action: "REACT_MESSAGE",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "pc-emoji-picker-mutli-file",
			category: "custom",
		},
	},
	{
		label: "Download",
		action: "DOWNLOAD_FILE",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "multifile-download",
			category: "custom",
		},
	},
];

export const multipleFileSentActions = [
	...messagaeReceivedActions,
	{
		label: "Reaction",
		action: "REACT_MESSAGE",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "pc-emoji-picker-mutli-file",
			category: "custom",
		},
	},
	{
		label: "Download",
		action: "DOWNLOAD_FILE",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "multifile-download",
			category: "custom",
		},
	},
	{
		label: "Delete",
		action: "DELETE_MESSAGE",
		className: "pc-dropdown-items",
		leftIcon: {
			name: "pc-replyTo-delete-icon",
			category: "custom",
		},
	},
];

export const mostUsedEmojies = [
	{ emoji: "😀" },
	{ emoji: "😁" },
	{ emoji: "😎" },
	{ emoji: "😯" },
];

export const mimeTypeToFileName = {
	"text/vcard": "vcard",
	"text/x-vcard": "x-vcard",
	"text/csv": "csv",
	"text/richtext": "richtext",
	"text/calendar": "calendar",
	"text/directory": "directory",
	"application/pdf": "pdf",
	"application/vcard": "vcard",
	"application/vnd.ms-powerpoint": "powerpoint",
	"application/msword": "msword",
	"application/vnd.ms-excel": "excel",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
		"docx",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation":
		"pptx",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
};

export const providerToLabel = {
	TELEGRAM: "Telegram",
	"TWILIO-WHATSAPP": "WhatsApp Twilio",
	MESSENGER: "Messenger",
	"WHATSAPP-WEB": "Whatsapp Web",
	"WHATSAPP-CLOUD-API": "Whatsapp Cloud API",
	INSTAGRAM: "Instagram",
};

export const groupProvider = {
	WHATSAPP_WEB: "WHATSAPP-WEB",
	TELEGRAM: "TELEGRAM",
};

export const receivedColor = "#00C094";
export const sentColor = "#64FFDB";
