import React, { useEffect, useRef } from "react";
import cx from "classnames";

import Input from "@salesforce/design-system-react/components/input";
import InputIcon from "@salesforce/design-system-react/components/icon/input-icon";
import Checkbox from "@salesforce/design-system-react/components/checkbox";
import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";
import Tooltip from "@salesforce/design-system-react/components/tooltip";

import { TextInputType } from "@custom-types/input-type";
import { setToastMessage } from "@containers/App/actions";
import { ACCOUNT_TYPE } from "@utils/constants";
import { TYP_CHECKBOX } from "./ElementTypes";
import "./styles/textinput.scss";

function TextInput(props: TextInputType): JSX.Element {
	const { autoFocus, valueObj = {} } = props;
	const inputElementRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (autoFocus && inputElementRef.current) {
			inputElementRef.current.focus();
		}
	}, []);

	const handlePersonalAccountType = (personal: boolean): void => {
		const record = {
			value: personal ? ACCOUNT_TYPE.PERSONAL : ACCOUNT_TYPE.BUSINESS,
			fieldId: "F72",
			sectionId: props.sectionId,
			typeCode: TYP_CHECKBOX,
		};

		props.handleChange(record);
	};

	const handleChange = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value } = event.target as HTMLInputElement;

		props.handleChange({
			typeCode: props.typeCode,
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			isRequired: props.isRequired,
			value,
			key: event.key,
		});
	};

	const handleFocus = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value } = event.target as HTMLInputElement;
		if (props.handleFocus) {
			props.handleFocus({
				typeCode: props.typeCode,
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				isRequired: props.isRequired,
				value,
				key: event.key,
			});
		}
	};

	const handleBlur = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value: (event.target as HTMLInputElement).value.trim(),
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleBlur(record);
	};

	const copyToClipboard = (): void => {
		navigator.clipboard.writeText(props.value);
		if (props.dispatch) {
			props.dispatch(
				setToastMessage({
					type: "success",
					body: {
						title: "Copied to clipboard",
						details: "",
					},
				}),
			);
		}
	};

	const getIconRight = (): JSX.Element | null => {
		if (props.showCopyText) {
			return (
				<InputIcon category="custom" name="pc-copy" onClick={copyToClipboard} />
			);
		}
		if (props.fieldId === "email" && !props.isStepLogin) {
			return (
				<Button
					className="email-change"
					variant="icon"
					iconName="pc-email-edit"
					iconCategory="custom"
					onMouseDown={props.handleResetUserName}
				/>
			);
		}
		return null;
	};
	const getIconLeft = (): JSX.Element | null => {
		if (props.leftIcon) {
			return <InputIcon category="custom" name={props.leftIcon} />;
		}
		return null;
	};

	return (
		<React.Fragment>
			<Input
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				inputRef={inputElementRef}
				id={props.fieldId}
				disabled={props.disable}
				name={props.name || props.fieldName || props.fieldId}
				onChange={handleChange}
				onBlur={handleBlur}
				onFocus={handleFocus}
				onKeyPress={handleChange}
				type="text"
				className={cx(props.className, "pc-modal-text-box")}
				value={props.value}
				placeholder={props.placeholder}
				autoComplete="off"
				label={props.label}
				minLength={props.minLength}
				maxLength={props.maxLength}
				iconRight={getIconRight()}
				iconLeft={getIconLeft()}
				readOnly={props.readOnly}
			/>
			{props.showRemainingAllowed && props.maxLength && (
				<span
					className={cx(
						"chracremaining",
						Number(props.maxLength) - (props.value || "").length === 0 &&
							"charactersover",
					)}
				>
					{Number(props.maxLength) - (props.value || "").length} Characters
					remaining
				</span>
			)}
			{props.showAccountType && (
				<Checkbox
					labels={{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						label: (
							<span className="span-label-content">
								Is it a Personal Account?
								<Tooltip
									content="Personal account is an individual account, which is not associated with any company."
									align="bottom"
									hasStaticAlignment
									dialogClassName="account-type-tooltip"
								>
									<div>
										<Icon
											category="custom"
											name="pc-info"
											size="xx-small"
											className="account-type-help-icon"
											style={{ cursor: "pointer" }}
										/>
									</div>
								</Tooltip>
							</span>
						),
					}}
					onChange={({ target: { checked } }): void => {
						handlePersonalAccountType(checked);
					}}
					checked={valueObj.F72 === ACCOUNT_TYPE.PERSONAL}
					disabled={props.disable}
					className="account-type-checkbox"
				/>
			)}
		</React.Fragment>
	);
}

export default TextInput;
