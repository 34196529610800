/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import capitalize from "lodash/capitalize";
import Button from "@salesforce/design-system-react/components/button";
import Radio from "@salesforce/design-system-react/components/radio-group/radio";
import Icon from "@salesforce/design-system-react/components/icon";
import Checkbox from "@salesforce/design-system-react/components/checkbox";
import { useIntl } from "react-intl";
import orderBy from "lodash/orderBy";
import { Formatizer } from "@components/Formatizer";
import FormElement from "@components/FormElement";
import "./style.scss";

import FormField from "@components/TinyComponents/FormField";
import {
	TYP_DATE_TIME,
	TYP_RICHTEXT,
	TYP_SELECT,
	TYP_SUGGESTION,
} from "@components/FormElement/ElementTypes";
import RecordAvatar from "@components/TinyComponents/RecordAvatar";

import { setToastMessage } from "@containers/App/actions";

import { postRequest } from "@utils/request";
import { validateField } from "@services/validationService";
import generateErrorMessage from "@services/generateErrorMessage";
import { getRelativeTimeFormat } from "@containers/Messages/helper";
import DuplicateRecordCard from "@components/DuplicateRecordCard";

const escapeRegExp = str => {
	if (str) return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
	return str;
};

const createActionItems = [
	{
		label: "Lead",
		value: `Create a Lead with first name "First Name" last name "Last Name" and email ID "Email ID"`,
	},
	{
		label: "Opportunity",
		value: `Create a new Opportunity with name "Name" worth "Amount"`,
	},
	{
		label: "Contact",
		value: `Create a new Contact with first name "First Name" last name "Last Name" and email ID "Email ID"`,
	},
	{
		label: "Account",
		value: `Create a new Account with the name "Company Name"`,
	},
	{
		label: "Log",
		value: `Add a call log "Had a meeting for negotiation" to the Contact "Name"`,
	},

	{
		label: "Note",
		value: `Create a note "note subject" for lead "Name"`,
	},
];

const updateActionItems = [
	{
		label: "Lead",
		value: `Move Lead "Name" to the Stage "Stage Name"`,
	},
	{
		label: "Opportunity",
		value: `Move opportunity "Name" to Stage "Stage name"`,
	},
	{
		label: "Contact",
		value: `Reassign contact "Name" to user "El Johnston"`,
	},
	{
		label: "Account",
		value: `Reassign account "Opal_Feest" to user "El Johnston"`,
	},
];

const suggestionActionCard = [
	{
		title: "Add event",
		activityType: "EVENT",
		formId: "EF1",
		templateId: "ET1",
		moduleId: "ACTM",
	},
	{
		title: "Add note",
		formId: "GF2",
		activityType: "NOTE",
		moduleId: "ACTM",
		templateId: "GT2",
	},
	{
		title: "Add log",
		formId: "GF1",
		activityType: "LOG",
		moduleId: "ACTM",
		templateId: "GT1",
	},
	{
		title: "Add task",
		formId: "TF1",
		moduleId: "ACTM",
		activityType: "TASK",
		templateId: "TT1",
	},
];

const RelatedLinkCard = ({ relatedLinks = [] }) => {
	const downloadFile = async url => {
		const {
			data: { data },
		} = await postRequest("/api/v2/download-file", { url });

		window.open(data.path, "_blank");
	};

	const getCard = (link, index) => {
		if (link.url) {
			return (
				<li
					className={cx(
						"link-main",
						(relatedLinks.length === 1 ||
							(relatedLinks.length === index + 1 && (index + 1) % 2 !== 0)) &&
							"single",
					)}
				>
					<a
						title={link.title || link.url}
						className="link-item"
						href={link.url}
						target="_blank"
					>
						<span>{link.title || link.url}</span>
						<Button
							variant="icon"
							iconCategory="utility"
							iconName="new_window"
						/>
					</a>
				</li>
			);
		}

		if (link.fileName) {
			return (
				<li
					className={cx(
						"link-main",
						(relatedLinks.length === 1 ||
							(relatedLinks.length === index + 1 && (index + 1) % 2 !== 0)) &&
							"single",
					)}
				>
					<a
						className="link-item"
						href="#"
						onClick={() => {
							downloadFile(link.filePath);
						}}
						role="button"
						title={link.fileName}
					>
						<span>{link.fileName}</span>
						<Button
							variant="icon"
							iconCategory="utility"
							iconName="new_window"
						/>
					</a>
				</li>
			);
		}

		return null;
	};

	return (
		<div className="related-links">
			<h3>Related Links</h3>
			<ul className="link-container">
				{relatedLinks.map((l, i) => getCard(l, i))}
			</ul>
		</div>
	);
};

RelatedLinkCard.propTypes = {
	relatedLinks: PropTypes.array,
};

export const ChooseOptionsCard = ({ handleAddPrompt }) => {
	const [showAll, setShowAll] = useState(false);

	const handleClick = () => {
		setShowAll(!showAll);
	};

	return (
		<div className="pc-options-choose-card">
			<p className="chose-option">Choose one of the options to continue</p>
			<p className="chip-head">Create records</p>
			<div className="chip-container">
				{createActionItems
					.slice(0, showAll ? createActionItems.length : 3)
					.map(item => (
						<button
							type="button"
							className="actionChips"
							key={item.label}
							onClick={() => handleAddPrompt(item.value)}
						>
							{item.label}
						</button>
					))}
				<button
					type="button"
					className="actionChips more-less-button"
					onClick={handleClick}
				>
					{showAll ? "Show less" : "Load more"}
				</button>
			</div>
			<p className="chip-head">Update records</p>
			<div className="chip-container">
				{updateActionItems.map(item => (
					<button
						type="button"
						className="actionChips"
						key={item.label}
						onClick={() => handleAddPrompt(item.value)}
					>
						{item.label}
					</button>
				))}
			</div>
		</div>
	);
};

ChooseOptionsCard.propTypes = {
	handleAddPrompt: PropTypes.func,
};

export const TypingConversationCard = props => {
	const {
		content: { message },
	} = props.message;
	const [currentText, setCurrentText] = useState("");
	const [currentIndex, setCurrentIndex] = useState(0);

	React.useEffect(() => {
		let timeout;
		if (currentIndex < message.length) {
			timeout = setTimeout(() => {
				props.scrollToBottom();
				setCurrentText(prevText => prevText + message[currentIndex]);
				setCurrentIndex(prevIndex => prevIndex + 1);
			}, 2);
		} else {
			props.scrollToBottom();
			props.clearTypingConversation();
		}

		return () => clearTimeout(timeout);
	}, [currentIndex]);

	return (
		<div className="msg-bubble">
			<div className="bot-success-message">
				<p className="knowledge-base">{currentText}</p>
			</div>
		</div>
	);
};

TypingConversationCard.propTypes = {
	message: PropTypes.object,
	clearTypingConversation: PropTypes.func,
	scrollToBottom: PropTypes.func,
};

export const MessageCard = ({
	msg,
	handleAddLogs,
	handleChatBotToggle,
	userInfo,
}) => {
	const { contentType, content, status, _id: id } = msg || {};
	const messageId = msg.id || msg._id;

	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();

	const getValueObj = requestField => {
		const out = {};
		requestField?.forEach(fi => {
			let { value } = fi;
			if (fi.fieldId === "F109" && !value) {
				value = new Date();
			}
			if (fi.fieldId === "F110" && !value) {
				value = moment(new Date()).add(1, "hours");
			}
			out[fi.fieldId] = value;
		});
		return out;
	};

	const getLabelObj = requestField => {
		const out = {};
		requestField?.forEach(fi => {
			out[fi.fieldId] = fi.label || "";
		});
		return out;
	};

	const getErrorObj = requestField => {
		const out = {};
		if (contentType !== "BOT_ACTION_REQUEST") {
			return {};
		}
		requestField?.forEach(fi => {
			if (fi.value) {
				const { message } = validateField(fi);
				out[fi.fieldId] = fi.message || message || "";
			}
		});
		return out;
	};
	const [state, setState] = React.useState({
		valueObj: getValueObj(content?.requestField || []),
		labelObj: getLabelObj(content?.requestField || []),
		errorObj: getErrorObj(content?.requestField || []),
		cardStatus: status || "ACTIVE",
		isLoading: false,
		choice: content?.recordId,
	});

	const { errorObj, valueObj, cardStatus, isLoading, labelObj } = state;

	if (cardStatus === "ARCHIVED" || !Object.keys(msg).length) return <div />;

	const handleChange = record => {
		const { fieldId, value, typeCode, label = "" } = record;

		const newValueObj = { ...valueObj };
		const newErrObj = { ...errorObj };

		if (
			typeCode === TYP_DATE_TIME ||
			typeCode === TYP_SELECT ||
			typeCode === "TYP_DATE" ||
			typeCode === TYP_SUGGESTION ||
			typeCode === "TYP_MULTI_SELECT"
		) {
			const dependentFields = content.requestField.filter(
				field => field.parentId === record.fieldId,
			);
			if (dependentFields.length) {
				dependentFields.forEach(field => {
					// check via typeCode
					if (field.typeCode === TYP_DATE_TIME) {
						if (record.value) {
							// this check is for when user select date that is after end date this will add one hour to selected date and updates the end date
							newValueObj[field.fieldId] = moment(record.value)
								.add(1, "hours")
								.toISOString();
						}
					}
				});
			}
			delete newErrObj[fieldId];

			const { message } = validateField(record);

			if (message) {
				newErrObj[fieldId] = message;
			}
		}

		setState(st => ({
			...st,
			valueObj: { ...newValueObj, [fieldId]: value },
			labelObj: { ...labelObj, [fieldId]: label },
			errorObj: newErrObj,
		}));
	};

	const handleBlur = field => {
		const newErrObj = { ...errorObj };
		delete newErrObj[field.fieldId];

		const { message } = validateField(field);

		if (message) {
			newErrObj[field.fieldId] = message;
		}
		setState(st => ({
			...st,
			errorObj: newErrObj,
		}));
	};

	const fetchDependentOptions = params => parentId => {
		const element = valueObj[parentId];
		if (!element) {
			return params;
		}
		const filteredParams = params.filter(p => p.parentParam === element) || [];
		return filteredParams;
	};

	const fetchParentValue = parentId => valueObj[parentId];

	const handleSubmitData = async () => {
		const newErrObj = {};
		let { requestField = [] } = content;
		const { availableFields = [], actionDetails } = content;

		requestField.forEach(fld => {
			const { message } = validateField({
				...fld,
				value: valueObj[fld.fieldId],
			});
			if (message) {
				newErrObj[fld.fieldId] = message;
			}
		});
		if (newErrObj && Object.keys(newErrObj).length) {
			setState(st => ({
				...st,
				errorObj: newErrObj,
			}));
			return;
		}
		requestField = requestField.map(element => {
			return {
				...element,
				value: valueObj[element.fieldId],
				label: labelObj[element.fieldId],
			};
		});
		setState(st => ({
			...st,
			isLoading: true,
		}));
		try {
			const {
				data: { transactionsLog },
			} = await postRequest(`api/v2/ai/bot-conversation`, {
				userSubmittedDetails: {
					fields: [...requestField, ...availableFields],
					logId: msg.id || msg._id,
					actionDetails,
					parentConversationDetails: content.parentConversationDetails,
					userInputPrompt: content.userInputPrompt,
					choice: state.choice,
				},
				originalContentType: contentType,
				contentType: "USER_SUBMIT",
			});
			handleAddLogs(transactionsLog, { ...msg, status: "ARCHIVED" });
			setState(st => ({
				...st,
				isLoading: false,
				cardStatus: "ARCHIVED",
			}));
		} catch (error) {
			let message = {};
			if (error.response) {
				const { data } = error.response;
				message = { ...data.message, status: data.status };
			}

			if (message.status && message.status === 409) {
				let fieldId;
				if (actionDetails.moduleId === "LM") {
					fieldId = "F7";
				} else if (actionDetails.moduleId === "CM") {
					fieldId = "F48";
					newErrObj.F48 = message.body.title;
				} else if (actionDetails.moduleId === "AM") {
					fieldId = "F69";
				}
				if (
					content.requestField &&
					!content.requestField.some(each => each.fieldId === fieldId)
				) {
					const logs = msg;
					const avlFields = [];
					const errorField = {};
					content.availableFields.map(each => {
						if (each.fieldId === fieldId) {
							logs.content.requestField.push(each);
							errorField[fieldId] = each.value;
						} else {
							avlFields.push(each);
						}
					});
					logs.content.availableFields = avlFields;
					handleAddLogs([], logs);
					setState(st => ({
						...st,
						valueObj: { ...valueObj, ...errorField },
					}));
				}
				const duplicateRecord = {};
				duplicateRecord[fieldId] = (
					<DuplicateRecordCard userInfo={userInfo} record={message.body} />
				);
				setState(st => ({
					...st,
					errorObj: { ...newErrObj, ...duplicateRecord },
					isLoading: false,
				}));
			} else {
				const errMessage = generateErrorMessage(error);
				dispatch(setToastMessage(errMessage));
			}
		}
	};

	const getFieldValue = field => {
		switch (field.typeCode) {
			case "TYP_CURRENCY":
				return `${field.value.currencyType}-${field.value.amount}`;
			case TYP_RICHTEXT: {
				let htmlValue;
				if (field.value.html) {
					htmlValue = field.value.html;
					if (field.value.attachments) {
						field.value.attachments.map(attachment => {
							const safeContentId = escapeRegExp(attachment.contentId);
							const cidRegexp = new RegExp(
								`cid:${safeContentId}(@[^'"]+)?`,
								"gi",
							);
							htmlValue = htmlValue.replace(
								cidRegexp,
								attachment.s3AttachmentUrl,
							);
						});
					}
				}
				return (
					<FormElement
						typeCode={TYP_RICHTEXT}
						value={{ html: htmlValue || field.value.text || field.value }}
						readOnly
						toolBar={[]}
						disable
						inline
					/>
				);
			}
			case "TYP_DATE_TIME":
				return moment(field.value)
					.tz(userInfo.locale.timeZone)
					.format(userInfo.locale.dateTimeFormat || "YYYY-MM-DD HH:mm:ss");
			case "TYP_DATE":
				return moment(field.value)
					.tz(userInfo.locale.timeZone)
					.format(userInfo.locale.dateTimeFormat.split(" ")[0] || "YYYY-MM-DD");
			case "TYP_CHECKBOX":
				return <Checkbox checked={!!field.value} />;
			case "TYP_MULTI_SELECT": {
				const str = [];
				if (field.value && field.value.length) {
					field.value.forEach(v => {
						str.push(v);
					});
					const sorted = orderBy(str, [opt => opt.toLowerCase()], "asc");
					return sorted.join(", ");
				}
				return "-";
			}
			default:
				return field.label || field.value;
		}
	};

	const cancelActionRequest = async () => {
		try {
			const {
				data: { transactionsLog },
			} = await postRequest(`api/v2/ai/bot-conversation`, {
				userSubmittedDetails: {
					logId: msg.id || msg._id,
				},
				contentType: "ACTION_REQUEST_CANCEL",
			});
			handleAddLogs(transactionsLog, { ...msg, status: "ARCHIVED" });
		} catch (e) {
			const errMessage = generateErrorMessage(e);
			dispatch(setToastMessage(errMessage));
		}
		setState(st => ({
			...st,
			isLoading: false,
			cardStatus: "ARCHIVED",
		}));
	};
	const handleActionSuggestionLog = async activityDetails => {
		try {
			const {
				data: { transactionsLog },
			} = await postRequest(`api/v2/ai/bot-conversation`, {
				userSubmittedDetails: {
					...activityDetails,
					methodType: "CREATE",
					parentConversationDetails: content.parentConversationDetails,
				},
				contentType: "USER_ACTION_REQUEST",
			});
			handleAddLogs(transactionsLog);
		} catch (e) {
			const errMessage = generateErrorMessage(e);
			dispatch(setToastMessage(errMessage));
		}
	};

	let card = <div />;
	switch (contentType) {
		case "BOT_DUPLICATE_RECORD":
		case "BOT_UNAUTHORIZED":
		case "BOT_SUCCESS":
		case "ACTION_REQUEST_CANCEL":
		case "BOT_RECORD_NOT_FOUND":
		case "BOT_ERROR":
			card = (
				<div className={cx("msg-bubble", `pc-toastCard-${contentType}`)}>
					<div className="bot-success-message">
						<Icon
							category="custom"
							name={`toast-icon-${contentType}`}
							size="medium"
							className="pc-detail-page-header-image"
						/>
						<p>{content.message}</p>
					</div>

					{content && content.url && (
						<Button
							label={content.buttonName}
							iconCategory="utility"
							iconName="chevronright"
							iconSize="x-small"
							iconPosition="right"
							className="view-more-btn"
							variant="link"
							onClick={() => {
								history.push(content.url);
								handleChatBotToggle(false);
							}}
						/>
					)}
				</div>
			);

			break;
		case "BOT_KNOWLEDGE_BASE_RESPONSE":
			card = (
				<div className="msg-bubble">
					<div className="bot-success-message">
						<p
							className={cx(
								contentType === "BOT_KNOWLEDGE_BASE_RESPONSE" &&
									"knowledge-base",
							)}
						>
							{content.message}
						</p>
						{Boolean(content.relatedLinks && content.relatedLinks.length) && (
							<RelatedLinkCard
								key={`related-links-${messageId}`}
								relatedLinks={content.relatedLinks}
							/>
						)}
					</div>
				</div>
			);
			break;
		case "USER_REQUEST":
			card = (
				<div className={cx("msg-bubble", `pc-toastCard-${contentType}`)}>
					<p>
						<Formatizer>{content.message}</Formatizer>
					</p>
				</div>
			);

			break;
		case "BOT_ACTION_SUGGESTION":
			card = (
				<div
					className={cx(
						"msg-bubble action-suggestion",
						`pc-toastCard-${contentType}`,
					)}
				>
					<p className="intro-text">{content.message}</p>
					{Boolean(content && content.fields && content.fields.length) && (
						<div className="action-container">
							<div className="details-container">
								<p className="details-header">
									{content?.moduleName ? capitalize(content.moduleName) : ""}{" "}
									details
								</p>
								<div className="details">
									{Boolean(content.fields && content.fields.length) &&
										content.fields.map(e => {
											return (
												<div className="details-inner">
													<p className="details-label">
														{intl.formatMessage({
															id: e.transKey || e.customName,
														})}
													</p>
													<p className="details-value" title={getFieldValue(e)}>
														{getFieldValue(e)}
													</p>
												</div>
											);
										})}
								</div>
							</div>
							<div className="update-action">
								<p className="update-header">
									Choose an option to update the record
								</p>
								{suggestionActionCard.map(each => (
									<Button
										onClick={() => handleActionSuggestionLog(each)}
										disabled={isLoading}
										className="action-buttons"
									>
										{each.title}
									</Button>
								))}
							</div>
						</div>
					)}
				</div>
			);
			break;
		case "BOT_ACTION_CHOICE":
			card = (
				<div className={cx("msg-bubble ", `pc-toastCard-${contentType}`)}>
					<p>{content.message}</p>
					<div className="bot-select-box">
						<div className="bot-form-block">
							{Boolean(content.requestField && content.requestField.length) && (
								<p className="bot-form-head">Details</p>
							)}
							{Boolean(content.requestField && content.requestField.length) &&
								content.requestField.map(eachField => {
									return (
										<FormField
											handleChange={handleChange}
											handleBlur={handleBlur}
											// skipParentCheck={fld.skipParentCheck}
											userInfo={userInfo}
											field={{
												...eachField,
												addProps: {
													inline: eachField.typeCode !== "TYP_RICHTEXT",
												},
												transKey: intl.formatMessage({
													id: eachField.transKey || eachField.customName,
												}),
												params: eachField.params ? eachField.params : [],
											}}
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											valueObj={valueObj}
											labelObj={labelObj}
											errorObj={errorObj}
											dispatch={dispatch}
											fetchParentValue={fetchParentValue}
										/>
									);
								})}
							{content.choices.length > 1 && <p>Records</p>}
							{content.choices.map(c => (
								<div className="choice-container">
									<div className="radio-box">
										<Radio
											id={`${id}-${c.value}`}
											onChange={() => {
												setState(st => ({ ...st, choice: c.value }));
											}}
											checked={state.choice === c.value}
										/>
									</div>
									<div className="radio-content">
										<div
											className="pc-text-center table-record-avatar"
											role="button"
											tabIndex="0"
										>
											<RecordAvatar variant="entity" title="AB" size="medium" />
										</div>
										<div>
											<p>{c.title}</p>
											<p>{c.description}</p>
										</div>
									</div>
								</div>
							))}
							<div className="button-block">
								<Button
									className="cancel-button"
									disabled={isLoading}
									onClick={cancelActionRequest}
								>
									Cancel
								</Button>
								<Button
									disabled={isLoading || !state.choice}
									onClick={() => handleSubmitData()}
								>
									Submit
								</Button>
							</div>
						</div>
					</div>
				</div>
			);
			break;
		case "BOT_ACTION_REQUEST":
		case "USER_ACTION_REQUEST":
			card = (
				<div className={cx("msg-bubble", `pc-toastCard-${contentType}`)}>
					<p className="intro-text">{content.message} </p>
					{Boolean(content.requestField && content.requestField.length) && (
						<div className="bot-form-block">
							<p className="bot-form-head">Details</p>
							{content.requestField.map(eachField => {
								return (
									<FormField
										handleChange={handleChange}
										handleBlur={handleBlur}
										// skipParentCheck={fld.skipParentCheck}
										userInfo={userInfo}
										field={{
											...eachField,
											addProps: {
												inline: eachField.typeCode !== "TYP_RICHTEXT",
												moduleId: content.actionDetails.moduleId,
											},
											transKey: intl.formatMessage({
												id: eachField.transKey || eachField.customName,
											}),
											params: eachField.params ? eachField.params : [],
										}}
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										valueObj={valueObj}
										labelObj={labelObj}
										errorObj={errorObj}
										dispatch={dispatch}
										fetchDependentOptions={fetchDependentOptions(
											eachField.params ? eachField.params : [],
										)}
										fetchParentValue={fetchParentValue}
									/>
								);
							})}
							<div className="button-footer">
								<Button
									disabled={isLoading}
									className="cancel-button"
									onClick={cancelActionRequest}
								>
									Cancel
								</Button>
								<Button onClick={() => handleSubmitData()} disabled={isLoading}>
									Submit
								</Button>
							</div>
						</div>
					)}
				</div>
			);
			break;
		default:
			card = <div />;
			break;
	}

	return card;
};

MessageCard.propTypes = {
	handleAddLogs: PropTypes.func,
	msg: PropTypes.object,
	handleChatBotToggle: PropTypes.func,
	userInfo: PropTypes.object,
};

export const MessageContainer = props => {
	const { userInfo, messageUser, createdAt, children } = props;

	return (
		<div className={cx("msg", messageUser ? "right-msg" : "left-msg")}>
			<div className="avatar-block">
				<RecordAvatar
					title={cx(userInfo.firstName, userInfo.lastName)}
					size="small"
					imageUrl={userInfo.avatar}
					variant="user"
				/>
				<Icon name="pc-glare-white" category="custom" />
			</div>
			{children}
			<p className="chat-time">
				{getRelativeTimeFormat(moment(createdAt).tz(userInfo.locale.timeZone))}
			</p>
		</div>
	);
};

MessageContainer.propTypes = {
	userInfo: PropTypes.object,
	messageUser: PropTypes.string,
	createdAt: PropTypes.string,
	children: PropTypes.node,
};
