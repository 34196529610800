// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
/**
 * ? ****************** Note ********************
 * If react-select used in a popover,
 * then "props.inline" should be "true"
 */

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import orderBy from "lodash/orderBy";

import {
	themes,
	dropdownStyles,
	inlineDropdownStyles,
	ClearIndicator,
	DropdownIndicator,
} from "@components/TinyComponents/Dropdown";

class SelectInput extends React.PureComponent {
	handleChange = event => {
		const label = (event && event.label) || "";
		const value = (event && event.value) || "";
		const isNew = (event && event.__isNew__) || false;
		const optData = (event && event.data) || {};

		const record = {
			label,
			value,
			isNew,
			fieldId: this.props.fieldId,
			fieldName: this.props.fieldName,
			sectionId: this.props.sectionId,
			typeCode: this.props.typeCode,
			isRequired: this.props.isRequired,
			data: optData,
		};

		this.props.handleChange(record);
	};

	handleBlur = () => {
		const record = {
			fieldId: this.props.fieldId,
			fieldName: this.props.fieldName,
			sectionId: this.props.sectionId,
			typeCode: this.props.typeCode,
			value: this.props.value,
			isRequired: this.props.isRequired,
		};
		if (this.props && this.props.handleBlur) this.props.handleBlur(record);
	};

	render() {
		const { props } = this;

		const dropDownStyles = props.inline ? inlineDropdownStyles : dropdownStyles;
		let Sortedoptions;
		let value = "";
		props.options.forEach(option => {
			if (option.options) {
				const childOptions = option.options;
				const opt = childOptions.find(o => o.value === props.value);
				if (opt) value = opt;
			}
			if (option.value === props.value) value = option;
		});

		props.options.forEach(option => {
			// Check for the Stage sorting
			if (option.label.props) {
				Sortedoptions = orderBy(
					props.options,
					[opt => opt.label.props.children.toLowerCase()],
					["asc"],
				);
			}
		});
		if (props.isCreatable) {
			return (
				<div>
					<div className="pc-form-field-with-tooltip">
						<CreatableSelect
							components={{
								ClearIndicator,
								DropdownIndicator,
								...props.components,
							}}
							isClearable={props.isClearable}
							isDisabled={props.disable}
							options={props.options}
							value={value}
							onChange={this.handleChange}
							sectionId={props.sectionId}
							menuShouldBlockScroll={!props.inline}
							menuPosition="fixed"
							menuPlacement="auto"
							menuPortalTarget={!props.inline && document.body}
							className={cx(
								props.className,
								"pc-modal-select-box",
								props.disable && "pc-modal-select-box-disabled",
							)}
							theme={themes}
							styles={props.dropdownStyles || dropdownStyles}
							placeholder={props.placeholder}
						/>
					</div>
				</div>
			);
		}

		return (
			<div>
				<div className="pc-form-field-with-tooltip">
					<Select
						id={props.fieldId}
						isClearable={props.isClearable}
						isDisabled={props.disable}
						options={Sortedoptions || props.options}
						components={{
							ClearIndicator,
							DropdownIndicator,
							...props.components,
						}}
						value={value}
						placeholder={props.placeholder}
						onChange={this.handleChange}
						menuShouldBlockScroll={!props.inline}
						menuPosition={props.menuPosition || "absolute"}
						menuPlacement={props.menuPlacement}
						menuPortalTarget={!props.inline && document.body}
						sectionid={props.sectionId}
						className={cx(
							props.className,
							"pc-modal-select-box",
							props.disable && "pc-modal-select-box-disabled",
						)}
						theme={themes}
						styles={props.dropdownStyles || dropDownStyles}
						isOptionDisabled={props.isOptionDisabled}
						onMenuOpen={props.onMenuOpen}
						onBlur={this.handleBlur}
					/>
				</div>
			</div>
		);
	}
}

SelectInput.propTypes = {
	fieldId: PropTypes.string,
	sectionId: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleChange: PropTypes.func,
	handleBlur: PropTypes.func,
	fieldName: PropTypes.string,
	components: PropTypes.object,
	menuPlacement: PropTypes.string,
	placeholder: PropTypes.string,
};

SelectInput.defaultProps = {
	components: {},
	menuPlacement: "auto",
};

export default SelectInput;
