import * as React from "react";
import "./style.scss";
import Button from "@salesforce/design-system-react/components/button";
import { getRequest } from "@utils/request";
import Icon from "@salesforce/design-system-react/components/icon";

const MaintenanceBar: React.FC = (): JSX.Element => {
	const [state, setState] = React.useState<{
		isMaintenanceBarOpen: boolean;
		banner: {
			container: {
				backgroundColor: string;
			};
			tag: {
				color: string;
				text: string;
				backgroundColor: string;
			};
			content: {
				text: string;
				color: string;
			};
		} | null;
	}>({
		isMaintenanceBarOpen: true,
		banner: null,
	});

	const [open, setOpen] = React.useState<boolean>(true);

	React.useEffect(() => {
		const appElement = document.getElementById("routesContainer");
		const globalSearch = document.getElementById("globalSearch");
		const maintenanceBar = document.getElementById("maintinancebar");
		const aircallPopup = document.getElementById("aircallPopup");
		const maintenanceBarHeight = maintenanceBar?.clientHeight;
		const messageContainer = document.getElementById("messageContainer");
		if (appElement) {
			if (open) {
				appElement.style.height = `calc(100vh - (${maintenanceBarHeight}px + 60px))`;
			} else {
				appElement.style.height = "calc(100vh - 61px)";
			}
		}
		if (globalSearch) {
			if (open) {
				globalSearch.style.top = `90px`;
			} else {
				globalSearch.style.top = "61px";
			}
		}
		if (aircallPopup) {
			if (open) {
				aircallPopup.style.top = `90px`;
			} else {
				aircallPopup.style.top = "61px";
			}
		}
		if (messageContainer) {
			if (open) {
				messageContainer.style.gridTemplateRows = "8vh auto";
			} else {
				messageContainer.style.gridTemplateRows = "8vh auto";
			}
		}
	}, [state, open]);

	const getBanner = async (): Promise<void> => {
		const {
			data: {
				data: { banner },
			},
		} = await getRequest("/api/v2/banner?type=ALERT");

		if (banner) {
			setState({ isMaintenanceBarOpen: true, banner });
		}
	};

	React.useEffect(() => {
		getBanner();
	}, []);

	return (
		<React.Fragment>
			<div
				className={`maintinancebar ${!open && "remove"}`}
				id="maintinancebar"
				style={{ background: state.banner?.container.backgroundColor }}
			>
				<div className="alertBannercontainer">
					{/* <div
						className="alertBannerTag"
						style={{
							background: state.banner?.tag.backgroundColor,
							color: state.banner?.tag.color,
						}}
					>
						{state.banner?.tag.text}
					</div> */}
					<Icon
						className="pc-alert-warning"
						name="pc-alert-warning"
						category="custom"
						size="x-small"
					/>
					<p
						className="alertBannerText"
						style={{ color: state.banner?.content.color }}
					>
						Live Webinar: An insider access to powerful AI + WhatsApp CRM
						features -{" "}
						<a
							href="https://us06web.zoom.us/webinar/register/1717234499108/WN_LkU345rTRwu7mTWZAnrCGw"
							target="_blank"
							rel="noreferrer"
						>
							Register Now
						</a>
					</p>
					{/* {state.banner?.content.text} */}
				</div>
				<div className="closeIcon">
					<Button
						iconCategory="custom"
						iconName="crossWhite"
						variant="base"
						title="Close"
						className="crossWhite"
						onClick={(): void => {
							setState(st => ({
								...st,
								isMaintenanceBarOpen: false,
							}));
							setOpen(false);
						}}
						iconSize="medium"
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

export default MaintenanceBar;
