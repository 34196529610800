import React, { useState } from "react";
import Popover from "@salesforce/design-system-react/components/popover";
import Button from "@salesforce/design-system-react/components/button";
import Picker from "emoji-picker-react";
import PropTypes from "prop-types";

function EmojiPicker({
	onEmojiClick,
	iconName = "pc-emoji-picker",
	isDisabled = false,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const handleToggle = () => {
		setIsOpen(!isOpen);
	};
	const handleRequestClose = () => {
		setIsOpen(false);
	};
	return (
		<Popover
			className="pc-message-emoji-popover"
			isOpen={isOpen}
			onClose={handleRequestClose}
			onRequestClose={handleRequestClose}
			body={<Picker onEmojiClick={onEmojiClick} />}
			align="right"
		>
			<Button
				variant="base"
				iconName={iconName}
				iconCategory="custom"
				onClick={handleToggle}
				className="slds-button slds-button_icon template-btn"
				id="notification-dropdown"
				disabled={isDisabled}
			/>
		</Popover>
	);
}
EmojiPicker.propTypes = {
	onEmojiClick: PropTypes.func,
	iconName: PropTypes.string,
	isDisabled: PropTypes.bool,
};
export default EmojiPicker;
