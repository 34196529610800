import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Icon from "@salesforce/design-system-react/components/icon";
import Popover from "@salesforce/design-system-react/components/popover";

import RecordAvatar from "@components/TinyComponents/RecordAvatar";
import { setLastActiveRoute } from "@utils/common";

const UserSettingsPopover = props => {
	const [state, setState] = React.useState({
		isOpen: false,
	});
	const { userInfo } = props;
	const history = useHistory();

	const handleToggle = () => {
		props.closeNav();
		setState(st => ({ ...st, isOpen: !state.isOpen }));
	};

	const navigate = url => {
		history.push(url);
		setState(st => ({ ...st, isOpen: false }));
		setLastActiveRoute("Setting", url);
	};

	const handleRequestClose = () => {
		setState(st => ({ ...st, isOpen: false }));
	};

	const { isOpen } = state;
	const { name, email, avatar } = userInfo;

	return (
		<li className="pc-navigation-bar-dropdown-list pc-navigation-profile">
			<Popover
				isOpen={isOpen}
				onClose={handleRequestClose}
				onRequestClose={handleRequestClose}
				triggerClassName="pc-navigation-profile-popover"
				body={
					<div className="pc-header-popover-body">
						<div className="pc-header-popover-heading-details">
							<div
								className="pc-header-popover-heading-details-name"
								title={name}
							>
								{name}
							</div>
							<div className="pc-header-popover-heading-details-email">
								{email}
							</div>
						</div>
						<div
							id="to-user"
							className="pc-header-popover-body-menu"
							onClick={() => {
								navigate("/setting/user/profile");
							}}
							onKeyPress={() => {
								navigate("/setting/user/profile");
							}}
							role="button"
							tabIndex="0"
						>
							<Icon
								category="custom"
								name="pc-user-setting-icon"
								size="small"
							/>{" "}
							<span className="pc-header-popover-body-menu-list">
								User settings
							</span>
						</div>
						<div
							id="log-out"
							className="pc-header-popover-body-menu"
							onClick={props.logout}
							onKeyPress={props.logout}
							role="button"
							tabIndex="0"
						>
							<Icon category="custom" name="pc-logout-icon" size="small" />
							<span className="pc-header-popover-body-menu-list">Logout</span>
						</div>
					</div>
				}
				align="bottom right"
				className="pc-header-popover"
			>
				<div
					className="pc-header-popover-heading"
					onClick={handleToggle}
					onKeyPress={handleToggle}
					role="button"
					tabIndex="0"
				>
					<div className="pc-header-popover-heading-image">
						<RecordAvatar
							title={name}
							variant="user"
							imageUrl={avatar}
							size="medium"
						/>
					</div>
					<div className="pc-header-popover-heading-arrow">
						<Icon name="chevrondown" category="utility" size="xx-small" />
					</div>
				</div>
			</Popover>
		</li>
	);
};

UserSettingsPopover.propTypes = {
	userInfo: PropTypes.object,
	logout: PropTypes.func,
	closeNav: PropTypes.func,
};

export default UserSettingsPopover;
