import React from "react";
import { useIntl } from "react-intl";

import noDataImg from "@assets/img/no-record.svg";

import "./styles/page-not-found.scss";

const RecordNotFound = () => {
	const intl = useIntl();

	return (
		<div className="page-not-found">
			<div className="no-record-found-cnt">
				<div className="no-record-img-content">
					<div className="pc-no-record-found-img slds-p-top_large">
						<img src={noDataImg} alt="No Data Found Img" width="300" />
					</div>
					<div>
						<p className="txt">
							{intl.formatMessage({
								id: "table.noDetailsRecord",
							})}
						</p>
						<p className="txt-content">
							The specific record you are trying to access has been deleted and
							is no longer available.
						</p>
					</div>
				</div>
				<br />
				<br />
				<a
					href="/"
					className="slds-button slds-button_neutral pc-modal-primary-btn"
				>
					{intl.formatMessage({
						id: "internalServerError.back.home",
					})}
				</a>

				<br />
				<br />
			</div>
		</div>
	);
};

export default RecordNotFound;
