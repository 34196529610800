import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

// Animation Icons
import { useIntl } from "react-intl";
import Popover from "@salesforce/design-system-react/components/popover";
import Button from "@salesforce/design-system-react/components/button";
import Card from "@components/Card";
import { setLastActiveRoute } from "@utils/common";
import GeneralIcon from "../../assets/lottie-animation/General.json";
import AutomationIcon from "../../assets/lottie-animation/Automation.json";
import UsersIcon from "../../assets/lottie-animation/Manage Users.json";
import CustomizationIcon from "../../assets/lottie-animation/Customisation.json";
import IntegrationIcon from "../../assets/lottie-animation/Integration.json";
import SecurityIcon from "../../assets/lottie-animation/Security.json";
import CustomTooltip from "@components/customTooltip";

const cards = [
	{
		id: 0,
		body: {
			title: "Manage users",
			animatedIcon: UsersIcon,
		},
		url: "/settings/controls",
	},
	{
		id: 1,
		body: {
			title: "Customisation",
			animatedIcon: CustomizationIcon,
		},
		url: "/settings/customization",
	},
	{
		id: 2,
		body: {
			title: "Security",
			animatedIcon: SecurityIcon,
		},
		url: "/settings/security",
	},
	{
		id: 3,
		body: {
			title: "Automation",
			animatedIcon: AutomationIcon,
		},
		url: "/settings/automation",
	},
	{
		id: 4,
		body: {
			title: "Integration",
			animatedIcon: IntegrationIcon,
		},
		url: "/settings/integration",
	},
	{
		id: 5,
		body: {
			title: "General",
			animatedIcon: GeneralIcon,
		},
		url: "/settings/general",
	},
];

const SettingsPopover = props => {
	const [state, setState] = React.useState({ isOpen: false });
	const history = useHistory();
	const intl = useIntl();

	const handleToggle = () => {
		props.closeNav();
		setState({ isOpen: !state.isOpen });
	};

	const navigate = url => {
		history.push(url);
		setState({ isOpen: false });
		setLastActiveRoute("Home", url);
	};

	const handleRequestClose = () => {
		setState({ isOpen: false });
	};

	const { isOpen } = state;
	const { userInfo } = props;
	return (
		<li className="pc-navigation-bar-dropdown-list pc-settings-popover">
			<Popover
				isOpen={isOpen}
				onClose={handleRequestClose}
				onRequestClose={handleRequestClose}
				body={
					<div className="pc-header-popover-body pc-settings-popover-card-body">
						<div className="slds-grid slds-wrap">
							{cards.map(card => (
								<Link
									key={card.id}
									to={card.url}
									className="slds-col slds-small-size_4-of-12 slds-medium-size_4-of-12 slds-large-size_4-of-12"
									onClick={() => {
										navigate(card.url);
									}}
								>
									<div className="pc-settings-popover-card-link">
										<Card
											type="center"
											className="pc-component-card-type-center no-footer no-header"
											{...card}
										/>
									</div>
								</Link>
							))}
						</div>
					</div>
				}
				heading={
					<div className="pc-header-popover-heading">
						<div className="pc-header-popover-heading-details">
							<div
								className="pc-header-popover-heading-details-name"
								title={userInfo.companyName}
							>
								{userInfo.companyName}
							</div>
							<div className="slds-grid">
								<div className="slds-col">
									<div className="pc-header-popover-heading-details-txt">
										<p className="placeholder">Website</p>
										{userInfo.organization.website}
									</div>
								</div>
								<div className="slds-col">
									<div className="pc-header-popover-heading-details-txt">
										<p className="placeholder">
											{intl.formatMessage({
												id: "phone.number",
											})}
										</p>
										{userInfo.organization.phone}
									</div>
								</div>
							</div>
						</div>
					</div>
				}
				align="bottom right"
				className="pc-header-popover"
			>
				<CustomTooltip desc="Settings">
					<Button
						variant="base"
						iconName="pc-cogwheel-white"
						iconCategory="custom"
						iconVariant="border-filled"
						onClick={handleToggle}
						className="pc-navigation-bar-dropdown-btn-icon"
					/>
				</CustomTooltip>
			</Popover>
		</li>
	);
};

SettingsPopover.propTypes = {
	userInfo: PropTypes.object,
	closeNav: PropTypes.func,
	intl: PropTypes.object,
};

export default SettingsPopover;
