import React from "react";
import { useIntl } from "react-intl";

import image from "@assets/img/404-error-img.svg";

import "./styles/page-not-found.scss";

function PageNotFound() {
	const intl = useIntl();

	return (
		<div className="page-not-found">
			<div className="slds-grid slds-wrap slds-gutters">
				<div className="slds-col slds-small-size_12-of-12 slds-medium-size_6-of-12 slds-size_5-of-12 slds-p-around_none">
					<div className="page-not-found-cnt">
						<h1>
							{intl.formatMessage({
								id: "internalServerError.lost",
							})}
						</h1>
						<h2>
							{intl.formatMessage({
								id: "internalServerError.lost.once",
							})}
						</h2>
						<br />

						<br />
						<a href="/" className="btn btn-dark back-to-home-btn">
							{intl.formatMessage({
								id: "internalServerError.back.to.home",
							})}
						</a>
					</div>
				</div>
				<div className="slds-col slds-small-size_12-of-12 slds-medium-size_6-of-12 slds-size_7-of-12 slds-p-around_none">
					<div className="error-img-wrapper not-found">
						<img src={image} alt="Not Found" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default PageNotFound;
