import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import qs from "qs";
import cx from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";

import AllPrompt from "@components/ChatBot/AllPrompt";
import {
	ChooseOptionsCard,
	MessageCard,
	MessageContainer,
	TypingConversationCard,
} from "@components/ChatBot/helper";
import { setToastMessage } from "@containers/App/actions";
import LottieIcon from "@components/LottieIcon";
import Loader from "@components/Loader";
import { TYP_MULTI_SELECT } from "@components/FormElement/ElementTypes";
import InfoModal from "@components/Modal/InfoModal";

import { postRequest, getRequest } from "@utils/request";
import { NOOP } from "@utils/common";
import generateErrorMessage from "@services/generateErrorMessage";
import useUser from "@hooks/useUser";

import Glare from "../../assets/lottie-animation/Glare.json";
import MultiSelectInput from "./FilterElements/MultiSelectInput";

function ChatBody({ handleChatBotToggle, active, knowledgebase }) {
	const scrollableRef = useRef(null);
	const textareaRef = useRef(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [page, setPage] = useState(0);
	const dispatch = useDispatch();
	const history = useHistory();

	const userInfo = useUser();

	const [state, setState] = useState({
		messages: [],
		loading: true,
		hasMore: false,
		input: "",
		processing: false,
		showPromptModal: false,
		groups: [],
		selectedGroups: [],
		showInfoModal: false,
		newAIResponseId: null,
	});

	const { messages, input, processing, showPromptModal } = state;
	const togglePromptModal = () => {
		setState(st => ({
			...st,
			showPromptModal: !showPromptModal,
		}));
	};

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	const scrollToBottom = () => {
		if (scrollableRef.current) {
			scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
		}
	};

	const getGroups = async () => {
		const { data } = await getRequest("/api/v2/ai/knowledge-base/groups");

		const sOptions = [];
		const allOptions = [];
		let defaultOption;
		data.forEach(o => {
			allOptions.push({ value: o.id, label: o.name });

			if (!o.isPublic) {
				sOptions.push({ value: o.id, label: o.name });
			} else {
				defaultOption = o;
			}
		});

		if (!sOptions.length && defaultOption) {
			sOptions.push({ value: defaultOption.id, label: defaultOption.name });
		}

		setState(st => ({ ...st, groups: allOptions, selectedGroups: sOptions }));
	};

	const handleGetBotConv = async () => {
		setState(st => ({
			...st,
			loading: true,
			messages: st.messages || [],
		}));
		try {
			const {
				data: {
					data: { result, hasMore },
				},
			} = await getRequest(
				`/api/v2/bot-conversation?${qs.stringify({
					$page: page,
					chatType: knowledgebase && "KNOWLEDGE_BASE",
				})}`,
			);
			setPage(page + 1);
			setState(st => ({
				...st,
				messages: result?.length
					? [...result, ...st.messages]
					: [...st.messages],
				loading: false,
				hasMore,
			}));
			if (page === 0) {
				scrollToBottom();
			}
		} catch (err) {
			const errMessage = generateErrorMessage(err);
			dispatch(setToastMessage(errMessage));
		}
	};

	useEffect(() => {
		if (knowledgebase) {
			getGroups();
		}
		// Attach the scroll event listener
		window.addEventListener("scroll", handleScroll);
		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		// Check if the scroll position is at the top
		if (scrollPosition === 0) {
			if (state.hasMore || page === 0) {
				handleGetBotConv();
			}
		}
	}, [scrollPosition]);

	const handleSearch = async () => {
		if (!input.trim().length) return;
		const prompt = input;
		// setProcessing(true);
		setState(st => ({
			...st,
			input: "",
			processing: true,
			messages: [
				...st.messages,
				...[
					{
						botConversationId: "",
						contentType: "USER_REQUEST",
						content: {
							message: prompt,
						},
						user: userInfo.id,
						org: userInfo.org,
					},
				],
			],
		}));
		if (textareaRef.current) {
			const textarea = textareaRef.current;
			textarea.style.height = "0";
		}
		try {
			setTimeout(() => {
				scrollToBottom();
			}, 100);
			const {
				data: { transactionsLog },
			} = await postRequest(`api/v2/ai/bot-conversation`, {
				input: prompt,
				contentType: "USER_REQUEST",
				isKnowledgebase: Boolean(knowledgebase),
				groups: state.selectedGroups.map(g => g.value),
			});
			setState(st => ({
				...st,
				messages: [...st.messages, ...transactionsLog],
				newAIResponseId:
					transactionsLog.find(
						l => l.contentType === "BOT_KNOWLEDGE_BASE_RESPONSE",
					)?.id ?? null,
				processing: false,
			}));
			setTimeout(() => {
				scrollToBottom();
			}, 100);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
			setState(st => ({
				...st,
				processing: false,
			}));
		}
	};

	const handleCommandChange = value => {
		setState(st => ({
			...st,
			showPromptModal: false,
			input: value,
		}));
		setTimeout(() => {
			scrollToBottom();
		}, 100);
	};

	const handleKeyDown = e => {
		const keyCode = e.which || e.keyCode;
		if (
			!processing &&
			keyCode === 13 &&
			!(e.shiftKey || e.altKey || e.ctrlKey)
		) {
			e.preventDefault();
			handleSearch();
		} else if (keyCode === 13 && (e.altKey || e.ctrlKey)) {
			e.preventDefault(); // Prevent the default behavior
			const newLine = "\n";

			const cursorPosition = e.target ? e.target.selectionStart : 0;
			let newMessage = e.target.value;
			if (cursorPosition !== null && cursorPosition !== undefined) {
				newMessage = `${e.target.value.slice(
					0,
					cursorPosition,
				)}${newLine}${e.target.value.slice(cursorPosition)}`;
			}
			handleCommandChange(newMessage);
			setTimeout(() => {
				if (cursorPosition >= 0) {
					e.target.selectionStart = cursorPosition + 1;
					e.target.selectionEnd = cursorPosition + 1;
				}
			}, 0);
		}
	};
	const handleAddLogs = (newLog, updateLog, index) => {
		setState(prev => {
			const newArray = [...prev.messages];
			if (updateLog) newArray[index] = updateLog;
			return {
				...prev,
				messages: [...newArray, ...newLog],
			};
		});
		setTimeout(() => {
			scrollToBottom();
		}, 100);
	};

	const [isActive, setIsActive] = useState(false);

	const handleTextAreaClick = () => {
		setIsActive(true);
	};

	const handleTextAreaBlur = () => {
		setIsActive(false);
	};

	const handleGroupChange = v => {
		setState(st => ({ ...st, selectedGroups: v.value }));
	};

	const naviagteToKnowledgeBase = () => {
		const { userType } = userInfo;

		if (userType === "USER") {
			setState(st => ({ ...st, showInfoModal: true }));
			return;
		}

		handleChatBotToggle(false);

		history.push("/settings/automation/knowledge-base");
	};

	const customFilter = (option, searchText) => {
		if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
			return true;
		}

		return false;
	};

	if (showPromptModal) {
		return (
			<AllPrompt
				togglePromptModal={togglePromptModal}
				handleAddPrompt={handleCommandChange}
			/>
		);
	}

	return (
		<div className={cx("message-body", active && "active")}>
			<main
				className={cx(
					"messenger-chat",
					knowledgebase && "knowledge-base-active",
				)}
				ref={scrollableRef}
			>
				{knowledgebase ? (
					<div className="openai-card-container">
						<div
							role="button"
							onKeyDown={NOOP}
							tabIndex={-1}
							className="openai-card"
							onClick={() => {
								window.open(
									"http://blog.peppercloud.com/assistai-knowledge-base-user-guide/",
									"_blank",
								);
							}}
						>
							<div className="icon-block">
								<Icon size="large" name="pc-knowledge-base" category="custom" />
							</div>
							<div className="slds-grid slds-grid_vertical slds-has-flexi-truncate medium-device">
								<div className="title">Welcome to AssistAI knowledge base</div>
								<p className="description">
									Get instant answers to product queries.
									<a
										target="_blank"
										href="http://blog.peppercloud.com/assistai-knowledge-base-user-guide/"
									>
										Know more
									</a>
								</p>
							</div>
						</div>
						<div
							className="openai-card"
							role="button"
							onClick={naviagteToKnowledgeBase}
							onKeyDown={NOOP}
							tabIndex={-1}
						>
							<div className="icon-block">
								<Icon
									size="large"
									name="pc-update-knowledge-base"
									category="custom"
								/>
							</div>
							<div className="slds-grid slds-grid_vertical slds-has-flexi-truncate medium-device">
								<div className="title bold">Update AssistAI knowledge base</div>
								<p className="description">
									Edit and update information in knowledge base. Go to
									<a href={() => false}>Knowledge base</a>
								</p>
							</div>
						</div>
					</div>
				) : (
					<div className="openai-card-container">
						<div
							className="openai-card"
							role="button"
							onKeyDown={NOOP}
							tabIndex={-1}
							onClick={() => {
								window.open(
									"http://blog.peppercloud.com/assistai-panel-user-guide/",
									"_blank",
								);
							}}
						>
							<div className="icon-block">
								<Icon size="large" name="pc-bulb" category="custom" />
							</div>
							<div className="slds-grid slds-grid_vertical slds-has-flexi-truncate medium-device">
								<div className="title">Welcome to AssistAI!</div>
								<p className="description">
									Start using AssistAI right away.{" "}
									<a href={() => false}>Know more</a>
								</p>
							</div>
						</div>
						<div
							className="openai-card"
							role="button"
							onKeyDown={NOOP}
							tabIndex={-1}
							onClick={togglePromptModal}
						>
							<div className="icon-block">
								<Icon
									size="large"
									name="pc-openai-prompt-black"
									category="custom"
								/>
							</div>
							<div className="slds-grid slds-grid_vertical slds-has-flexi-truncate medium-device">
								<div className="title bold">Prompts</div>
								<p className="description">
									Try out suggested prompts from our collection.{" "}
									<a onClick={togglePromptModal} href={() => false}>
										View prompts
									</a>
								</p>
							</div>
						</div>
					</div>
				)}

				<div className="chat-bot-chat-side">
					<div className="bot-chat-avatar">
						<Icon name="pc-glare-white" category="custom" />
					</div>
					<div className="bot-send-message">
						{!knowledgebase ? (
							<p>
								Hey, I am here to help you. Here are a few things you can ask me
								to do.
							</p>
						) : (
							<p>
								Hey, I am here to help you. Ask me a question and I&apos;ll
								answer.
							</p>
						)}
					</div>
				</div>
				{!knowledgebase && (
					<div className="chat-bot-chat-side">
						<div className="bot-chat-avatar">
							<Icon name="pc-glare-white" category="custom" />
						</div>
						<div className="bot-send-message">
							<ChooseOptionsCard handleAddPrompt={handleCommandChange} />
						</div>
					</div>
				)}

				{state.hasMore && (
					<div className="pc-text-center pc-load-more-btn">
						<Button
							variant="base"
							disabled={state.loading}
							onClick={() => handleGetBotConv()}
						>
							Load more
						</Button>
					</div>
				)}
				<br />
				<Loader loading={state.loading} />
				{messages.map((each, index) => {
					if (!each || each.status === "ARCHIVED") return null;

					if (each.id && each.id === state.newAIResponseId) {
						return (
							<MessageContainer
								userInfo={userInfo}
								createdAt={each.createdAt}
								messageUser={each.user}
							>
								<TypingConversationCard
									message={each}
									clearTypingConversation={() => {
										setState(st => ({ ...st, newAIResponseId: null }));
									}}
									scrollToBottom={scrollToBottom}
								/>
							</MessageContainer>
						);
					}

					return (
						<MessageContainer
							userInfo={userInfo}
							createdAt={each.createdAt}
							messageUser={each.user}
						>
							<MessageCard
								msg={each}
								handleAddLogs={(newLog, updateLog = null) =>
									handleAddLogs(newLog, updateLog, index)
								}
								handleChatBotToggle={handleChatBotToggle}
								userInfo={userInfo}
							/>
						</MessageContainer>
					);
				})}
				{processing ? (
					<div className="processing">
						<span className="thinking-box">
							<LottieIcon
								autoplay
								loop
								animationData={Glare}
								style={{
									height: 20,
									width: 20,
								}}
								hover={false}
								play
								// eslint-disable-next-line react/jsx-boolean-value
							/>
							<p>Thinking....</p>
						</span>
					</div>
				) : null}
			</main>
			<div className="msger-inputarea">
				<div className="messenger-input-inner">
					<div className="messenger-block">
						<span className="assist-content">Ask AssistAI</span>
						{knowledgebase && (
							<div className="knowledge-base-filter-block">
								<div className="knowledgebase-group-select">
									<MultiSelectInput
										field={{
											fieldId: "group",
											fieldName: "Groups",
											transKey: "Group",
											typeCode: TYP_MULTI_SELECT,
											params: state.groups,
										}}
										value={state.selectedGroups}
										handleChange={handleGroupChange}
										filterOption={customFilter}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="messenger-text-block">
						{!knowledgebase && (
							<div className="bot-icon">
								<Button className="prompt-icon-box" onClick={togglePromptModal}>
									<Icon
										size="small"
										name="pc-openai-prompt"
										category="custom"
									/>
								</Button>
							</div>
						)}
						<div
							className={cx("bot-text-box", isActive && "bot-text-box-active")}
						>
							<TextareaAutosize
								minRows={1}
								maxRows={4}
								value={input}
								type="text"
								className="msger-input"
								placeholder="Type here"
								onChange={e => handleCommandChange(e.target.value)}
								onKeyDown={handleKeyDown}
								ref={textareaRef}
								onClick={handleTextAreaClick}
								onBlur={handleTextAreaBlur}
								readOnly={state.newAIResponseId}
							/>
							<Button
								iconCategory="utility"
								iconName="send"
								variant="icon"
								onClick={() => handleSearch()}
								iconSize="large"
								disabled={processing || state.newAIResponseId}
								className="msger-send-btn"
							/>
						</div>
					</div>
				</div>
			</div>
			{state.showInfoModal && (
				<InfoModal
					toggleOpen={() => {
						setState(st => ({ ...st, showInfoModal: false }));
					}}
					title="Contact admin!"
					subText="You do not have access to upload resources in AssistAI knowledge base."
					toggleSubmit={() => {
						setState(st => ({ ...st, showInfoModal: false }));
					}}
					buttonLabel="Ok"
					icon={
						<Icon category="custom" name="pc-alert-circle-bg" size="large" />
					}
				/>
			)}
		</div>
	);
}

ChatBody.propTypes = {
	handleChatBotToggle: PropTypes.func,
	active: PropTypes.bool,
	knowledgebase: PropTypes.bool,
};

export default ChatBody;
