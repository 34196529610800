import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

export class NotificationControl extends React.PureComponent {
	handleChangeSelect = record => {
		const value = {
			interval: record.value || "",
			value: (this.props.value && this.props.value.value) || 0,
		};
		this.props.handleChange({
			typeCode: this.props.typeCode,
			fieldName: this.props.fieldName,
			fieldId: this.props.fieldId,
			isRequired: this.props.isRequired,
			value,
		});
	};

	handleChange = event => {
		const value = {
			interval: this.props.value.interval,
			value: event.value
				? Number.parseFloat(event.value.replace(/,/g, "")) || 0
				: event.value,
		};

		this.props.handleChange({
			typeCode: this.props.typeCode,
			fieldName: this.props.fieldName,
			fieldId: this.props.fieldId,
			isRequired: this.props.isRequired,
			value,
		});
	};

	handleBlur = event => {
		const checkVal = Number.parseFloat(event.value.replace(/,/g, ""));

		const value = {
			interval: (this.props.value && this.props.value.interval) || "",
			value: !Number.isNaN(checkVal) ? checkVal : 0,
		};

		const record = {
			fieldId: this.props.fieldId,
			fieldName: this.props.fieldName,
			sectionId: this.props.sectionId,
			value,
			typeCode: this.props.typeCode,
			isRequired: this.props.isRequired,
		};

		this.props.handleBlur(record);
	};

	render() {
		const inputValue = { value: 0, interval: "" };
		const { options, disable, value } = this.props;
		inputValue.value = value && value.value;
		inputValue.interval = value && value.interval;

		return (
			<div className="pc-notification-control-box-counter">
				<SelectInput
					inline={this.props.inline}
					typeCode="TYP_SELECT"
					options={options}
					handleChange={this.handleChangeSelect}
					handleBlur={this.handleChangeSelect}
					value={inputValue.interval}
					disable={disable}
					className={this.props.className || this.props.customClass} // ! couldnt find where customClass was used. Please advice on removal
					isClearable
				/>
				<div className="pc-notification-control-box-counter-input">
					<TextInput
						value={inputValue.value}
						handleChange={this.handleChange}
						handleBlur={this.handleBlur}
						disable={disable}
						className={this.props.className || this.props.customClass} // ! couldnt find where customClass was used. Please advice on removal
						maxLength={16}
					/>
				</div>
			</div>
		);
	}
}

NotificationControl.propTypes = {
	handleBlur: PropTypes.func,
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	options: PropTypes.array,
	sectionId: PropTypes.string,
	customClass: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.object,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleChange: PropTypes.func,
	disable: PropTypes.bool,
	inline: PropTypes.bool,
};

NotificationControl.defaultProps = { disable: false };

export default injectIntl(NotificationControl);
