/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { IntlProvider } from "react-intl";

import { makeSelectLocale } from "./selectors";

export class LanguageProvider extends React.PureComponent {
	render() {
		return (
			<IntlProvider
				locale={this.props.locale}
				key={this.props.locale}
				messages={this.props.messages[this.props.locale]}
				// OnError is added to remove console warning for missing translations
				onError={err => {
					if (
						// process.env.NODE_ENV === "development" &&
						err.code === "MISSING_TRANSLATION"
					) {
						return null;
					}

					// eslint-disable-next-line no-console
					console.error(err);
					return err;
				}}
			>
				{React.Children.only(this.props.children)}
			</IntlProvider>
		);
	}
}

LanguageProvider.propTypes = {
	locale: PropTypes.string,
	messages: PropTypes.object,
	children: PropTypes.element.isRequired,
};

const mapStateToProps = createSelector(makeSelectLocale(), locale => ({
	locale,
}));

export default connect(mapStateToProps)(LanguageProvider);
