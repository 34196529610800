import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { stringify } from "qs";
import CreatableSelect from "react-select/creatable";

import Checkbox from "@salesforce/design-system-react/components/checkbox";
import Icon from "@salesforce/design-system-react/components/icon";
import Tooltip from "@salesforce/design-system-react/components/tooltip";

import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import { getRequest } from "@utils/request";

import {
	ClearIndicator,
	DropdownIndicator,
	inlineDropdownStyles,
	dropdownStyles,
	Option,
	SingleValue,
	themes,
	MultiValue,
} from "@components/TinyComponents/Dropdown";

import { ACCOUNT_TYPE } from "@utils/constants";
import { TYP_CHECKBOX, TYP_SUGGESTION } from "./ElementTypes";

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

const URL = "/api/v2/params-lookup";

function SuggestionInput(props) {
	const { value, valueLabel, suggestionProps } = props;
	const handleAccountType = personal => {
		const record = {
			value: personal ? ACCOUNT_TYPE.PERSONAL : ACCOUNT_TYPE.BUSINESS,
			fieldId: "F72",
			sectionId: props.sectionId,
			typeCode: TYP_CHECKBOX,
		};

		props.handleChange(record);
	};

	const handleChange = event => {
		const suggProps = {};
		if (props.fieldId === "F61") {
			suggProps.accountType = event?.accountType;
			suggProps.isNew = event?.__isNew__;
		}

		let record;
		/** for Email modal */
		if (props.isMulti) {
			record = {
				label: event ? event.map(e => e.label) : [],
				value: event ? event.map(e => e.value) : [],
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				sectionId: props.sectionId,
				typeCode: TYP_SUGGESTION,
				isRequired: props.isRequired,
				isNew: event && event.__isNew__,
				suggestionProps: suggProps,
			};
		} else {
			const label = (event && event.label) || "";
			const eventValue = (event && event.value) || "";

			record = {
				label,
				value: eventValue,
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				sectionId: props.sectionId,
				typeCode: TYP_SUGGESTION,
				isRequired: props.isRequired,
				isNew: event && event.__isNew__,
				suggestionProps: suggProps,
			};
		}

		props.handleChange(record);
	};

	const handleBlur = () => {
		/**
		 * we are only supporting handleblur for "to" field in email modal currently,
		 *  this might also work for the rest need to check multiple places before removing this check verify in  filters,forms etc..
		 */
		const supportedField = props.fieldId === "to";
		if (supportedField) {
			const record = {
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				sectionId: props.sectionId,
				typeCode: TYP_SUGGESTION,
				value: props.value,
				label: props.valueLabel,
				isRequired: props.isRequired,
			};
			props.handleBlur(record);
		}
	};

	const getFieldId = () => {
		if (!["LM", "CM"].includes(props.moduleId)) {
			return "ALL_EMAIL";
		}
		return props.moduleId === "LM" ? "F7" : "F48";
	};

	const loadOptions = async (search, prevOptions) => {
		const {
			field: { fieldId },
			moduleId,
			bypass,
		} = props;
		const query = { bypass, fieldId, search, skip: prevOptions.length };

		if (fieldId === "F5" || fieldId === "F150") {
			query.moduleId = props.moduleId;
		}
		if (fieldId === "to" || fieldId === "cc") {
			query.fieldId = getFieldId();
			query.moduleId = moduleId;
		}

		return (async () => {
			const {
				data: { data },
			} = await getRequest(`${URL}?${stringify(query)}`);

			if (props.fieldId === "Transfer_To") {
				data.options = data.options.filter(flt => {
					return flt.value.indexOf(props.removeUser) === -1;
				});
			}

			return data;
		})();
	};

	let valueObj = null;
	if (value) {
		if (props.isMulti) {
			valueObj = valueLabel
				? valueLabel.map((l, i) => ({ label: l, value: value[i] }))
				: null;
		} else {
			valueObj = { label: valueLabel, value };
		}
	}
	return props.isCreatable ? (
		<React.Fragment>
			<CreatableAsyncPaginate
				components={{ ClearIndicator, DropdownIndicator, SingleValue, Option }}
				isDisabled={props.disable}
				placeholder={props.placeholder || "Search..."}
				defaultOptions
				isMulti={!!props.isMulti}
				value={valueObj}
				loadOptions={loadOptions}
				debounceTimeout={300}
				onChange={handleChange}
				isClearable={props.isClearable}
				sectionId={props.sectionId}
				menuShouldBlockScroll
				menuPosition="absolute"
				menuPlacement="auto"
				menuPortalTarget={!props.inline && document.body}
				className={cx(
					props.className,
					"pc-modal-select-box",
					props.disable && "pc-modal-select-box-disabled",
				)}
				theme={themes}
				styles={dropdownStyles}
				selectProps={{ ...suggestionProps }}
				id={props.fieldId}
			/>
			{props.showAccountType && suggestionProps.isNew && (
				<React.Fragment>
					<Checkbox
						labels={{
							label: (
								<span>
									Is it a Personal Account?
									<Tooltip
										content="Personal account is an individual account, which is not associated with any company."
										align="bottom"
										hasStaticAlignment
										dialogClassName="account-type-tooltip"
									>
										<div>
											<Icon
												category="custom"
												name="pc-info"
												size="xx-small"
												className="account-type-help-icon"
												style={{ cursor: "pointer" }}
											/>
										</div>
									</Tooltip>
								</span>
							),
						}}
						onChange={({ target: { checked } }) => {
							handleAccountType(checked);
						}}
						checked={props.valueObj.F72 === ACCOUNT_TYPE.PERSONAL}
						className="account-type-checkbox"
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	) : (
		<AsyncPaginate
			components={{
				ClearIndicator,
				DropdownIndicator,
				SingleValue,
				Option,
				MultiValue,
			}}
			isDisabled={props.disable}
			placeholder="Search..."
			defaultOptions
			isMulti={!!props.isMulti}
			value={valueObj}
			loadOptions={loadOptions}
			debounceTimeout={300}
			onChange={handleChange}
			onBlur={handleBlur}
			isClearable={props.isClearable}
			sectionId={props.sectionId}
			menuShouldBlockScroll
			menuPosition="absolute"
			menuPlacement="auto"
			menuPortalTarget={!props.inline && document.body}
			className={cx(
				props.className,
				"pc-modal-select-box",
				props.disable && "pc-modal-select-box-disabled",
			)}
			theme={themes}
			styles={props.inline ? inlineDropdownStyles : dropdownStyles}
			selectProps={{ ...suggestionProps }}
		/>
	);
}

SuggestionInput.propTypes = {
	bypass: PropTypes.bool,
	fieldId: PropTypes.string,
	moduleId: PropTypes.string,
	sectionId: PropTypes.string,
	value: PropTypes.string,
	valueLabel: PropTypes.string,
	disable: PropTypes.bool,
	isRequired: PropTypes.bool,
	isClearable: PropTypes.bool,
	inline: PropTypes.bool,
	className: PropTypes.string,
	handleChange: PropTypes.func,
	fieldName: PropTypes.string,
	field: PropTypes.object,
	isMulti: PropTypes.bool,
	isCreatable: PropTypes.bool,
	handleBlur: PropTypes.func,
	removeUser: PropTypes.string,
	showAccountType: PropTypes.bool,
	suggestionProps: PropTypes.object,
	valueObj: PropTypes.object,
	placeholder: PropTypes.string,
};

SuggestionInput.defaultProps = {
	suggestionProps: {},
};

export default SuggestionInput;
