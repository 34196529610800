const getCookie = key => {
	try {
		const cookieArr = (document.cookie && document.cookie.split(";")) || [];
		const value = cookieArr.find(val => val.trim().startsWith(key));
		if (value) return value.split("=")[1];
	} catch (e) {
		return false;
	}
	return false;
};

const deleteCookies = () => {
	const allCookies = document.cookie.split(";");

	// The "expire" attribute of every cookie is
	// Set to "Thu, 01 Jan 1970 00:00:00 GMT"
	for (let i = 0; i < allCookies.length; i++)
		document.cookie = `${allCookies[i]}=;domain=${
			process.env.DOMAIN
		};expires=${new Date(0).toUTCString()}`;
};

export { getCookie, deleteCookies };
