import React from "react";
import cx from "classnames";

import noDataImg from "@assets/img/no-data-found.svg";

function NoData({
	image,
	message,
	actions = [],
	className = "",
}: {
	image?: JSX.Element;
	message?: JSX.Element | string;
	actions?: JSX.Element[];
	className?: string;
}): JSX.Element {
	return (
		<div className={cx(className, "pc-no-data-found-div")}>
			<div>
				<div className="pc-no-data-found-img slds-p-top_large">
					{image || <img src={noDataImg} alt="No Data Img" width="300" />}
				</div>
				<div className="slds-p-vertical_large txt">{message}</div>
				<div>{actions.map(action => action)}</div>
			</div>
		</div>
	);
}

export default NoData;
