// Generic Actions
export const SENDING_REQUEST =
	"crm-b2b-frontend/GenericCore/Listing/SENDING_REQUEST";
export const REQUEST_ERROR =
	"crm-b2b-frontend/GenericCore/Listing/REQUEST_ERROR";

// Specific actions for screens (Listing, Details etc) like Loading the screen contents and the table data

// Load Screen Content
export const LOAD_SCREEN = "crm-b2b-frontend/GenericCore/Listing/LOAD_SCREEN";
export const LOAD_SCREEN_FILTERS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_SCREEN_FILTERS";

export const LOAD_SCREEN_API = {
	type: "GET",
	url: "/api/v2/screen",
};
export const SCREEN_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/SCREEN_LOADED";

// Load Table Views
export const LOAD_TABLE_VIEWS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_TABLE_VIEWS";
export const LOAD_TABLE_VIEWS_API = {
	type: "POST",
	url: "/api/v2/table-views/:screenId",
};
export const TABLE_VIEWS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/TABLE_VIEWS_LOADED";

// Load Kanban Views
export const LOAD_KANBAN_VIEWS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_KANBAN_VIEWS";
export const LOAD_KANBAN_VIEWS_API = {
	type: "GET",
	url: "/api/v2/kanban-views/:screenId",
};
export const KANBAN_VIEWS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/KANBAN_VIEWS_LOADED";

// For updating the stage of the card in kanban
export const UPDATE_TRANSACTION_PROCESS_API = {
	type: "PATCH",
	url: id => `/api/v2/records/update-pipeline/${id}`,
	bodyParams: {},
};

export const UPDATE_TRANSACTION =
	"crm-b2b-frontend/GenericCore/Kanban/UPDATE_TRANSACTION";
export const TRANSACTIONS_UPDATED =
	"crm-b2b-frontend/GenericCore/Kanban/TRANSACTIONS_UPDATED";

// Visual Type of Transactions
export const KANBAN_VIEW = "KANBAN_VIEW";
export const LISTING_VIEW = "LISTING_VIEW";
export const FORECAST_VIEW = "FORECAST_VIEW";
export const ACCOUNT_AGGREGATE_VIEW = "ACCOUNT_AGGREGATE_VIEW";
export const ACCOUNT_FORECAST_VIEW = "ACCOUNT_FORECAST_VIEW";

export const moduleNamesToID = {
	lead: "LM",
	account: "AM",
	contact: "CM",
	opportunity: "OM",
};

// Action views
export const LISTING = "LISTING";
export const EXPORT = "EXPORT";
export const EXPORT_HISTORY = "EXPORT_HISTORY";

// Intervals
export const MONTHLY = "MONTHLY";
export const QUATERLY = "QUATERLY";

export const CREATE_TABLE_VIEW_API = {
	type: "POST",
	url: "/api/v2/table-views",
};
export const TABLE_VIEW_CREATED =
	"crm-b2b-frontend/GenericCore/Listing/TABLE_VIEW_CREATED";

export const CREATE_KANBAN_VIEW_API = {
	type: "POST",
	url: "/api/v2/kanban-views",
};

// Getting called directly without sagas
export const LOAD_FORM_API = {
	type: "GET",
	url: "/api/v2/form",
	requestParams: {
		screenId: "",
	},
};

export const LOAD_RECORD_IDS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_RECORD_IDS";
export const CLEAR_RECORD_IDS =
	"crm-b2b-frontend/GenericCore/Listing/CLEAR_RECORD_IDS";

// Load Filters for table filtering
export const LOAD_FILTERS = "crm-b2b-frontend/GenericCore/Listing/LOAD_FILTERS";
export const LOAD_FILTERS_API = {
	type: "GET",
	url: "/api/v2/form-filters/:formId",
};
export const FILTERS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/FILTERS_LOADED";

// Update Table View
export const UPDATE_TABLE_VIEW =
	"crm-b2b-frontend/GenericCore/Listing/UPDATE_TABLE_VIEW";
export const UPDATE_TABLE_VIEW_API = {
	type: "POST",
	url: "/api/v2/table-views/:viewId",
};
export const TABLE_VIEW_UPDATED =
	"crm-b2b-frontend/GenericCore/Listing/TABLE_VIEW_UPDATED";
export const UPDATE_KANBAN_VIEW_API = {
	type: "POST",
	url: "/api/v2/kanban-views/:viewId",
};
// Delete Table View
export const DELETE_VIEW =
	"crm-b2b-frontend/GenericCore/Listing/DELETE_TABLE_VIEW";

// Load Transactions for the Table
export const LOAD_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_TRANSACTIONS";

// Load Transactions for Kanban
export const LOAD_KANBAN_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_KANBAN_TRANSACTIONS";

export const LOAD_NOCLOSEBY_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_NOCLOSEBY_TRANSACTIONS";

export const BUSINESS_RECORD_API_V2 = "/api/v2/records";

export const LOAD_TRANSACTIONS_API = {
	type: "POST",
	url: "/api/v2/records",
	forecastURL: "/api/forecast/transactions",
	forecastTotalsURL: "/api/v2/records/forecast-totals",
	bodyParams: {
		moduleId: "",
		formId: "",
		search: "",
		filters: [],
		sorted: [],
		$limit: 25,
		$page: 0,
	},
};

export const EXPORT_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/EXPORT_TRANSACTIONS";

export const EXPORT_TRANSACTIONS_API = {
	type: "POST",
	url: "/api/v2/exports",
	bodyParams: {
		moduleId: "",
		formId: "",
		search: "",
		filter: [],
		columns: [],
		extension: "",
		sorted: [],
		pageSize: "",
	},
};

// Load Forecast Transactions
export const LOAD_FORECAST_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_FORECAST_TRANSACTIONS";

export const FORECAST_LOAD_MORE_API = {
	type: "GET",
	url: "/api/v2/records",
	bodyParams: {
		search: "",
		filter: [],
	},
};
export const FORECAST_COLUMNS_UPDATE =
	"crm-b2b-frontend/GenericCore/Listing/FORECAST_COLUMNS_UPDATE";
export const FORECAST_FETCH_MORE =
	"crm-b2b-frontend/GenericCore/Listing/FORECAST_FETCH_MORE";
export const FORECAST_LOADMORE_LOADER =
	"crm-b2b-frontend/GenericCore/Listing/FORECAST_LOADMORE_LOADER";

export const FORECAST_TRANSACTIONS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/FORECAST_TRANSACTIONS_LOADED";

export const KANBAN_TRANSACTIONS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/KANBAN_TRANSACTIONS_LOADED";

export const FORECAST_TOTALS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/FORECAST_TOTALS_LOADED";

export const LOAD_ACCOUNT_FORECAST_TOTALS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_ACCOUNT_FORECAST_TOTALS";

export const ACCOUNT_FORECAST_TOTALS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/ACCOUNT_FORECAST_TOTALS_LOADED";

export const LOAD_ACCOUNT_FORECAST_TOTALS_API = {
	url: "/api/v2/records/account-forecast-totals",
};

export const TRANSACTIONS_COUNT_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/TRANSACTIONS_COUNT_LOADED";

export const LOAD_KANBAN_TRANSACTIONS_API = {
	type: "POST",
	url: "/api/v2/records",
	bodyParams: {
		moduleId: "",
		formId: "",
		search: "",
		filter: [],
		pipeline: "",
	},
};
export const UPDATE_TRANSACTION_FIELDS = {
	type: "POST",
	url: "/api/v2/records",
	bodyParams: {
		records: [],
	},
};
export const TRANSACTIONS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/TRANSACTIONS_LOADED";

export const VIEW_TRANSACTIONS = "Listing/VIEW_TRANSACTIONS";
export const ACCOUNT_FORECAST_RECORDS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/ACCOUNT_FORECAST_RECORDS_LOADED";

// Update Transactions in Bulk
export const BULK_UPDATE_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/BULK_UPDATE_TRANSACTIONS";

export const BULK_UPDATE_TRANSACTIONS_COMPLETE =
	"crm-b2b-frontend/GenericCore/Listing/BULK_UPDATE_TRANSACTIONS_COMPLETE";

export const BULK_UPDATE_TRANSACTIONS_API = {
	type: "PATCH",
	url: "/api/v2/records",
	bodyParams: [],
};

export const SET_LOADER = "crm-b2b-frontend/GenericCore/Listing/SET_LOADER";
export const KANBAN_LOADING =
	"crm-b2b-frontend/GenericCore/Listing/KANBAN_LOADING";
export const SAVE_CURRENCY_CONVERTER =
	"crm-b2b-frontend/GenericCore/Listing/SAVE_CURRENCY_CONVERTER";

export const FETCHING_FIELDS_INFO =
	"crm-b2b-frontend/GenericCore/Listing/FETCHING_FIELDS_INFO";

export const FETCHING_FORMS_AND_FIELDS_API = {
	type: "GET",
	url: "/api/v2/form-fields",
};

export const LOAD_FIELD_MAPPING_CONFIG_API = {
	url: "/api/v2/field-mapping",
	method: "GET",
};
export const FETCH_FIELDS_INFO =
	"crm-b2b-frontend/GenericCore/Listing/FETCH_FIELDS_INFO";
export const FIELDS_INFO_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/FIELDS_INFO_LOADED";

export const CLEAR_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/CLEAR_TRANSACTIONS";

export const SET_PAGE_INFO = "SET_PAGE_INFO";
export const LOAD_MORE_TRANSACTIONS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_MORE_TRANSACTIONS";
export const LOAD_MORE_TRANSACTIONS_API = {
	method: "POST",
	url: "/api/v2/records",
	bodyParams: {
		moduleId: "",
		formId: "",
		search: "",
		filter: [],
		stage: "",
	},
};
export const LOAD_KANBAN_CURRENCY_INFO =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_KANBAN_CURRENCY_INFO";
export const LOAD_KANBAN_CURRENCY_INFO_API = {
	method: "POST",
	url: "/api/v2/records/kanban-totals",
	bodyParams: {
		moduleId: "",
		formId: "",
		search: "",
		filter: [],
		pipeline: "",
		localeCurrency: "",
	},
};
export const KANBAN_CURRENCY_INFO_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/KANBAN_CURRENCY_INFO_LOADED";

export const SET_LOADING_FLAG =
	"crm-b2b-frontend/GenericCore/Listing/SET_LOADING_FLAG";
export const NOCLOSEDATE_RECORDS_UPDATE =
	"crm-b2b-frontend/GenericCore/Listing/FORECAST_NOCLOSEDATE_RECORDS_UPDATE";

export const SCREEN_LOADING =
	"crm-b2b-frontend/GenericCore/Listing/SCREEN_LOADING";

export const LOAD_LOST_FIELDS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_LOST_FIELDS";

export const LOAD_LOST_FIELDS_API = {
	type: "GET",
	url: fieldId => `/api/v2/customizable-system-fields?fieldId=${fieldId}`,
};

export const LOST_FIELDS_LOADED =
	"crm-b2b-frontend/GenericCore/Listing/LOST_FIELDS_LOADED";

export const LOAD_ACCOUNT_AGGREGATED_RECORDS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_ACCOUNT_AGGREGATED_RECORDS";
export const LOAD_ACCOUNT_FORECAST_RECORDS =
	"crm-b2b-frontend/GenericCore/Listing/LOAD_ACCOUNT_FORECAST_RECORDS";

export const IMPORT = "IMPORT";
export const GENERIC = "GENERIC";
export const EMAIL = "EMAIL";
export const MAILCHIMP = "MAILCHIMP";
export const MAILERLITE = "MAILERLITE";
export const EXPORT_TO_MAILCHIMP = "EXPORT_TO_MAILCHIMP";
export const EXPORT_TO_MAILERLITE = "EXPORT_TO_MAILERLITE";
export const BULKEDIT = "BULKEDIT";
export const TAG_EDIT = "TAG_EDIT";
export const GDRIVE = "GDRIVE";
export const WHATSAPP = "WHATSAPP";
export const CHOOSE_CHANNEL_FOR_WHATSAPP = "CHOOSE_CHANNEL_FOR_WHATSAPP";
export const MESSAGE_TEMPLATE = "MESSAGE_TEMPLATE";
export const TRANSFER_ACCOUNT = "TRANSFER_ACCOUNT";
export const DRIVE = "DRIVE";
export const RECURRING_OPPORTUNITY = "RECURRING_OPPORTUNITY";
export const CLONE = "CLONE";
export const CLEAR_ALL = "crm-b2b-frontend/GenericCore/Listing/CLEAR_ALL";
export const CATALOG = "CATALOG";

export const CREATE_OR_UPDATE_VIEW =
	"crm-b2b-frontend/GenericCore/Listing/CREATE_OR_UPDATE_TABLE_VIEW";

export const AccountAggregateViewColumns = {
	accountName: { size: 2, transKey: "account.name", minWidth: 200 },
	opportunityCount: { size: 2, transKey: "opprtunity.count", minWidth: 160 },
	opportunityValue: { size: 2, transKey: "opportunity.value", minWidth: 220 },
	bubbleActivityTimeline: {
		size: 2,
		transKey: "activity.bubble.timeline",
		minWidth: 200,
	},
	barActivityTimeline: {
		size: 1,
		transKey: "activity.bar.timeline",
		minWidth: 150,
	},
	lastEvent: { size: 1, transKey: "last.event", minWidth: 100 },
	lastActivity: { size: 1, transKey: "last.activity", minWidth: 100 },
	owner: { size: 1, transKey: "owner", minWidth: 50 },
};

export const DefaultAccountAggregatedViewColumns = [
	"accountName",
	"opportunityCount",
	"opportunityValue",
	"bubbleActivityTimeline",
	"lastEvent",
	"lastActivity",
	"owner",
];

export const ASCENDING = "asc";
export const DESCENDING = "desc";
export const RECORDSCOUNT = 200;

export const AccountAggregateViewSortOptions = {
	_id: {
		label: "Created at",
		value: "_id",
	},
	accountName: {
		label: "Account name",
		value: "accountName",
	},
	openValue: {
		label: "Open opportunity value",
		value: "openValue",
	},
	wonValue: {
		label: "Won opportunity value",
		value: "wonValue",
	},
	lostValue: {
		label: "Lost opportunity value",
		value: "lostValue",
	},
	divider: { type: "divider" },
	[ASCENDING]: {
		label: "Ascending",
		value: ASCENDING,
	},
	[DESCENDING]: {
		label: "Descending",
		value: DESCENDING,
	},
};

export const sortDisabledFields = [
	"F5",
	"F61",
	"F69",
	"F95",
	"F96",
	"F97",
	"F98",
];
