import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import qs from "qs";

import Icon from "@salesforce/design-system-react/components/icon";
import Button from "@salesforce/design-system-react/components/button";
import NoIntegrationModal from "@components/Modal/NoIntegrationModal";

import { postRequest, deleteRequest } from "@utils/request";
import generateErrorMessage from "@services/generateErrorMessage";

import { setToastMessage } from "@containers/App/actions";

import { VideoLinkInputType } from "@custom-types/input-type";
import { UserIntegration } from "@custom-types/user-integration";
import { RichTextValue } from "@custom-types/tiny-types";

import { TYP_RICHTEXT } from "./ElementTypes";

function VideoLinkInput(props: VideoLinkInputType): JSX.Element {
	const {
		value: { meetingJoinUrl = "", user = "", meetingId = "" } = {},
		valueObj,
		userInfo,
	} = props;

	const intl = useIntl();
	const dispatch = useDispatch();
	const [state, setState] = useState(() => {
		const zoomIntegration = userInfo?.integrations?.find(
			(i: UserIntegration) => i.provider === "ZOOM",
		);

		return {
			hasVideoIntegration: Boolean(zoomIntegration),
			openIntegrateModal: false,
			creatingLink: false,
			deletingLink: false,
		};
	});

	const {
		hasVideoIntegration,
		openIntegrateModal,
		creatingLink,
		deletingLink,
	} = state;

	const copyToClipboard = (): void => {
		navigator.clipboard.writeText(meetingJoinUrl);
		dispatch(
			setToastMessage({
				type: "success",
				body: {
					title: "Copied to clipboard",
					details: "",
				},
			}),
		);
	};

	const deleteMeeting = async (): Promise<void> => {
		if (user === userInfo.id) {
			try {
				setState(st => ({ ...st, deletingLink: true }));
				await deleteRequest(`/api/v2/integrations/video/meeting/${meetingId}`);
			} catch (err) {
				const message = generateErrorMessage(err);
				dispatch(setToastMessage(message));
			}
		}

		props.handleChange({
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value: "",
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		});
		setState(st => ({ ...st, deletingLink: false }));
	};

	const toggleIntegrateModal = (): void =>
		setState(st => ({ ...st, openIntegrateModal: !st.openIntegrateModal }));

	const handleClick = async (): Promise<void> => {
		try {
			if (meetingJoinUrl) {
				window.open(meetingJoinUrl, "_blank");
				return;
			}

			if (!hasVideoIntegration) {
				setState(st => ({ ...st, openIntegrateModal: true }));
				return;
			}

			setState(st => ({ ...st, creatingLink: true }));

			const { F108, F109, F110, F112 } = valueObj;

			const duration = moment(F110 as string).diff(F109 as string, "minutes");

			const { timeZone } = userInfo.locale;

			const params = {
				topic: F108,
				duration,
				start_time: moment(F109 as string)
					.tz(timeZone)
					.format("YYYY-MM-DDTHH:mm:ssZ"),
				timezone: timeZone,
			};

			const response = await postRequest(
				`/api/v2/integrations/video/meeting?${qs.stringify(params)}`,
			);
			const { data } = response.data;
			props.handleChange({
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				sectionId: props.sectionId,
				value: data,
				typeCode: props.typeCode,
				isRequired: props.isRequired,
			});
			props.handleChange({
				fieldId: "F112",
				fieldName: "description",
				sectionId: props.sectionId,
				value: {
					...(F112 as RichTextValue),
					html: (F112 as RichTextValue).html + data.invitation,
				},
				typeCode: TYP_RICHTEXT,
				isRequired: false,
			});
			setState(st => ({ ...st, creatingLink: false }));
		} catch (err) {
			setState(st => ({ ...st, creatingLink: false }));
			const message = generateErrorMessage(err);
			dispatch(setToastMessage(message));
		}
	};

	if (openIntegrateModal) {
		return (
			<NoIntegrationModal
				toggleOpen={toggleIntegrateModal}
				service="video"
				title="Integrate"
				subText="You have not integrated Zoom account yet. Connect the tool to make video calls."
			/>
		);
	}

	return (
		<div className="pc-zoom-meeting-link">
			<Button
				onClick={handleClick}
				className="pc-zoom-link-text"
				disabled={creatingLink}
			>
				<Icon name="pc-zoom" category="custom" size="small" />
				{intl.formatMessage({
					id: meetingJoinUrl ? "join.zoom.meeting" : "make.zoom.meeting",
				})}
			</Button>
			{creatingLink && <div className="pc-save-btn-loader" />}
			{meetingJoinUrl && (
				<Button
					iconCategory="custom"
					iconName="pc-copy"
					onClick={copyToClipboard}
					className="pc-zoom-link-copy"
				/>
			)}
			{meetingJoinUrl && (
				<Button
					iconCategory="custom"
					iconName="pc-delete-gray"
					onClick={deleteMeeting}
					className="pc-zoom-link-delete"
				/>
			)}
			{deletingLink && <div className="pc-save-btn-loader" />}
		</div>
	);
}

export default VideoLinkInput;
