import React, { useState } from "react";
import cx from "classnames";
import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// SLDS components
import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";
import GlobalNavigationBar from "@salesforce/design-system-react/components/global-navigation-bar";
import GlobalNavigationBarLink from "@salesforce/design-system-react/components/global-navigation-bar/link";
import GlobalNavigationBarRegion from "@salesforce/design-system-react/components/global-navigation-bar/region";
import GlobalNavigationBarDropdown from "@salesforce/design-system-react/components/global-navigation-bar/dropdown";

import GlobalSearchInput from "@components/GlobalSearchInput";
import Aircall from "@components/Aircall";

import { unsetAnnoncement } from "@containers/App/actions";
import { setLastActiveRoute } from "@utils/common";

// Assets and Styles
import logoLarge from "@assets/icons/logo.png";

// Component helpers
import SettingsPopover from "./SettingsPopover";
import UserSettingsPopover from "./UserSettingsPopover";
import NotificationContainer from "./NotificationContainer";
import ClickAwayListener from "./ClickAwayListener";
import "./styles/header.scss";
import CustomTooltip from "@components/customTooltip";

const USER = "USER";

export function Header(props) {
	const [openWebinar, setOpenWebinar] = React.useState(true);
	const announcementObj = localStorage.getItem("announcements");
	const anyNewProductUpdate = announcementObj
		? Object.values(JSON.parse(announcementObj)).some(e => e === false)
		: false;
	const handleCloseAnnoncement = () => {
		props.dispatch(unsetAnnoncement());
	};

	const [receiveNewMessage, setReceiveNewMessage] = useState(false);

	const closeNav = () => {
		props.toggleMenu(false);
	};

	const businessModules = props.menus.filter(e => e.type === "BUSINESS");
	const activityModules = props.menus
		.filter(
			e =>
				(e.moduleId === "ACTM" || e.moduleId === "QTM") &&
				e.subModuleId !== "ACTMI",
		)
		.map(e => ({
			...e,
			label: props.intl.formatMessage({
				id: e.transKey,
			}),
			value: e.url,
			href: e.url,
		}));
	const logModules = props.menus
		.filter(e => e.subModuleId === "LOG")
		.map(e => ({
			...e,
			label: props.intl.formatMessage({
				id: e.transKey,
			}),
			value: e.url,
			href: e.url,
		}));

	const { permissions } = props.userInfo.profile;
	["IMPORT", "EXPORT"].forEach(k => {
		const hasPermission = Object.keys(permissions).some(
			v => permissions[v][k] === true,
		);
		if (hasPermission) {
			const url = `/queues/${k.toLowerCase()}/history`;
			logModules.push({
				label: `${capitalize(k)}`,
				value: url,
				url,
				href: url,
			});
		}
	});

	// mailchimp and mailerlite
	const hasLeadOrContactReadPermission =
		permissions.CM.READ || permissions.LM.READ;

	if (hasLeadOrContactReadPermission) {
		const url = "/queues/campaign/history";
		logModules.push({
			label: "Campaigns",
			value: url,
			url,
			href: url,
		});
	}

	const hasMessageCreate = permissions.MSG.READ;

	if (hasMessageCreate && hasLeadOrContactReadPermission) {
		const url = `/queues/broadcast/history`;
		logModules.push({
			label: "Broadcast",
			value: url,
			url,
			href: url,
		});
	}

	if (props.userInfo.userType !== "USER") {
		const url = `/queues/knowledge-base/history`;
		logModules.push({
			label: "Knowledge base",
			value: url,
			url,
			href: url,
		});
	}
	logModules.push({
		label: "Activity stream",
		value: "/stream",
		url: "/stream",
		href: "/stream",
	});
	const inboxObj = props.menus.find(e => e.subModuleId === "ACTMI");
	const messagingModules = props.menus.find(e => e.moduleId === "MSG");

	const hasAircallIntegration = props.userInfo.integrations.find(
		each => each.provider === "AIRCALL" && each.status === "ACTIVE",
	);

	return (
		<div className="navigationHeader">
			<ClickAwayListener onClickAway={closeNav}>
				{/** Banner for product related annoncemnts */}
				{props.userInfo && props.userInfo.announcement && (
					<div id="announcement-banner" className="pc-announcement-banner">
						<p className="pc-announcement-banner-content">
							<b>Important Announcement:</b> We are migrating our Pepper Cloud
							CRM product from its current home at{" "}
							<a className="link" href="/" target="_blank">
								https://peppercloud.io
							</a>{" "}
							to its new home at{" "}
							<a
								className="link"
								href="https://app.peppercloud.com"
								target="_blank"
							>
								https://app.peppercloud.com
							</a>
							. This change will take effect on Sunday 30th May between 9-11PM
							SGT. After the transition, your username and password will remain
							the same and you should be able to use the CRM as before.
						</p>
						<Button
							assistiveText={{ label: "menu" }}
							iconCategory="utility"
							iconName="close"
							iconSize="medium"
							variant="icon"
							iconVariant="bare"
							className="pc-announcement-banner-close"
							onClick={handleCloseAnnoncement}
						/>
					</div>
				)}

				<GlobalNavigationBar
					className={cx(
						"pc-navigation-bar",
						props.userInfo &&
							props.userInfo.announcement &&
							"with-annoncements",
					)}
				>
					<GlobalNavigationBarRegion region="primary">
						<div className="pc-menubar">
							{receiveNewMessage && (
								<span className="pc-message-notification-dot"></span>
							)}
							<Button
								assistiveText={{ label: "menu" }}
								iconCategory="custom"
								iconName="pc-menubar"
								iconSize="medium"
								variant="icon"
								iconVariant="bare"
								className="pc-menubar-icon"
								onClick={props.toggleMenu}
							/>
						</div>
						<a
							href="/"
							onClick={e => {
								e.preventDefault();
								props.navigate("/");
							}}
						>
							{/* @Sudha, Pass explicit height and widht for img tag */}
							{/* https://web.dev/optimize-cls/?utm_source=lighthouse&utm_medium=devtools#images-without-dimensions */}
							<img
								src={logoLarge}
								className="pc-navigation-bar-logo"
								alt="PC_Logo"
								title="Pepper Cloud CRM - Version 14.0"
							/>
						</a>
					</GlobalNavigationBarRegion>
					<GlobalNavigationBarRegion
						region="secondary"
						className={`pc-navigation-bar-contents ${props.menuClass}`}
					>
						<GlobalNavigationBarLink
							key="/dashboard"
							href="/dashboard"
							onClick={() => props.navigate("/dashboard")}
							label="Dashboard"
							active={props.activeRoute === "dashboard"}
							id="/dashboard"
						/>
						{businessModules.map(data => (
							<GlobalNavigationBarLink
								key={data.url}
								id={data.url}
								active={props.activeRoute === data.moduleId}
								href={data.url}
								onClick={() => props.navigate(data.url)}
								label={props.intl.formatMessage({
									id: data.transKey,
								})}
							/>
						))}
						{inboxObj && (
							<GlobalNavigationBarLink
								key={inboxObj.url}
								href={inboxObj.url}
								onClick={() => props.navigate(`${inboxObj.url}?type=inbox`)}
								label={props.intl.formatMessage({
									id: inboxObj.transKey,
								})}
								active={props.activeRoute === inboxObj.subModuleId}
								id={inboxObj.url}
							/>
						)}
						{messagingModules && (
							<li
								className={`slds-context-bar__item ${
									props.activeRoute === messagingModules.moduleId
										? "slds-is-active"
										: ""
								}`}
								id={messagingModules.url}
							>
								{/* eslint-disable jsx-a11y/anchor-is-valid */}
								{/* eslint-disable jsx-a11y/click-events-have-key-events */}
								{/* eslint-disable jsx-a11y/no-static-element-interactions */}
								<Link
									href={messagingModules.url}
									to={messagingModules.url}
									className="slds-context-bar__label-action"
									title="Messages"
									onClick={async () => {
										const lastMessageId =
											await localStorage.getItem("lastMessageId");
										setReceiveNewMessage(false);
										closeNav();
										if (lastMessageId && window && window.innerWidth > 1024)
											props.navigate(
												`${messagingModules.url}#${lastMessageId}`,
											);
										setLastActiveRoute("MSG", "/messages");
									}}
								>
									<span className="slds-assistive-text">Current page:</span>
									<span className="slds-truncate" title="Messages">
										<div
											className="pc-message-button"
											style={{ userSelect: "none" }}
										>
											{props.intl.formatMessage({
												id: messagingModules.transKey,
											})}
											{receiveNewMessage && (
												<span className="pc-message-notification-dot"></span>
											)}
										</div>
									</span>
								</Link>
							</li>
						)}
						<span className="pc-message-notification" />
						{activityModules.length !== 0 && (
							<GlobalNavigationBarDropdown
								assistiveText={{ icon: "Activity Module" }}
								id="actModules"
								label="Activities"
								buttonClassName="pc-navigation-activities-dropdown"
								className="pc-navigation-activities-dropdown-menu"
								active={
									activityModules.find(
										e => e.subModuleId === props.activeRoute,
									) || props.activeRoute === "QTM"
								}
								options={activityModules}
								onSelect={e => {
									props.navigate(e.url);
								}}
							/>
						)}
						<GlobalNavigationBarDropdown
							assistiveText={{ icon: "Log" }}
							id="logModules"
							label="Logs"
							buttonClassName="pc-navigation-activities-dropdown"
							className="pc-navigation-activities-dropdown-menu"
							active={
								props.activeRoute === "stream" || props.activeRoute === "LOG"
							}
							options={logModules}
							onSelect={e => {
								props.navigate(e.url);
							}}
						/>
					</GlobalNavigationBarRegion>
					<GlobalNavigationBarRegion
						region="tertiary"
						className="pc-navigation-bar-dropdown"
					>
						<GlobalSearchInput />
						<li className="pc-navigation-bar-dropdown-list pc-products-updates-dropdown">
							<CustomTooltip desc="Announcements">
								<Button
									assistiveText={{ icon: "Product Announcements" }}
									onClick={() => {
										props.handleAnnouncement();
									}}
									iconCategory="custom"
									iconName="pc-announcement"
									variant="base"
									iconVariant="border-filled"
									className="pc-navigation-bar-dropdown-btn-icon pc-product-updates-icon"
								/>
							</CustomTooltip>
							{anyNewProductUpdate && (
								<span className="pc-notification-count" />
							)}
						</li>
						<NotificationContainer
							inlt={props.intl}
							io={props.io}
							activeRoute={props.activeRoute}
							userInfo={props.userInfo}
							dispatch={props.dispatch}
							closeNav={closeNav}
							handleNewMessage={() => setReceiveNewMessage(true)}
						/>
						{props.userInfo.userType !== USER ? (
							<SettingsPopover userInfo={props.userInfo} closeNav={closeNav} />
						) : null}
						{hasAircallIntegration && <Aircall />}
						<UserSettingsPopover
							userInfo={props.userInfo}
							logout={props.logout}
							closeNav={closeNav}
						/>
					</GlobalNavigationBarRegion>
				</GlobalNavigationBar>
			</ClickAwayListener>
			{/* close-side-webinar */}
			{process.env.NODE_ENV !== "development" ? (
				<div
					className={cx(openWebinar ? "side-webinar" : "close-side-webinar")}
				>
					<div className="outer-box-div">
						<div className="inner-box-div">
							<a
								href="https://us06web.zoom.us/webinar/register/4116514659299/WN_Gskxrc35S2KW2P7X2yddOQ"
								target="_blank"
							>
								Live Webinar <br />
								<span>View Details</span>
							</a>
							<Button
								alt="click here to hide"
								title="Click here to hide"
								onClick={() => setOpenWebinar(!openWebinar)}
							>
								<Icon
									name="webinar-play"
									className="webinar-play"
									category="custom"
									size="large"
								/>
							</Button>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}

Header.propTypes = {
	navigate: PropTypes.func.isRequired,
	activeRoute: PropTypes.string,
	menus: PropTypes.array,
	intl: PropTypes.object,
	logout: PropTypes.func,
	userInfo: PropTypes.object,
	menuClass: PropTypes.string,
	toggleMenu: PropTypes.func,
	io: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	dispatch: PropTypes.func,
	handleAnnouncement: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(null, mapDispatchToProps);

export default React.memo(compose(withConnect, injectIntl)(Header));
