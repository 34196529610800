import React, { useState } from "react";
import { Link } from "react-router-dom";
import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";
import Axios from "axios";
import { saveAs } from "file-saver";
import { connect } from "react-redux";
// SLDS components
import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";

// Utilities
import { setToastMessage } from "@containers/App/actions";
import { generateFileName } from "@utils/common";
import { postRequest } from "@utils/request";
import generateErrorMessage from "@services/generateErrorMessage";

// This regex Find extension type
const extensionRegex = /(?:\.([^.]+))?$/;

const axios = Axios.create();
function NotificationListItem({ notification, message, postText }) {
	if (notification.title === "DELETE") {
		return (
			<p className="pc-notification-list_item-text" data-cy="notification-text">
				{notification.meta.actorName} {message} <b>{notification.object}</b>{" "}
				{postText || ""}{" "}
				{`"${notification.meta.objectName}" ${
					notification.object === "CONVERSATION" ? "assigned to you" : ""
				}`}
			</p>
		);
	}
	return (
		<a
			target="_blank"
			href={
				notification?.meta?.redirectURL
					? notification.meta.redirectURL
					: () => false
			}
			className="pc-notification-list_item-text notification-list_link"
			data-cy="notification-text"
		>
			{notification.meta.actorName} {message} <b>{notification.object}</b>{" "}
			{postText || ""}{" "}
			{`"${notification.meta.objectName}" ${
				notification.object === "CONVERSATION" ? "assigned to you" : ""
			}`}
		</a>
	);
}

NotificationListItem.propTypes = {
	notification: PropTypes.object,
	message: PropTypes.string,
	postText: PropTypes.string,
};

const moduleNamesByAbriviation = {
	LM: "lead",
	AM: "account",
	CM: "contact",
	OM: "opportunity",
};

const notificationIcons = {
	EXPORT: "pc-export-notification",
	BROADCAST_REPORT: "pc-export-notification",
	BULK_DELETE: "pc-bulk-edit-notification",
	BULK_RECURRING_OPPORTUNITY: "pc-bulk-edit-notification",
	BULK_ACCOUNT_TRANSFER: "pc-bulk-edit-notification",
	BULK_EDIT: "pc-bulk-edit-notification",
	MESSAGE_BROADCAST: "pc-broadcast-notification",
	IMPORT: "pc-export-notification",
	SYNC_GOOGLE_CONTACTS: "pc-export-notification",
	SYNC_OUTLOOK_CONTACTS: "pc-export-notification",
	MAILCHIMP: "pc-mailchimp-notification",
	MAILERLITE: "pc-mailerlite-notification",
	"MAIL SYNC": "pc-email-notification",
	SYNC_GMAIL_THREADS: "pc-email-notification",
	SYNC_O365_THREADS: "pc-email-notification",
	REFRESH_GMAIL_THREADS: "pc-gmail-refresh-threads-notification",
	REFRESH_O365_THREADS: "pc-gmail-refresh-threads-notification",
	BULK_MAIL: "pc-bulk-email-notification",
	RECORD_BACKUP: "backup-notification-icon",
	ASSOCIATE: "pc-accosiate-notification",
	DISASSOCIATE: "pc-disaccociate-notification",
};

function NotificationList({
	notifications,
	notification,
	intl,
	userId,
	io,
	handleUpdateNotification,
	handleRemoveNotification,
	dispatch,
}) {
	const [loading, setLoading] = useState(false);

	const getTitle = () => {
		const {
			meta: {
				stats: { success },
				stats,
				moduleId,
			},
		} = notification;

		switch (notification.title) {
			case "BULK_ACCOUNT_TRANSFER":
				return `${stats.AM.success} record${
					stats.AM.success > 1 ? "s" : ""
				} in ${moduleNamesByAbriviation[moduleId]} module are bulk transferred`;
			case "BULK_DELETE":
				return `${success} record${success > 1 ? "s are" : " is"} deleted in ${
					moduleNamesByAbriviation[moduleId]
				} module`;
			case "BULK_RECURRING_OPPORTUNITY":
				return `${success} record${
					success === 1 ? " is" : "s are"
				} set recurring successfully`;
			case "BULK_EDIT":
				return `${success} record${success > 1 ? "s are" : " is"} updated in ${
					moduleNamesByAbriviation[moduleId]
				} module`;
			case "MESSAGE_BROADCAST":
				return `Message broadcasted to ${success} ${
					moduleNamesByAbriviation[moduleId]
				}${success > 1 ? "s" : ""}`;
			case "EXPORT":
				return `Export of  ${
					notification.object === "OPPORTUNITY"
						? "opportunities"
						: `${notification.object.toLowerCase()}s`
				} is completed`;
			case "BROADCAST_REPORT":
				return `Broadcast Report of  ${notification.meta.name} is generated`;
			case "IMPORT":
				return `Import of  ${
					moduleId === "OM"
						? "opportunities"
						: `${moduleNamesByAbriviation[moduleId].toLowerCase()}s`
				} is completed`;
			case "BULK_MAIL":
				return `${notification.meta.actorName} has sent ${notification.meta.stats.total} emails successfully`;
			case "RECORD_BACKUP":
				return `Scheduled data backup completed`;
			default:
				return "";
		}
	};

	const getStatsIcons = (total, success, failed) => (
		<React.Fragment>
			<span>
				<Icon
					className="slds-m-right_xx-small"
					category="custom"
					name="pc-settings-import-inserted"
					size="xx-small"
				/>
				Total - {total}{" "}
			</span>
			<span className="slds-p-left_small">
				<Icon
					className="slds-m-right_xx-small"
					category="custom"
					name="pc-settings-import-updated"
					size="xx-small"
				/>
				Success - {success}{" "}
			</span>
			<span className="slds-p-left_small">
				<Icon
					className="slds-m-right_xx-small"
					category="custom"
					name="pc-settings-import-errors"
					size="xx-small"
				/>
				Failed - {failed}
			</span>
		</React.Fragment>
	);

	// On click Notification list item
	const clickNotification = () => {
		io.socketIo.get(`/notification/markRead/${notification._id}`, async () => {
			setLoading(true);
			const _notifications = notifications.map(notif => {
				if (notif._id === notification._id) {
					return {
						...notif,
						readBy: [...notif.readBy, userId],
					};
				}
				return notif;
			});
			const url = notification.meta.redirectURL || notification.fileUrl;
			if (url) {
				let down = {};
				down.url = "/api/v2/download-file";
				switch (notification.title) {
					case "MAILCHIMP":
					case "MAILERLITE":
					case "SYNC_GOOGLE_CONTACTS":
					case "SYNC_OUTLOOK_CONTACTS":
					case "BROADCAST_REPORT":
					case "MESSAGE_BROADCAST":
						down.name = `${generateFileName(
							notification.title,
							notification.meta.name || "",
						)}.${extensionRegex.exec(url)[1]}`;
						down.type = notification.title;
						break;
					case "EXPORT":
						down.name = `${
							notification.meta.fileName || notification.meta.name
						}.${extensionRegex.exec(url)[1]}`;
						down.type = notification.title;
						break;
					case "GOOGLE CONTACTS IMPORTED":
						down.name = "SaveContactError.xlsx";
						down.type = "IMPORT";
						break;
					case "OUTLOOK CONTACTS IMPORTED":
						down.name = "SaveContactError.xlsx";
						down.type = "IMPORT";
						break;
					case "RECORD_BACKUP":
						down.name = `${notification.meta.name}.${
							extensionRegex.exec(url)[1]
						}`;
						down.type = notification.title;
						break;
					default:
						down = null;
				}
				if (down) {
					const response = await postRequest(down.url, {
						url,
						type: down.type,
					});
					try {
						const file = await axios({
							method: "GET",
							url: response.data.data.path,
							headers: {
								"Access-Control-Allow-Origin": "*",
							},
							responseType: "blob",
							withCredentials: true,
						});
						saveAs(new Blob([file.data]), down.name);
					} catch (error) {
						const message = generateErrorMessage(error);
						dispatch(setToastMessage(message));
					}
				}
			}
			handleUpdateNotification({ notifications: _notifications });
			setLoading(false);
		});
	};

	// Remove Notification list item
	const removeOne = () => {
		io.socketIo.get(`/notification/removeOne/${notification._id}`, async () => {
			const _notifications = notifications.filter(
				notif => notif._id !== notification._id,
			);
			handleRemoveNotification(_notifications);
		});
	};

	return (
		<li
			className={notification.readBy.indexOf(userId) > -1 ? "seen" : "unseen"}
		>
			<Button
				iconCategory="utility"
				iconName="close"
				iconSize="small"
				iconVariant="bare"
				variant="icon"
				className="pc-notification-close"
				onClick={() => {
					removeOne();
				}}
			/>
			<a
				onClick={e => {
					if (notification && !loading) {
						clickNotification(e, notification);
					}
				}}
				href={() => false}
			>
				<div className="pc-notification-list_item">
					<div
						className={`pc-notification-list_item-icon ${notification.meta.iconName}`}
					>
						<Icon
							assistiveText={{ label: notification.title }}
							category="custom"
							name={
								notification.meta.iconName ||
								notificationIcons[notification.title]
							}
							size="medium"
						/>
					</div>
					<div className="pc-notification-list_item-data">
						{/* eslint-disable-next-line consistent-return */}
						{(() => {
							// ? [DISCUSS] Can't we make Generic component for these cases, @Yash, @Sudhansu
							switch (notification.title) {
								case "TASK COMPLETED": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.record.completed",
											})}
										/>
									);
								}
								case "OPPORTUNITY REOPENED": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.opportunity.reopened",
											})}
										/>
									);
								}
								case "OPPORTUNITY WON": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.opportunity.won",
											})}
										/>
									);
								}
								case "OPPORTUNITY LOST": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.opportunity.lost",
											})}
										/>
									);
								}
								case "LEAD REOPENED": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.lead.reopened",
											})}
										/>
									);
								}
								case "LEAD CONVERTED": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.lead.convert",
											})}
										/>
									);
								}
								case "LEAD REJECTED": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.lead.reject",
											})}
										/>
									);
								}
								case "CREATE":
								case "ASSIGN": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.record.assign",
											})}
										/>
									);
								}
								case "UPDATE": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.record.update",
											})}
										/>
									);
								}
								case "DELETE": {
									return (
										<NotificationListItem
											notification={notification}
											message={intl.formatMessage({
												id: "notification.record.delete",
											})}
										/>
									);
								}
								case "REMINDER": {
									return (
										<NotificationListItem
											notification={notification}
											message="Reminding"
										/>
									);
								}
								case "TASK REMINDER":
								case "EVENT REMINDER":
									return (
										<NotificationListItem
											notification={notification}
											message="Reminding"
										/>
									);
								case "DUPLICATE":
									return (
										<NotificationListItem
											notification={notification}
											message="Duplicate"
											postText="request"
										/>
									);
								case "MAILCHIMP":
								case "MAILERLITE": {
									return (
										<div>
											<p className="pc-notification-list_item-text">
												<b>
													{`${
														notification.title === "MAILCHIMP"
															? "Mailchimp"
															: "MailerLite"
													} sync completed`}
												</b>
												<br />
												{notification.meta.stats ? (
													<React.Fragment>
														<span>
															Success - {notification.meta.stats.success}
														</span>
														<span className="slds-p-left_small">
															Failed - {notification.meta.stats.failed}
														</span>
														<br />
														<div className="pc-notification-download-loader">
															{notification.meta.errorFile && (
																<a
																	href={() => false}
																	className="pc-noti-error-file-download"
																>
																	<Icon
																		name="pc-download-file-notification"
																		category="custom"
																		size="xx-small"
																		className="slds-m-right_x-small"
																	/>
																	<span className="txt">
																		Download Error File
																	</span>
																</a>
															)}
															{loading && (
																<div className="pc-download-loader" />
															)}
														</div>
													</React.Fragment>
												) : (
													<React.Fragment>
														<span>Success - {notification.meta.success}</span>
														<span className="slds-p-left_small">
															Failed - {notification.meta.failed}
														</span>
														<br />
														<div className="pc-notification-download-loader">
															{notification.value && (
																<a
																	href={() => false}
																	className="pc-noti-error-file-download"
																>
																	<Icon
																		name="pc-download-file-notification"
																		category="custom"
																		size="xx-small"
																		className="slds-m-right_x-small"
																	/>
																	<span className="txt">
																		Download Error File
																	</span>
																</a>
															)}
															{loading && (
																<div className="pc-download-loader" />
															)}
														</div>
													</React.Fragment>
												)}
											</p>
										</div>
									);
								}
								case "EXPORT":
								case "BROADCAST_REPORT": {
									return (
										<div data-cy="export">
											<p className="pc-notification-list_item-text">
												{notification?.meta?.stats ? (
													<React.Fragment>
														<b data-cy="message">{getTitle()}</b> <br />{" "}
														<span data-cy="total">
															{notification?.meta?.stats?.total === 1
																? `Total Record`
																: `Total Records`}
															- {notification?.meta?.stats?.total}
														</span>
														<br />
														<div className="pc-notification-download-loader">
															{notification.meta.file && (
																<a
																	href={() => false}
																	className="pc-noti-error-file-download"
																>
																	<Icon
																		name="pc-download-file-notification"
																		category="custom"
																		size="xx-small"
																		className="slds-m-right_x-small"
																	/>
																	<span className="txt">Download File</span>
																</a>
															)}
															{loading && (
																<div className="pc-download-loader" />
															)}
														</div>
													</React.Fragment>
												) : (
													<React.Fragment>
														<b data-cy="message">
															{notification.meta.message
																? notification.meta.message
																: "Export Complete"}
														</b>{" "}
														<br />{" "}
														<span data-cy="total">
															{notification?.meta?.stats?.total === 1
																? `Total Record`
																: `Total Records`}
															- {notification?.meta?.totalRecords}
														</span>
														<br />
														<div className="pc-notification-download-loader">
															{notification.value && (
																<a
																	href={() => false}
																	className="pc-noti-error-file-download"
																>
																	<Icon
																		name="pc-download-file-notification"
																		category="custom"
																		size="xx-small"
																		className="slds-m-right_x-small"
																	/>
																	<span className="txt">Download File</span>
																</a>
															)}
															{loading && (
																<div className="pc-download-loader" />
															)}
														</div>
													</React.Fragment>
												)}
											</p>
										</div>
									);
								}
								case "ASSOCIATE": {
									return (
										<a
											target="_blank"
											href={
												notification?.meta?.redirectURL
													? notification.meta.redirectURL
													: () => false
											}
											className="pc-notification-list_item-text notification-list_link"
											data-cy="notification-text"
										>
											{notification.meta.actorName}{" "}
											{intl.formatMessage({
												id: "notification.record.associate",
											})}{" "}
											<b>{notification.object}</b> &quot;
											{notification.meta.objectName}
											&quot;{" "}
											{intl.formatMessage({
												id: "notification.record.associate.with",
											})}{" "}
											<b>{notification.meta.associatedObject}</b> &quot;
											{notification.meta.associatedObjectName}&quot;
										</a>
									);
								}
								case "DISSOCIATE": {
									return (
										<a
											target="_blank"
											href={
												notification?.meta?.redirectURL
													? notification.meta.redirectURL
													: () => false
											}
											className="pc-notification-list_item-text notification-list_link"
											data-cy="notification-text"
										>
											{notification.meta.actorName}{" "}
											{intl.formatMessage({
												id: "notification.record.dissociate",
											})}{" "}
											<b>{notification.object}</b> &quot;
											{notification.meta.objectName}
											&quot;{" "}
											{intl.formatMessage({
												id: "notification.record.dissociate.from",
											})}{" "}
											<b>{notification.meta.associatedObject}</b>{" "}
											{`"${notification.meta.associatedObjectName}"`}
										</a>
									);
								}
								case "IMPORT": {
									return (
										<div>
											<p className="pc-notification-list_item-text">
												{notification.meta.stats ? (
													<React.Fragment>
														<b data-cy="message"> {getTitle()}</b> <br />{" "}
														<span data-cy="total">
															Total - {notification.meta.stats.total}
														</span>
														<span data-cy="created">
															{" "}
															· Created - {notification.meta.stats.inserted}
														</span>
														<span data-cy="updated">
															{" "}
															· Updated - {notification.meta.stats.updated}
														</span>
														<span data-cy="skip">
															{" "}
															· Skipped - {notification.meta.stats.skipped}
														</span>
														<span data-cy="error">
															{" "}
															· Error - {notification.meta.stats.failed}
														</span>
														<br />
														<Link
															to="/queues/import/history"
															className="pc-noti-error-file-download"
														>
															<Icon
																name="pc-download-file-notification"
																category="custom"
																size="xx-small"
																className="slds-m-right_x-small"
															/>
															<span className="txt">View imports</span>
														</Link>
													</React.Fragment>
												) : (
													<React.Fragment>
														<b data-cy="message">
															{" "}
															{notification.meta.message}
														</b>{" "}
														<br />{" "}
														<span data-cy="total">
															Total - {notification.meta.total}
														</span>
														<span data-cy="created">
															{" "}
															· Created - {notification.meta.created}
														</span>
														<span data-cy="updated">
															{" "}
															· Updated - {notification.meta.updated}
														</span>
														<span data-cy="skip">
															{`· Skip - ${
																notification.meta.skip
																	? notification.meta.skip
																	: 0
															}`}
														</span>
														<span data-cy="error">
															{" "}
															· Error - {notification.meta.errors}
														</span>
														<br />
														{notification.meta.redirectURL && (
															<a
																href={notification.meta.redirectURL}
																className="pc-noti-error-file-download"
															>
																<Icon
																	name="pc-download-file-notification"
																	category="custom"
																	size="xx-small"
																	className="slds-m-right_x-small"
																/>
																<span className="txt">View imports</span>
															</a>
														)}
													</React.Fragment>
												)}
											</p>
										</div>
									);
								}
								case "SYNC_GOOGLE_CONTACTS":
								case "SYNC_OUTLOOK_CONTACTS": {
									return (
										<div>
											<p className="pc-notification-list_item-text">
												<span>Total - {notification.meta.stats.total}</span>
												<span className="slds-p-left_small">
													Success -{" "}
													{notification.meta.stats.total -
														notification.meta.stats.failed}
												</span>
												<span className="slds-p-left_small">
													Failed - {notification.meta.stats.failed}
												</span>
												<br />
												{notification.meta.errorFile && (
													<a
														href={() => false}
														className="pc-noti-error-file-download"
													>
														Download Error File
													</a>
												)}
											</p>
										</div>
									);
								}

								case "REFRESH_GMAIL_THREADS":
								case "REFRESH_O365_THREADS":
									return (
										<div>
											<p className="pc-notification-list_item-text">
												Your threads are refreshed
											</p>
										</div>
									);
								case "SYNC_GMAIL_THREADS":
								case "SYNC_O365_THREADS":
									return (
										<div>
											<p className="pc-notification-list_item-text">
												Your Mailbox is Synced
											</p>
										</div>
									);
								case "BULK_ACCOUNT_TRANSFER":
									return (
										<div>
											<p className="pc-notification-list_item-text">
												<b>{getTitle()}</b> <br />{" "}
												{!!notification.meta.stats &&
													Object.keys(notification.meta.stats).map(
														ele =>
															!!notification.meta.stats[ele].total && (
																<React.Fragment>
																	<p>
																		{" "}
																		<strong>
																			{capitalize(
																				moduleNamesByAbriviation[ele],
																			)}
																		</strong>{" "}
																	</p>
																	{getStatsIcons(
																		notification.meta.stats[ele].total,
																		notification.meta.stats[ele].success,
																		notification.meta.stats[ele].failed,
																	)}
																</React.Fragment>
															),
													)}
											</p>
										</div>
									);
								case "BULK_RECURRING_OPPORTUNITY":
									return (
										<div>
											<p className="pc-notification-list_item-text">
												<b>{getTitle()}</b> <br />{" "}
											</p>
										</div>
									);
								case "BULK_DELETE":
								case "BULK_EDIT":
								case "MESSAGE_BROADCAST":
									return (
										<div>
											<p className="pc-notification-list_item-text">
												<b>{getTitle()}</b> <br />{" "}
												{getStatsIcons(
													notification.meta.stats.total,
													notification.meta.stats.success,
													notification.meta.stats.failed,
												)}
											</p>
										</div>
									);
								case "OUTLOOK CONTACTS IMPORTED":
								case "GOOGLE CONTACTS IMPORTED": {
									return (
										<div>
											<p className="pc-notification-list_item-text">
												<span>Total - {notification.meta.totalRecords}</span>
												<span className="slds-p-left_small">
													Success - {notification.meta.success}
												</span>
												<span className="slds-p-left_small">
													Failed - {notification.meta.failed}
												</span>
												<br />
												{notification.value && (
													<a
														href={() => false}
														className="pc-noti-error-file-download"
													>
														Download Error File
													</a>
												)}
											</p>
										</div>
									);
								}

								case "MAIL SYNC": {
									return (
										<div>
											<p className="pc-notification-list_item-text">
												{notification.meta.message}
											</p>
										</div>
									);
								}
								case "BULK ACTION":
								case "ACCOUNT TRANSFER":
								case "RECURRING OPPORTUNITY":
								case "MESSAGES": {
									return (
										<div>
											<p className="pc-notification-list_item-text">
												<b> {notification.meta.message} </b> <br />{" "}
												{!!notification.meta.stats &&
													Object.keys(notification.meta.stats).map(
														ele =>
															!!notification.meta.stats[ele].total && (
																<React.Fragment>
																	<p>
																		{" "}
																		<strong>
																			{moduleNamesByAbriviation[ele]}
																		</strong>{" "}
																	</p>
																	<span>
																		<Icon
																			className="slds-m-right_xx-small"
																			category="custom"
																			name="pc-settings-import-inserted"
																			size="xx-small"
																		/>
																		Total - {notification.meta.stats[ele].total}{" "}
																	</span>
																	<span className="slds-p-left_small">
																		<Icon
																			className="slds-m-right_xx-small"
																			category="custom"
																			name="pc-settings-import-updated"
																			size="xx-small"
																		/>
																		Success -{" "}
																		{notification.meta.stats[ele].success}{" "}
																	</span>
																	<span className="slds-p-left_small">
																		<Icon
																			className="slds-m-right_xx-small"
																			category="custom"
																			name="pc-settings-import-errors"
																			size="xx-small"
																		/>
																		Failed -{" "}
																		{notification.meta.stats[ele].failed}
																	</span>
																</React.Fragment>
															),
													)}
											</p>
										</div>
									);
								}
								case "BULK_MAIL":
								case "RECORD_BACKUP": {
									return (
										<React.Fragment>
											<div>
												<p className="pc-notification-list_item-text">
													<b>{getTitle()}</b> <br />{" "}
												</p>
											</div>
											<div className="pc-notification-download-loader">
												{notification.meta.file && (
													<a
														href={() => false}
														className="pc-noti-error-file-download"
													>
														<Icon
															name="pc-download-file-notification"
															category="custom"
															size="xx-small"
															className="slds-m-right_x-small"
														/>
														<span className="txt">Download File</span>
													</a>
												)}
												{loading && <div className="pc-download-loader" />}
											</div>
										</React.Fragment>
									);
								}
								default: {
									return null;
								}
							}
						})()}
						<div className="slds-grid slds-grid_vertical-align-center">
							<div className="slds-col">
								<p
									className={`pc-notification-list_item-timestamp ${notification.meta.iconName}`}
									data-cy={`${notification.object}-timestamp`}
								>
									{notification.timestamp}
								</p>
							</div>
							<div className="slds-col pc-text-right">
								<p className="pc-notification-list_item-title">
									{
										// eslint-disable-next-line no-nested-ternary
										notification.title === "BULK ACTION" &&
										notification.meta.length === "1"
											? "UPDATE"
											: notification.title === "RECORD_BACKUP"
											? "BACKUP"
											: notification.title
									}
								</p>
							</div>
						</div>
					</div>
				</div>
			</a>
		</li>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

NotificationList.propTypes = {
	notifications: PropTypes.array,
	intl: PropTypes.object,
	io: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	notification: PropTypes.object,
	userId: PropTypes.string,
	handleUpdateNotification: PropTypes.func,
	handleRemoveNotification: PropTypes.func,
	dispatch: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(NotificationList);
