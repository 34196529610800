import {
	LOAD_SCREEN,
	LOAD_SCREEN_FILTERS,
	SCREEN_LOADED,
	FILTERS_LOADED,
	TABLE_VIEWS_LOADED,
	KANBAN_VIEWS_LOADED,
	DELETE_VIEW,
	LOAD_TRANSACTIONS,
	LOAD_FORECAST_TRANSACTIONS,
	VIEW_TRANSACTIONS,
	TRANSACTIONS_LOADED,
	UPDATE_TRANSACTION,
	BULK_UPDATE_TRANSACTIONS,
	FIELDS_INFO_LOADED,
	FETCH_FIELDS_INFO,
	CLEAR_TRANSACTIONS,
	LOAD_KANBAN_TRANSACTIONS,
	LOAD_MORE_TRANSACTIONS,
	LOAD_KANBAN_CURRENCY_INFO,
	FORECAST_TRANSACTIONS_LOADED,
	FORECAST_TOTALS_LOADED,
	FORECAST_LOADMORE_LOADER,
	FORECAST_FETCH_MORE,
	FORECAST_COLUMNS_UPDATE,
	KANBAN_TRANSACTIONS_LOADED,
	EXPORT_TRANSACTIONS,
	SET_LOADER,
	LOAD_RECORD_IDS,
	CLEAR_RECORD_IDS,
	NOCLOSEDATE_RECORDS_UPDATE,
	TRANSACTIONS_UPDATED,
	LOAD_LOST_FIELDS,
	LOST_FIELDS_LOADED,
	CLEAR_ALL,
	CREATE_OR_UPDATE_VIEW,
	BULK_UPDATE_TRANSACTIONS_COMPLETE,
	LOAD_ACCOUNT_AGGREGATED_RECORDS,
	LOAD_ACCOUNT_FORECAST_RECORDS,
	ACCOUNT_FORECAST_RECORDS_LOADED,
	LOAD_ACCOUNT_FORECAST_TOTALS,
	ACCOUNT_FORECAST_TOTALS_LOADED,
} from "./constants";

export function loadLostFieldData(fieldId) {
	return {
		type: LOAD_LOST_FIELDS,
		fieldId,
	};
}

export function lostFieldDataLoaded(lostFields) {
	return {
		type: LOST_FIELDS_LOADED,
		lostFields,
	};
}
// Load Screen Actions
export function loadScreen(moduleName, screenType, visualType) {
	return { type: LOAD_SCREEN, moduleName, screenType, visualType };
}

export function loadScreenFilters(moduleName) {
	return { type: LOAD_SCREEN_FILTERS, moduleName };
}

export function screenLoaded(screenData) {
	return { type: SCREEN_LOADED, screenData };
}

export function filtersLoaded(filters) {
	return { type: FILTERS_LOADED, filters };
}

export function updateColumns(columns) {
	return { type: FORECAST_COLUMNS_UPDATE, columns };
}

export function tableViewsLoaded(viewsData) {
	return { type: TABLE_VIEWS_LOADED, viewsData };
}

export function kanbanViewsLoaded(viewsData) {
	return { type: KANBAN_VIEWS_LOADED, viewsData };
}

// For updating the stage
export function updateTransaction(params, showToast = false, transactionsInfo) {
	return {
		type: UPDATE_TRANSACTION,
		params,
		showToast,
		transactionsInfo,
	};
}

/**
 * table view actions
 */
// create or Update TableView
export function createOrUpdateView(type, visualType, data) {
	return {
		type: CREATE_OR_UPDATE_VIEW,
		data: { type, visualType, data },
	};
}
// Delete Table View
export function deleteView(viewId, screenId, visualType) {
	return { type: DELETE_VIEW, viewId, screenId, visualType };
}

// Load Transaction Records for the table
export function loadTransactions(data) {
	return {
		type: LOAD_TRANSACTIONS,
		data,
	};
}

// Export
export function exportTransactions(
	moduleId,
	formId,
	search,
	filters,
	selectedRecordIds,
	columns,
	extension,
	sorted,
	pageSize,
) {
	return {
		type: EXPORT_TRANSACTIONS,
		moduleId,
		formId,
		search,
		filters,
		selectedRecordIds,
		columns,
		extension,
		sorted,
		pageSize,
	};
}

export function loadRecordIds(data) {
	return {
		type: LOAD_RECORD_IDS,
		data,
	};
}

export function clearRecordIds() {
	return {
		type: CLEAR_RECORD_IDS,
		data: [],
	};
}

export const loadAccountAggregatedViewRecords = params => ({
	type: LOAD_ACCOUNT_AGGREGATED_RECORDS,
	...params,
});

export const loadAccountForecastViewRecords = params => ({
	type: LOAD_ACCOUNT_FORECAST_RECORDS,
	...params,
});

// Load Forecast Transaction Records
export function loadForecastTransactions(
	search,
	filters,
	sorted,
	startDate,
	interval,
) {
	return {
		type: LOAD_FORECAST_TRANSACTIONS,
		search,
		filters,
		sorted,
		startDate,
		interval,
	};
}

// Load More Forecast Transaction
export function forecastLoadMore(data) {
	return {
		type: FORECAST_FETCH_MORE,
		...data,
	};
}

// Load More Forecast Transaction
export function forecastLoadMoreLoader(data) {
	return {
		type: FORECAST_LOADMORE_LOADER,
		data,
	};
}

// Load Transaction Records for kanban
export function loadKanbanTransactions(
	moduleId,
	formId,
	search,
	filters,
	pipeline,
	localeCurrency,
	recordStatus,
	showOnlyExpired = false,
) {
	return {
		type: LOAD_KANBAN_TRANSACTIONS,
		moduleId,
		formId,
		search,
		filters,
		pipeline,
		localeCurrency,
		recordStatus,
		showOnlyExpired,
	};
}

export function transactionsLoaded(data) {
	return { type: TRANSACTIONS_LOADED, data };
}

export const viewTransactions = data => ({ type: VIEW_TRANSACTIONS, data });

export function kanbanTransactionsLoaded(data) {
	return { type: KANBAN_TRANSACTIONS_LOADED, data };
}

export function forecastDataLoaded(data) {
	return { type: FORECAST_TRANSACTIONS_LOADED, data };
}

export function forecastTotalsLoaded(data) {
	return { type: FORECAST_TOTALS_LOADED, data };
}
// Bulk Update Transactions
export function bulkUpdateTransactions(data) {
	return { type: BULK_UPDATE_TRANSACTIONS, data };
}

export function bulkUpdateTransactionsCompleted(complete) {
	return { type: BULK_UPDATE_TRANSACTIONS_COMPLETE, complete };
}

export function fieldsInfoLoaded(data) {
	return {
		type: FIELDS_INFO_LOADED,
		data,
	};
}
export function fetchingFieldsInfo(params) {
	return {
		type: FETCH_FIELDS_INFO,
		params,
	};
}

export function clearTransactions() {
	return {
		type: CLEAR_TRANSACTIONS,
	};
}

// added action to set loader
export function loaderAction(flag = true) {
	return {
		type: SET_LOADER,
		flag,
	};
}
export function loadMore(
	moduleId,
	formId,
	search,
	filters,
	stageId,
	recordsFetched,
	currentTransactions,
	pipeline,
	recordStatus,
) {
	return {
		type: LOAD_MORE_TRANSACTIONS,
		moduleId,
		formId,
		search,
		filters,
		stageId,
		recordsFetched,
		currentTransactions,
		pipeline,
		recordStatus,
	};
}

export function loadKanbanCurrencyInfo(
	moduleId,
	formId,
	search,
	filters,
	pipeline,
	localeCurrency,
	recordStatus,
) {
	return {
		type: LOAD_KANBAN_CURRENCY_INFO,
		moduleId,
		formId,
		search,
		filters,
		pipeline,
		localeCurrency,
		recordStatus,
	};
}

export function updateNoCloseDate(data) {
	return {
		type: NOCLOSEDATE_RECORDS_UPDATE,
		data,
	};
}
export function updateRecordInTransactions(records, oldRecords) {
	const newRecords = [...oldRecords];
	const recIndex = oldRecords.findIndex(oldRec => oldRec._id === records.id);
	const newRecord = { ...records };
	newRecord._id = records.id;
	delete newRecord.id;
	newRecords[recIndex] = newRecord;
	return { type: TRANSACTIONS_UPDATED, data: { transactions: newRecords } };
}

export function createRecordInTransactions(
	record,
	oldRecords,
	recordsTotal,
	pageSize,
) {
	record._id = record.id;
	delete record.id;
	const newRecords = [record, ...oldRecords];
	if (newRecords.length > pageSize) {
		newRecords.pop();
	}
	return {
		type: TRANSACTIONS_LOADED,
		data: {
			records: newRecords,
			total: recordsTotal + 1,
			recordsFiltered: newRecords.length,
			loading: false,
		},
	};
}

export const accountForecastTransactionsLoaded = data => ({
	type: ACCOUNT_FORECAST_RECORDS_LOADED,
	data,
});

export const loadAccountForecastTotals = params => ({
	type: LOAD_ACCOUNT_FORECAST_TOTALS,
	...params,
});

export const accountForecastTotalsLoaded = data => ({
	type: ACCOUNT_FORECAST_TOTALS_LOADED,
	data,
});

export function clearAll() {
	return { type: CLEAR_ALL };
}
