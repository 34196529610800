/* eslint-disable max-lines */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";

import ErrorBoundary from "@components/ErrorBoundary";
import Loader from "@components/Loader";

import lazyRetry from "@utils/lazyRetry";

import Sound from "@containers/Messages/MessageSetting/sound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Auth2FARoute from "./Auth2FARoute";

// Private Routes
const HomePage = React.lazy(() =>
	lazyRetry(
		() => import(/* webpackChunkName: "HomePage" */ "@containers/HomePage"),
	),
);

const Dashboard = React.lazy(() =>
	lazyRetry(
		() => import(/* webpackChunkName: "Dashboard" */ "@containers/Dashboard"),
	),
);

const Stream = React.lazy(() =>
	lazyRetry(
		() => import(/* webpackChunkName: "Stream" */ "@containers/Stream"),
	),
);

// Listing
const ListingPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "BusinessListing" */ "@containers/Business/Listing"
			),
	),
);

const ActivityListingPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "ActivityListing" */ "@containers/Activities/Listing"
			),
	),
);

const QuotationListingPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "QuotationListing" */ "@containers/Quotation/Listing"
			),
	),
);

const EstimateListingPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "EstimateListing" */ "@containers/Estimate/Listing"
			),
	),
);

const Inbox = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "InboxListing" */ "@containers/Inbox/Listing"
			),
	),
);

// Details
const DetailPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "BusinessDetails" */ "@containers/Business/Details"
			),
	),
);

const ActivityDetailPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "ActivityDetails" */ "@containers/Activities/Details"
			),
	),
);

// Settings
const Settings = React.lazy(() =>
	lazyRetry(
		() => import(/* webpackChunkName: "Settings" */ "@containers/Settings"),
	),
);

const UserSettings = React.lazy(() =>
	lazyRetry(
		() =>
			import(/* webpackChunkName: "UserSettings" */ "@containers/UserSettings"),
	),
);

const WebFormPublicView = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "WebFormPublicView" */ "@containers/Settings/Automation/WebForm/WebFormPublicView"
			),
	),
);

// Google Integrations
const GmailCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "GmailCallback" */ "@components/GmailCallback"
			),
	),
);

const GdriveCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "GdriveCallback" */ "@components/GdriveCallback"
			),
	),
);

const GcalendarCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "GcalendarCallback" */ "@components/GcalendarCallback"
			),
	),
);

const OcalendarCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "OcalendarCallback" */ "@components/Office365CalendarCallback"
			),
	),
);

const ZoomCallback = React.lazy(() =>
	lazyRetry(() => import("@components/ZoomCallback")),
);

// Microsoft Integrations
const OneDriveCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "OneDriveCallback" */ "@components/OneDriveCallback"
			),
	),
);

const Office365MailCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "Office365MailCallback" */ "@components/Office365MailCallback"
			),
	),
);

const Office365CalendarCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "Office365CalendarCallback" */ "@components/Office365CalendarCallback"
			),
	),
);

const OutlookContactsCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "OutlookContactsCallback" */ "@components/OutlookContactsCallback"
			),
	),
);

const GoogleContactsCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "GoogleContactsCallback" */ "@components/GoogleContactsCallback"
			),
	),
);

const XeroCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(/* webpackChunkName: "XeroCallback" */ "@components/XeroCallback"),
	),
);

const QuickBooksCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "QuickBooksCallback" */ "@components/QuickBooksCallback"
			),
	),
);

const AircallCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "AircalllCallback" */ "@components/AircallCallback"
			),
	),
);

const FacebookCallback = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "FacebookCallback" */ "@components/FacebookCallback"
			),
	),
);

const QueueHistory = React.lazy(() =>
	lazyRetry(
		() => import(/* webpackChunkName: "QueueLog" */ "@containers/QueueHistory"),
	),
);

// 2FA Route
const TwoFactorAuth = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "TwoFactorAuth" */ "@containers/TwoFactorAuth"
			),
	),
);

// Public Routes
const LoginPage = React.lazy(() =>
	lazyRetry(
		() => import(/* webpackChunkName: "LoginPage" */ "@containers/LoginPage"),
	),
);

const AccountLocked = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "AccountLockedPage" */ "@containers/LoginPage/AccountLockedPage"
			),
	),
);

const UnlockAccount = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "UnlockAccountPage" */ "@containers/LoginPage/UnlockAccountPage"
			),
	),
);

const ForgotPasswordPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "ForgotPassword" */ "@containers/LoginPage/ForgotPassword"
			),
	),
);

const PasswordResetPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "PasswordReset" */ "@containers/LoginPage/PasswordReset"
			),
	),
);

const VerifyEmailPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "VerifyEmail" */ "@containers/LoginPage/VerifyEmail"
			),
	),
);
const PageNotFound = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "PageNotFound" */ "@containers/PageLayout/PageNotFound"
			),
	),
);

const Quotation = React.lazy(() =>
	lazyRetry(
		() =>
			import(/* webpackChunkName: "PublicQuote" */ "@containers/PublicQuote"),
	),
);

const Estimate = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "PublicEstimate" */ "@containers/PublicEstimate"
			),
	),
);

const Messages = React.lazy(() =>
	lazyRetry(
		() => import(/* webpackChunkName: "Messages" */ "@containers/Messages"),
	),
);

const Workflow = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "Workflow" */ "@containers/Settings/Automation/Workflow"
			),
	),
);

function App(): JSX.Element {
	return (
		<React.Fragment>
			<Helmet
				titleTemplate="%s - Pepper Cloud CRM"
				defaultTitle="Pepper Cloud CRM"
			>
				<meta name="description" content="Simple CRM for Smart People" />
			</Helmet>
			<ErrorBoundary>
				<Suspense fallback={<Loader />}>
					<Switch>
						<PrivateRoute exact path="/" component={HomePage} />
						<PublicRoute exact path="/quotation/:qId" component={Quotation} />
						<PublicRoute exact path="/estimate/:eId" component={Estimate} />
						<PrivateRoute exact path="/dashboard" component={Dashboard} />
						<PrivateRoute path="/messages" component={Messages} />
						<PrivateRoute
							exact
							path="/listing/activity/:module(task|event)"
							component={ActivityListingPage}
						/>
						<PrivateRoute
							exact
							path="/listing/activity/:module(quotation)"
							component={QuotationListingPage}
						/>
						<PrivateRoute
							exact
							path="/listing/activity/:module(estimate)"
							component={EstimateListingPage}
						/>
						<PrivateRoute
							exact
							path="/listing/:module(lead|account|contact|opportunity)"
							component={ListingPage}
						/>
						<PrivateRoute
							exact
							path="/queues/:type(import|export|knowledge-base|broadcast|campaign)/history"
							component={QueueHistory}
						/>
						<PrivateRoute
							exact
							path="/detail/:module(lead|account|contact|opportunity)/:recordId"
							component={DetailPage}
						/>
						<PrivateRoute
							exact
							path="/detail/activity/:module(task|event)/:recordId"
							component={ActivityDetailPage}
						/>

						<PrivateRoute
							exact
							path="/listing/activity/:module(inbox)"
							component={Inbox}
						/>
						<PrivateRoute exact path="/settings" component={Settings} />
						<PrivateRoute
							exact
							path="/connect/gmail"
							component={GmailCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/gdrive"
							component={GdriveCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/gcalendar"
							component={GcalendarCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/ocalendar"
							component={OcalendarCallback}
						/>
						<PrivateRoute exact path="/connect/xero" component={XeroCallback} />
						<PrivateRoute
							exact
							path="/connect/quickbooks"
							component={QuickBooksCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/aircall"
							component={AircallCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/facebook"
							component={FacebookCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/omail"
							component={Office365MailCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/odrive"
							component={OneDriveCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/office365Calendar"
							component={Office365CalendarCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/ocontacts"
							component={OutlookContactsCallback}
						/>
						<PrivateRoute
							exact
							path="/connect/gcontacts"
							component={GoogleContactsCallback}
						/>
						<PrivateRoute exact path="/connect/zoom" component={ZoomCallback} />
						<PrivateRoute
							exact
							path="/settings/automation/workflow/:workflowId/edit"
							component={Workflow}
							withoutKey
						/>
						<PrivateRoute
							exact
							path="/settings/automation/workflow/:workflowId/history/:historyId"
							component={Workflow}
							withoutKey
						/>
						<PrivateRoute exact path="/settings/*" component={Settings} />
						<PrivateRoute
							exact
							path="/setting/user/*"
							component={UserSettings}
						/>
						<PrivateRoute exact path="/stream" component={Stream} />
						<Auth2FARoute exact path="/login/2fa" component={TwoFactorAuth} />

						{/* Public Routes  */}
						<PublicRoute exact path="/login" component={LoginPage} />
						<PublicRoute
							exact
							path="/login/suspicious-activity"
							component={AccountLocked}
						/>
						<PublicRoute
							exact
							path="/login/user/unlock/:token"
							component={UnlockAccount}
						/>
						<PublicRoute
							exact
							path="/password/reset/:token"
							component={PasswordResetPage}
						/>
						<PublicRoute
							exact
							path="/verify/email/:token"
							component={VerifyEmailPage}
						/>
						<PublicRoute
							exact
							path="/password/forgot"
							component={ForgotPasswordPage}
						/>
						<Route exact path="/form/:formId" component={WebFormPublicView} />
						<Route
							exact
							path="/form/:formId/embed"
							component={WebFormPublicView}
						/>

						<PublicRoute path="*" component={PageNotFound} />
					</Switch>
					<div id="overlay-portal" />
					<Sound />
				</Suspense>
			</ErrorBoundary>
		</React.Fragment>
	);
}

export default App;
