import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import Button from "@salesforce/design-system-react/components/button";

import image from "@assets/img/pc-404-error-img.svg";

import "./styles/page-not-found.scss";
import { clearLastActiveRoute } from "@utils/common";

function InternalServerError(props) {
	const intl = useIntl();

	const openFeedback = () => {
		Sentry.showReportDialog({ eventId: props.eventId });
	};

	return (
		<div className="page-not-found">
			<div className="slds-grid slds-wrap slds-gutters">
				<div className="slds-col slds-small-size_12-of-12 slds-medium-size_6-of-12 slds-size_5-of-12 slds-p-around_none">
					<div className="page-not-found-cnt">
						<h1>
							{intl.formatMessage({
								id: "internalServerError.page.not.found",
							})}
						</h1>
						<h2>
							{intl.formatMessage({
								id: "internalServerError.patience",
							})}{" "}
						</h2>
						<br />
						<br />
						<a
							href="/"
							className="slds-button slds-button_neutral pc-modal-secondary-btn"
							onClick={clearLastActiveRoute}
						>
							{intl.formatMessage({
								id: "internalServerError.back.home",
							})}
						</a>
						{props.showFeedback && (
							<Button
								id="feedback"
								label={intl.formatMessage({
									id: "button.report.feedback",
								})}
								className="slds-button slds-button_neutral pc-modal-primary-btn slds-m-left_x-small"
								onClick={openFeedback}
							/>
						)}
						<br />
						<br />
					</div>
				</div>
				<div className="slds-col slds-small-size_12-of-12 slds-medium-size_6-of-12 slds-size_7-of-12 slds-p-around_none">
					<div className="error-img-wrapper">
						<img src={image} alt="Not Found" />
					</div>
				</div>
			</div>
		</div>
	);
}

InternalServerError.propTypes = {
	showFeedback: PropTypes.bool,
	eventId: PropTypes.any,
};

export default InternalServerError;
