import React from "react";

export default [
	{
		id: "1724040180000",
		type: "Product Update",
		title: "Message access, WhatsApp group broadcasts, and quick replies",
		content: (
			<div>
				Define message access permissions for profiles, broadcast to WhatsApp
				groups, and create quick replies.
			</div>
		),
		link: "https://blog.peppercloud.com/message-access-permission-whatsapp-group-broadcasts-quick-replies",
		linkName: "Know more",
	},
	{
		id: "1721113515000",
		type: "Product Update",
		title:
			"Broadcast assignment, data administration in CRM, and other enhancements",
		content: (
			<div>
				Automate broadcast assignments, create automatic or manual data backups,
				and manage group chat privacy.
			</div>
		),
		link: "https://blog.peppercloud.com/broadcast-assignment-data-administration-in-crm-and-other-enhancements",
		linkName: "Know more",
	},
	{
		id: "1716455820000",
		type: "Product Update",
		title:
			"WhatsApp templates in CRM, mention users in group, and other features",
		content: (
			<div>
				Create WhatsApp templates within CRM, link conversations to accounts and
				opportunities, pin important conversations, and use “@” to address
				members in group chats.
			</div>
		),
		link: "https://blog.peppercloud.com/whatsapp-templates-in-crm-associate-conversations-other-features",
		linkName: "Know more",
	},
	{
		id: "1713946320001",
		type: "Product Update",
		title: "Broadcast to opportunities, edit messages, and other enhancements",
		content: (
			<div>
				Schedule and send broadcasts to opportunities, edit sent messages on
				WhatsApp Web and Telegram channels. Explore other feature enhancements
			</div>
		),
		link: "https://blog.peppercloud.com/broadcast-to-opportunities-edit-messages-and-other-enhancements/",
		linkName: "Know more",
	},
	{
		id: "1713946320000",
		type: "Announcement",
		title: "Update: WhatsApp Web channel integration",
		content: (
			<div>
				Meta&apos;s frequent security updates might disrupt WhatsApp Web channel
				functionality. Use WhatsApp Cloud API alongside WhatsApp Web for
				uninterrupted conversations.
			</div>
		),
	},
	{
		id: "1710933600000",
		type: "Product Update",
		title: "WhatsApp Flows, archive conversations, and other features",
		content: (
			<div>
				Automatically capture leads, enable easy appointment bookings, and more
				with WhatsApp Flows. Archive conversations, create tasks, and execute
				bulk actions in the message module.
			</div>
		),
		link: "https://blog.peppercloud.com/whatsapp-flows-message-module-features-other-enhancements",
		linkName: "Know more",
	},
	{
		id: "1704451320000",
		type: "Product Update",
		title: "Introducing QuickBooks integration, Message dashboard, and more",
		content: (
			<div>
				Create and track estimates with QuickBooks integration. Monitor
				resolution time, broadcast performance, and other metrics with message
				dashboard. Set up multiple branches within workflows. Explore other
				feature enhancements.
			</div>
		),
		link: "https://blog.peppercloud.com/quickbooks-integration-message-dashboard-workflow-branching-and-other-features",
		linkName: "Know more",
	},
	{
		id: "1699529514120",
		type: "Product Update",
		title: "New features: Click-to-chat ad lead tracking and more",
		content: (
			<div>
				Seamlessly capture and track Facebook Messenger ad leads. Automatically
				cross-verify and link new conversations with records.
			</div>
		),
		link: "https://blog.peppercloud.com/track-click-to-chat-ad-leads-and-link-conversation-with-records",
		linkName: "Know more",
	},
	{
		id: "1696577793637",
		type: "Product Update",
		title: "WhatsApp catalog and other enhancements",
		content: (
			<div>
				Showcase your products with WhatsApp catalogs. Enrich AssistAI Knowledge
				Base with URLs and links. Search conversations effortlessly within the
				message module. Explore other enhancements.
			</div>
		),
		link: "https://blog.peppercloud.com/catalogs-assistai-and-other-enhancements/",
		linkName: "Know more",
	},
	{
		id: "1693318020000",
		type: "Product Update",
		title: "WhatsApp Group, AssistAI knowledge base, and other features",
		content: (
			<div>
				Create WhatsApp group with your customers. Use Knowledge Base & AssistAI
				to auto-respond to queries. Comply with data privacy laws with customer
				consent management.
			</div>
		),
		link: "https://blog.peppercloud.com/whatsapp-groups-assistai-knowledge-base-and-other-features",
		linkName: "Know more",
	},
	{
		id: "1690183080000",
		type: "Product Update",
		title: "Aircall integration, AssistAI panel, and other enhancements",
		content: (
			<div>
				Talk to your customers seamlessly with Aircall integration. Manage and
				perform activities through intuitive AssistAI panel. Leverage message
				module and workflow enhancements to boost sales efficiency.
			</div>
		),
		link: "https://blog.peppercloud.com/aircall-integration-assistai-chatbot-and-more?utm_source=Product+notification&utm_medium=notification&utm_campaign=Blog+promotion",
		linkName: "Know more",
	},
	{
		id: "1684747500000",
		type: "Product Update",
		title: "SMTP Configuration and other enhancements",
		content: (
			<div>
				Configure SMTP for seamless email communication. Set up WhatsApp
				notifications to enhance workflow automation. Enjoy improved messaging
				capabilities such as replying to specific messages, adding reactions to
				messages, and deleting sent messages.
			</div>
		),
		link: "https://blog.peppercloud.com/smtp-configuration-and-other-features",
		linkName: "Know more",
	},
	{
		id: "1681126380000",
		type: "Announcement",
		title: "WhatsApp pricing updates: Effective from June 01, 2023",
		content: (
			<div>
				WhatsApp is introducing a new pricing structure in which the
				conversations will be charged according to the categories.
			</div>
		),
		link: "https://blog.peppercloud.com/whatsapp-api-pricing-everything-you-need-to-know/",
		linkName: "Know more",
	},
	{
		id: "1680985800000",
		type: "Product Update",
		title:
			"New features: Channel access permissions, Website chat widget, AssistAI",
		content: (
			<div>
				Make messaging channels private with access permissions. Communicate
				with website visitors through the chat widget. Summarise and modify text
				messages using AI-powered AssistAI.
			</div>
		),
		link: "https://blog.peppercloud.com/channel-access-permissions-chat-widget-assistai",
		linkName: "Know more",
	},
	{
		id: "1677670380000",
		type: "Announcement",
		title: "WhatsApp pricing updates - Effective from April 01, 2023",
		content: (
			<div>
				Only user-initiated conversations will qualify for free-tier
				conversations. Business-initiated conversations will no longer be
				eligible.
			</div>
		),
		link: "https://blog.peppercloud.com/whatsapp-api-pricing-everything-you-need-to-know/",
		linkName: "Know more",
	},
	{
		id: "1677495660265",
		type: "Product Update",
		title: "Telegram group chat and other feature-enhancements",
		content: (
			<div>
				Access Telegram group from within CRM. Send WhatsApp messages with just
				a click. &quot;Log out&quot; and &quot;Reconnect&quot; WhatsApp Web
				accounts with an easy tap on the icons.
			</div>
		),
		link: "https://blog.peppercloud.com/telegram-group-chat-and-whatsapp-messaging/",
		linkName: "Know more",
	},
	{
		id: "1675776120000",
		type: "Product Update",
		title:
			"WhatsApp Web and Workflow automation features to enhance your productivity",
		content: (
			<div>
				Connect your WhatsApp Business with CRM and chat with all your contacts
				with ease.
			</div>
		),
		link: "https://blog.peppercloud.com/pepper-cloud-crm-integrates-with-whatsapp/",
		linkName: "Know more",
		secondContent: (
			<div>
				Streamline your processes by enabling workflow automation in your CRM.
			</div>
		),
		secondLink: "https://blog.peppercloud.com/crm-workflow-automation-update/",
	},
	{
		id: "1672302480000",
		type: "Product Update",
		title: "Boost your communication with Telegram",
		content: (
			<div>
				Integrate Telegram with your CRM to interact with your prospects,
				convert them into leads, send media files, respond to messages, and
				fast-track your sales process.
			</div>
		),
		link: "https://blog.peppercloud.com/telegram-crm-integration-update/",
		linkName: "Know more",
	},
	{
		id: "1670406319970",
		type: "Product Update",
		title: "Your CRM is now integrated with Instagram!",
		content: (
			<div>
				Talk to your leads and customers on Instagram, right from your CRM.
				Engage with them through rich-media messages and send instant replies
				with pre-made message templates.
			</div>
		),
		link: "https://blog.peppercloud.com/intagram-crm-integration-update/",
		linkName: "Know more",
	},
	{
		id: "1667989474138",
		type: "Product Update",
		title: "Integrate seamlessly with WhatsApp Cloud",
		content: (
			<div>
				Connect to your WhatsApp directly from CRM. Send image, video, and
				document templates to your prospects without any hassle.
			</div>
		),
		link: "https://blog.peppercloud.com/whatsapp-cloud-api-crm-integration-update/",
		linkName: "Know more",
	},
	{
		id: "1665811054060",
		type: "Product Update",
		title: "MailerLite, LinkedIn, Shopify, UTM and more...",
		content: (
			<div>
				Integrate with MailerLite to send email campaigns; automatically track
				the lead sources through UTM tracking; import Leads and Contacts from
				Shopify and LinkedIn; and do much more with your CRM.
			</div>
		),
		link: "https://bit.ly/3fKanbN",
		linkName: "Know more",
	},
	{
		id: "1659515668636",
		type: "Product Update",
		title:
			"Tags, Global search, Zapier integrations, and other functionalities",
		content: (
			<div>
				Explore the record tagging, Kanban view configuration, quotation clone,
				Zoom integration, Zapier integrations, and many other features and
				functionalities with our latest update.
			</div>
		),
		link: "https://blog.peppercloud.com/latest-crm-integrations-product-updates-and-enhancements-july-2022/",
		linkName: "Know more",
	},
	{
		id: "1654679755386",
		type: "Product Update",
		title: "Integrate Office 365 calendar & Facebook lead forms with your CRM",
		content: (
			<div>
				Sync your Office 365 calendar with your CRM and access all your
				meetings, appointments, and events on the go. Integrate Facebook lead ad
				forms with CRM to effortlessly port and view all your leads in one
				place.
			</div>
		),
		link: "https://bit.ly/3t8DR79",
		linkName: "Know more",
	},
	{
		id: "1654679755385",
		type: "Product Update",
		title:
			"Facebook messenger integration, Google calendar integration and more",
		content: (
			<div>
				Integrate Google calendar and CRM to get instant visibility into your
				upcoming events and for effortless planning of your day. Integrate
				Facebook messenger with CRM to talk with your Facebook leads right from
				your CRM and port them into your CRM automatically.
			</div>
		),
		link: "https://bit.ly/3Q5HLr2",
		linkName: "Know more",
	},
	{
		id: "1644537600000",
		type: "Product Update",
		title: "Chat with your Facebook and WhatsApp Leads right from your CRM",
		content: (
			<div>
				Now chat with your leads and contacts from within the CRM. Multiple
				users can respond on the same WhatsApp number. Send personal or bulk
				messages as per your convenience. Assign conversations and collaborate.
			</div>
		),
		link: "http://blog.peppercloud.com/channel-integration/",
		linkName: "Know more",
	},
	{
		id: "1638273950161",
		type: "Product Update",
		title: "Close deals faster with XERO integration",
		content: (
			<div>
				Integrate your CRM with XERO software to generate quotations, track the
				status of sent quotations, and monitor your accounts right from your
				CRM.
			</div>
		),
		link: "https://blog.peppercloud.com/crm-xero-integration/",
		linkName: "Know more",
	},
	{
		id: "1632373489926",
		type: "Product Update",
		title:
			"Account forecast view, easy account transfer and other product updates",
		content: (
			<div>
				Get a glimpse of your accounts forecast with &ldquo;Forecast
				view&rdquo;. Transfer the accounts from one team member to other
				effortlessly. Experience improved contact import process.
			</div>
		),
		link: "https://blog.peppercloud.com/experience-effortless-account-transfer-and-much-more",
		linkName: "Know more",
	},
	{
		id: "1629876287177",
		type: "Product Update",
		title: "Account Planning - Launching “Aggregated View”",
		content: (
			<div>
				View all the details of your existing accounts in one screen. You can
				customise the layout and sort them based on value and number of
				opportunities.
			</div>
		),
		link: "https://blog.peppercloud.com/account-management-made-easy",
		linkName: "Know more",
	},
	{
		id: "1628227786299",
		type: "Announcement",
		title:
			"A community for Pepper Cloud CRM users and sales enthusiasts. Get your exclusive pass.",
		content: (
			<div>
				Join Pepper Cloud Sales Community on Facebook to decode sales
				strategies, request and get a sneak peek of new product features, access
				free training content, network with other users, etc.
			</div>
		),
		link: "https://peppercloud.com/redirect?url=https%3A%2F%2Fwww.facebook.com%2Fgroups%2Fpeppercloudsalescommunity&utm_source=app&utm_medium=button&utm_campaign=FB&utm_id=FBGroup",
		linkName: "Join now",
	},
	{
		id: "1627995352571",
		type: "Product Update",
		title: "Better look and convenient interface",
		content: (
			<div>
				Business and activity details page now has a new look and enhanced user
				interface. Experience ease of navigation, improved work flow, and a
				better user experience.
			</div>
		),
		link: "https://blog.peppercloud.com/user-interface-enhancements/",
		linkName: "Learn more",
	},
	{
		id: "1623067977400",
		type: "Product Update",
		title: "Access your emails and contacts from your CRM account",
		content: (
			<div>
				Avoid constant tab switching! Access your email conversations and your
				contacts right from your CRM account.
				<br />
				With our brand new &quot;Inbox&quot; feature, send emails and manage
				responses on the go. Integrate google contacts or outlook contacts and
				get complete details of your contacts in your CRM database.
			</div>
		),
		link: "https://blog.peppercloud.com/introducing-crm-inbox/",
		linkName: "Learn more",
	},
];
