/* eslint-disable react/no-danger */
import * as React from "react";
import { Dispatch } from "redux";
import capitalize from "lodash/capitalize";

import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";
import RecordAvatar from "@components/TinyComponents/RecordAvatar";
import { setToastMessage } from "@containers/App/actions";

import { getAvatarTitle, moduleIdToName } from "@utils/common";
import { getRequest } from "@utils/request";
import { BusinessRecord } from "@interfaces/business-record";

import { Hit, Source } from "./types";

const getModuleName = (source: Source): string => {
	switch (source.moduleId) {
		case "ACTM":
			return capitalize(source.activityType);
		default: {
			return moduleIdToName[source.moduleId];
		}
	}
};

const getTitle = (
	record: Source,
	highlight: Record<string, string[]>,
): string => {
	if (record.moduleId === "QTM") {
		return highlight.quoteNumber
			? highlight.quoteNumber[0]
			: record.quoteNumber;
	}

	const { records = {} } = record;

	const recs = { ...records };

	Object.keys(highlight).forEach(h => {
		const key = h.replace("records.", "");
		recs[key] = highlight[h][0];
	});

	return getAvatarTitle(recs).title;
};

const getFieldName = (
	key: string,
	fieldNames: Record<string, string>,
): string => {
	let fieldId = key.replace("records.", "");
	fieldId = fieldId.replace(".text", "");
	return fieldNames[fieldId];
};

const getAvatar = (record: Source): JSX.Element => {
	switch (record.moduleId) {
		case "ACTM":
		case "QTM":
			return (
				<Icon
					size="medium"
					name={`pc-${
						record.moduleId === "QTM" ? "QUOTATION" : record.activityType
					}-avatar`}
					category="custom"
				/>
			);
		case "AM":
		case "OM":
			return (
				<Icon
					size="medium"
					name={`pc-${moduleIdToName[record.moduleId].toLowerCase()}-avatar`}
					category="custom"
				/>
			);
		default:
			return (
				<RecordAvatar
					variant="user"
					title={getAvatarTitle(record.records).title}
					size="medium"
					imageUrl={(record.records?.F133 as string) || ""}
				/>
			);
	}
};

const ResultCard = ({
	record: { _source, highlight = {}, _id },
	fieldNames,
	dispatch,
	history,
}: {
	record: Hit;
	fieldNames: Record<string, string>;
	dispatch: Dispatch;
	history: unknown;
}): JSX.Element => {
	const getAssociatedModule = async (): Promise<{
		moduleName: string;
		businessRecordId: string;
	}> => {
		let moduleName = "";
		let businessRecordId = "";
		try {
			const {
				data: {
					data: { businesses },
				},
			}: { data: { data: { businesses: BusinessRecord[] } } } =
				await getRequest(`/api/v2/associations/${_id}/records`);
			if (businesses.length) {
				const { moduleId, id } = businesses[0];
				businessRecordId = id;
				moduleName = moduleIdToName[moduleId];
			} else {
				const message = {
					type: "error",
					body: {
						title: "Associated record not found",
						details:
							"The record associated with this document has been deleted.",
					},
				};
				dispatch(setToastMessage(message));
			}
		} catch (err) {
			// something happened
		}

		return {
			moduleName,
			businessRecordId,
		};
	};

	const getLink = async (): Promise<void> => {
		let url = "";
		switch (_source.moduleId) {
			case "ACTM": {
				const { activityType } = _source;
				if (activityType === "EVENT" || activityType === "TASK") {
					url = `/detail/activity/${activityType.toLowerCase()}/${_id}`;
				} else {
					const { moduleName, businessRecordId } = await getAssociatedModule();
					if (moduleName) {
						url = `/detail/${moduleName.toLowerCase()}/${businessRecordId}?tab=${activityType}`;
					}
				}
				break;
			}
			case "QTM":
				if (_source.oppId) {
					url = `/detail/opportunity/${_source.oppId}?tab=QUOTATION`;
				}
				break;
			default: {
				const mdouleName = getModuleName(_source).toLowerCase();
				url = `/detail/${mdouleName}/${_id}`;
			}
		}

		if (!url) return;

		(history as { push: (u: string) => void }).push(url);
	};
	return (
		<Button variant="link" className="result-card" onClick={getLink}>
			<div>{getAvatar(_source)}</div>
			<div className="slds-grid slds-grid_vertical slds-has-flexi-truncate result-content medium-device">
				<div className="title">
					<p
						className="slds-truncate"
						dangerouslySetInnerHTML={{ __html: getTitle(_source, highlight) }}
					/>
				</div>
				<span className="slds-truncate record-info">
					in {getModuleName(_source)}
					{" . "}
					{Object.keys(highlight).map(r => (
						<span>
							{getFieldName(r, fieldNames)}
							{" . "}
							<span dangerouslySetInnerHTML={{ __html: highlight[r][0] }} />
							{" . "}
						</span>
					))}
				</span>
			</div>
		</Button>
	);
};

export default ResultCard;
