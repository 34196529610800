import React from "react";

import image from "@assets/img/403-error-img.svg";
import "./styles/page-not-found.scss";

function AccessDenied() {
	return (
		<div className="page-not-found">
			<div className="slds-grid slds-wrap slds-gutters">
				<div className="slds-col slds-small-size_12-of-12 slds-medium-size_6-of-12 slds-size_5-of-12 slds-p-around_none">
					<div className="page-not-found-cnt">
						<h1>Whoops! it seems you have entered a no-fly zone</h1>
						<h2>
							Please contact your captain (a.k.a. admin) for authorization.
						</h2>
						<br />
						<br />
						<a href="/" className="btn btn-dark back-to-home-btn">
							BACK TO HOME
						</a>
					</div>
				</div>
				<div className="slds-col slds-small-size_12-of-12 slds-medium-size_6-of-12 slds-size_7-of-12 slds-p-around_none">
					<div className="error-img-wrapper access-denied">
						<img src={image} alt="Not Found" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AccessDenied;
