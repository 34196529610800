import Mixpanel from "mixpanel-browser";
import { takeLatest, call, put, all } from "redux-saga/effects";
import {
	LOAD_MENU_REQUEST,
	LOAD_USER_ROLES_AND_PROFILES,
	LOAD_MENU_API,
	LOAD_USER_INFO_API,
	SENDING_REQUEST,
	LOAD_USER_INTEGRATIONS_API,
} from "@containers/App/constants";
import {
	userInfoLoaded,
	menusLoaded,
	loadUserInfo,
	setToastMessage,
} from "@containers/App/actions";

// Utilities and Services
import { getRequest } from "@utils/request";
import { deleteCookies } from "@services/getSetCookie";
import generateErrorMessage from "@services/generateErrorMessage";

import productUpdates from "@components/AnnouncementOverlay/announcements";

export function* loadMenuSaga() {
	try {
		// Load menu
		const response = yield call(getRequest, LOAD_MENU_API.url);
		const { data } = response.data;
		yield put(menusLoaded(data));
		yield put(loadUserInfo());
	} catch (error) {
		if (error.response) {
			const { data } = error.response;
			yield put(setToastMessage({ ...data }));
		} else {
			const errorMessage = generateErrorMessage(error);
			yield put(setToastMessage(errorMessage));
		}
	}
}

export function* loadUserProfilesAndRoles() {
	try {
		const [response, integrationResp, perResp] = yield all([
			call(getRequest, LOAD_USER_INFO_API.url),
			call(getRequest, LOAD_USER_INTEGRATIONS_API),
			call(getRequest, "/api/v2/personalization"),
		]);
		const { integrations } = integrationResp.data;
		const { data } = response.data;
		data.integrations = integrations;
		if (window._mfq) {
			window._mfq.push(["setVariable", "USER", data.email]);
		}
		const persObj = perResp.data.data;
		const hasAnnuncementInLS = Object.keys(persObj).includes("announcements");

		const currentProductUpdateIds = productUpdates.map(e => e.id);
		const updatesIdObj = {};
		productUpdates.forEach(e => {
			updatesIdObj[e.id] = false;
		});
		if (!hasAnnuncementInLS) {
			localStorage.setItem("announcements", JSON.stringify(updatesIdObj));
		}
		let messageSetting = {};
		let pinnedConversations = [];
		Object.keys(persObj).forEach(d => {
			if (d === "announcements") {
				const viewdUpdates = { ...persObj[d] };
				Object.keys(viewdUpdates).forEach(e => {
					if (!currentProductUpdateIds.includes(e)) {
						delete viewdUpdates[e];
					}
				});
				localStorage.setItem(
					d,
					JSON.stringify({ ...updatesIdObj, ...viewdUpdates }),
				);
			} else if (d === "messageSetting") {
				messageSetting = persObj[d];
				localStorage.setItem(d, JSON.stringify(persObj[d]));
			} else if (d === "pinnedConversations") {
				pinnedConversations = persObj[d];
				localStorage.setItem(d, JSON.stringify(persObj[d]));
			} else {
				localStorage.setItem(d, JSON.stringify(persObj[d]));
			}
		});

		yield put(
			userInfoLoaded({
				...data,
				announcement: false,
				messageSetting,
				pinnedConversations,
			}),
		);
		Mixpanel.identify(data.id);
		Mixpanel.people.set({
			$email: data.email,
			org: data.companyName,
		});
	} catch (error) {
		// Make toast for error
		// eslint-disable-next-line no-console
		console.error(error);
	} finally {
		yield put({ type: SENDING_REQUEST, sending: false });
	}
}

export function* logoutSaga() {
	try {
		yield call(getRequest, "/api/v2/auth/logout");
		deleteCookies();
		localStorage.setItem("logout-event", `logout${Math.random()}`);
		window.location.href = "/login";
		// yield put(userInfoLoaded(data));
	} catch (error) {
		const message = generateErrorMessage(error);
		yield put(setToastMessage(message));
		// yield put({ type: SIDEBAR_PAGE_ERROR, error: error.message });
	} finally {
		// yield put({ type: SENDING_REQUEST, sending: false });
	}
}

// Individual exports for testing
export default function* pageLayoutSaga() {
	yield takeLatest(LOAD_MENU_REQUEST, loadMenuSaga);
	yield takeLatest(LOAD_USER_ROLES_AND_PROFILES, loadUserProfilesAndRoles);
	yield takeLatest("LOGOUT", logoutSaga);
}
