import React from "react";
import PropTypes from "prop-types";
import Icon from "@salesforce/design-system-react/components/icon";

const Tag = ({ tag }) => {
	const handlClick = link => {
		if (link) window.open(link, "_blank");
	};

	return (
		<div
			className={`pc-status-TAGS ${tag.link ? "pc-cursor" : ""}`}
			title={tag.value}
		>
			{tag.isMetaTag ? (
				<Icon name="pc-meta" category="custom" size="small" />
			) : (
				<span className="status" style={{ backgroundColor: tag.color }}></span>
			)}
			<p className="txt">{tag.value}</p>
			{tag.link && (
				<span
					role="button"
					tabIndex={0}
					onKeyDown={() => undefined}
					className="pc-redirect"
					onClick={() => handlClick(tag.link)}
				>
					<Icon name="open-in-new-page" category="custom" size="small" />
				</span>
			)}
		</div>
	);
};

Tag.propTypes = {
	tag: PropTypes.object,
};

export default Tag;
