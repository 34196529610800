import React from "react";
import { useIntl } from "react-intl";

import cx from "classnames";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import Button from "@salesforce/design-system-react/components/button";
// import Icon from "@salesforce/design-system-react/components/icon";
import localStorage from "@services/localStorage";

import announcements from "./announcements";

/**
 * Content Schema
 * @param {*} props
 * { "id", "title", "content", "link", "timestamp" }
 */
function AnnouncementContent(props) {
	const redirect = link => {
		window.open(link, "_blank");
		props.closeOverlay();
	};

	return (
		<div className="pc-announcement-content-card" id={props.id} role="listitem">
			<div className="pc-content-tags">
				<div>
					<span
						className={cx(
							"tag",
							String(props.type).replace(" ", "-").toLowerCase(),
						)}
					>
						<span>{props.type}</span>
					</span>
					<span className="date-txt">
						{moment(new Date(+props.id)).format("MMM DD, YYYY")}
					</span>
				</div>
				{!localStorage.getItem("announcements", props.id) && (
					<span className="pc-unread" />
				)}
			</div>
			<h3 className="pc-content-title">{props.title}</h3>
			<p className="pc-content">{props.content}</p>
			{Boolean(props.link) && (
				<div className="pc-content-read-more-btn">
					<Button
						variant="link"
						onClick={() => redirect(props.link)}
						label={props.linkName || "Learn More"}
					/>
				</div>
			)}

			{props.secondContent && (
				<React.Fragment>
					<hr className="pc-announcement-hr" />
					<p className="pc-content slds-m-top_medium">{props.secondContent}</p>
					{Boolean(props.secondLink) && (
						<div className="pc-content-read-more-btn">
							<Button
								variant="link"
								onClick={() => redirect(props.secondLink)}
								label={props.linkName || "Learn More"}
							/>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
}

AnnouncementContent.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	link: PropTypes.string,
	linkName: PropTypes.string,
	closeOverlay: PropTypes.func,
	secondContent: PropTypes.string,
	secondLink: PropTypes.string,
};

function AnnouncementContainer(props) {
	const intl = useIntl();

	return (
		<React.Fragment>
			<div className="pc-announcement-header">
				{intl.formatMessage({ id: "components.AnnouncementContainer.header" })}
				<Button
					iconCategory="utility"
					iconName="close"
					className="pc-close"
					onClick={props.closeOverlay}
				/>
			</div>
			<div className="pc-announcement-content">
				{announcements.length ? (
					<div className="pc-content-container" role="list">
						{announcements.map(content => (
							<AnnouncementContent
								key={content.id}
								{...content}
								closeOverlay={props.closeOverlay}
							/>
						))}
					</div>
				) : (
					<div className="pc-no-updates">
						{intl.formatMessage({
							id: "components.AnnouncementContainer.noUpdates",
						})}
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

AnnouncementContainer.propTypes = {
	closeOverlay: PropTypes.func.isRequired,
	intl: PropTypes.object,
};

export default AnnouncementContainer;
