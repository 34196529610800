import React from "react";
import cx from "classnames";
import Input from "@salesforce/design-system-react/components/input";
import { EmailInputType } from "@custom-types/input-type";

const EmailInput: React.FC<EmailInputType> = (props): JSX.Element => {
	const handleChange = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value } = event.target as HTMLInputElement;

		props.handleChange({
			typeCode: props.typeCode,
			fieldName: props.fieldName,
			isRequired: props.isRequired,
			fieldId: props.fieldId,
			value,
			isDataMasked: props.isDataMasked,
		});
	};

	const handleBlur = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value: (event.target as HTMLInputElement).value.trim(),
			typeCode: props.typeCode,
			isRequired: props.isRequired,
			isDataMasked: props.isDataMasked,
		};
		props.handleBlur(record);
	};

	return (
		<Input
			id={props.fieldId}
			disabled={props.disable}
			name={props.name || props.fieldName || props.fieldId}
			onChange={handleChange}
			onBlur={handleBlur}
			type="email"
			value={props.value}
			className={cx(props.className, "pc-modal-text-box")}
			maxLength={props.max}
			autoComplete="off"
			placeholder={props.placeholder}
			readOnly={props.readOnly}
		/>
	);
};

export default EmailInput;
