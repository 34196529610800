import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import ClickAwayListener from "react-click-away-listener";

import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";
import ChatBody from "./ChatBody";

ChatBot.propTypes = {
	handleChatBotToggle: PropTypes.func,
};
function ChatBot({ handleChatBotToggle }) {
	const [state, setState] = useState({
		isKnowledgebase: Boolean(
			JSON.parse(localStorage.getItem("isKnowledgebase")),
		),
	});

	const { isKnowledgebase } = state;

	const escFunction = React.useCallback(event => {
		if (event.keyCode === 27) {
			handleChatBotToggle(false);
		}
	}, []);

	React.useEffect(() => {
		localStorage.setItem("isKnowledgebase", isKnowledgebase);
	}, [isKnowledgebase]);

	React.useEffect(() => {
		document.addEventListener("keydown", escFunction);

		return () => {
			document.removeEventListener("keydown", escFunction);
		};
	}, []);

	return (
		<ClickAwayListener
			onClickAway={() => handleChatBotToggle(false)}
			focusEvent
		>
			<section className="pc-chatBot-content">
				<header className="msger-header">
					<div className="msger-ader-titlehe">
						<div className="bot-avatar">
							<Icon size="large" name="pc-glare-white" category="custom" />
						</div>
						<div className=" bot-name-description">
							<h3>AssistAI</h3>
							<p>AI-powered chatbot</p>
						</div>
					</div>
					<div className="msger-header-options">
						<Button
							iconCategory="custom"
							iconName="pc-know-more-white"
							className="how-it-work-button"
							label="User Manual"
							iconSize="medium"
							onClick={() => {
								window.open(
									"http://blog.peppercloud.com/assistai-panel-user-guide/",
									"_blank",
								);
							}}
						/>
						<Button
							iconCategory="custom"
							iconName="pc-close-light-new"
							className="pc-close"
							onClick={() => handleChatBotToggle(false)}
						/>
					</div>
					<div className="knowledge-base-switch-block">
						<div className="switch-block">
							<Button
								className={cx(!isKnowledgebase && "active")}
								onClick={() => {
									setState(st => ({
										...st,
										isKnowledgebase: false,
									}));
								}}
							>
								AssistAI
							</Button>
							<Button
								className={cx(isKnowledgebase && "active")}
								onClick={() => {
									setState(st => ({
										...st,
										isKnowledgebase: true,
									}));
								}}
							>
								Knowledge base
							</Button>
						</div>
					</div>
				</header>
				<ChatBody
					active={!isKnowledgebase}
					handleChatBotToggle={handleChatBotToggle}
					knowledgebase={false}
					key="assist-ai-chat"
				/>
				<ChatBody
					active={isKnowledgebase}
					handleChatBotToggle={handleChatBotToggle}
					knowledgebase
					key="knowledge-base-chat"
				/>
			</section>
		</ClickAwayListener>
	);
}

export default ChatBot;
