import React, { useRef, useEffect, ReactNode, ReactNodeArray } from "react";

declare type ClickAwayListenerProps = {
	children: ReactNode | ReactNodeArray;
	onClickAway: (event: React.MouseEvent<HTMLElement>) => void;
};

function ClickAwayListener({
	children,
	onClickAway,
}: ClickAwayListenerProps): JSX.Element {
	const wrapperRef = useRef<{ contains: (target: EventTarget) => boolean }>();

	useEffect(() => {
		if (!onClickAway) return;
		const handleClickOutside = (event: React.MouseEvent<HTMLElement>): void => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				onClickAway(event);
			}
		};

		// Bind the event listener
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		document.addEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line consistent-return
		return function (): void {
			// Unbind the event listener on clean up
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClickAway, wrapperRef]);

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<div id="click-away-wrapper" ref={wrapperRef}>
			{children}
		</div>
	);
}

export default ClickAwayListener;
