// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import Select, { components as comps } from "react-select";
import CreatableSelect from "react-select/creatable";
import Icon from "@salesforce/design-system-react/components/icon";
import { patchRequest } from "@utils/request";
import {
	themes,
	dropdownStyles,
	ClearIndicator,
	DropdownIndicator,
	Option,
	MultiValueContainer,
	MaxLengthInput,
	ValueContainer,
} from "@components/TinyComponents/Dropdown";
import tagFields from "@utils/constants/tags";
import colors from "@utils/constants/colors";
import { loadScreenFilters } from "@containers/Business/Listing/actions";

const moduleName = {
	LM: "lead",
	AM: "account",
	CM: "contact",
	OM: "opportunity",
};

const MultiValueRemove = props => {
	if (props.data.isMetaTag && props.data?.moduleId === "MSG") {
		return (
			<span
				style={{ color: "#878787", padding: "0 5px 0 0" }}
				title="You cannot remove meta tags"
			>
				<Icon category="custom" name="pc-user-close" />
			</span>
		);
	}
	return <comps.MultiValueRemove {...props} />;
};

class TagsInput extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleChange = (input1, input2) => {
		this.onMenuOpen();
		const event = [];
		input1.forEach(element => {
			if (event.length && !event.find(v => v.value === element.value)) {
				event.push(element);
			} else if (!event.length) {
				event.push(element);
			}
		});
		input2.forEach(element => {
			if (event.length && !event.find(v => v.value === element.value)) {
				event.push(element);
			} else if (!event.length) {
				event.push(element);
			}
		});
		this.props.handleChange({
			fieldName: this.props.fieldName,
			typeCode: this.props.typeCode,
			fieldId: this.props.fieldId,
			isRequired: this.props.isRequired,
			isMultiSelect: true,
			label: event ? event.map(e => e.label) : [],
			value: event ? event.map(e => e.value) : [],
		});
	};

	onCreateOption = async inputValue => {
		const bodyParams = {
			fieldIds: [tagFields[this.props.moduleId]],
			params: [
				{
					key: inputValue,
					value: inputValue,
					color: `${colors[Math.floor(Math.random() * colors.length)]}`,
				},
			],
		};

		const data = await patchRequest("/api/v2/tag/create-new", bodyParams);
		const updatedParams = (this.props.options &&
			this.props.options.concat([data.data.data])) || [data.data.data];
		this.props.updateFieldParams(this.props.fieldId, updatedParams);
		if (this.props.dispatch) {
			this.props.dispatch(loadScreenFilters(moduleName[this.props.moduleId]));
		}
	};

	isValidNewOption = (inputValue, selectValue, options) => {
		const input = inputValue.toLowerCase().trim();
		const comma = input.includes(",");
		if (inputValue.length > 1) {
			const notValid = options.find(
				val => input === val.value.toLowerCase().trim(),
			);
			if (notValid || comma) {
				return false;
			}
			return true;
		}
		return false;
	};

	onMenuOpen = () => {
		const selectedEl = document.getElementsByClassName("messageBody");
		if (selectedEl && selectedEl.length) {
			selectedEl[0].scrollTop = selectedEl[0].scrollHeight + 30;
		}
	};

	render() {
		const {
			props: { value, userInfo, valueLabel, options, moduleId },
			props,
		} = this;
		let Sortedoptions = options;
		if (props?.fieldId === "F139" && Sortedoptions)
			Sortedoptions = Sortedoptions.map(o => ({ ...o, moduleId }));
		const isTag = tagFields[moduleId];
		let valueObj = null;
		// added the check "-" to solve the filter issue.
		const hiddenOption = [];
		if (valueLabel && value && props?.fieldId !== "F139") {
			valueObj = valueLabel
				? valueLabel.map((l, i) => ({ label: l, value: value[i] }))
				: [];
		} else if (Sortedoptions) {
			valueObj =
				props.value &&
				props.value.map(val => Sortedoptions.find(opt => opt.value === val));
			if (props.value) {
				props.value.forEach(g => {
					if (g && !Sortedoptions.find(opt => opt.value === g)) {
						hiddenOption.push({ label: g, value: g });
					}
				});
			}
		} else {
			valueObj = null;
		}

		if (moduleId === "MSG" && valueObj && valueObj.length) {
			valueObj.forEach(o => {
				if (o && o.isMetaTag) hiddenOption.push(o);
			});
		}

		let items = [];
		const ActiveOptions = this.props.options || [];
		if (ActiveOptions) {
			if (moduleId === "MSG" && this.props?.fieldId === "F139")
				items = ActiveOptions.filter(
					o => o.status === "ACTIVE" && !o.isMetaTag,
				);
			else if (
				(moduleId === "LM" || moduleId === "CM") &&
				this.props?.fieldId === "F139"
			)
				items = ActiveOptions.filter(o => o.status === "ACTIVE" && o.isMetaTag);
			else items = ActiveOptions.filter(o => o.status === "ACTIVE");
		} else {
			items = [];
		}
		if (
			isTag &&
			userInfo.profile.permissions.TAG.CREATE &&
			!props.hideCreatable
		) {
			return (
				<div className="pc-form-field-with-tooltip">
					<CreatableSelect
						isMulti
						isClearable={props.isClearable}
						isDisabled={props.disable}
						options={items}
						defaultValue={props.defaultValue}
						components={{
							ClearIndicator,
							DropdownIndicator,
							Option,
							MultiValueContainer,
							ValueContainer,
							Input: MaxLengthInput,
							MultiValueRemove,
						}}
						isValidNewOption={this.isValidNewOption}
						value={valueObj}
						menuShouldBlockScroll={!props.inline}
						menuPosition="absolute"
						menuPlacement="auto"
						menuPortalTarget={!props.inline && document.body}
						onChange={event => this.handleChange(event, hiddenOption)}
						closeMenuOnSelect={false}
						onMenuOpen={this.onMenuOpen}
						className={`${props.className} ${
							!props.disable
								? "pc-modal-select-box"
								: "pc-modal-select-box pc-modal-select-box-disabled"
						}`}
						theme={themes}
						styles={
							props.dropdownStyles || {
								...dropdownStyles,
								valueContainer: base => ({
									...base,
									maxHeight: "100px",
									overflowY: "scroll",
									"::-webkit-scrollbar": {
										width: "4px !important",
									},
									"::-webkit-scrollbar-track": {
										background: "#fff !important",
										boxShadow: "none !important",
									},
									"::-webkit-scrollbar-thumb": {
										background: "#eee !important",
									},
									"::-webkit-scrollbar-thumb:hover": {
										background: "#fff !important",
									},
								}),
								...(props.inline && {
									control: styles => ({
										...styles,
										minWidth: "200px",
										maxWidth: "200px",
									}),
								}),
								multiValue: base => ({
									...base,
									background: "#f1f1f1",
									padding: "0 1rem",
									margin: "1px",
									display: "flex",
									borderRadius: "1.25rem",
									whiteSpace: "nowrap",
									fontSize: "14px",
									lineHeight: "18px",
									alignItems: "center",
								}),
							}
						}
						onCreateOption={this.onCreateOption}
						placeholder={
							props.inlineTags
								? "Create or choose tags"
								: "Enter value or select from the list"
						}
					/>
				</div>
			);
		}

		return (
			<div className="pc-form-field-with-tooltip">
				<Select
					isMulti
					isClearable={props.isClearable}
					isDisabled={props.disable}
					options={items}
					defaultValue={props.defaultValue}
					components={{
						ClearIndicator,
						DropdownIndicator,
						Option,
						MultiValueContainer,
						Input: MaxLengthInput,
						MultiValueRemove,
					}}
					value={valueObj}
					menuShouldBlockScroll={!props.inline}
					menuPosition="absolute"
					menuPlacement="auto"
					menuPortalTarget={!props.inline && document.body}
					onChange={event => this.handleChange(event, hiddenOption)}
					closeMenuOnSelect={false}
					className={`${props.className} ${
						!props.disable
							? "pc-modal-select-box"
							: "pc-modal-select-box pc-modal-select-box-disabled"
					}`}
					theme={themes}
					styles={
						props.dropdownStyles || {
							...dropdownStyles,
							multiValue: base => ({
								...base,
								background: "#f1f1f1",
								padding: "0 1rem",
								margin: "1px",
								display: "flex",
								borderRadius: "1.25rem",
								whiteSpace: "nowrap",
								fontSize: "14px",
								lineHeight: "18px",
								alignItems: "center",
							}),
						}
					}
					placeholder={props.placeholder}
				/>
			</div>
		);
	}
}

TagsInput.propTypes = {
	value: PropTypes.string,
	valueLabel: PropTypes.string,
	options: PropTypes.string,
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	userInfo: PropTypes.object,
	handleChange: PropTypes.func,
};

export default TagsInput;
