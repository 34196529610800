import React from "react";
import Modal from "@salesforce/design-system-react/components/modal";
import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";

function InfoModal({
	toggleOpen,
	title,
	subText,
	toggleSubmit,
	buttonLabel = "Ok",
	icon,
}: {
	toggleOpen: () => void;
	title: string;
	subText: string;
	toggleSubmit: () => void;
	buttonLabel?: string;
	icon?: React.ReactNode;
}): JSX.Element {
	return (
		<Modal
			containerClassName="pc-email-warning-modal"
			isOpen
			onRequestClose={toggleOpen}
			prompt="warning"
			footer={
				<div className="pc-text-right">
					<Button
						key="yesBtn"
						id="YES" // cypress id for test case
						label={buttonLabel}
						className="pc-modal-primary-btn"
						onClick={toggleSubmit}
					/>
				</div>
			}
		>
			<div>
				{icon || (
					<Icon
						className="pc-prompt-warning-icon"
						category="custom"
						name="pc-prompt-warning"
					/>
				)}

				<br />
				<br />
				{title && (
					<h2 data-cy="info-modal-title" className="pc-modal-prompt-title">
						{title}
					</h2>
				)}
				<p className="pc-modal-prompt-text">{subText}</p>
			</div>
		</Modal>
	);
}

export default InfoModal;
