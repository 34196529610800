/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable react/no-this-in-sfc */
import React from "react";
import Select, { components } from "react-select";
import Icon from "@salesforce/design-system-react/components/icon";
import PropTypes from "prop-types";

import Button from "@salesforce/design-system-react/components/button";

import { DropdownIndicator, themes } from "@components/TinyComponents/Dropdown";

export const selectStyles = {
	control: provided => ({
		...provided,
		minWidth: 240,
		margin: 8,
	}),
	menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
	container: base => ({
		...base,
		boxShadow: `0px 3px 36px #00000029`,
		padding: `5px 10px`,
	}),
	option: base => ({
		...base,
		borderBottom: `1px solid #ececec`,
		backgroundColor: "#fff",
		color: "#444463",
		maxWidth: "300px",
		overflow: "hidden",
		display: "flex",
	}),
	input: base => ({
		...base,
		color: "#000",
	}),
};

const Menu = props => {
	const shadow = "hsla(218, 50%, 10%, 0.1)";
	return (
		<div
			style={{
				backgroundColor: "white",
				borderRadius: 4,
				boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
				marginTop: 8,
				position: "absolute",
				zIndex: 299,
			}}
			{...props}
		/>
	);
};
const Blanket = props => (
	<div
		style={{
			bottom: 0,
			left: 0,
			top: 0,
			right: 0,
			position: "fixed",
			zIndex: 199,
		}}
		{...props}
	/>
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
	<div style={{ position: "relative" }}>
		{target}
		{isOpen ? <Menu>{children}</Menu> : null}
		{isOpen ? <Blanket onClick={onClose} /> : null}
	</div>
);

const SelectDropdown = props => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Dropdown
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			target={
				<Button
					iconCategory="utility"
					iconName="chevrondown"
					iconPosition="right"
					iconSize="small"
					label={
						<span className="select-element">
							{props.label}{" "}
							{Boolean(props.value && props.value.length) && (
								<span className="select-count" />
							)}
						</span>
					}
					onClick={() => setIsOpen(true)}
					className="quick-filter-dropdown-btn"
				/>
			}
		>
			{props.children}
		</Dropdown>
	);
};

const processedState = (options = [], value) => {
	const rValue = [...(value || [])];

	const sValue = [];

	let containsAll = true;
	options.forEach(p => {
		const opt = rValue.find(v => v === p.value);

		if (opt !== undefined) {
			sValue.push(p);
		} else {
			containsAll = false;
		}
	});

	if (containsAll && options.length) {
		sValue.unshift({ label: "All", value: "NO_FILTER" });
	}

	return {
		selectValue: sValue,
		options: options.length
			? [{ label: "All", value: "NO_FILTER" }, ...options]
			: [],
	};
};

function MultiSelectAll(props) {
	const [state, setState] = React.useState(() =>
		processedState(props?.options, props.value),
	);

	const { selectValue, options } = state;

	React.useEffect(() => {
		setState(processedState(props.options, props.value));
	}, [props?.options?.length, props.value]);

	const selectRef = React.useRef(null);
	const isSelectedAll = Boolean(
		selectValue?.[0]?.value === "NO_FILTER" ?? null,
	);

	const handleSelectChange = opValue => {
		let nValue = [...opValue];
		const allIndex = opValue.findIndex(v => v.value === "NO_FILTER");
		if (allIndex !== -1) {
			// selecting all
			if (!isSelectedAll) {
				nValue = [...options];
			}

			// previosly selected all now removed one option
			if (isSelectedAll && options.length !== opValue.length) {
				nValue = opValue
					?.slice(0, allIndex)
					.concat(opValue.slice(allIndex + 1));
			}
		}

		// previously selected all now removed all option
		if (isSelectedAll && allIndex === -1) {
			nValue = [];
		}
		setState(st => ({ ...st, selectValue: nValue }));
		props.handleChange({
			fieldId: props.field.fieldId,
			fieldName: props.field.fieldName,
			typeCode: props.field.typeCode,
			isRequired: props.field.isRequired,
			isMultiSelect: true,
			label: nValue.map(e => e.label).filter(l => l !== "All"),
			value: nValue.map(e => e.value).filter(f => f !== "NO_FILTER"),
		});
	};

	const Option = props => (
		<div>
			{" "}
			<components.Option {...props}>
				<div>
					<Icon
						category="custom"
						size="x-small"
						name={
							props.isSelected || props.selectProps.selectedAll
								? "pc-checked"
								: "pc-unchecked"
						}
					/>
				</div>
				<div className="slds-truncate slds-m-left_x-small" title={props.label}>
					{props.label}
				</div>
			</components.Option>{" "}
		</div>
	);

	let label =
		props.field && props.field.transKey
			? props.intl.formatMessage({
					id: props.field.transKey || "",
			  })
			: props.placeholderButtonLabel;

	if (props.moduleId === "QTM" && props.field.fieldId === "F5") {
		label = "Created By";
	}

	return (
		<SelectDropdown label={label} value={props.value}>
			<Select
				ref={selectRef}
				autoFocus
				backspaceRemovesValue={false}
				components={{
					DropdownIndicator,
					IndicatorSeparator: null,
					Option,
				}}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				menuIsOpen
				onChange={handleSelectChange}
				options={options}
				placeholder={props.placeholder}
				styles={selectStyles}
				tabSelectsValue={false}
				value={selectValue}
				isMulti
				theme={themes}
				selectedAll={isSelectedAll}
			/>
		</SelectDropdown>
	);
}

MultiSelectAll.propTypes = {
	fieldName: PropTypes.string,
	field: PropTypes.object,
	handleChange: PropTypes.func,
	isRequired: PropTypes.bool,
	fieldId: PropTypes.string,
	typeCode: PropTypes.string,
	value: PropTypes.array,
	moduleId: PropTypes.string,
	placeholderButtonLabel: PropTypes.string,
	intl: PropTypes.object,
};

export default MultiSelectAll;
