import React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";
import Modal from "@salesforce/design-system-react/components/modal";
import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";

export default function NoIntegrationModal({
	toggleOpen,
	service,
	title,
	subText,
	isOrgLevel = false,
	children,
	isPrompt = true,
	className = "",
	prompt = "warning",
}) {
	const history = useHistory();

	return (
		<Modal
			containerClassName={cx("pc-email-warning-modal", className)}
			isOpen
			toggleOpen={toggleOpen}
			onRequestClose={toggleOpen}
			prompt={isPrompt ? prompt : undefined}
			footer={
				<div className="pc-text-right">
					<Button
						key="noBtn"
						id="NO" // cypress id for test case
						label="Cancel"
						onClick={toggleOpen}
						className="pc-modal-secondary-btn"
					/>
					<Button
						key="yesBtn"
						id="YES" // cypress id for test case
						label="Integrate"
						className="pc-modal-primary-btn"
						onClick={() => {
							history.push(
								isOrgLevel
									? `/settings/integration/${service}`
									: `/setting/user/integration/${service}`,
							);
						}}
					/>
				</div>
			}
		>
			{children || (
				<div>
					<Icon
						className="pc-prompt-warning-icon"
						category="custom"
						name="pc-prompt-warning"
					/>
					<br />
					<br />
					<h2 className="pc-modal-prompt-title">{title}</h2>
					<p className="pc-modal-prompt-text">{subText}</p>
				</div>
			)}
		</Modal>
	);
}

NoIntegrationModal.propTypes = {
	toggleOpen: PropTypes.func,
	title: PropTypes.string,
	subText: PropTypes.string,
	service: PropTypes.string,
	isOrgLevel: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	prompt: PropTypes.string,
	isPrompt: PropTypes.bool,
};
