/* eslint-disable max-lines */
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";

import Icon from "@salesforce/design-system-react/components/icon";

import { FormElement } from "@components/FormElement";
import { TYP_CHECKBOX } from "@components/FormElement/ElementTypes";
import NoData from "@components/TinyComponents/Nodata";
import { NOOP } from "@components/Modal/ConversionModal/helper";

import { getRequest } from "@utils/request";
import { setToastMessage } from "@containers/App/actions";
import generateErrorMessage from "@services/generateErrorMessage";
import {
	AppContextInterface,
	GlobalSearchContext,
} from "@context/globalSearch";
import useUser from "@hooks/useUser";
import { AllModuleIds } from "@custom-types/module-id";

import NoSearchResults from "@assets/emptySearch.svg";
import MinCharWarning from "@assets/minCharWarning.svg";

import "./styles.scss";
import GlobalSearchInputForMobile from "@components/GlobalSearchInputForMobile";
import ResultCard from "./ResultCard";
import { GlobalFilter, Hit, Result } from "./types";

interface State {
	results: Hit[];
	searching: boolean;
	filter: string;
	total: number;
	nextSearch: number;
	showMine: boolean;
	individualStats: Partial<Record<GlobalFilter, number>>;
	searchedTerm: string;
}

const GlobalSearchResults = (): JSX.Element => {
	const userInfo = useUser();
	const dispatch = useDispatch();
	const intl = useIntl();
	const history = useHistory();

	const [state, setState] = React.useState<State>({
		searching: false,
		results: [],
		filter: "ALL",
		total: 0,
		nextSearch: 0,
		showMine: false,
		individualStats: {},
		searchedTerm: "",
	});
	const searchCtx = React.useContext<AppContextInterface | null>(
		GlobalSearchContext,
	);

	const allowedFilters = React.useMemo(() => {
		const {
			profile: { permissions },
		} = userInfo;

		let f: Record<string, string> = {
			All: "ALL",
		};

		const moduleIdToName = {
			LM: "Leads",
			OM: "Opportunities",
			CM: "Contacts",
			AM: "Accounts",
			QTM: "Quotations",
			ACTM: "Activities",
		};

		Object.keys(permissions).forEach(m => {
			if (m === "ACTM" && permissions[m].READ) {
				f = {
					...f,
					Tasks: "TASK",
					Notes: "NOTE",
					Events: "EVENT",
					Logs: "LOG",
					Documents: "DOCUMENT",
				};
			} else if (
				permissions[m as AllModuleIds].READ &&
				moduleIdToName[m as AllModuleIds]
			) {
				f[moduleIdToName[m as AllModuleIds]] = m;
			}
		});

		return f;
	}, []);

	const {
		ref: searchResultEndRef,
		inView,
		entry,
	} = useInView({
		/* Optional options */
	});

	const { searching, results, filter, total, nextSearch, showMine } = state;

	const handleSearch = React.useCallback(
		async (q: string, f: string, mine: boolean, searchAfter?: number) => {
			try {
				const isSearchTermChanged = state.searchedTerm !== q;

				const searchFilter = isSearchTermChanged ? "ALL" : f;

				const { data }: { data: Result } = await getRequest(
					`/api/v2/search?${qs.stringify({
						q,
						filter: searchFilter,
						searchAfter,
						filterCurrentUser: mine,
					})}`,
				);
				let nSearch = 0;
				const lastResult = data.results[data.results.length - 1];
				if (lastResult && lastResult.sort) {
					nSearch = lastResult.sort[0];
				}

				setState(st => ({
					...st,
					results: [...st.results, ...data.results],
					total: data.total?.value ?? st.total,
					searching: false,
					nextSearch: nSearch,
					individualStats:
						searchFilter === "ALL"
							? {
									...data.individualStats,
									ALL: searchAfter
										? st.individualStats.ALL
										: data.total?.value || 0,
							  }
							: st.individualStats,
					searchedTerm: q,
					filter: searchFilter,
				}));
			} catch (err) {
				const message = generateErrorMessage(err);
				dispatch(setToastMessage(message));
			}
		},
		[state.searchedTerm],
	);

	const handleNext = React.useCallback(() => {
		if (nextSearch && !searching) {
			handleSearch(globalSearch, filter, showMine, nextSearch);
		}
	}, [searchCtx?.globalSearch, filter, showMine, nextSearch, searching]);

	React.useEffect(() => {
		if (entry && inView) {
			handleNext();
		}
	}, [inView]);

	React.useEffect(() => {
		if (globalSearch && globalSearch.trim().length > 2) {
			setState(st => ({ ...st, searching: true, total: 0, results: [] }));
			handleSearch(globalSearch, filter, showMine);
		} else {
			setState(st => ({
				...st,
				searching: false,
				results: [],
				individualStats: {},
				total: 0,
				searchedTerm: globalSearch,
			}));
		}
	}, [searchCtx?.globalSearch, filter, showMine]);

	const handleFilterChange = (f: GlobalFilter): void => {
		setState(st => ({
			...st,
			filter: allowedFilters[f as GlobalFilter],
			results: [],
			nextSearch: 0,
		}));
	};

	const escFunction = React.useCallback((event: KeyboardEvent) => {
		if (event.keyCode === 27) {
			setGlobalSearchShowPanel(false);
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener("keydown", escFunction);

		return () => {
			document.removeEventListener("keydown", escFunction);
		};
	}, []);

	if (!searchCtx) return <React.Fragment />;

	const {
		globalSearch,
		showGlobalSearchPanel,
		setGlobalSearchShowPanel,
		fieldNames,
	} = searchCtx;

	return (
		<div
			className={cx(
				"pc-global-search-results-container",
				showGlobalSearchPanel && "active",
				!results.length && "no-results",
				!showGlobalSearchPanel && "inactive",
			)}
			id="globalSearch"
		>
			<div className="slds-hide_large">
				<GlobalSearchInputForMobile />
			</div>
			<div className="results-header">
				<div className="heading">
					<h2 className="filter"> {intl.formatMessage({ id: "searchBy" })}</h2>
					<div className="show-result">
						<FormElement
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							typeCode={TYP_CHECKBOX}
							value={state.showMine}
							handleChange={(): void => {
								setState(st => ({
									...st,
									showMine: !st.showMine,
									filter: "ALL",
								}));
							}}
							handleBlur={NOOP}
						/>
						<p> {intl.formatMessage({ id: "myRecords" })}</p>
					</div>
				</div>
				<div className="global-search-filters">
					{Object.keys(allowedFilters).map(f => (
						<span
							key={f}
							role="button"
							tabIndex={-1}
							className={cx(
								"filter",
								allowedFilters[f as GlobalFilter] === state.filter &&
									"selected",
							)}
							onClick={(): void => {
								if (state.filter !== allowedFilters[f]) {
									handleFilterChange(f as GlobalFilter);
								}
							}}
						>
							{f}
							{state.searchedTerm === globalSearch &&
							state.individualStats[allowedFilters[f] as GlobalFilter] ? (
								<div className="count">
									{state.individualStats[allowedFilters[f] as GlobalFilter]}
								</div>
							) : (
								""
							)}
						</span>
					))}
				</div>
				{globalSearch && total > 0 && (
					<h3 className="result-title">
						{total} Search result{total > 1 ? "s" : ""} found
					</h3>
				)}
				{!results.length && searching && (
					<div className="search-loader">
						<div className="pc-save-btn-loader" />
					</div>
				)}
			</div>
			<div className="search-body">
				<div className="search-results">
					{/* Initial screen */}
					{!searching && !globalSearch && (
						<NoData
							className="no-data-div"
							image={
								<img
									className="emptyscreen-image-size"
									src={NoSearchResults}
									alt="No Data Img"
								/>
							}
							message={
								<div className="empty-conversations-text">
									<span>
										{" "}
										{intl.formatMessage({ id: "empty.conversations.text" })}
									</span>
								</div>
							}
						/>
					)}

					{/* When search length is less than 2 */}
					{!searching && globalSearch && globalSearch.trim().length <= 2 && (
						<NoData
							className="no-data-div"
							image={
								<img
									className="emptyscreen-image-size"
									src={MinCharWarning}
									alt="No Data Img"
								/>
							}
							message={
								<div className="empty-conversations-text">
									<span> {intl.formatMessage({ id: "min.character" })}</span>
								</div>
							}
						/>
					)}

					{/* When no matching result found */}
					{!searching && globalSearch.trim().length > 2 && !results.length && (
						<NoData
							className="no-data-div"
							image={
								<img
									className="emptyscreen-image-size"
									src={NoSearchResults}
									alt="No Data Img"
								/>
							}
							message={
								<div className="empty-conversations-text">
									<p>No result found</p>
									<span>
										There are no matching results. Check spelling errors and
										search again.
									</span>
								</div>
							}
						/>
					)}
					{results.map(result => (
						<ResultCard
							record={result}
							fieldNames={fieldNames}
							dispatch={dispatch}
							history={history}
						/>
					))}
					{Boolean(results.length) && (
						<div className="search-result-end" ref={searchResultEndRef}>
							{intl.formatMessage({ id: "result.end" })}
						</div>
					)}
				</div>
			</div>
			{!results.length && (
				<div className="results-footer">
					<div>
						<Icon size="large" name="pc-bulb" category="custom" />
					</div>
					<div className="slds-grid slds-grid_vertical slds-has-flexi-truncate medium-device">
						<div className="title bold">
							{intl.formatMessage({ id: "need.help" })}
						</div>
						<span className="slds-truncate light">
							{intl.formatMessage({ id: "know.more.about" })}{" "}
							<a
								href="https://blog.peppercloud.com/global-search-for-crm/"
								target="_blank"
								rel="noreferrer"
							>
								{intl.formatMessage({ id: "global.search" })}
							</a>
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default GlobalSearchResults;
