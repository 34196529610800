/**
 *
 * Toast
 *
 */

import React from "react";
import PropTypes from "prop-types";

import Toast from "@salesforce/design-system-react/components/toast";
import ToastContainer from "@salesforce/design-system-react/components/toast/container";
import "./index.scss";

function ToastMessage(props) {
	return (
		<div className="toast-container">
			<ToastContainer>
				<Toast
					variant={props.variant}
					duration={props.duration}
					labels={{
						heading: props.message,
						details: props.details,
						headingLink: props.headingLink,
					}}
					onRequestClose={props.clearToastMessage}
					onClickHeadingLink={props.onClickHeadingLink}
				/>
			</ToastContainer>
		</div>
	);
}

ToastMessage.defaultProps = {
	variant: "error",
	duration: 3000,
};

ToastMessage.propTypes = {
	message: PropTypes.string,
	variant: PropTypes.string,
	details: PropTypes.string,
	headingLink: PropTypes.any,
	duration: PropTypes.number,
	clearToastMessage: PropTypes.func,
	onClickHeadingLink: PropTypes.func,
};

export default ToastMessage;
