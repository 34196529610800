import * as React from "react";
import { useIntl } from "react-intl";
import qs from "qs";

import { getRequest } from "@utils/request";
import { Fields } from "@interfaces/fields";

export declare interface AppContextInterface {
	setGlobalSearch: React.Dispatch<React.SetStateAction<string>>;
	setGlobalSearchShowPanel: React.Dispatch<React.SetStateAction<boolean>>;
	globalSearch: string;
	showGlobalSearchPanel: boolean;
	fieldNames: Record<string, string>;
}

export const GlobalSearchContext =
	React.createContext<AppContextInterface | null>(null);

const GlobalSearchProvider = ({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element => {
	const [globalSearch, setGlobalSearch] = React.useState("");
	const [fieldNames, setFieldNames] = React.useState({});
	const [showGlobalSearchPanel, setGlobalSearchShowPanel] =
		React.useState(false);

	const intl = useIntl();

	const fetchFields = async (): Promise<void> => {
		const fieldIdToName: Record<string, string> = {};
		try {
			const types = [
				"TYP_TEXTAREA",
				"TYP_URL",
				"TYP_TEXT",
				"TYP_PHONE",
				"TYP_ZIPCODE",
				"TYP_EMAIL",
				"TYP_RICHTEXT",
			];
			const { data }: { data: Fields[] } = await getRequest(
				`/api/v2/form-field?${qs.stringify({
					typeCodes: types,
					select: ["fieldId", "fieldName", "customName", "transKey"],
				})}`,
			);
			data.forEach(f => {
				fieldIdToName[f.fieldId] =
					f.customName || intl.formatMessage({ id: f.transKey });
			});
		} catch (err) {
			// do something
		}
		setFieldNames(fieldIdToName);
	};

	React.useEffect(() => {
		fetchFields();
	}, []);

	return (
		<GlobalSearchContext.Provider
			value={{
				setGlobalSearch,
				setGlobalSearchShowPanel,
				globalSearch,
				showGlobalSearchPanel,
				fieldNames,
			}}
		>
			{children}
		</GlobalSearchContext.Provider>
	);
};

export default GlobalSearchProvider;
