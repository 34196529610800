import { Field, RecordValue } from "@components/TinyComponents/FormField";
import { validateField } from "@services/validationService";

export const NOOP = (): void => {
	// do nothing;
};

export const validateConversationField = (
	field: Field,
	value: RecordValue,
): Record<string, string> => {
	const errorObj: Record<string, string> = {};

	const { fieldLevelErrorCount, formLevelErrorCount, message } = validateField(
		{ ...field, value },
		0,
		0,
	);
	const errorCount = formLevelErrorCount || fieldLevelErrorCount || 0;
	if (errorCount) {
		errorObj[field.fieldId] = message;
	}

	return errorObj;
};
