import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@salesforce/design-system-react/components/card";
import Icon from "@salesforce/design-system-react/components/icon";

class CardTypeMedia extends Component {
	getHeader = (title, summary, rightContent) => {
		if (title || summary || rightContent)
			return (
				<div className="media-card-header-flex">
					<div className="media-card-header-left max-w">
						<h2 className="media-card-header-title">{title}</h2>
						<p className="media-card-header-summary">{summary}</p>
					</div>
					<div className="media-card-header-right">{rightContent}</div>
				</div>
			);
		return "";
	};

	getFooter = footer => {
		/**
		 * this is for the media card which has footer
		 */
		if (footer) {
			const { footerLeft, footerRight } = footer;
			return (
				<div className="media-card-footer-flex">
					<div className="media-card-footer-left">{footerLeft}</div>
					<div className="media-card-footer-right">{footerRight}</div>
				</div>
			);
		}
		return null;
	};

	getBody = body => {
		if (body.title || body.summary || body.icon)
			return (
				<div className="slds-media">
					<div className="slds-media__body">
						<div
							className={`media-card-body-title media-card-body-title-${body.align}`}
						>
							{body.title}
							{body.isNew && <span className="new-version" />}
							{body.showActive && (
								<span className="status-label active">Active</span>
							)}
						</div>
						<div
							className={`media-card-body-summary media-card-body-summary-${body.align}`}
						>
							{body.summary}
						</div>
					</div>
					{body.icon && (
						<div className="slds-media__figure slds-media__figure_reverse">
							<Icon
								category="custom"
								name={body.icon.name}
								size={body.icon.size}
							/>
						</div>
					)}
				</div>
			);
		return null;
	};

	getHeaderIcon = icon => {
		if (icon.custom) return icon.html;
		return <Icon category="custom" size={icon.size} name={icon.name} />;
	};

	render() {
		const { header, body, footer, className } = this.props;
		return (
			<Card
				heading={
					(header &&
						this.getHeader(
							header.title,
							header.summary,
							header.rightContent,
						)) ||
					""
				}
				className={className}
				icon={header && header.icon && this.getHeaderIcon(header.icon)}
				footer={footer && this.getFooter(footer)}
			>
				{body && this.getBody(body)}
			</Card>
		);
	}
}

CardTypeMedia.propTypes = {
	header: PropTypes.object,
	body: PropTypes.object,
	footer: PropTypes.object,
	className: PropTypes.string,
};

export default CardTypeMedia;
