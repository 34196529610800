// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Input from "@salesforce/design-system-react/components/input";

class URLInput extends React.PureComponent {
	handleChange = event => {
		const { value } = event.target;

		this.props.handleChange({
			typeCode: this.props.typeCode,
			fieldId: this.props.fieldId,
			fieldName: this.props.fieldName,
			isRequired: this.props.isRequired,
			value,
		});
	};

	handleBlur = event => {
		const record = {
			fieldId: this.props.fieldId,
			fieldName: this.props.fieldName,
			sectionId: this.props.sectionId,
			value: event.target.value.trim(),
			typeCode: this.props.typeCode,
			isRequired: this.props.isRequired,
		};
		this.props.handleBlur(record);
	};

	render() {
		const { props } = this;
		return (
			<Input
				id={props.fieldId}
				placeholder={props.placeholder}
				disabled={props.disable}
				tabIndex={props.tabIndex}
				name={props.name || props.fieldName || props.fieldId}
				onChange={e => this.handleChange(e)}
				onBlur={e => this.handleBlur(e)}
				sectionid={props.sectionId}
				type="text"
				className={`${props.className} pc-modal-text-box`}
				value={props.value}
				autoComplete="off"
				maxLength={props.maxLength}
			/>
		);
	}
}

URLInput.propTypes = {
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	sectionId: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
};

export default URLInput;
