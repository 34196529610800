import validator from "validator";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isValidPhoneNumber } from "react-phone-number-input/max";
import moment from "moment-timezone";
import {
	TYP_TEXT,
	TYP_EMAIL,
	TYP_CURRENCY,
	TYP_DATE,
	TYP_DATE_TIME,
	TYP_NUMBER,
	TYP_PERCENT,
	TYP_PHONE,
	TYP_URL,
	TYP_TEXTAREA,
	TYP_ZIPCODE,
	TYP_SELECT,
	TYP_SYSTEM_SELECT,
	TYP_MULTI_SELECT,
	TYP_RICHTEXT,
	TYP_SUGGESTION,
	TYP_NOTIFICATION_CONTROL,
	TYP_PASSWORD,
} from "@components/FormElement/ElementTypes";
import { MINUTES, HOURS, DAYS, WEEKS } from "../utils/common";
import {
	chineseRegex,
	thaiRegex,
	koreanRegex,
	extendedAscii,
} from "../utils/constants";

const validateFieldType = (input, record, isTemplate) => {
	const regex = /^([@+\-=])/i;
	const result = input;

	try {
		switch (record.typeCode) {
			case TYP_RICHTEXT: {
				const { value } = record;
				if (record.isRequired) {
					const { text = "", html = "" } = value || {};
					if (!text.trim() && !html.trim()) {
						result.formLevelErrorCount += 1;
						result.message = "Please fill the required field";
					}
				}

				break;
			}

			case TYP_PASSWORD: {
				const sanitizedInput = validator.trim(record.value);
				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				}
				break;
			}

			case TYP_TEXT: {
				const sanitizedInput = validator.trim(record.value);

				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (regex.test(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message =
						"Special characters (=,-,@,+) are not allowed as the first character";
				} else if (
					!validator.isLength(sanitizedInput, { max: record.maxLimit || 300 })
				) {
					result.fieldLevelErrorCount += 1;
					result.message = "You have exceeded the maximum character limit";
				} else if (
					!validator.isAscii(sanitizedInput) &&
					!chineseRegex.test(sanitizedInput) &&
					!koreanRegex.test(sanitizedInput) &&
					!thaiRegex.test(sanitizedInput) &&
					!extendedAscii.test(sanitizedInput) &&
					!isTemplate
				) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid text";
				}

				break;
			}
			case TYP_TEXTAREA: {
				const sanitizedInput = validator.trim(record.value);

				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (regex.test(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message =
						"Special characters (=,-,@,+) are not allowed as the first character";
				}

				break;
			}
			case TYP_EMAIL: {
				const sanitizedInput = validator.trim(record.value);

				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (!validator.isLength(sanitizedInput, { max: 100 })) {
					result.fieldLevelErrorCount += 1;
					result.message = "Check the length of this field";
				} else if (!validator.isEmail(sanitizedInput)) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid email";
				}

				break;
			}

			case TYP_SELECT: {
				if (
					record.isMultiSelect &&
					record.isRequired &&
					(!record.value || !record.value.length)
				) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (
					record.isRequired &&
					validator.isEmpty(record.value, { ignore_whitespace: true })
				) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				}

				break;
			}

			case TYP_SYSTEM_SELECT: {
				if (
					record.isMultiSelect &&
					record.isRequired &&
					(!record.value || !record.value.length)
				) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (
					record.isRequired &&
					validator.isEmpty(record.value || "", { ignore_whitespace: true })
				) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				}

				break;
			}
			case TYP_NOTIFICATION_CONTROL: {
				if (
					(record.isRequired && !record.value) ||
					!record.value.interval ||
					!record.value.value
				) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (
					record.value.interval === MINUTES &&
					record.value.value > 40320
				) {
					result.formLevelErrorCount += 1;
					result.message = "Must be between 0 and 40,320 minutes";
				} else if (
					record.value.interval === HOURS &&
					record.value.value > 672
				) {
					result.formLevelErrorCount += 1;
					result.message = "Must be between 0 and 672 hours";
				} else if (record.value.interval === DAYS && record.value.value > 28) {
					result.formLevelErrorCount += 1;
					result.message = "Must be between 0 and 28 days";
				} else if (record.value.interval === WEEKS && record.value.value > 4) {
					result.formLevelErrorCount += 1;
					result.message = "Must be between 0 and 4 weeks";
				}

				break;
			}
			case TYP_SUGGESTION: {
				if ((record.isRequired && !record.value) || !record.value.length) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				}

				break;
			}
			case TYP_MULTI_SELECT: {
				if (record.isRequired && (!record.value || !record.value.length)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				}
				break;
			}
			case TYP_ZIPCODE: {
				const sanitizedInput = validator.trim(record.value);

				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (!validator.isPostalCode(sanitizedInput, "any")) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid zipcode";
				}

				break;
			}

			case TYP_CURRENCY: {
				/**
				 * * Problems in this validation
				 * - If amount in string we have to trim spaces - Amount is string while importing
				 * - If amount is Zero (0) it is invalid - Need to  check this - // ! Fixed
				 */
				if (
					record.isRequired &&
					// Revert the changes done due to Import
					(!record.value ||
						record.value.amount === "" ||
						!record.value.currencyType)
				) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (!record.value.currencyType) {
					result.fieldLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (
					Number(record.value.amount) &&
					!validator.isCurrency(
						Number(record.value.amount)
							? String(Number(record.value.amount))
							: "",
						{
							// thousands_separator: ',',
							decimal_separator: ".",
							digits_after_decimal: [1, 2],
						},
					)
				) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid currency";
				} else if (Number(record.value.amount) < 0) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid currency";
				}
				break;
			}

			case TYP_NUMBER: {
				const sanitizedInput = record.value.toString();

				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (!validator.isLength(sanitizedInput, { max: 15 })) {
					result.fieldLevelErrorCount += 1;
					result.message = "Check the length of this field";
				} else if (
					!validator.isNumeric(sanitizedInput, { no_symbols: false })
				) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid number";
				}

				break;
			}

			case TYP_PERCENT: {
				const sanitizedInput = record.value.toString();

				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (!validator.isLength(sanitizedInput, { max: 7 })) {
					result.fieldLevelErrorCount += 1;
					result.message = "Check the length of this field";
				} else if (
					!validator.isFloat(sanitizedInput, {
						min: 0.0,
						max: 100.0,
						locale: "en-US",
					})
				) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid percentage";
				}

				break;
			}

			case TYP_URL: {
				const sanitizedInput = validator.trim(record.value);

				if (record.isRequired && validator.isEmpty(sanitizedInput)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (!validator.isURL(sanitizedInput)) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid URL";
				} else if (!validator.isLength(sanitizedInput, { max: 2048 })) {
					result.fieldLevelErrorCount += 1;
					result.message = "You have exceeded the maximum character limit";
				} else if (
					isTemplate &&
					!validator.isURL(sanitizedInput, { require_protocol: true })
				) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid URL";
				}
				break;
			}

			case TYP_PHONE: {
				if (record.isRequired && validator.isEmpty(record.value)) {
					result.formLevelErrorCount += 1;
					result.message = "Please fill the required field";
				} else if (
					// !validator.isMobilePhone(record.value, 'any', { strictMode: false })
					!isValidPhoneNumber(record.value)
				) {
					result.fieldLevelErrorCount += 1;
					result.message = "Input a valid phone number";
				}

				break;
			}

			case TYP_DATE: {
				const isValidInput = moment(record.value).isValid();
				if (!isValidInput) {
					result.fieldLevelErrorCount += 1;
					result.formLevelErrorCount += 1;
					result.message = "Input a valid date";
				} else {
					result.message = "";
				}
				break;
			}
			case TYP_DATE_TIME: {
				const isValidInput = moment(record.value).isValid();
				if (!isValidInput) {
					result.fieldLevelErrorCount += 1;
					result.formLevelErrorCount += 1;
					result.message = "Input a valid date and time";
				} else {
					result.message = "";
				}
				break;
			}
			default:
				break;
		}
	} catch (e) {
		result.message = "Invalid Value";
	}

	return result;
};

// eslint-disable-next-line import/prefer-default-export
export const validateField = (
	fieldInfo,
	fieldLevelErrorCount,
	formLevelErrorCount,
	isTemplate,
) => {
	const record = { ...fieldInfo };

	const result = { fieldLevelErrorCount, message: "", formLevelErrorCount };

	// Validation is only triggered when the field is required OR the field contains any value
	if (record.isRequired || record.value) {
		return validateFieldType(result, record, isTemplate);
	}
	return result;
};
