export const DEFAULT_LOCALE = "en";

export const SENDING_REQUEST = "crm-b2b-frontend/App/SENDING_REQUEST";

export const LOAD_MENU_REQUEST = "crm-b2b-frontend/App/LOAD_MENU_REQUEST";
export const LOAD_MENU_RESPONSE = "crm-b2b-frontend/App/LOAD_MENU_RESPONSE";

export const LOAD_USER_ROLES_AND_PROFILES =
	"crm-b2b-frontend/App/LOAD_USER_ROLES_AND_PROFILES";
export const USER_ROLES_AND_PROFILES_LOADED =
	"crm-b2b-frontend/App/USER_ROLES_AND_PROFILES_LOADED";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_TOAST_MESSAGE = "crm-b2b-frontend/App/SET_TOAST_MESSAGE";
export const CLEAR_TOAST_MESSAGE = "crm-b2b-frontend/App/CLEAR_TOAST_MESSAGE";
export const SET_RESPONSE_CODE = "crm-b2b-frontend/App/SET_RESPONSE_CODE";

export const LOAD_MENU_API = {
	type: "GET",
	url: "/api/v2/screen/menu",
};
export const LOAD_USER_INFO_API = {
	type: "GET",
	url: "/api/v2/users/settings",
};

export const LOAD_USER_INTEGRATIONS_API = "/api/v2/user/integrations";

export const SET_USER_SECRET_FLAG =
	"crm-b2b-frontend/Auth2F/SET_USER_SECRET_FLAG";

export const SET_USER_SECRET_URL =
	"crm-b2b-frontend/Auth2F/SET_USER_SECRET_URL";
export const SET_LOGIN_DATA = "crm-b2b-frontend/Auth2F/SET_LOGIN_DATA";

export const SET_2FA_FLAG = "crm-b2b-frontend/Auth2F/SET_2FA_FLAG";

export const UNSET_ANNONCEMENT = "crm-b2b-frontend/Auth2F/UNSET_ANNONCEMENT";
