// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Input from "@salesforce/design-system-react/components/input";
import InputIcon from "@salesforce/design-system-react/components/icon/input-icon";

function PasswordInput(props) {
	const { autoFocus } = props;
	const inputElementRef = useRef(null);
	const [isVisibility, setVisibility] = useState(false);

	const toggleVisibilty = () => {
		setVisibility(!isVisibility);
	};

	useEffect(() => {
		if (autoFocus && inputElementRef.current) {
			inputElementRef.current.focus();
		}
	}, []);

	const handleChange = event => {
		const { value } = event.target;
		props.handleChange({
			typeCode: props.typeCode,
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			isRequired: props.isRequired,
			value,
		});
	};

	const handleBlur = event => {
		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value: event.target.value.trim(),
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleBlur(record);
	};

	const iconRight = props.visibilityToggle && (
		<InputIcon
			assistiveText={{
				icon: "Clear",
			}}
			name={isVisibility ? "pc-settings-pwd-show" : "pc-settings-pwd-hide"}
			category="custom"
			size="medium"
			onClick={toggleVisibilty}
		/>
	);
	return (
		<Input
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			inputRef={inputElementRef}
			id={props.fieldId}
			disabled={props.disable}
			tabIndex={props.tabIndex}
			name={props.name || props.fieldName || props.fieldId}
			onChange={handleChange}
			onBlur={handleBlur}
			sectionid={props.sectionId}
			type={isVisibility ? "text" : "password"}
			className={`${props.className} pc-modal-text-box`}
			value={props.value}
			placeholder={props.placeholder}
			iconRight={iconRight}
			autoComplete={props.autoComplete}
			label={props.label}
		/>
	);
}

PasswordInput.propTypes = {
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	sectionId: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	autoComplete: PropTypes.string,
};

PasswordInput.defaultProps = {
	autoComplete: "off",
};

export default PasswordInput;
