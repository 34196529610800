import React, { useState } from "react";
import PropTypes from "prop-types";

const socketIOClient = require("socket.io-client");
const sailsIOClient = require("sails.io.js");

export const SocketContext = React.createContext();

export function SocketProvider({ children }) {
	const [socketIo, setIo] = useState();

	const connectSocket = () => {
		let io;

		if (socketIOClient.sails) {
			io = socketIOClient;
		} else {
			io = sailsIOClient(socketIOClient);
		}

		io.sails.url = process.env.NOTIF_URI;

		io.sails.reconnection = true;
		setIo(io.socket);
	};

	return (
		<SocketContext.Provider value={{ connectSocket, socketIo }}>
			{children}
		</SocketContext.Provider>
	);
}

SocketProvider.propTypes = {
	children: PropTypes.node,
};
