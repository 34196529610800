export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";
export const ACCOUNT_TYPE = {
	PERSONAL: "PERSONAL",
	BUSINESS: "BUSINESS",
};
export const CHANNEL_NAME_SIZE = 25;
export const KANBAN_VIEW = "KANBAN_VIEW";
export const FORECAST = "FORECAST";

export const aircallDashboardTooltips = {
	406: "Track inbound and outbound call volume over the given time period.",
	411: "Track inbound calls over the given time period.",
	412: "Percentage of outbound calls successfully connected with the recipient.",
	414: "Track outbound calls over the given time period, categorised as connected and not connected.",
	415: "The person who ends the call is the main participant. If multiple participants are involved in the call, only the last participant is considered the main participant.",
	501: "Total number of conversations that are opened by either sending a message to the client or receiving the message from the client.",
	503: "Total number of conversations that have been successfully converted into Leads from the message module.",
	502: "Total number of conversations that have been closed manually. To view this metric for any specific user, refer to the leaderboard.",
	504: "Total number of conversations that have been successfully converted into Contacts from the message module.",
	505: "Total number of conversations categorised by messaging channel providers.",
	506: "Total number of conversations converted into Leads or Contacts within the defined period of time. If the date range is not defined, the default period will be the last 28 days.",
	507: "Total number of conversations by open and closed statuses within the defined period of time. If a closed conversation is opened again, it will be counted as new open conversation.",
	508: "Breakdown of conversations with open and close status within the defined period of time or in the last 24 hours.",
	509: "Average duration between the opening and closing of conversations.",
	510: "Average amount of time taken to close the conversation from the time the conversation was first assigned. If the conversation is closed and opened again, it will be counted as a new conversation.",
	511: "Average duration between opening the conversations and sending the first response.",
	512: "Average duration between the opening the conversations and assigning the conversations.",
	513: "Breakdown of the closed conversations based on their resolution times.",
	514: "Breakdown of the closed conversations based on their first response times.",
	515: "Breakdown of closed conversations based on the first assignment to closed time.",
	516: "Breakdown of closed conversations based on the open time to first assignment.",
};

export const messageDashboardHeadings = {
	"ROW-MESSAGES_1": "Conversation overview",
	"ROW-MESSAGES_4": "Response and resolution reports",
	"ROW-MESSAGES_7": "Leaderboards",
	"ROW-MESSAGES_8": "Broadcast overview",
};

export const messageDashboardHeadingToolTips = {
	"ROW-MESSAGES_1":
		"Track and monitor message activity across messaging platforms.",
	"ROW-MESSAGES_4":
		"Track and monitor response times, issue resolution times, and other key metrics.",
	"ROW-MESSAGES_7": "Track and monitor the performance of your team.",
	"ROW-MESSAGES_8":
		"Track audience engagement and other performance metrics of message broadcasts.",
};

export const chineseRegex =
	/[\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3005\u3007\u3021-\u3029\u3038-\u303B\u3400-\u4DB5\u4E00-\u9FD5\uF900-\uFA6D\uFA70-\uFAD9]/;
export const koreanRegex = /[\u3131-\uD79D]/;
export const thaiRegex = /[\u0E00-\u0E7F]/;
export const extendedAscii = /[\u2013\u2014\u2019]/; // em dash, en dash, right single quotation mark
export const getMsgProvider = {
	AIRCALL: "AIRCALL",
	WHATSAPP: "MESSAGE",
	EMAIL: "EMAIL",
	BROADCAST: "MESSAGE",
};
