/* eslint-disable react/react-in-jsx-scope */
import React from "react";

import Button from "@salesforce/design-system-react/components/button";
import { Field, Option } from "@components/TinyComponents/FormField";

const Menu = (props: JSX.IntrinsicElements["div"]): JSX.Element => (
	<div
		style={{
			backgroundColor: "white",
			borderRadius: 4,
			boxShadow: "0px 3px 36px #00000029",
			// marginTop: 8,
			position: "absolute",
			zIndex: 299,
			top: "-330px",
			height: "315px",
			right: "0px",
		}}
		{...props}
	/>
);
const Blanket = (props: JSX.IntrinsicElements["div"]): JSX.Element => (
	<div
		style={{
			bottom: 0,
			left: 0,
			top: 0,
			right: 0,
			position: "fixed",
			zIndex: 199,
		}}
		{...props}
	/>
);
const Dropdown = ({
	children,
	isOpen,
	target,
	onClose,
}: {
	children?: React.ReactNode;
	readonly isOpen: boolean;
	readonly target: React.ReactNode;
	readonly onClose: () => void;
}): JSX.Element => (
	<div style={{ position: "relative" }}>
		{isOpen ? <Menu>{children}</Menu> : null}
		{target}
		{isOpen ? <Blanket onClick={onClose} /> : null}
	</div>
);

interface Props {
	children: React.ReactNode;
	field: Field;
	value: Option[] | null;
}

const SelectDropdown = (props: Props): JSX.Element => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Dropdown
			isOpen={isOpen}
			onClose={(): void => setIsOpen(false)}
			target={
				<Button
					iconCategory="utility"
					iconName="chevronup"
					iconPosition="right"
					label={
						<span className="select-element">
							{props.field.fieldName}{" "}
							{Boolean(props.value && props.value.length) && (
								<span className="select-count" />
							)}
						</span>
					}
					onClick={(): void => setIsOpen(true)}
					className=""
				/>
			}
		>
			{props.children}
		</Dropdown>
	);
};

export default SelectDropdown;
