// General actions
import {
	LOAD_MENU_REQUEST,
	LOAD_MENU_RESPONSE,
	LOAD_USER_ROLES_AND_PROFILES,
	USER_ROLES_AND_PROFILES_LOADED,
	SET_TOAST_MESSAGE,
	CLEAR_TOAST_MESSAGE,
	SET_USER_SECRET_FLAG,
	SET_USER_SECRET_URL,
	SET_LOGIN_DATA,
	SET_2FA_FLAG,
	SET_RESPONSE_CODE,
	UNSET_ANNONCEMENT,
} from "./constants";

export function unsetAnnoncement() {
	return { type: UNSET_ANNONCEMENT };
}

export function loadMenus(newMenuState) {
	return { type: LOAD_MENU_REQUEST, newMenuState };
}

export function menusLoaded(menus) {
	return { type: LOAD_MENU_RESPONSE, menus };
}

// export function getCSRF() {
// 	return {
// 		type: "GET_CSRF",
// 	};
// }

export function loadUserInfo() {
	return { type: LOAD_USER_ROLES_AND_PROFILES };
}

export function userInfoLoaded(userInfo) {
	return { type: USER_ROLES_AND_PROFILES_LOADED, userInfo };
}

export function setToastMessage(message) {
	return { type: SET_TOAST_MESSAGE, message };
}

export function clearToastMessage() {
	return { type: CLEAR_TOAST_MESSAGE };
}

export function setUserSecretFlag(secretFlag) {
	return {
		type: SET_USER_SECRET_FLAG,
		secretFlag,
	};
}

export function setQRDataURL(secretURL) {
	return {
		type: SET_USER_SECRET_URL,
		secretURL,
	};
}

export function setLoginData(loginData) {
	return {
		type: SET_LOGIN_DATA,
		loginData,
	};
}

export function set2FAEnableFlag(is2FA) {
	return {
		type: SET_2FA_FLAG,
		is2FA,
	};
}

export function setResponseCode(code) {
	return { type: SET_RESPONSE_CODE, code };
}
