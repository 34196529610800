import React from "react";
import PropTypes from "prop-types";
import RecordAvatar from "@components/TinyComponents/RecordAvatar";
import AccountTypeIcon from "@components/TinyComponents/AccountTypeIcon";

const DuplicateRecordCard = ({ record }) => {
	const duplicateRecord = data => {
		if (data && (data.module === "lead" || data.module === "contact")) {
			return (
				<div className="pc-existing-email-details">
					<div className="slds-grid slds-gutters slds-wrap">
						<div className="slds-col slds-size_12-of-12 slds-medium-size_6-of-12">
							<div className="avatar-flex">
								<RecordAvatar
									variant="user"
									imageUrl={data.avatar}
									title={
										data.firstName
											? `${data.firstName} ${data.lastName}`
											: data.lastName
									}
									size="large"
								/>
								<div className="slds-truncate">
									<h2 className="name slds-truncate">{`${data.firstName} ${data.lastName}`}</h2>
									<p className="email slds-truncate">{data.email}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		if (data && data.module === "account") {
			return (
				<div className="pc-existing-email-details">
					<div className="slds-grid slds-gutters slds-wrap">
						<div className="slds-col slds-size_5-of-12">
							<div className="avatar-flex">
								<RecordAvatar
									variant="user"
									title={data.accountName}
									size="large"
								/>
								<div className="slds-truncate" style={{ marginRight: "1rem" }}>
									<h2 className="name slds-truncate">{`${data.accountName}`}</h2>
								</div>
								<AccountTypeIcon accountType={data.accountType} />
							</div>
						</div>
					</div>
				</div>
			);
		}
		return <div />;
	};
	return (
		<div>
			{record.title}{" "}
			{record.duplicate && (
				<a
					target="_blank"
					href={`/detail/${record.duplicate.module}/${record.duplicate._id}`}
					className="pc-existing-email-link"
				>
					{duplicateRecord(record.duplicate)}
				</a>
			)}
		</div>
	);
};

DuplicateRecordCard.propTypes = {
	record: PropTypes.object,
};

export default DuplicateRecordCard;
