// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@salesforce/design-system-react/components/checkbox";

class ToggleInput extends React.PureComponent {
	setCheckBox = e => {
		this.props.handleChange({
			fieldId: this.props.fieldId,
			fieldName: this.props.fieldName,
			typeCode: this.props.typeCode,
			value: e.target.checked,
		});
	};

	render() {
		const { props } = this;
		return (
			<Checkbox
				disabled={props.disable}
				name={props.name || props.fieldName || props.fieldId}
				onChange={this.setCheckBox}
				sectionid={props.sectionId}
				checked={props.value}
				variant="toggle"
				labels={props.labels}
			/>
		);
	}
}

ToggleInput.propTypes = {
	typeCode: PropTypes.string,
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	handleChange: PropTypes.func,
};

export default ToggleInput;
