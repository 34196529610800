import React from "react";
import cx from "classnames";

import Checkbox from "@salesforce/design-system-react/components/checkbox";
// import Button from "@salesforce/design-system-react/components/button";
// import Tooltip from "@salesforce/design-system-react/components/tooltip";

import CardTypeMedia from "@components/Card/CardTypeMedia";

import { CheckBoxInputType } from "@custom-types/input-type";
import "./styles/checkbox.scss";

const CheckBoxInput: React.FC<CheckBoxInputType> = (props): JSX.Element => {
	const setCheckBox: React.ChangeEventHandler<HTMLElement> = (event): void => {
		props.handleChange({
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			typeCode: props.typeCode,
			value: (event.target as HTMLInputElement).checked,
		});
	};

	if (props.field && (props.field.customName || props.field.transKey)) {
		return (
			<div id="pc-checkbox-card">
				<CardTypeMedia
					className={cx(
						props.className,
						"pc-component-card-type-media pc-checkbox-card pc-form-field-checkbox",
						"no-footer",
						"no-body",
						props.disable && "disable",
					)}
					header={{
						title: (
							<React.Fragment>
								{/* {props.field.helpText && (
								<span className="pc-modal-helper-text">
									<Tooltip content={props.field.helpText} align="right">
										<Button
											iconCategory="custom"
											iconName="pc-info"
											// size="small"
											variant="icon"
										/>
									</Tooltip>
								</span>
							)} */}
								{props.field.customName ||
									props.intl.formatMessage({
										id: props.field.transKey,
									})}
							</React.Fragment>
						),
						// icon: {
						// 	name: "pc-settings-field-type-checkbox",
						// 	size: "small",
						// },
						rightContent: (
							<React.Fragment>
								<Checkbox
									name={props.name || props.fieldName || props.fieldId}
									// className={cx(props.className)}
									onChange={setCheckBox}
									checked={props.value}
									disabled={props.disable}
									variant={props.variant || "base"}
								/>
								{props.placeholder ? (
									<span className="chk-label">{props.placeholder}</span>
								) : (
									""
								)}
							</React.Fragment>
						),
						align: "left",
					}}
				/>
			</div>
		);
	}
	return (
		<React.Fragment>
			<Checkbox
				labels={props.labels}
				disabled={props.disable}
				name={props.name || props.fieldName || props.fieldId}
				onChange={setCheckBox}
				checked={props.value}
				variant={props.variant || "base"}
			/>
			{props.placeholder ? (
				<span className="chk-label">{props.placeholder}</span>
			) : (
				""
			)}
		</React.Fragment>
	);
};

export default CheckBoxInput;
