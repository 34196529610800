/**
 * @description Singleton class to read & manipulate localStorage
 */

import { patchRequest } from "@utils/request";

class LocalStorage {
	constructor(localStorage = window.localStorage) {
		if (LocalStorage.exists) {
			// eslint-disable-next-line no-constructor-return
			return LocalStorage.instance;
		}
		this.storage = localStorage;
		LocalStorage.instance = this;
		LocalStorage.exists = true;
	}

	parseJSON = obj => {
		try {
			return JSON.parse(obj);
		} catch (e) {
			return undefined;
		}
	};

	getItem = (moduleId, itemName) => {
		const moduleObj = this.parseJSON(this.storage[moduleId]);
		return moduleObj && moduleObj[itemName];
	};

	getItemFromView = (moduleId, viewName, itemName) => {
		const moduleObj = this.parseJSON(this.storage[moduleId]);
		const viewObj = moduleObj && moduleObj[viewName];
		return viewObj && viewObj[itemName];
	};

	setItem = (moduleId, itemName, data) => {
		const moduleObj = this.parseJSON(this.storage[moduleId]) || {};
		moduleObj[itemName] = data;
		this.savePersonalization({ [this.idToName(moduleId)]: moduleObj });
		return this.storage.setItem(moduleId, JSON.stringify(moduleObj));
	};

	setItemToView = (moduleId, viewName, itemName, data) => {
		const moduleObj = this.parseJSON(this.storage[moduleId]) || {};
		const viewObj = moduleObj[viewName] || {};
		viewObj[itemName] = data;
		moduleObj[viewName] = viewObj;
		this.savePersonalization({ [this.idToName(moduleId)]: moduleObj });
		return this.storage.setItem(moduleId, JSON.stringify(moduleObj));
	};

	savePersonalization = data => {
		try {
			clearTimeout(this.timer);
			this.timer = setTimeout(
				() => patchRequest("/api/v2/personalization", data),
				3000,
			);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	idToName = id => {
		switch (id) {
			case "LM":
				return "lead";
			case "OM":
				return "opportunity";
			case "CM":
				return "contact";
			case "AM":
				return "account";
			case "TASK":
				return "task";
			case "EVENT":
				return "event";
			case "QTM":
				return "quotation";
			case "EMM":
				return "estimate";
			case "STREAM":
				return "stream";
			case "announcements":
				return "announcements";
			case "allConversation":
				return "allConversation";
			case "myConversation":
				return "myConversation";
			case "unAssigned":
				return "unAssigned";
			case "archive":
				return "archive";
			case "messageSetting":
				return "messageSetting";
			case "chatBotButtonPosition":
				return "chatBotButtonPosition";
			default:
				return "NA";
		}
	};
}

const localStorage = new LocalStorage();
export default localStorage;
