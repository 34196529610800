import React, {
	useState,
	ReactNode,
	useEffect,
	useRef,
	CSSProperties,
} from "react";
import { createRoot } from "react-dom/client";
import "./style.scss";

interface TooltipProps {
	children: ReactNode;
	title: string | null;
	desc: string | null;
}

interface TooltipState {
	isVisible: boolean;
	content: React.ReactNode | null;
	position: { x: number; y: number };
}

const tooltipState: TooltipState = {
	isVisible: false,
	content: null,
	position: { x: 0, y: 0 },
};

const TooltipRenderer: React.FC = () => {
	const [state, setState] = useState<TooltipState>(tooltipState);
	const tooltipRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const updateTooltip = (): void => setState({ ...tooltipState });
		document.addEventListener("tooltip-update", updateTooltip);

		const hideTooltip = (): void => {
			tooltipState.isVisible = false;
			document.dispatchEvent(new Event("tooltip-update"));
		};

		const handleClickOutside = (e: MouseEvent): void => {
			if (
				tooltipRef.current &&
				!tooltipRef.current.contains(e.target as Node)
			) {
				hideTooltip();
			}
		};

		const handleBlur = (): void => {
			hideTooltip();
		};

		document.addEventListener("mousedown", handleClickOutside);
		window.addEventListener("blur", handleBlur);

		return () => {
			document.removeEventListener("tooltip-update", updateTooltip);
			document.removeEventListener("mousedown", handleClickOutside);
			window.removeEventListener("blur", handleBlur);
		};
	}, []);

	const getTooltipPosition = (): CSSProperties => {
		if (!tooltipRef.current) return { left: "0px", top: "0px" };
		const tooltipRect = tooltipRef.current.getBoundingClientRect();
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;

		const { x, y } = state.position;

		const adjustedX =
			x + tooltipRect.width > viewportWidth
				? viewportWidth - tooltipRect.width - 10
				: x;

		const adjustedY =
			y + tooltipRect.height > viewportHeight
				? viewportHeight - tooltipRect.height - 10
				: y;

		return { left: `${adjustedX}px`, top: `${adjustedY}px` };
	};

	const tooltipStyle: CSSProperties = {
		...getTooltipPosition(),
		visibility: state.isVisible ? "visible" : "hidden",
	};

	return (
		<div
			ref={tooltipRef}
			style={tooltipStyle}
			className={`tooltip-content ${state.isVisible ? "visible" : ""} ${
				state.content && typeof state.content === "object" ? "breakText" : ""
			}`}
		>
			{state.content}
		</div>
	);
};

const tooltipRoot = document.createElement("div");
document.body.appendChild(tooltipRoot);
const root = createRoot(tooltipRoot);
root.render(<TooltipRenderer />);

const CustomTooltip: React.FC<TooltipProps> = ({
	children,
	title,
	desc,
}): JSX.Element => {
	const childrenRef = useRef<HTMLDivElement>(null);

	const showTooltip = (e: React.MouseEvent): void => {
		const content = (
			<React.Fragment>
				<div className="tooltip-title">{title}</div>
				{desc !== null && <div className="tooltip-desc">{desc}</div>}
			</React.Fragment>
		);

		tooltipState.isVisible = true;
		tooltipState.content = content;
		tooltipState.position = { x: e.clientX + 15, y: e.clientY + 15 };
		document.dispatchEvent(new Event("tooltip-update"));
	};

	const hideTooltip = (): void => {
		tooltipState.isVisible = false;
		document.dispatchEvent(new Event("tooltip-update"));
	};

	return (
		<div
			ref={childrenRef}
			className="tooltip-wrapper"
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}
			onMouseMove={showTooltip}
		>
			{children}
		</div>
	);
};

export default CustomTooltip;
