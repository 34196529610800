export enum ModuleShortNames {
	LM = "LM",
	CM = "CM",
	AM = "AM",
	OM = "OM",
	ACTM = "ACTM",
	QTM = "QTM",
	MSG = "MSG",
}

export enum ModuleToId {
	lead = "LM",
	contact = "CM",
	account = "AM",
	opportunity = "OM",
	activity = "ACTM",
	quotation = "QTM",
	messages = "MSG",
}
