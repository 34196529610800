/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import get from "lodash/get";
import cx from "classnames";

import "./styles/dateStyles.scss";

function DateNewInput(props) {
	const { timeZone } = props.userInfo.locale;
	const values = props.value ? moment(props.value).tz(timeZone) : null;

	const weekDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

	const handleChange = e => {
		if (e && e.isValid) {
			const {
				day,
				month: { number },
				year,
			} = e;
			const value = moment(`${year}-${number}-${day}`)
				.tz(timeZone)
				.endOf("day")
				.format()
				.valueOf();

			const utcVal = moment(value).toISOString();
			props.handleChange({
				fieldName: props.fieldName,
				fieldId: props.fieldId,
				typeCode: props.typeCode,
				value: utcVal,
				isRequired: props.isRequired,
			});
		} else {
			props.handleChange({
				fieldName: props.fieldName,
				fieldId: props.fieldId,
				typeCode: props.typeCode,
				value: "",
				isRequired: props.isRequired,
			});
		}
	};

	const [dateFormat] = get(
		props,
		"userInfo.locale.dateTimeFormat",
		"DD/MM/YYYY HH:mm",
	).split(" ");

	// eslint-disable-next-line react/react-in-jsx-scope
	return (
		<div className="pc-single-date-picker">
			<DatePicker
				id={props.fieldId}
				placeholder={`${dateFormat}`}
				weekDays={weekDays}
				inputClass={cx(props.className, "custom-input")}
				value={values && values.format(dateFormat)}
				onChange={handleChange}
				format={dateFormat}
				arrow={false}
				numberOfMonths={props.numberOfMonths}
				disabled={props.disable}
				calendarPosition="bottom"
			/>
		</div>
	);
}

DateNewInput.propTypes = {
	handleChange: PropTypes.func,
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	typeCode: PropTypes.string,
	disable: PropTypes.bool,
	isRequired: PropTypes.bool,
	userInfo: PropTypes.object,
	numberOfMonths: PropTypes.number,
	// small: PropTypes.bool,
	value: PropTypes.string,
	className: PropTypes.string,
	// alwaysOpen: PropTypes.bool,
};

export default DateNewInput;
