const colors = [
	"#007A82",
	"#04B9B3",
	"#02AE7B",
	"#069322",
	"#32C24F",
	"#96C100",
	"#00508F",
	"#006DFF",
	"#0A6ACA",
	"#7090FF",
	"#3A96FF",
	"#0FC7FF",
	"#5222BD",
	"#8943B4",
	"#BE4AFF",
	"#CF3E94",
	"#D81081",
	"#E35C9B",
	"#EF4244",
	"#E6515E",
	"#FF8282",
	"#FF6060",
	"#FF7135",
	"#FF4C00",
	"#B58634",
	"#C5921F",
	"#F1B200",
	"#F59800",
	"#FEBA00",
	"#cddc39",
];

export default colors;
