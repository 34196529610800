/* eslint-disable no-else-return */
import React from "react";
import { thaiRegex, koreanRegex } from "@utils/constants";
import "./styles.scss";
import CustomTooltip from "@components/customTooltip";

export interface RecordAvatarProps {
	title: string;
	size: "small" | "x-small" | "medium" | "large";
	imageUrl?: string;
	variant: "entity" | "user";
	color?: string;
	isRetentionExpired?: boolean;
	isDeletionExpired?: boolean;
}
const colorObj = {
	A: "#CF3E94",
	B: "#96C100",
	C: "#7090FF",
	D: "#E6515E",
	E: "#02AE7B",
	F: "#BE4AFF",
	G: "#FF8282",
	H: "#04B9B3",
	I: "#8943B4",
	J: "#FF9443",
	K: "#318AE2",
	L: "#CF3E94",
	M: "#96C100",
	N: "#7090FF",
	O: "#E6515E",
	P: "#02AE7B",
	Q: "#BE4AFF",
	R: "#FF8282",
	S: "#04B9B3",
	T: "#8943B4",
	U: "#FF9443",
	V: "#318AE2",
	W: "#CF3E94",
	X: "#96C100",
	Y: "#7090FF",
	Z: "#E6515E",
	0: "#CF3E94",
	1: "#96C100",
	2: "#7090FF",
	3: "#E6515E",
	4: "#02AE7B",
	5: "#BE4AFF",
	6: "#FF8282",
	7: "#04B9B3",
	8: "#8943B4",
	9: "#FF9443",
};

export const randomColor = (colorSelect: string): string => {
	if (!colorSelect || !colorSelect.length) {
		return "";
	}
	const format = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g;
	if (format.test(colorSelect)) {
		return "#318AE2";
	} else if (thaiRegex.test(colorSelect) || koreanRegex.test(colorSelect)) {
		return "#02AE7B";
	} else {
		const first = colorSelect.trim().charAt(0).toUpperCase();
		return colorObj[first as keyof typeof colorObj];
	}
};

function RecordAvatar(props: RecordAvatarProps): JSX.Element {
	const {
		title,
		size = "large",
		imageUrl,
		variant,
		color,
		isRetentionExpired = false,
		isDeletionExpired = false,
	} = props;

	const name = (newName: string): string => {
		if (!newName || !newName.length) {
			return "";
		}
		const fullName = newName.trim();

		const values = newName.split(" ").filter(function (v) {
			return v !== "";
		});
		if (values.length > 1) {
			const firstLetter = fullName[0].toUpperCase();
			const lastWord = fullName.split(" ");
			const lastLetter = lastWord[lastWord.length - 1][0].toUpperCase();
			return `${firstLetter}${lastLetter}`;
		} else if (fullName[0] && fullName[1]) {
			return `${fullName[0].toUpperCase()}${fullName[1]}`;
		} else {
			return `${fullName.toUpperCase()}`;
		}
	};

	let tooltipMsg = "";

	if (isDeletionExpired) {
		tooltipMsg = "Retention & deletion period expired";
	} else if (isRetentionExpired) {
		tooltipMsg = "Retention period expired";
	}

	return tooltipMsg ? (
		<CustomTooltip title={title} desc={tooltipMsg}>
			<span
				className={`slds-avatar slds-avatar_circle slds-avatar_${size} ${
					isRetentionExpired ? "expired-avatar" : ""
				}`}
			>
				{imageUrl ? (
					<img alt={`${title}`} src={`${imageUrl}`} />
				) : (
					<abbr
						className={`slds-avatar__initials slds-icon-standard-${variant}`}
						style={{ background: color || `${randomColor(title)}` }}
					>
						{name(title)}
					</abbr>
				)}
			</span>
		</CustomTooltip>
	) : title ? (
		<CustomTooltip title={title} desc={null}>
			<span className={`slds-avatar slds-avatar_circle slds-avatar_${size} `}>
				{imageUrl ? (
					<img alt={`${title}`} src={`${imageUrl}`} />
				) : (
					<abbr
						className={`slds-avatar__initials slds-icon-standard-${variant}`}
						style={{ background: color || `${randomColor(title)}` }}
					>
						{name(title)}
					</abbr>
				)}
			</span>
		</CustomTooltip>
	) : (
		<span className={`slds-avatar slds-avatar_circle slds-avatar_${size} `}>
			{imageUrl ? (
				<img alt={`${title}`} src={`${imageUrl}`} />
			) : (
				<abbr
					className={`slds-avatar__initials slds-icon-standard-${variant}`}
					style={{ background: color || `${randomColor(title)}` }}
				>
					{name(title)}
				</abbr>
			)}
		</span>
	);
}

export default RecordAvatar;
