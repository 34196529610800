/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
// const addLocaleData = require("react-intl").addLocaleData; //eslint-disable-line
// const enLocaleData = require("react-intl/locale-data/en");
// const deLocaleData = require("react-intl/locale-data/de");
// require("@formatjs/intl-pluralrules/polyfill");
// require("@formatjs/intl-pluralrules/locale-data/de");
// require("@formatjs/intl-pluralrules/locale-data/en");
// require("@formatjs/intl-relativetimeformat/polyfill");
// require("@formatjs/intl-relativetimeformat/locale-data/de");
// require("@formatjs/intl-relativetimeformat/locale-data/en");

const enTranslationMessages = require("./translations/en.json");
const deTranslationMessages = require("./translations/de.json");

// addLocaleData(enLocaleData);
// addLocaleData(deLocaleData);

const DEFAULT_LOCALE = "en";

const appLocales = ["en", "de"];

const formatTranslationMessages = (locale, messages) => {
	// [CHECK] Nested Closure check its garbage collection
	const defaultFormattedMessages =
		locale !== DEFAULT_LOCALE
			? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
			: {};

	// [CHECK] Inherited param
	const flattenFormattedMessages = (formattedMessages, key) => {
		const formattedMessage =
			!messages[key] && locale !== DEFAULT_LOCALE
				? defaultFormattedMessages[key]
				: messages[key];
		return Object.assign(formattedMessages, { [key]: formattedMessage });
	};
	return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
	en: formatTranslationMessages("en", enTranslationMessages),
	de: formatTranslationMessages("de", deTranslationMessages),
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
