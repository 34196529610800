export const Chunk = {
	background: "#3c4247",
	border: "1px solid #32373c",
	borderRadius: ".2em",
	margin: "0 .15em",
};

export const CodingFont = {
	fontFamily: "Menlo",
	fontSize: "0.825em",
	textTransform: "none",
};
