import React from "react";
import Input from "@salesforce/design-system-react/components/input";
import { NumberInputType } from "@custom-types/input-type";

const NumberInput: React.FC<NumberInputType> = (props): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (event: any): void => {
		const { value: unsanitizedValue } = event.target;
		const value = !Number.isNaN(parseFloat(unsanitizedValue))
			? parseFloat(unsanitizedValue)
			: "";

		if (
			typeof value === "number" &&
			props.hasOwnProperty("maxValue") &&
			value > (props.maxValue as number)
		) {
			return;
		}

		props.handleChange({
			typeCode: props.typeCode,
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			isRequired: props.isRequired,
			value,
		});
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleBlur = (event: any): void => {
		const { value: unsanitizedValue } = event.target as HTMLInputElement;
		const value = !Number.isNaN(parseFloat(unsanitizedValue))
			? parseFloat(unsanitizedValue)
			: "";

		// eslint-disable-next-line no-param-reassign
		event.target.value = value;

		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value,
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleBlur(record);
	};

	const handleFocus = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (props.handleFocus) {
			const { value: unsanitizedValue } = event.target as HTMLInputElement;
			const value = !Number.isNaN(parseFloat(unsanitizedValue))
				? parseFloat(unsanitizedValue) || ""
				: "";

			const record = {
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				sectionId: props.sectionId,
				value,
				typeCode: props.typeCode,
				isRequired: props.isRequired,
			};
			props.handleFocus(record);
		}
	};

	const validateKeyStroke = (
		evt: React.KeyboardEvent<HTMLInputElement>,
	): void => {
		if (["e", "E", "+", "-"].includes(evt.key)) {
			evt.preventDefault();
		}
	};

	const nonNumericInput = (
		event: React.KeyboardEvent<HTMLInputElement>,
	): void => {
		const evt = event || window.event;
		const charCode = evt.key;

		const regex = props.allowDecimal ? /^[0-9.]+$/ : /^[0-9]+$/;

		if (!charCode.match(regex)) evt.preventDefault();
	};

	return (
		<Input
			id={props.fieldId}
			disabled={props.disable}
			name={props.name || props.fieldName || props.fieldId}
			onKeyPress={nonNumericInput}
			onChange={handleChange}
			onBlur={handleBlur}
			onFocus={handleFocus}
			onKeyDown={validateKeyStroke}
			type="number"
			value={props.value}
			className={`${props.className} pc-modal-text-box`}
			maxLength={props.max || props.field.max}
			autoComplete="off"
			placeholder={props.placeholder}
			minValue={props.hasOwnProperty("minValue") ? props.minValue : undefined}
			maxValue={props.hasOwnProperty("maxValue") ? props.maxValue : undefined}
			fixedTextRight={props.fixedTextRight}
		/>
	);
};

export default NumberInput;
