/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux-immutable";
import globalReducer from "@containers/App/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const rootReducer = (injectedReducers = {}) =>
	combineReducers({
		global: globalReducer,
		...injectedReducers,
	});

export default rootReducer;
