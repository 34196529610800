import moment from "moment-timezone";
import capitalize from "lodash/capitalize";
import { ProfilePermissions, Permission } from "@interfaces/profile";
import { BusinessRecordsData } from "@interfaces/business-record";
import { AllModuleIds } from "@custom-types/module-id";
import { Meta } from "@custom-types/tiny-types";
import { TaskType } from "@containers/QueueHistory/types";
import { User } from "@interfaces/user";

export const moduleIdToName = {
	LM: "Lead",
	OM: "Opportunity",
	AM: "Account",
	CM: "Contact",
	ACTM: "Activity",
	QTM: "Quotation",
	MSG: "Messages",
};

export const moduleIdToNames = {
	LM: "Leads",
	OM: "Opportunities",
	AM: "Accounts",
	CM: "Contacts",
	ACTM: "Activity",
	MSG: "Messages",
};

export const moduleNameToId = {
	lead: "LM",
	account: "AM",
	opportunity: "OM",
	contact: "CM",
	quotation: "QTM",
	task: "TASK",
	event: "EVENT",
	messages: "MSG",
};

export const checkPermission = (
	permission: ProfilePermissions,
	moduleId: AllModuleIds,
	action: keyof Permission,
): boolean => {
	const actionPermission = permission[moduleId];
	return actionPermission[action];
};

export const escapeRegExp = (str: string): string => {
	if (str) return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
	return str;
};

export const getAvatarTitle = (
	records: BusinessRecordsData,
): { title: string; variant: string } => {
	let variant = "user";
	let title = "";
	if (records.F3 || records.F2) {
		const firstName = records.F2; // firstName
		const lastName = records.F3; // lastName
		const fN = firstName || "";
		const lN = lastName || "";
		title = `${fN} ${lN}`; // lead
	} else if (records.F93 || records.F92) {
		const firstName = records.F92; // firstName
		const lastName = records.F93; // lastName

		const fN = firstName || "";
		const lN = lastName || "";

		title = `${fN} ${lN}`; // contact
	} else if (records.F69) {
		title = records.F69 as string; // accountname
		variant = "entity";
	} else if (records.F57) {
		variant = "entity";
		title = records.F57 as string; // opportunity name
	} else if (records.F22) {
		title = records.F22 as string;
	} else if (records.F108) {
		title = records.F108 as string;
	} else if (records.F73) {
		title = records.F73 as string;
	} else if (records.F102) {
		title = (records.F102 as Meta).name;
	}

	return { title: title || "", variant };
};

export const formatTimeFromNow = (date: string, tz: string): string => {
	if (!date) {
		return "-";
	}

	return moment(date).locale(tz).fromNow();
};

export const formatString = (
	number = 0,
	word: string,
	uppercase = false,
): string => {
	let returnString = word;

	if (number > 1) {
		returnString += "s";
	}

	if (uppercase) returnString = capitalize(returnString);

	return returnString;
};

export const getTimeForAccountView = (timeOf = "LAST_UPDATED"): string => {
	const currentTime = moment().tz("Asia/Singapore");
	const currentMinute = currentTime.minute();

	if (timeOf === "LAST_UPDATED") {
		if (currentMinute <= 30) {
			return currentTime.format("HH:00");
		}
		return currentTime.format("HH:30");
	}

	if (currentMinute <= 30) {
		return currentTime.format("HH:30");
	}
	return currentTime.add(1, "hour").format("HH:00");
};

export const MINUTES = "Minutes";
export const HOURS = "Hours";
export const DAYS = "Days";
export const WEEKS = "Weeks";

export const getAttachmentFileSizeFormat = (size: number): string => {
	const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	let l = 0;
	let n = parseInt(String(size), 10) || 0;

	while (n >= 1024) {
		l += 1;
		n /= 1024;
	}

	return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};

export const NOOP = (): void => {
	// nothing
};

export const moduleNameToFormId = {
	lead: "LF1",
	opportunity: "OF1",
	account: "AF1",
	contact: "CF1",
};

export const copyMessage = {
	type: "success",
	body: {
		title: "Copied to clipboard",
	},
};

export const setLastActiveRoute = (route: string, url: string): void => {
	localStorage.setItem(
		"lastActiveRoute",
		JSON.stringify({
			route,
			url,
		}),
	);
};

export const clearLastActiveRoute = (): void => {
	localStorage.removeItem("lastActiveRoute");
};

export const marketingFieldsToDisplay = {
	FB_LEAD_GENERATION: [
		{
			tabIndex: 12,
			fieldId: "F140",
			order: 1,
			rowCode: "R1",
		},
		{
			tabIndex: 12,
			fieldId: "F141",
			order: 2,
			rowCode: "R1",
		},
		{
			tabIndex: 12,
			fieldId: "F142",
			order: 3,
			rowCode: "R2",
		},
	],
	WEB_FORM: [
		{
			tabIndex: 12,
			fieldId: "F143",
			order: 1,
			rowCode: "R1",
		},
		{
			tabIndex: 12,
			fieldId: "F144",
			order: 2,
			rowCode: "R1",
		},
		{
			tabIndex: 12,
			fieldId: "F145",
			order: 3,
			rowCode: "R2",
		},
		{
			tabIndex: 12,
			fieldId: "F146",
			order: 4,
			rowCode: "R2",
		},
		{
			tabIndex: 12,
			fieldId: "F147",
			order: 5,
			rowCode: "R3",
		},
		{
			tabIndex: 12,
			fieldId: "F148",
			order: 6,
			rowCode: "R3",
		},
		{
			tabIndex: 12,
			fieldId: "F151",
			order: 7,
			rowCode: "R4",
		},
	],
	MESSENGER_AD: [
		{
			tabIndex: 12,
			fieldId: "F141",
			order: 1,
			rowCode: "R1",
		},
		{
			tabIndex: 12,
			fieldId: "F156",
			order: 2,
			rowCode: "R1",
		},
		{
			tabIndex: 12,
			fieldId: "F139",
			order: 3,
			rowCode: "R2",
			layout: {
				small: 12,
				medium: 12,
				large: 12,
			},
		},
	],
};

export const generateFileName = (
	taskType: TaskType,
	name: string,
	errorFile: boolean,
): string => {
	let fileName = taskType.toLowerCase();
	switch (taskType) {
		case "EXPORT":
		case "BROADCAST_REPORT":
			fileName = name;
			break;
		case "IMPORT":
			fileName = `${name}-error`;
			break;
		case "MAILCHIMP":
		case "MAILERLITE":
			fileName = `${fileName}-sync-error`;
			break;
		case "SYNC_GOOGLE_CONTACTS":
		case "SYNC_OUTLOOK_CONTACTS":
			fileName = `contact-sync-error`;
			break;
		case "MESSAGE_BROADCAST":
			fileName = !errorFile ? name : `${name}-error`;
			break;
		default:
			break;
	}

	return fileName;
};
export type ModuleId = "LM" | "CM";
export const retentionModuleIdToField: Record<ModuleId, string> = {
	LM: "F158",
	CM: "F160",
};
export const deletionModuleIdToField = {
	LM: "F159",
	CM: "F161",
};
interface ExpiryStatus {
	isRetentionExpired: boolean;
	isDeletionExpired: boolean;
}
const isDateExpired = (
	date: string,
	timeZone: string,
	currentDate: moment.Moment,
): boolean => {
	if (typeof date === "string") {
		const dateInTimeZone = moment(date).tz(timeZone).startOf("day");
		return dateInTimeZone.isBefore(currentDate);
	}
	return false;
};
export const checkBusinessRecordExpiry = (
	records: BusinessRecordsData,
	timeZone: string,
	moduleId: ModuleId,
): ExpiryStatus => {
	let isRetentionExpired = false;
	let isDeletionExpired = false;

	if (records && timeZone && moduleId && ["LM", "CM"].includes(moduleId)) {
		const currentDate = moment().tz(timeZone).startOf("day");
		const retentionDate = records[retentionModuleIdToField[moduleId]] as string;
		const deletionDate = records[deletionModuleIdToField[moduleId]] as string;
		if (retentionDate) {
			isRetentionExpired = isDateExpired(retentionDate, timeZone, currentDate);
		}
		if (deletionDate) {
			isDeletionExpired = isDateExpired(deletionDate, timeZone, currentDate);
		}
	}

	return { isRetentionExpired, isDeletionExpired };
};

/* eslint-disable */

export const getWarningMessage = (
	isOptOut: boolean,
	isExpired: boolean,
	provider: string,
	isSingleOptoutOrExpired: boolean = false,
): string => {
	let warningMsg = "";
	if (isOptOut && isExpired) {
		switch (provider) {
			case "MESSAGE":
				if (isSingleOptoutOrExpired) {
					warningMsg =
						"The retention period of this recipient has expired, and has opted out of messaging permissions. Please refrain from sending any messages.";
				} else {
					warningMsg =
						"The retention period for some of the recipients has expired, and has opted out of messaging permissions. Please refrain from sending any messages.";
				}
				break;
			case "AIRCALL":
				warningMsg =
					"The retention period of this recipient has expired, and has opted out of calling permissions. Please refrain from calling.";
				break;
			case "EMAIL":
				if (isSingleOptoutOrExpired) {
					warningMsg =
						"The retention period of this recipient has expired, and has opted out of email permissions. Please refrain from sending any emails.";
				} else {
					warningMsg =
						"The retention period for some of the recipients has expired, and has opted out of email permissions. Please refrain from sending any emails.";
				}
				break;
			default:
				break;
		}
	} else if (isExpired) {
		switch (provider) {
			case "MESSAGE":
				if (isSingleOptoutOrExpired) {
					warningMsg =
						"Data retention period for this record has expired. Please refrain from messaging this recipient.";
				} else {
					warningMsg =
						"The retention period for some of the recipients has expired. Please refrain from sending any messages.";
				}
				break;
			case "AIRCALL":
				warningMsg =
					"Data retention period for this record has expired. Please refrain from calling this recipient.";
				break;
			case "EMAIL":
				if (isSingleOptoutOrExpired) {
					warningMsg =
						"Data retention period for this record has expired. Please refrain from emailing this recipient.";
				} else {
					warningMsg =
						"The retention period for some of the recipients has expired. Please refrain from sending any emails.";
				}
				break;
			default:
				break;
		}
	} else if (isOptOut) {
		switch (provider) {
			case "MESSAGE":
				if (isSingleOptoutOrExpired) {
					warningMsg =
						"The recipient has opted out of messaging permissions. Please refrain from sending any marketing messages.";
				} else {
					warningMsg =
						"Some of the recipients have opted out of message permissions. Please refrain from sending any marketing messages.";
				}
				break;
			case "AIRCALL":
				warningMsg =
					"The recipient has opted out of calling permissions. Please refrain from any calls.";
				break;
			case "EMAIL":
				if (isSingleOptoutOrExpired) {
					warningMsg =
						"The recipient has opted out of email permissions. Please refrain from sending any marketing emails.";
				} else {
					warningMsg =
						"Some of the recipients have opted out of email permissions. Please refrain from sending any marketing emails.";
				}
				break;
			default:
				break;
		}
	}
	return warningMsg;
};
export const checkExpiry = (
	records: BusinessRecordsData,
	moduleId: ModuleId,
	user: User,
): boolean => {
	if (records && moduleId && ["LM", "CM"].includes(moduleId)) {
		const {
			locale: { timeZone },
		} = user;

		const { isRetentionExpired } = checkBusinessRecordExpiry(
			records,
			timeZone,
			moduleId,
		);
		return isRetentionExpired;
	}
	return false;
};
