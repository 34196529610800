import * as React from "react";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AircallPhone from "aircall-everywhere";
import Button from "@salesforce/design-system-react/components/button";

import AlertModal from "@components/Modal/AlertModal";
import useUser from "@hooks/useUser";

export declare interface AppContextInterface {
	isAircallShown: boolean;
	toggleAircall: React.Dispatch<React.SetStateAction<void>>;
	aircallOpen: React.Dispatch<React.SetStateAction<void>>;
	aircallClose: React.Dispatch<React.SetStateAction<void>>;
	dialNumber: (value: string) => void;
	checkProceed: (value: string, isExpired: boolean) => boolean;
	isOptOut: boolean;
	isExpired: boolean;
}

export const AircallContext = React.createContext<AppContextInterface | null>(
	null,
);

const AircallProvider = ({
	children,
}: {
	children: React.JSX.Element;
}): JSX.Element => {
	const [isAircallShown, setIsAircallShown] = React.useState(false);
	const [aircallPhone, setAircallPhone] = React.useState(null);
	const [isAlertOpen, setIsAlertOpen] = React.useState(false);
	const [isOptOut, setIsOptOut] = React.useState(false);
	const [isExpired, setIsExpired] = React.useState(false);

	const history = useHistory();

	const {
		integrations,
		profile: { permissions },
		userType,
	} = useUser();

	const toggleAircall = (): void => setIsAircallShown(st => !st);

	const aircallOpen = (): void => setIsAircallShown(true);

	const aircallClose = (): void => setIsAircallShown(false);

	const dialNumber = (phoneNumber: string): void => {
		if (aircallPhone && phoneNumber) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			aircallPhone.send("dial_number", { phone_number: phoneNumber });
		}
	};

	const getAircallIntegration = (): boolean =>
		!!integrations.find(
			each => each.provider === "AIRCALL" && each.status === "ACTIVE",
		);

	const toggleAlert = (): void => setIsAlertOpen(st => !st);

	const checkProceed = (val: string, isExpired: boolean): boolean => {
		const hasAircallIntegration = getAircallIntegration();
		setIsExpired(isExpired);
		if (val === "Opt out") {
			setIsOptOut(true);
		} else {
			setIsOptOut(false);
		}
		if (!hasAircallIntegration || !permissions.ACTM.CREATE) {
			setIsAlertOpen(true);
			return false;
		}

		return true;
	};

	const getTitle = (): string => {
		const hasAircallIntegration = getAircallIntegration();

		if (!hasAircallIntegration) return "Integrate!";
		return "Contact admin!";
	};

	const getBody = (): string => {
		const hasAircallIntegration = getAircallIntegration();

		if (!hasAircallIntegration || userType === "USER") {
			if (userType === "USER") {
				return "You have not integrated any voice call service yet. Contact your admin to proceed with the integration.";
			}
			return "You have not integrated any voice call service yet. Integrate to make or receive calls.";
		}

		return "You have not been provided with access to voice call services. Contact your admin for permissions.";
	};

	const getFooter = (): JSX.Element => {
		const hasAircallIntegration = getAircallIntegration();
		if (!hasAircallIntegration && userType !== "USER") {
			return (
				<React.Fragment>
					<Button
						key="noBtn"
						id="NO"
						label="Cancel"
						onClick={toggleAlert}
						className="pc-modal-secondary-btn"
					/>
					<Button
						key="yesBtn"
						id="YES"
						label="Integrate"
						onClick={(): void => {
							setIsAlertOpen(false);
							history.push("/settings/integration/call");
						}}
						className="pc-modal-primary-btn"
					/>
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				<Button
					key="okBtn"
					id="OK"
					label="OK"
					onClick={toggleAlert}
					className="pc-modal-primary-btn"
				/>
			</React.Fragment>
		);
	};

	React.useEffect(() => {
		if (permissions.ACTM.CREATE) {
			const airCallPhone = new AircallPhone({
				domToLoadPhone: "#aircall",
				size: "big",
			});
			setAircallPhone(airCallPhone);
		}
	}, []);

	return (
		<AircallContext.Provider
			value={{
				isAircallShown,
				toggleAircall,
				aircallOpen,
				aircallClose,
				dialNumber,
				checkProceed,
				isOptOut,
				isExpired,
			}}
		>
			{children}
			<AlertModal
				isOpen={isAlertOpen}
				toggleAlert={toggleAlert}
				iconProps={{
					name: "pc-info-circle",
					size: "large",
				}}
				title={getTitle()}
				footer={getFooter()}
			>
				{getBody()}
			</AlertModal>
		</AircallContext.Provider>
	);
};

export default AircallProvider;
