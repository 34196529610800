import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import RecordNotFound from "@containers/PageLayout/RecordNotFound";
import PageNotFound from "@containers/PageLayout/PageNotFound";

import AccessDenied from "@containers/PageLayout/AccessDenied";
import InternalServerError from "@containers/PageLayout/InternalServerError";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			eventId: null,
			causeOfError: false,
		};
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// eslint-disable-next-line no-console
		console.log(error);
		Sentry.withScope(scope => {
			scope.setExtras(errorInfo);
			scope.setTag("app.status", "CRASHED");
			const eventId = Sentry.captureException(error);
			this.setState({ eventId, error, causeOfError: error.cause });
		});
	}

	render() {
		const { hasError, error } = this.state;
		if (hasError) {
			if (error && error.status) {
				if (error.status === 404) {
					// page not found
					return <PageNotFound message={error.message} />;
				}
				if (error.status === 403) {
					// access denied
					return <AccessDenied message={error.message} />;
				}
			}

			if (this.state.causeOfError) {
				return <RecordNotFound />;
			}
			// You can render any custom fallback UI - internal server error
			return <InternalServerError eventId={this.state.eventId} showFeedback />;
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
};

export default ErrorBoundary;
