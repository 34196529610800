import React from "react";
import messageAudio from "../../../assets/audio/Message - Notification.mp3";

const audio = new Audio(messageAudio);

class Sound extends React.Component {
	onClicked = () => {
		try {
			if (audio.paused) {
				audio.play().catch(error => {
					// eslint-disable-next-line no-console
					console.error("Error playing audio:", error);
				});
			}
		} catch (e) {
			/* empty */
		}
	};

	render() {
		return (
			<div style={{ height: 0, visibility: "hidden",display:'none' }}>
				<button
					type="submit"
					id="newMessageSoundPlay"
					onClick={() => this.onClicked()}
				>
					play
				</button>
			</div>
		);
	}
}
export default Sound;
