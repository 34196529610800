import React from "react";
import {
	StylesConfig,
	components,
	OptionProps,
	ContainerProps,
} from "react-select";

import Icon from "@salesforce/design-system-react/components/icon";

import { Option as CustomOption } from "@components/TinyComponents/FormField";

export const selectStyles: StylesConfig<CustomOption, true> = {
	control: provided => ({
		...provided,
		minWidth: 240,
		margin: 8,
	}),
	menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
	menuList: (base, props) => ({
		...base,
		height: !props.getValue().length ? "200px" : "250px",
	}),
	container: base => ({
		...base,
		boxShadow: `0px 3px 36px #00000029`,
		padding: `5px 10px`,
		height: "inherit",
		width: "336px",
		".pc-error-message": {
			width: "100%",
			backgroundColor: "#ffe1d9",
			padding: "0.3rem 1rem",
			borderRadius: "8px 8px",
			fontSize: "13px",
			margin: "0.4rem 0",
		},
	}),
	option: base => ({
		...base,
		borderBottom: `1px solid #ececec`,
		backgroundColor: "#fff",
		color: "#444463",
		maxWidth: "300px",
		overflow: "hidden",
		display: "flex",
	}),
	input: base => ({
		...base,
		color: "#000",
	}),
};

export const Option = (props: OptionProps<CustomOption, true>): JSX.Element => (
	<div>
		{" "}
		<components.Option {...props}>
			<div>
				<Icon
					category="custom"
					size="x-small"
					name={
						props.isSelected || props.selectProps.selectedAll
							? "pc-checked"
							: "pc-unchecked"
					}
				/>
			</div>
			<div className="slds-truncate slds-m-left_x-small" title={props.label}>
				{props.label}
			</div>
		</components.Option>{" "}
	</div>
);

export const CustomContainer = (
	props: ContainerProps<CustomOption, true>,
): JSX.Element => {
	const value = props.getValue();

	return (
		<components.SelectContainer {...props}>
			{Boolean(!value || !value.length) && (
				<p className="pc-error-message">
					<Icon
						name="pc-alert-circle"
						category="custom"
						size="x-small"
						className="slds-m-right_small"
					/>
					Select at least 1 group to continue.
				</p>
			)}
			{props.children}
		</components.SelectContainer>
	);
};
