/**
 *
 * Card
 *
 */

import React from "react";
import PropTypes from "prop-types";
import Card from "@salesforce/design-system-react/components/card";
import CardTypeCenter from "./CardTypeCenter";
import CardTypeMedia from "./CardTypeMedia";

function CardComponent(props) {
	if (props.type === "center") return <CardTypeCenter {...props} />;
	if (props.type === "media") return <CardTypeMedia {...props} />;

	// If not props.type or doesn't match with center || media
	return <Card>{props.children}</Card>;
}

CardComponent.propTypes = {
	cardType: PropTypes.string,
	item: PropTypes.object,
	children: PropTypes.node,
	type: PropTypes.string,
};

export default CardComponent;
