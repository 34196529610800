// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Textarea from "react-textarea-autosize";
import EmojiPicker from "@containers/Messages/ChatScreen/EmojiPicker";

function TextArea(props) {
	const ref = useRef(null);
	const handleChange = event => {
		const { value } = event.target;

		props.handleChange({
			typeCode: props.typeCode,
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			isRequired: props.isRequired,
			value,
		});
	};

	const handleBlur = event => {
		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionid: props.sectionId,
			value: event.target.value.trim(),
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleBlur(record);
	};

	const onEmojiClick = emojiObject => {
		let text;
		if (ref.current) {
			const cursor = ref.current.selectionStart;
			text =
				props.value.slice(0, cursor) +
				emojiObject.emoji +
				props.value.slice(cursor);

			// Codes added for the new cursor
			const newCursor = cursor + emojiObject.emoji.length;
			setTimeout(() => {
				if (ref.current) {
					ref.current.setSelectionRange(newCursor, newCursor);
				}
			}, 10);
		} else text = props.value + emojiObject.emoji;
		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionid: props.sectionId,
			value: text,
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleChange(record);
	};

	const handleFontChange = type => {
		let text;
		let font;
		if (type === "b") {
			font = `**`;
		} else if (type === "i") {
			font = `__`;
		} else {
			font = `~~`;
		}

		if (ref.current) {
			const cursor = ref.current.selectionStart;
			const selected = window.getSelection().toString();
			if (selected) {
				switch (type) {
					case "b":
						text = props.value.replace(selected, `*${selected}*`);
						break;
					case "i":
						text = props.value.replace(selected, `_${selected}_`);
						break;
					default:
						text = props.value.replace(selected, `~${selected}~`);
						break;
				}
			} else {
				text = `${props.value.slice(0, cursor)}${font}${props.value.slice(
					cursor,
				)}`;
			}
			const record = {
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				sectionid: props.sectionId,
				value: text,
				typeCode: props.typeCode,
				isRequired: props.isRequired,
			};
			props.handleChange(record);
			const name = document.getElementById(props.fieldId);
			name.focus();
			setTimeout(() => {
				if (ref.current) {
					ref.current.setSelectionRange(cursor + 1, cursor + 1);
				}
			}, 10);
		}
	};
	return (
		<React.Fragment>
			{props.showEmojiWithFonts && (
				<div className="emoji-block">
					<EmojiPicker onEmojiClick={onEmojiClick} iconName="pc-emoji-picker" />
					<button type="button" onClick={() => handleFontChange("b")}>
						B
					</button>
					<button type="button" onClick={() => handleFontChange("i")}>
						I
					</button>
					<button type="button" onClick={() => handleFontChange("s")}>
						S
					</button>
				</div>
			)}
			<Textarea
				ref={ref}
				rows={props.maxRows}
				minRows={props.minRows}
				disabled={props.disable}
				name={props.name || props.fieldName || props.fieldId}
				tabIndex={props.tabIndex}
				onChange={e => handleChange(e)}
				onBlur={e => handleBlur(e)}
				sectionid={props.sectionId}
				id={props.fieldId}
				value={props.value}
				className={cx(props.className, "pc-modal-textarea-box")}
				placeholder={props.placeholder}
				maxLength={props.maxLength}
				readOnly={props.readOnly}
			/>
			{props.showEmoji && (
				<div className="emoji-block">
					<EmojiPicker onEmojiClick={onEmojiClick} iconName="pc-emoji-picker" />
				</div>
			)}
			{props.showRemainingAllowed && props.maxLength && (
				<span
					className={cx(
						"chracremaining",
						props.maxLength - (props.value || "").length === 0 &&
							"charactersover",
					)}
				>
					{props.maxLength - (props.value || "").length} Characters remaining
				</span>
			)}
		</React.Fragment>
	);
}

TextArea.propTypes = {
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	sectionId: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	showEmoji: PropTypes.bool,
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	maxRows: PropTypes.number,
	minRows: PropTypes.number,
};

TextArea.defaultProps = {
	maxRows: 10,
	minRows: 3,
	showEmoji: false,
};

export default TextArea;
