import React from "react";
import Input from "@salesforce/design-system-react/components/input";
import { PercentInputType } from "@custom-types/input-type";

const PercentInput: React.FC<PercentInputType> = (props): JSX.Element => {
	const handleChange = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value: unsanitizedValue } = event.target as HTMLInputElement;
		const value = !Number.isNaN(parseFloat(unsanitizedValue))
			? parseFloat(unsanitizedValue)
			: "";

		if (
			(!Number.isNaN(value) &&
				(value as number) >= 0 &&
				(value as number) <= 100) ||
			value === ""
		) {
			props.handleChange({
				typeCode: props.typeCode,
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				isRequired: props.isRequired,
				value,
			});
		}
	};

	const handleBlur = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value: unsanitizedValue } = event.target as HTMLInputElement;
		const value = !Number.isNaN(parseFloat(unsanitizedValue))
			? parseFloat(unsanitizedValue).toFixed(2)
			: "";

		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value,
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleBlur(record);
	};

	const validateKeyStroke = (
		evt: React.KeyboardEvent<HTMLInputElement>,
	): void => {
		if (["e", "E", "+", "-"].includes(evt.key)) {
			evt.preventDefault();
		}
	};

	return (
		<Input
			id={props.fieldId}
			disabled={props.disable}
			name={props.name || props.fieldName || props.fieldId}
			onChange={handleChange}
			onBlur={handleBlur}
			onKeyDown={validateKeyStroke}
			type="number"
			value={props.value}
			className={`${props.className} pc-modal-text-box`}
			maxLength={props.field.max}
			placeholder={props.placeholder}
			autoComplete="off"
		/>
	);
};

export default PercentInput;
