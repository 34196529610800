/**
 * Create the store with dynamic reducers
 */

import { fromJS } from "immutable";
import createSagaMiddleware, { Saga, SagaMiddleware } from "redux-saga";
import {
	legacy_createStore as createStore,
	applyMiddleware,
	compose,
	Store,
} from "redux";

import createRootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();

// -------- Types -----------
type keypair = { [k: string]: never };
type createSagaInjectorType = (
	runSaga: SagaMiddleware["run"],
) => ModifiedStore["injectSaga"];

export interface ModifiedStore extends Store {
	asyncReducers: keypair;
	injectReducer: (k: string, r: never) => void;
	injectSaga: (k: string, r: never) => void;
}

// runSaga is middleware.run function
const createSagaInjector: createSagaInjectorType = runSaga => {
	// Create a dictionary to keep track of injected sagas
	const injectedSagas = new Map();
	const isInjected = (key: string): boolean => injectedSagas.has(key);
	const injectSaga: ModifiedStore["injectSaga"] = (key, saga: Saga) => {
		// We won't run saga if it is already injected
		if (isInjected(key)) return;

		// Sagas return task when they executed, which can be used
		// to cancel them
		const task = runSaga(saga);

		// Save the task if we want to cancel it in the future
		injectedSagas.set(key, task);
	};

	return injectSaga;
};

const configureStore = (): ModifiedStore => {
	// Create the store with two middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	const middlewares = [sagaMiddleware];

	const enhancers = [applyMiddleware(...middlewares)];

	// If Redux DevTools Extension is installed use it, otherwise use Redux compose
	let composeEnhancers: typeof compose = compose;
	if (
		process.env.NODE_ENV !== "production" &&
		typeof window === "object" &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
	}

	const store: ModifiedStore = createStore(
		createRootReducer(),
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		fromJS({}),
		composeEnhancers(...enhancers),
	);

	// Add a dictionary to keep track of the registered async reducers
	store.asyncReducers = {};

	// Create an inject reducer function
	// This function adds the async reducer, and creates a new combined reducer
	store.injectReducer = (
		key: string,
		asyncReducer: Record<string, unknown>,
	): void => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createRootReducer(store.asyncReducers));
	};

	// Add injectSaga method to our store
	store.injectSaga = createSagaInjector(sagaMiddleware.run);

	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	if (module.hot) {
		// eslint-disable-next-line no-console
		console.log("Hot loading redux...");

		module.hot.accept("./reducers", () => {
			store.replaceReducer(createRootReducer(store.asyncReducers));
		});
	}

	return store;
};

const store = configureStore();

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
