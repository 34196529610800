/* eslint-disable react/default-props-match-prop-types */
/**
 *
 * Custom Modal, build on SLDS Modal
 *
 */

import React from "react";
import PropTypes from "prop-types";
import Button from "@salesforce/design-system-react/components/button";
import Icon from "@salesforce/design-system-react/components/icon";
import Settings from "@salesforce/design-system-react/components/settings";
import Modal from "@salesforce/design-system-react/components/modal";
import "../styles/aleart-modal.scss";

class AlertModal extends React.PureComponent {
	static displayName = "AlertModal";

	componentDidMount() {
		Settings.setAppElement("#app");
	}

	render() {
		return (
			<Modal
				containerClassName={this.props.containerClassName}
				dismissible={false}
				footer={
					<div className="pc-text-right">
						{this.props.footer || (
							<React.Fragment>
								<Button
									key="noBtn"
									id="NO"
									label="Cancel"
									onClick={this.props.toggleAlert}
									className="pc-modal-secondary-btn"
								/>
								<Button
									key="yesBtn"
									id="YES"
									label="Confirm"
									onClick={() => this.props.toggleAlert("Yes")}
									className="pc-modal-primary-btn"
								/>
							</React.Fragment>
						)}
					</div>
				}
				isOpen={this.props.isOpen}
				prompt="warning"
			>
				<div className="modalclose-aleart">
					<Icon
						className="pc-prompt-warning-icon"
						category="custom"
						{...this.props.iconProps}
					/>
					<h2 className="pc-modal-prompt-title">{this.props.title}</h2>
					<p className="pc-modal-prompt-text">{this.props.children}</p>
				</div>
			</Modal>
		);
	}
}

AlertModal.defaultProps = {
	isOpen: false,
	align: "center",
	dismissible: false,
	containerClassName: "pc-modal-container-medium",
	title: "Confirm!",
	iconProps: {
		name: "pc-prompt-warning",
	},
};

AlertModal.propTypes = {
	isOpen: PropTypes.bool,
	children: PropTypes.string,
	containerClassName: PropTypes.string,
	toggleAlert: PropTypes.func,
	footer: PropTypes.node,
	title: PropTypes.string,
	iconProps: PropTypes.object,
};

export default AlertModal;
