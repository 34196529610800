/* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { getRequest } from "@utils/request";

import {
	themes,
	dropdownStyles,
	ClearIndicator,
	DropdownIndicator,
} from "@components/TinyComponents/Dropdown";
import { AllModuleIds } from "@custom-types/module-id";

declare type LocationInputProps = {
	fieldId: string;
	fieldName: string;
	sectionId: string;
	typeCode: string;
	isRequired: boolean;
	handleChange: (data: {
		typeCode?: string;
		fieldId: string;
		fieldName: string;
		isRequired?: boolean;
		value: string;
		valueLabel?: string;
	}) => void;
	autoFocus: boolean;
	disable: boolean;
	name: string;
	value: string;
	tabIndex: string;
	moduleId: AllModuleIds;
};

declare type GetLocationOptions = {
	text: string;
	address: string;
	place_name: string;
	context: {
		id: string;
		text: string;
	}[];
}[];

const mapboxFieldName = {
	address: "address",
	country: "country",
	region: "state",
	postcode: "zipcode",
	place: "city",
};

/**
 * fieldIds to autocomplete city, state, country, and zip code
 * place: city,
 * region: state,
 * country,
 * postcode: zipcode
 * Required due to mapbox api convention - https://docs.mapbox.com/api/search/geocoding/#data-types
 */
const mapboxFieldId: { [key: string]: { [key1: string]: string } } = {
	LM: {
		address: "F16",
		region: "F17",
		place: "F18",
		country: "F19",
		postcode: "F20",
	},
	AM: {
		address: "F86",
		region: "F88",
		place: "F87",
		country: "F89",
		postcode: "F90",
	},
	AM_S: {
		address: "F75",
		region: "F76",
		place: "F77",
		country: "F78",
		postcode: "F23",
	},
	CM: {
		address: "F27",
		region: "F71",
		place: "F60",
		country: "F62",
		postcode: "F32",
	},
};

function LocationInput(props: LocationInputProps): JSX.Element {
	// selected suggestion for enrichment
	const [getLocationOption, setLocationOption] = useState<
		GetLocationOptions | []
	>([]);

	useEffect(() => {
		if (getLocationOption.length) {
			const searchModule = props.fieldId || props.moduleId;
			const locationKeys = Object.keys(mapboxFieldId[searchModule]);
			locationKeys.map(ele => {
				const contextPayload = {
					fieldId: mapboxFieldId[searchModule][ele],
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					fieldName: mapboxFieldName[ele],
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					value: getLocationOption[0][ele] || "",
				};

				props.handleChange(contextPayload);
				return contextPayload;
			});
			setLocationOption([]);
		}
	}, [getLocationOption]);

	type LoadOptionType = {
		hasMore: boolean;
		options: [];
		length: number;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const loadOptions = async (search: string): Promise<LoadOptionType> => {
		if (search === "") {
			return {
				hasMore: false,
				options: [],
				length: 0,
			};
		}

		const {
			data: { data: features },
		} = await getRequest(`/api/v2/records/location?search=${search}`);

		const data = features.map(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(e: { place_name: any; id: any; text: any; context: any }) => ({
				label: e.place_name,
				map: e,
				value: e.id,
				text: e.text,
				context: e.context,
			}),
		);
		return {
			hasMore: false,
			options: data,
			length: data.length,
		};
	};

	const handleChange = (e: React.KeyboardEvent<never>): void => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const isNew = (e && e.__isNew__) || false;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const locationData = (e && e.map) || [];

		const mappingData: { [p: string]: string | undefined } = {
			address: locationData.text,
		};
		// eslint-disable-next-line no-unused-expressions
		locationData.context &&
			locationData.context.map(
				({ id, text }: { id: string; text: string }): void | null => {
					const getLocationData = id.split(".")[0];
					if (getLocationData) mappingData[getLocationData] = text;
					return null;
				},
			);

		if (!isNew) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setLocationOption([mappingData]);
		}
	};

	const { value } = props;
	let valueObj = null;
	if (value) {
		valueObj = { label: value, value };
	}

	return (
		<div className="pc-modal-row-field-padding">
			<div className="form-field">
				<AsyncPaginate
					components={{ ClearIndicator, DropdownIndicator }}
					placeholder="Type to search address..."
					defaultOptions
					value={valueObj}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					loadOptions={loadOptions}
					debounceTimeout={800}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					onChange={handleChange}
					isClearable
					sectionId={props.sectionId}
					menuShouldBlockScroll
					menuPosition="absolute"
					menuPlacement="auto"
					theme={themes}
					styles={dropdownStyles}
				/>
			</div>
		</div>
	);
}

export default LocationInput;
