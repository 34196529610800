import React from "react";
import capitalize from "lodash/capitalize";
import Icon from "@salesforce/design-system-react/components/icon";

function AccountTypeIcon({
	accountType,
}: {
	accountType: string;
}): JSX.Element {
	const type = accountType || "";

	return (
		<Icon
			category="custom"
			name={`pc-${type.toLowerCase()}-account`}
			size="small"
			style={{ "margin-right": "0.5rem" }}
			title={`${capitalize(type)} Account`}
		/>
	);
}

const memoizedAccountType = React.memo(AccountTypeIcon);

export default memoizedAccountType;
