import * as React from "react";
import cx from "classnames";
import debounce from "lodash/debounce";
import Button from "@salesforce/design-system-react/components/button";
import searchIcon from "@assets/icons/search-white.svg";
import { GlobalSearchContext } from "@context/globalSearch";
import "./index.scss";

const GlobalSearchInputForMobile: React.FC = (): JSX.Element => {
	const [search, setSearch] = React.useState("");
	const searchRef = React.useRef<HTMLInputElement>(null);
	const [showSearchInput, setShowSearchInput] = React.useState(false);

	const searchCtx = React.useContext(GlobalSearchContext);

	const delayedQuery = React.useCallback(
		debounce(q => setGlobalSearch(q), 500),
		[],
	);

	React.useEffect(() => {
		delayedQuery(search);
	}, [search]);

	React.useEffect(() => {
		if (showSearchInput) searchRef.current?.focus();
	}, [showSearchInput]);

	if (!searchCtx) return <React.Fragment />;

	const { showGlobalSearchPanel, setGlobalSearchShowPanel, setGlobalSearch } =
		searchCtx;

	return (
		<div
			role="presentation"
			className={cx("mb-global-search-container")}
			onClick={(): void => {
				if (!showSearchInput) {
					setShowSearchInput(true);
				}
			}}
		>
			<div className="mb-search-box">
				<Button
					variant="icon"
					iconName="pc-right-arrow"
					iconCategory="custom"
					className="left-arrow"
					onClick={(): void => {
						setGlobalSearchShowPanel(false);
					}}
				/>
				<div className="mb-global-search-bar">
					<img
						src={searchIcon}
						className="search-icon"
						alt="global-search-icon"
						title="Global Search"
					/>

					<input
						ref={searchRef}
						type="text"
						value={search}
						onChange={(e): void => {
							if (!showGlobalSearchPanel) setGlobalSearchShowPanel(true);
							setSearch(e.target.value);
						}}
						onFocus={(): void => {
							setGlobalSearchShowPanel(true);
						}}
						placeholder="Search"
						onKeyDown={(e): void => {
							if (e.key === "Enter" && !showGlobalSearchPanel) {
								setGlobalSearchShowPanel(true);
							}
						}}
					/>

					<Button
						iconName="clear"
						iconCategory="utility"
						onClick={(): void => {
							setSearch("");
							searchRef.current?.focus();
						}}
						className="clear-search-button"
						variant="icon"
					/>
				</div>
			</div>
		</div>
	);
};

export default GlobalSearchInputForMobile;
