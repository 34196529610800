export const TYP_TEXT = "TYP_TEXT";
export const TYP_EMAIL = "TYP_EMAIL";
export const TYP_SELECT = "TYP_SELECT";
export const TYP_SYSTEM_SELECT = "TYP_SYSTEM_SELECT";
export const TYP_SUGGESTION = "TYP_SUGGESTION";
export const TYP_URL = "TYP_URL";
export const TYP_LABEL = "TYP_LABEL";
export const TYP_TEXTAREA = "TYP_TEXTAREA";
export const TYP_AVATAR = "TYP_AVATAR";
export const TYP_ZIPCODE = "TYP_ZIPCODE";
export const TYP_FILEPICKER = "TYP_FILEPICKER";
export const TYP_AUTO_NUMBER = "TYP_AUTO_NUMBER";
export const TYP_CHECKBOX = "TYP_CHECKBOX";
export const TYP_TOGGLE = "TYP_TOGGLE";
export const TYP_CURRENCY = "TYP_CURRENCY";
export const TYP_DATE = "TYP_DATE";
export const TYP_DATE_TIME = "TYP_DATE_TIME";
export const TYP_NUMBER = "TYP_NUMBER";
export const TYP_PHONE = "TYP_PHONE";
export const TYP_MULTI_SELECT = "TYP_MULTI_SELECT";
export const TYP_PASSWORD = "TYP_PASSWORD";
export const TYP_FORMULA = "FORMULA";
export const TYP_SUMMARY = "SUMMARY";
export const TYP_LOOKUP = "LOOKUP";
export const TYP_EXT_LOOKUP = "EXT_LOOKUP";
export const TYP_HIDDEN_DATE_TIME = "HIDDEN_DATE_TIME";
export const TYP_TIME = "TIME";
export const TYP_GEOLOCATION = "GEOLOCATION";
export const TYP_PERCENT = "TYP_PERCENT";
export const TYP_RICHTEXT = "TYP_RICHTEXT";
export const TYP_LOCATION = "TYP_LOCATION";
export const TYP_HIDDEN_PLAIN_TEXT = "HIDDEN_PLAIN_TEXT";
export const TYP_HIDDEN_PLAIN_LABEL = "HIDDEN_PLAIN_LABEL";
export const TYP_ENCODED = "ENCODED";
export const TYP_VIDEO_CONFERENCE = "TYP_VIDEO_CONFERENCE";
export const TYP_NOTIFICATION_CONTROL = "TYP_NOTIFICATION_CONTROL";
export const TYP_TAG = "TYP_TAG";
export const TYP_TEXT_SELECT = "TYP_TEXT_SELECT";
export const TYP_GROUP_SELECT = "TYP_GROUP_SELECT";
export const TYP_RELATIVE_DATE = "TYP_RELATIVE_DATE";
export const TYP_ABSOLUTE_DATE = "TYP_ABSOLUTE_DATE";
export const TYP_ABSOLUTE_DATE_RANGE = "TYP_ABSOLUTE_DATE_RANGE";
export const TYP_ID = "TYP_ID";
export const TYP_REF = "TYP_REF";
export const TYP_META = "TYP_META";
