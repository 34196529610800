import React from "react";
import cx from "classnames";

import Icon from "@salesforce/design-system-react/components/icon";

// eslint-disable-next-line import/no-named-as-default
import Tooltip from "@salesforce/design-system-react/components/tooltip";
import { Dispatch } from "redux";
// eslint-disable-next-line import/no-named-as-default
import FormElement from "@components/FormElement";

import { User } from "@interfaces/user";
import { BusinessModuleId } from "@custom-types/module-id";
import { RichTextValue } from "@custom-types/tiny-types";
import { FieldType } from "@interfaces/fieldType";

export declare interface Option {
	value: string;
	label: string;
	isDefault?: boolean;
	key?: string;
	data?: unknown;
	isNew?: boolean;
	isDisabled?: boolean;
}
export declare interface GroupOption {
	label: string;
	options: Option[];
	data: Record<string, unknown>;
}
export declare interface Field {
	transKey?: string;
	typeCode: string;
	fieldId: string;
	isRequired?: boolean;
	disable?: boolean;
	params?: GroupOption[] | Option[];
	skipParentCheck?: boolean;
	fieldName?: string;
	customName?: string;
	isInlineEditable?: boolean;
	type?: FieldType;
	addProps?: Record<string, unknown>;
	helpText?: string;
	size?: {
		small: number;
		medium: number;
		large: number;
	};
	moduleId?: BusinessModuleId;
	suggestionProps?: Record<string, string | boolean>;
	defaultValue?: RecordValue;
	parentId?: string;
	infoText?: string;
	variant?: string;
}

export const getfieldClassName = (field?: Partial<Field>): string => {
	const size = {
		small: 12,
		medium: 12,
		large: 12,
		...(field?.size || {}),
	};

	return `slds-small-size_${size.small}-of-12 slds-medium-size_${size.medium}-of-12 slds-large-size_${size.large}-of-12`;
};

export declare type MultiSelectValue = string[];

export type AmountValue = {
	currencyType: string;
	amount: number;
};

export type DateRangeValue = {
	startDate: string;
	endDate: string;
};

export declare type RecordValue =
	| string
	| number
	| boolean
	| Record<string, string | number>
	| RichTextValue
	| MultiSelectValue
	| AmountValue
	| DateRangeValue;

export declare interface ChangeValue extends Field {
	value: RecordValue;
	label: string | string[];
	data?: Record<string, unknown>;
}

function FormField({
	field,
	valueObj,
	errorObj,
	handleChange,
	handleBlur,
	userInfo,
	showLabel = true,
	alphabeticSort,
	dispatch,
	labelObj = {},
	dataObj = {},
	skipParentCheck,
	fetchParentValue,
	fetchDependentOptions,
	children,
}: {
	field: Field;
	valueObj: Record<string, RecordValue>;
	errorObj: Record<string, string>;
	labelObj?: Record<string, string | string[]>;
	userInfo?: User;
	showLabel?: boolean;
	alphabeticSort?: boolean;
	handleChange: (obj: ChangeValue) => void;
	handleBlur: (obj: ChangeValue) => void;
	dispatch?: Dispatch;
	dataObj?: Record<string, unknown>;
	skipParentCheck?: boolean;
	fetchParentValue?: (value: string) => void;
	fetchDependentOptions?: (parentId: string, fieldId: string) => void;
	leftIcon?: string;
	children?: JSX.Element;
}): JSX.Element {
	const addFieldProps = field.addProps || {};
	return (
		<div className="form-field-wrap">
			{showLabel && (
				<span className="placeholder">
					<span>{field.transKey}</span>
					{field.isRequired && (
						<span className="pc-modal-required-field"> *</span>
					)}
					&nbsp;
					{field.helpText && (
						<Tooltip
							id="base"
							align="top left"
							content={field.helpText}
							dialogClassName="dialog-classname"
						>
							<div>
								<Icon
									name="pc-widget-message-info-icon"
									category="custom"
									size="xx-small"
								/>
							</div>
						</Tooltip>
					)}
				</span>
			)}
			<span className="form-field-input">
				<FormElement
					visibilityToggle
					isRequired={field.isRequired}
					disable={field.disable}
					handleChange={handleChange}
					handleBlur={handleBlur}
					typeCode={field.typeCode}
					userInfo={userInfo}
					field={field}
					fieldId={field.fieldId}
					value={valueObj[field.fieldId] || ""}
					valueLabel={labelObj[field.fieldId] || ""}
					data={dataObj[field.fieldId]}
					className={cx(errorObj[field.fieldId] && "pc-error-form-element")}
					errorText={errorObj[field.fieldId]}
					dispatch={dispatch}
					valueObj={valueObj}
					alphabeticSort={alphabeticSort}
					skipParentCheck={skipParentCheck}
					fetchParentValue={fetchParentValue}
					fetchDependentOptions={fetchDependentOptions}
					{...addFieldProps}
				/>
			</span>
			{children}
		</div>
	);
}

export default FormField;
