// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function PhoneInputComponent(props) {
	const defaultCountry = useMemo(() => {
		const { country } = props.userInfo?.organization ?? {};

		return country || props.country || "";
	}, []);

	const handleChange = value => {
		props.handleChange({
			typeCode: props.typeCode,
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			isRequired: props.isRequired,
			value: value || "",
		});
	};

	const handleBlur = () => {
		props.handleBlur({
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionid: props.sectionId,
			value: props.value,
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		});
	};

	return (
		<PhoneInput
			id={props.fieldId}
			disabled={props.disable}
			name={props.name || props.fieldName || props.fieldId}
			onChange={handleChange}
			onBlur={handleBlur}
			value={props.value}
			sectionid={props.sectionId}
			className={`${props.className} slds-input pc-modal-phone-box${
				props.disable ? " disabled" : ""
			}`}
			maxLength={props.max}
			defaultCountry={defaultCountry}
			placeholder={props.placeholder}
			readOnly={props.readOnly}
			numberInputProps={{ readOnly: props.readOnly }}
		/>
	);
}

PhoneInputComponent.propTypes = {
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	sectionId: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	handleChange: PropTypes.func,
	handleBlur: PropTypes.func,
	value: PropTypes.string,
};

export default memo(PhoneInputComponent);
