import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@salesforce/design-system-react/components/card";
import Icon from "@salesforce/design-system-react/components/icon";
import LottieIcon from "../LottieIcon";

export default class CardTypeCenter extends Component {
	state = {
		play: false,
	};

	getFooter = footer =>
		footer && (
			<a className="slds-card footer-action" href={() => false}>
				{footer}
			</a>
		);

	stopAndPlay = () => {
		this.setState(prevState => ({
			play: !prevState.play,
		}));
	};

	render() {
		const { header, body, footer, className, isNew } = this.props;
		return (
			<div
				onMouseEnter={() => {
					this.stopAndPlay();
				}}
				onMouseLeave={() => {
					this.stopAndPlay();
				}}
			>
				<Card
					heading={header && header.title}
					icon={header && header.icon}
					footer={this.getFooter(footer)}
					className={className}
				>
					<div>
						{body.icon && (
							<Icon
								name={body.icon.name}
								category="custom"
								className={`${body.icon.size}-icon`}
								size={body.icon.size}
							/>
						)}
						{body.animatedIcon && (
							<LottieIcon
								loop
								autoplay={false}
								animationData={body.animatedIcon}
								style={{
									height: 70,
									width: 70,
								}}
								hover
								play={this.state.play}
							/>
						)}
					</div>
					<p className="txt">{body.title}</p>
					{isNew && <span className="new-version" />}
				</Card>
			</div>
		);
	}
}

CardTypeCenter.propTypes = {
	header: PropTypes.object,
	body: PropTypes.object,
	footer: PropTypes.object,
	className: PropTypes.string,
	isNew: PropTypes.bool,
};
