/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */

import React, { JSX } from "react";
import cx from "classnames";
import moment from "moment-timezone";
import orderBy from "lodash/orderBy";
import { sortBy } from "lodash";

import Icon from "@salesforce/design-system-react/components/icon";

import { BusinessRecord } from "@interfaces/business-record";
import { Pipeline } from "@interfaces/pipeline";
import { ModuleShortNames } from "@enums/module-names.enum";
import {
	ConversationMessageBodyType,
	ConversationMessage,
	replyToMessage,
	replyToStory,
} from "@interfaces/conversation-message";
import { Conversation } from "@interfaces/conversation";
import { ChatProviders } from "@interfaces/integration";
import { LabelAndValue } from "@custom-types/tiny-types";

import { getAvatarTitle } from "@utils/common";
import { getRequest } from "@utils/request";
import { randomColor } from "@components/TinyComponents/RecordAvatar";

import { AllowedModuleIds } from "./DetailsPanel";
import {
	ConversationData,
	DetailsFieldId,
	ErrorObj,
	ValidationResult,
	UserObj,
	ChatParticipant,
	GroupMember,
} from "./types";
import mentionDefaultStyle from "./ChatScreen/mentionDefaultStyle";

export const FB_EXPIRE_WINDOW = 10079;
export const INSTAGRAM_EXPIRE_WINDOW = 10079;
export const CLOUDAPI_EXPIRE_WINDOW = 1439;

export const getFullName = (conversation?: Conversation | null): string => {
	if (!conversation) return "";
	const { details, businessRecord } = conversation;
	if (businessRecord) {
		return getAvatarTitle(businessRecord.records).title;
	}
	if (details && (details.firstName || details.lastName)) {
		return cx(details.firstName, details.lastName);
	}
	return "";
};

export const transformField = (
	value: string,
	keepStart: number,
	keepEnd: number,
): string => {
	if (!value || typeof value !== "string") return value;

	const start = value.slice(0, keepStart);
	const end = value.slice(-keepEnd);
	const middleLength = Math.max(0, value.length - keepStart - keepEnd);
	const middle = "x".repeat(middleLength);

	return start + middle + end;
};

const extractNumbers = (input: string): string[] => {
	const numbers = input.match(/@\[[^\]]*\]\((\d+)\)/g);
	if (numbers) {
		const allNumbers: string[] = [];
		numbers.forEach(n => {
			const num = n.match(/\d+/g);
			if (num) {
				allNumbers.push(...num);
			}
		});
		return allNumbers;
	}
	return [];
};

export const maskNumbersInTheMessage = (
	message: string,
	provider: string,
	type: string,
	hideParticipants: boolean,
): string => {
	if (
		provider === "WHATSAPP-WEB" &&
		type === "GROUP" &&
		message &&
		hideParticipants
	) {
		let uMessage = message;
		const numbers = extractNumbers(message);

		numbers.forEach(n => {
			uMessage = uMessage.replace(new RegExp(n, "g"), transformField(n, 1, 1));
		});

		return uMessage;
	}

	return message;
};

export const trimMessage = (message: string, length: number): string => {
	if (!message) return "";

	return (
		(message.split("\n")[0]?.slice(0, length) || "") +
		(message.length && message.length > length ? "..." : "")
	);
};

export const hideNumbersInMessageText = (
	text: string,
	originalText: string,
	provider: string,
	type: string,
	hideParticipant: boolean,
): string => {
	if (!text) return "";
	let fMesssage = text;
	if (
		provider === "WHATSAPP-WEB" &&
		type === "GROUP" &&
		originalText &&
		hideParticipant
	) {
		const numbers = extractNumbers(originalText);

		numbers.forEach(n => {
			fMesssage = fMesssage.replace(
				new RegExp(n, "g"),
				transformField(n, 1, 1),
			);
		});
	}

	return fMesssage;
};

export const getGroupMemberWithSearch = (
	chatParticipant: ChatParticipant[],
	// searchQuery?: string,
): GroupMember[] => {
	const memberParticipants = chatParticipant.filter(
		participant => participant.status === "member",
	);
	const participantsWithDisplay = memberParticipants.map(participant => {
		const partNumber =
			typeof participant.id === "string"
				? participant.id?.split("@")
				: participant.id;
		const number =
			partNumber && partNumber.length ? partNumber[0] : participant.number;

		return {
			id: (number || participant.id) ?? "",
			display:
				participant?.first_name || participant?.last_name
					? cx(participant?.first_name, participant?.last_name)
					: (participant?.number
							? `+${participant.number}`
							: participant?.id) ?? "",
			masked: (participant.number || participant.id) ?? "",
		};
	});

	// we can use this for search if required.

	// const filteredParticipants = participantsWithDisplay.filter(participant =>
	// 	includes(
	// 		participant.display.toLowerCase(),
	// 		searchQuery?.toLowerCase() || "",
	// 	),
	// );
	const sortedParticipants = sortBy(
		participantsWithDisplay,
		[(participant): string => participant.display.toLowerCase()],
		["asc"],
	);
	return sortedParticipants;
};

export const getAvatar = (conversation?: Conversation | null): string => {
	if (!conversation) return "";
	const { details, businessRecord } = conversation;
	if (businessRecord && businessRecord.records.F133) {
		return businessRecord.records.F133 as string;
	}
	if (details && details.avatar) {
		return details.avatar;
	}
	return "";
};

export const getConversationDate = (date: string): string => {
	if (moment(date).isSame(moment(), "day")) {
		return moment(date).format("HH:mm");
	}

	return moment(date).format("MMM DD");
};

export const messageEditTime = (message: ConversationMessage): boolean => {
	const createdAtDate = new Date(message.createdAt);
	const currentDate = new Date();
	const timeDifference = (currentDate as any) - (createdAtDate as any);

	if (message.provider === "TELEGRAM" || message.provider === "WHATSAPP-WEB") {
		const editTimeInMilliseconds =
			message.provider === "TELEGRAM"
				? 2 * 24 * 60 * 60 * 1000 - 60 * 1000
				: 20 * 60 * 1000 - 60 * 1000;

		const isMoreThanThreeDays = timeDifference > editTimeInMilliseconds;

		if (isMoreThanThreeDays) return true;

		return false;
	}
	return true;
};

export const getStatusIcon = {
	RECEIVED: "pc-message-received",
	SENT: "pc-message-sent",
	DELIVERED: "pc-message-delivered",
	READ: "pc-message-read",
	FAILED: "pc-message-failed",
	QUEUED: "pc-expiry-time-icon",
	UNDELIVERED: "pc-message-undelivered",
};

export const getStatusIconForMessage = {
	RECEIVED: "pc-message-received",
	SENT: "pc-single-tick",
	DELIVERED: "pc-delivered-double-tick",
	READ: "pc-delivered and seen",
	FAILED: "pc-error-icon-msg",
	QUEUED: "pc-expiry-time-icon",
	UNDELIVERED: "pc-message-undelivered",
};

type FilterType =
	| "user"
	| "channelId"
	| "tags"
	| "status"
	| "archived"
	| "unread";

type FilterValue = (string | null)[];

export declare interface Filter {
	value: FilterValue;
	type: FilterType | string;
}

export const verifyCurrentFilters = ({
	conversation,
	filters,
	convMessage,
	user,
	currentConversation,
	type: tabType,
}: {
	conversation: Conversation;
	filters: Filter[];
	convMessage: ConversationMessage;
	user: string;
	currentConversation: Conversation;
	type: string;
}): boolean => {
	if (!conversation.user) {
		// eslint-disable-next-line no-param-reassign
		conversation.user = "";
	}

	const unreadFilter = filters.find(each => each.type === "unread");
	const archiveFilter = filters.find(each => each.type === "archived");

	let matched = true;
	switch (tabType) {
		case "newConversation":
			matched = isConversationNew(conversation);
			break;
		case "unAssigned":
			matched = isConversationUnAssigned(conversation, user);
			break;
		case "myConversation":
			matched = isConversationMine(conversation, user);
			break;
		default:
			break;
	}

	if (!matched) {
		return false;
	}

	if (
		unreadFilter &&
		unreadFilter.value &&
		currentConversation?._id === convMessage.conversationId &&
		convMessage.type !== "ACTIVITY"
	) {
		return false;
		// eslint-disable-next-line no-else-return
	} else if (
		!archiveFilter?.value &&
		unreadFilter &&
		unreadFilter.value &&
		currentConversation?._id !== convMessage.conversationId &&
		convMessage.type !== "ACTIVITY"
	) {
		return true;
	} else if (
		unreadFilter &&
		unreadFilter.value &&
		convMessage.type === "ACTIVITY"
	) {
		return false;
	}

	if (filters && filters.length) {
		for (let i = 0; i < filters.length; i += 1) {
			const { type, value } = filters[i];

			if (value && value.length) {
				if ((type === "tags" || type === "channelId") && conversation[type]) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const difference = value.filter(x => !conversation[type].includes(x));
					if (difference.length === value.length) return false;
				} else if (
					type === "status" &&
					!(value.includes("UNREAD") || value.includes(conversation[type]))
				) {
					return false;
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
				} else if (type === "archived") {
					const userId = (value[0] || "") as never;
					if (
						convMessage?.archivedByUser &&
						convMessage?.archivedByUser.includes(userId)
					) {
						return true;
					}
					return false;
				} // eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				else if (!value.includes(conversation[type]) && type !== "status") {
					return false;
				}
			} else if (type === "archived") {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (!value.length && !convMessage?.archivedByUser?.includes(user)) {
					return true;
				}
				return false;
			}
		}
	}
	return true;
};

interface Message {
	[key: string]: any;
}

export const isConversationUnAssigned = (
	conv: Message,
	user: string,
): boolean => {
	if (
		!conv.user &&
		(conv.businessRecordUser === user ||
			conv.status === "CLOSED" ||
			(conv.status === "OPEN" && conv.businessRecordUser))
	) {
		return true;
	}

	return false;
};

export const isConversationMine = (conv: Message, user: string): boolean => {
	if (conv.user === user) {
		return true;
	}

	return false;
};

export const isConversationNew = (conv: Message): boolean => {
	if (!conv.user && !conv.businessRecordUser && conv.status === "OPEN") {
		return true;
	}

	return false;
};
export const isConversationArchived = (
	conv: Message,
	user: string,
): boolean => {
	if (conv.archivedBy?.includes(user)) {
		return true;
	}

	return false;
};

export const doesMessageMatchFilter = ({
	conversationFilterValue,
	filters,
	user,
}: {
	conversationFilterValue: Message;
	filters: Filter[];
	user: string | null;
}): boolean => {
	if (!filters || !filters.length) {
		return true; // If no filters are provided, consider it a match
	}

	if (!conversationFilterValue?.user) {
		// eslint-disable-next-line no-param-reassign
		conversationFilterValue.user = "";
	}

	const unreadFilter = filters.find(each => each.type === "unread");
	const archiveFilter = filters.find(each => each.type === "archived");

	if (
		unreadFilter &&
		unreadFilter.value &&
		!archiveFilter?.value?.length &&
		conversationFilterValue?.archivedBy?.includes(user)
	) {
		return false;
		// eslint-disable-next-line no-else-return
	} else if (
		unreadFilter &&
		unreadFilter.value &&
		archiveFilter?.value?.length &&
		conversationFilterValue?.archivedBy?.includes(user)
	) {
		return true;
	}

	for (let i = 0; i < filters.length; i += 1) {
		const { type, value } = filters[i];

		if (value && value.length) {
			switch (type) {
				case "tags":
					if (
						!conversationFilterValue[type] ||
						!value.every(val => conversationFilterValue[type].includes(val))
					) {
						return false;
					}
					break;
				case "channelId":
					if (
						!conversationFilterValue[type] ||
						!value.includes(conversationFilterValue[type][0])
					) {
						return false;
					}
					break;
				case "status":
					if (
						!value.includes("UNREAD") &&
						!value.includes(conversationFilterValue[type])
					) {
						return false;
					}
					break;
				case "archived":
					if (
						value.length &&
						conversationFilterValue?.archivedBy?.includes(user)
					) {
						return true;
					}
					return false;
				default:
					if (!value.includes(conversationFilterValue[type])) {
						return false;
					}
					break;
			}
		} else if (type === "archived") {
			if (
				!conversationFilterValue.archivedBy ||
				!conversationFilterValue.archivedBy.includes(user as string)
			) {
				return true;
			}
			return false;
		}
	}

	return true;
};

export const getConversationLastMessageContent = (
	message: ConversationMessageBodyType | null,
	provider: string,
	type: string,
	hideParticipant: boolean,
): string | JSX.Element => {
	if (!message) return "";
	if (message.type === "text") {
		if (message.button && message.button.text) return message.button.text;
		return hideNumbersInMessageText(
			message.text,
			message.originalText,
			provider,
			type,
			hideParticipant,
		);
	}

	if (message.type === "location") {
		return (
			<div className="conversation-list-content">
				<Icon
					category="custom"
					size="x-small"
					name="conversation-last-message-location"
				/>
				<p className="conversation-list-content-filename">Location</p>
			</div>
		);
	}

	if (message.type === "attachment") {
		if (message.attachment && message.attachment[0].mimeType) {
			return (
				<div className="conversation-list-content">
					<Icon
						category="custom"
						size="x-small"
						name={`conversation-last-message-${
							message.attachment[0].mimeType.split("/")[0]
						}`}
					/>
					<p className="conversation-list-content-filename">
						{message.attachment[0].fileName}
					</p>
				</div>
			);
		}
		return "(attachment)";
	}
	if (message.type === "interactive") {
		if (
			message?.interactive?.type === "product_list" ||
			message?.interactive?.type === "catalog_message" ||
			message?.interactive?.type === "product"
		) {
			return "(Catalog)";
		}
	}
	if (message.type === "order") {
		return "(Order)";
	}
	if (message.type === "errors") {
		return "(unsupported message)";
	}
	return message.type ? `(${message.type})` : "";
};

export const getConversationLastMessage = (
	message: ConversationMessageBodyType | null,
	provider: string,
	type: string,
	hideParticipant: boolean,
): string => {
	if (!message) return "";
	if (message.type === "text") {
		if (message.button && message.button.text) return message.button.text;
		return hideNumbersInMessageText(
			message.text,
			message.originalText,
			provider,
			type,
			hideParticipant,
		);
	}
	if (message.type === "attachment") {
		if (message.attachment && message.attachment[0].mimeType) {
			if (message.attachment[0].mimeType.split("/")[0] === "application") {
				return "(file)";
			}
			return `(${message.attachment[0].mimeType.split("/")[0]})`;
		}
		return "(attachment)";
	}
	if (message.type === "errors") {
		return "(unsupported message)";
	}

	return message.type ? `(${message.type})` : "";
};

export const getExpiry = (
	isScreenSmall: boolean,
	date: string,
	type = "TWILIO-WHATSAPP",
): JSX.Element => {
	if (type === "TELEGRAM" || type === "WHATSAPP-WEB") return <div />;
	const minutes = moment(moment()).diff(date, "minutes");
	let expireMin = 1439;
	if (type === "INSTAGRAM" || type === "MESSENGER") {
		expireMin = INSTAGRAM_EXPIRE_WINDOW;
	} else if (type === "WHATSAPP-CLOUD-API") {
		expireMin = CLOUDAPI_EXPIRE_WINDOW;
	}
	if (minutes > expireMin) {
		return (
			<div className="conversation-time-expired">
				<span className="inner-div">
					<Icon
						category="custom"
						size="small"
						name={isScreenSmall ? "user-active-time" : "pc-expiry-session"}
					/>
					<span>Expired</span>
				</span>
			</div>
		);
	}

	const remainingMinutes = expireMin - minutes;
	const duration = moment.duration(remainingMinutes, "minutes");
	return (
		<div className="conversation-time-remaining">
			<span className="inner-div">
				<Icon
					category="custom"
					size="small"
					name={
						isScreenSmall ? "user-active-time" : "pc-expiry-time-display-icon"
					}
				/>
				<span>
					{`${
						duration.days() ? `${duration.days()}d` : ""
					} ${duration.hours()}h ${duration.minutes()}m`}{" "}
				</span>
			</span>
		</div>
	);
};

export const getRelativeTimeFormat = (date: string): string =>
	moment(date).calendar(null, {
		// when the date is closer, specify custom values
		lastWeek: "dddd h:mm A",
		lastDay: "[Yesterday] h:mm A",
		sameDay: "h:mm A",
		// when the date is further away, use from-now functionality
		sameElse: "DD/MM/YYYY h:mm A",
	});

export const getConvertedRecordData = async (
	id: AllowedModuleIds,
	valueObj: Record<DetailsFieldId, string>,
	userId: string,
	metaTags: string[],
	adDetails: { adId: string; adName: string },
): Promise<Partial<BusinessRecord>> => {
	switch (id) {
		case ModuleShortNames.CM:
			return {
				formId: "CF1",
				moduleId: ModuleShortNames.CM,
				templateId: "CT1",
				records: {
					F5: userId,
					F48: valueObj.email,
					F61: valueObj.company || "",
					F49: valueObj.mobile || "",
					F92: valueObj.firstName || "",
					F93: valueObj.lastName,
					F133: valueObj.avatar,
					F139: metaTags,
					F156: adDetails.adId,
					F141: adDetails.adName,
				},
			};
		case ModuleShortNames.LM: {
			const {
				data: {
					data: { pipelines },
				},
			} = await getRequest("/api/v2/pipelines/?moduleId=LM");
			const defaultPipeline = (pipelines as Pipeline[]).find(p => p.isDefault);
			if (!defaultPipeline) return {};
			return {
				formId: "LF1",
				moduleId: ModuleShortNames.LM,
				templateId: "LT1",
				records: {
					F2: valueObj.firstName || "",
					F3: valueObj.lastName,
					F4: valueObj.company,
					F5: userId,
					F6: "OPEN",
					F7: valueObj.email,
					F64: valueObj.mobile || "",
					F95: defaultPipeline.id,
					F96: defaultPipeline.stages[0].id,
					F133: valueObj.avatar,
					F139: metaTags,
					F156: adDetails.adId,
					F141: adDetails.adName,
				},
			};
		}

		default:
			return {};
	}
};

export const validateLeadOrContact = (
	obj: Record<DetailsFieldId, string>,
): ValidationResult => {
	const fields: DetailsFieldId[] = ["email", "lastName"];

	const errorObj: ErrorObj = {};
	let isValid = true;

	fields.forEach(fld => {
		if (!obj[fld]) {
			errorObj[fld] = "Please fill required field";
			isValid = false;
		}
	});

	return {
		isValid,
		errorObj,
	};
};

export const getModuleName = (
	businessRecord: BusinessRecord | null,
): string => {
	if (!businessRecord) return "";

	const { moduleId } = businessRecord;

	switch (moduleId) {
		case "LM":
			return "Lead";
		case "CM":
			return "Contact";
		default:
			return "";
	}
};

export const providerToClassNames: Record<ChatProviders, string> = {
	"TWILIO-WHATSAPP": "pc-whatsapp-messenger",
	MESSENGER: "pc-facebook-messenger",
	"WHATSAPP-CLOUD-API": "pc-meta-messenger",
	INSTAGRAM: "pc-meta-instagram",
	TELEGRAM: "pc-telegram",
	"WHATSAPP-WEB": "pc-meta-whatsAppWeb",
};

export const providerToName: Record<ChatProviders, string> = {
	"WHATSAPP-CLOUD-API": "Whatsapp Cloud API",
	"TWILIO-WHATSAPP": "WhatsApp Twilio",
	MESSENGER: "Facebook Messenger",
	INSTAGRAM: "Instagram",
	TELEGRAM: "Telegram",
	"WHATSAPP-WEB": "Whatsapp Web",
};

const providerWiseCharacterLimit = {
	MESSENGER: 2000,
	"WHATSAPP-WEB": 2000,
	"WHATSAPP-CLOUD-API": 2000,
	INSTAGRAM: 1000,
	"TWILIO-WHATSAPP": 1600,
	TELEGRAM: 4096,
	"TELEGRAM-WITH-FILE": 1024,
};

export const generateCharacterLimitMessage = (
	provider: string | undefined,
	textLength: number,
	filesLength?: number,
): number | undefined => {
	if (!provider) return undefined;
	if (
		filesLength &&
		provider === "TELEGRAM" &&
		textLength > providerWiseCharacterLimit["TELEGRAM-WITH-FILE"]
	) {
		return providerWiseCharacterLimit["TELEGRAM-WITH-FILE"];
	}
	if (
		textLength >
		providerWiseCharacterLimit[
			provider as keyof typeof providerWiseCharacterLimit
		]
	) {
		return providerWiseCharacterLimit[
			provider as keyof typeof providerWiseCharacterLimit
		];
	}

	return undefined;
};

const defaultDetails = {
	firstName: "",
	lastName: "",
	email: "",
	mobile: "",
	company: "",
	avatar: "",
	tags: [],
	businessTags: [],
};

export const getConversationDetails = (
	conversation: Conversation | null,
): Record<DetailsFieldId, string> => {
	let details: Record<DetailsFieldId, any> = {
		...defaultDetails,
	};
	if (!conversation) return details;
	if (conversation) {
		const { businessRecord } = conversation;
		if (businessRecord) {
			details.mobile =
				((businessRecord.records.F64 ||
					businessRecord.records.F49) as string) || "";
			details.email =
				((businessRecord.records.F7 || businessRecord.records.F48) as string) ||
				"";
			details.firstName =
				((businessRecord.records.F2 || businessRecord.records.F92) as string) ||
				"";
			details.lastName =
				((businessRecord.records.F3 || businessRecord.records.F93) as string) ||
				"";
			details.company =
				(businessRecord.records.F4 as string) ||
				(businessRecord.records.F61 as LabelAndValue)?.label ||
				"";
			details.businessTags =
				(businessRecord.records.F135 as any) ||
				(businessRecord.records.F136 as any) ||
				[];
			details.avatar = ((businessRecord.records.F133 || "") as string) || "";
		} else if (conversation.type === "GROUP") {
			details = {
				...defaultDetails,
				...{ lastName: conversation.details.lastName },
			};
		} else {
			details = { ...defaultDetails, ...conversation.details };
		}
	}

	return details;
};

export const getConversationTags = (
	conversation: Conversation | null,
): string[] => {
	if (!conversation) return [];
	return (conversation && conversation.tags) || [];
};

export const getConversationAdDetails = (
	conversation: Conversation | null,
): { adId: string; adName: string } => {
	if (conversation?.adDetails?.adId && conversation?.adDetails?.adName) {
		return conversation.adDetails;
	}
	return { adId: "", adName: "" };
};

export const getConversationProviderIcon = (
	conversation: Conversation,
): string => {
	const lastMessageProvider = conversation.lastMessage?.provider || "";

	return lastMessageProvider ? providerToClassNames[lastMessageProvider] : "";
};

export const getConversationChannelName = (
	channelNames: Record<string, string>,
	conversation: Conversation,
): JSX.Element => {
	const source = conversation.lastMessage?.source;
	if (source) {
		const channelName = channelNames[source];
		if (channelName) {
			return (
				<p
					style={{
						backgroundColor: randomColor(channelName),
						color: "white",
					}}
					className="channel-color-container slds-truncate"
					title={channelName}
				>
					{channelName}
				</p>
			);
		}
	}

	return <div />;
};

export const getFilteredConversation = (
	convData: ConversationData,
	uConversation: Conversation,
	user?: string,
	status?: string,
): Conversation[] | [] => {
	if (!convData) return [];
	if (status) {
		let userFilter: (string | null)[] = [];
		let statusFilter: (string | null)[] = [];
		if (convData && convData.filters) {
			convData.filters.forEach(
				(each: { type: string; value: (string | null)[] }) => {
					if (each.type === "user") userFilter = each.value;
					if (each.type === "status") statusFilter = each.value;
				},
			);
		}
		let updateConv;
		if (
			(!userFilter ||
				(!user && !userFilter.length) ||
				!userFilter.length ||
				userFilter.some((each: string | null) => each === user)) &&
			(!statusFilter ||
				!statusFilter.length ||
				statusFilter.some((each: string | null) => each === status))
		) {
			if (
				convData &&
				(!convData.conversations.length ||
					!convData.conversations.some(
						eachC => eachC._id === uConversation._id,
					))
			) {
				updateConv = orderBy(
					[...convData.conversations, ...[uConversation]],
					["lastMessageAt"],
					["desc"],
				);
				return updateConv;
			}
			updateConv = (convData as ConversationData).conversations.map(c => {
				if (c._id === uConversation._id) {
					return {
						...c,
						...uConversation,
					};
				}
				return c;
			});
			return updateConv;
		}
		updateConv = (convData as ConversationData).conversations.filter(
			c => c._id !== uConversation._id,
		);
		return updateConv;
	}
	if (user) {
		let userFilter: (string | null)[] = [];
		if (convData && convData.filters) {
			convData.filters.forEach(
				(each: { type: string; value: (string | null)[] }) => {
					if (each.type === "user") userFilter = each.value;
				},
			);
		}
		let updateConv;
		if (
			!userFilter ||
			!userFilter.length ||
			userFilter.some((each: string | null) => each === user)
		) {
			if (
				convData &&
				!convData.conversations.some(eachC => eachC._id === uConversation._id)
			) {
				updateConv = orderBy(
					[...convData.conversations, ...[uConversation]],
					["lastMessageAt"],
					["desc"],
				);
				return updateConv;
			}
			updateConv = (convData as ConversationData).conversations.map(c => {
				if (c._id === uConversation._id) {
					return {
						...c,
						...uConversation,
					};
				}
				return c;
			});
			return updateConv;
		}
		updateConv = (convData as ConversationData).conversations.filter(
			c => c._id !== uConversation._id,
		);
		return updateConv;
	}
	const updateConv = (convData as ConversationData).conversations.map(c => {
		if (c._id === uConversation._id) {
			return {
				...c,
				...uConversation,
			};
		}
		return c;
	});
	return updateConv;
};

export const chatParticipantName = (messagedUser: {
	[key: string]: string;
}): string => {
	let fullName = "";
	if (messagedUser) {
		if (messagedUser?.first_name) {
			fullName += messagedUser.first_name;
		}
		fullName += " ";
		if (messagedUser?.last_name) {
			fullName += messagedUser.last_name;
		}
		fullName = fullName.trim();
	}
	return fullName;
};

export const getReplyToName = (
	message:
		| ConversationMessage
		| replyToMessage
		| replyToStory
		| Record<string, unknown>,
	users: UserObj,
	conversation: Conversation | null,
): string => {
	if ("story" in message && message.story) {
		return "Replied to your story";
	}
	if (message?.status === "RECEIVED" && message?.messagedUser) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return chatParticipantName(message?.messagedUser);
	}
	if (message?.status === "RECEIVED") {
		return getFullName(conversation);
	}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (message.user && users[message.user]?.label) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return users[message.user]?.label;
	}
	return getFullName(conversation) || "Replied to you";
};

type PlainObject = { [key: string]: Partial<React.CSSProperties> };

const isPlainObject = (obj: Partial<React.CSSProperties>): boolean =>
	!(obj instanceof Date) && obj === Object(obj) && !Array.isArray(obj);

const keys = (obj: PlainObject): string[] =>
	obj === Object(obj) ? Object.keys(obj) : [];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const mergeDeep = (target: any, source: any): any => {
	// const output = Object.assign({}, target);
	const output = { ...target };
	if (isPlainObject(target) && isPlainObject(source)) {
		keys(source).forEach(key => {
			if (isPlainObject(source[key])) {
				if (!(key in target)) Object.assign(output, { [key]: source[key] });
				else output[key] = mergeDeep(target[key], source[key]);
			} else {
				Object.assign(output, { [key]: source[key] });
			}
		});
	}
	return output;
};

const merge = (target: unknown, ...sources: any): PlainObject[] =>
	sources.reduce((t: PlainObject, s: PlainObject) => mergeDeep(t, s), target);
export const style = merge({}, mentionDefaultStyle, {
	input: {
		overflow: "auto",
		minHeight: 36,
	},
	highlighter: {
		boxSizing: "border-box",
		overflow: "hidden",
		minHeight: 36,
	},
});

export const maskNumberIfHideParticipants = (
	number: string,
	hideParticipant: boolean,
): string => {
	if (hideParticipant) {
		return transformField(number, 1, 1);
	}

	return number;
};
