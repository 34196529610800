import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Lottie from "lottie-react";

const LottieIcon = props => {
	const ref = useRef();

	useEffect(() => {
		if (props.play) {
			ref.current?.play();
		} else {
			ref.current?.stop();
		}
	}, [props.play]);

	return (
		<Lottie
			lottieRef={ref}
			autoplay={props.autoplay}
			loop={props.loop}
			animationData={props.animationData}
			style={props.style}
			onMouseEnter={() => {
				if (props.hover) ref.current?.play();
			}}
			onMouseLeave={() => {
				if (props.hover) ref.current?.stop();
			}}
		/>
	);
};

LottieIcon.propTypes = {
	autoplay: PropTypes.bool,
	loop: PropTypes.bool,
	style: PropTypes.object,
	animationData: PropTypes.object,
	hover: PropTypes.bool,
	play: PropTypes.bool,
};

export default LottieIcon;
