// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import each from "lodash/each";
import orderBy from "lodash/orderBy";
import moment from "moment-timezone";
import tagFields from "@utils/constants/tags";

import TextInput from "./TextInput";
import DateNewInput from "./NewDateInput";
import NewRangePicker from "./NewRangePicker";
import EmailInput from "./EmailInput";
import URLInput from "./URLInput";
import TextArea from "./TextArea";
import SelectInput from "./SelectInput";
import MultiSelect from "./MultiSelectInput";
import TagsInput from "./TagsInput";
import MultiSelectAll from "./MultiSearchable";
import PhoneInput from "./PhoneInput";
import CheckBoxInput from "./CheckBoxInput";
import CurrencyInput from "./CurrencyInput";
import NewDateTimeInput from "./NewDateTimeInput";
import NumberInput from "./NumberInput";
import PasswordInput from "./PasswordInput";
import RichTextInput from "./RichTextInput";
import ZipCodeInput from "./ZipCodeInput";
import PercentInput from "./PercentInput";
import ToggleInput from "./ToggleInput";
import Suggestion from "./Suggestion";
import LocationInput from "./LocationInput";
import VideoLinkInput from "./VideoLinkInput";
import NotificationControl from "./NotificationControl";
import TagInline from "./TagInline";

import {
	TYP_TEXT,
	TYP_URL,
	TYP_EMAIL,
	TYP_SELECT,
	TYP_SYSTEM_SELECT,
	TYP_PHONE,
	TYP_DATE,
	TYP_DATE_TIME,
	TYP_TEXTAREA,
	TYP_CHECKBOX,
	TYP_NUMBER,
	TYP_LABEL,
	TYP_ZIPCODE,
	TYP_CURRENCY,
	TYP_PERCENT,
	TYP_PASSWORD,
	TYP_RICHTEXT,
	TYP_MULTI_SELECT,
	TYP_TOGGLE,
	TYP_SUGGESTION,
	TYP_VIDEO_CONFERENCE,
	TYP_LOCATION,
	TYP_NOTIFICATION_CONTROL,
	TYP_TAG,
} from "./ElementTypes";
import "./styles/index.scss";
import "./styles/multisearchable.scss";

export function FormElement(props) {
	useEffect(() => {
		const { actionType, field, typeCode, value, handleChange } = props;
		// Set default value of TYP_SELECT if the current value does not exist options.
		if (
			// If actionType is UPDATE then we don't have to load default values into input fields
			actionType !== "UPDATE" &&
			typeCode === TYP_SELECT &&
			typeof value !== "undefined"
		) {
			const { params } = field;
			if (!params.find(eachOption => eachOption.value === value)) {
				const defaultOption = params.find(eachOption => eachOption.isDefault);
				if (defaultOption && typeof defaultOption.value !== "undefined")
					handleChange({
						sectionId: props.sectionId,
						fieldName: props.fieldName,
						fieldId: props.fieldId,
						typeCode: props.typeCode,
						value: defaultOption.value,
						parentId: props.field.parentId,
						isRequired: props.isRequired,
					});
			}
		}
	}, []);

	const { typeCode } = props;
	let field = <div />;
	switch (typeCode) {
		case TYP_LABEL:
			field = <div />;
			break;
		case TYP_PASSWORD:
			field = <PasswordInput {...props} />;
			break;
		case TYP_TEXT:
			field = <TextInput {...props} />;
			break;

		case TYP_URL:
			field = <URLInput {...props} />;
			break;

		case TYP_TEXTAREA:
			field = <TextArea {...props} />;
			break;

		case TYP_ZIPCODE:
			field = <ZipCodeInput {...props} />;
			break;

		case TYP_SELECT: {
			let options = [];
			let parentValue = "";
			try {
				options = props.field.parentId
					? props.fetchDependentOptions(props.field.parentId, props.fieldId)
					: props.field.params;
				parentValue = props.field.parentId
					? props.fetchParentValue(props.field.parentId)
					: "";

				if (options && options.length && props.intl) {
					each(options, (o, i) => {
						options[i].label = props.intl.formatMessage({
							id: o.key || o.label,
						});
					});
					if (!props.disableOnly) {
						options = filter(options, o => !o.isDisabled);
					}
					if (props.alphabeticSort) {
						options = orderBy(options, [o => o.label.toLowerCase()], ["asc"]);
					}
				}
				field = (
					<SelectInput
						key={parentValue}
						{...props}
						options={options}
						isClearable={props.isClearable}
					/>
				);
			} catch (w) {
				// eslint-disable-next-line no-console
			}
			break;
		}

		case TYP_SYSTEM_SELECT: {
			let options = [];
			let parentValue = "";
			try {
				options =
					props.field.parentId && !props.skipParentCheck
						? props.fetchDependentOptions(props.field.parentId, props.fieldId)
						: props.field.params;
				parentValue =
					props.field.parentId && !props.skipParentCheck
						? props.fetchParentValue(props.field.parentId)
						: "";

				if (props.skipParentCheck) {
					if (props.field.parentId) {
						const groupOptionsByParent = groupBy(options, "parentLabel");
						const newOptions = [];
						Object.keys(groupOptionsByParent).forEach(group => {
							newOptions.push({
								label: <p className="pc-filter-stage-pl-name">{group}</p>,
								options: groupOptionsByParent[group],
							});
						});
						options = newOptions;
					} else if (props.field.fieldName === "assignedTo") {
						options = filter(options, o => {
							if (props.userInfo.userType === "USER") return o.allowAssign;
							return true;
						});
					} else {
						options = filter(options, o => !o.isDisabled && o.allowAssign);
					}
				} else {
					options = filter(options, o => !o.isDisabled && o.allowAssign);
				}

				field = <SelectInput key={parentValue} {...props} options={options} />;
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
			}
			break;
		}

		case TYP_SUGGESTION: {
			// Check for Account Creat Permission
			let { isCreatable } = props.field;
			const { params = [] } = props.field;
			if (props.fieldId === "F61" && !props.isConversionModal) {
				const { permissions } = props.userInfo.profile;
				isCreatable = permissions.AM.CREATE;
			}
			field = props.field.isQuickFilter ? (
				<MultiSelectAll
					{...props}
					isClearable={props.isClearable}
					options={params}
				/>
			) : (
				<Suggestion {...props} isCreatable={isCreatable} />
			);
			break;
		}
		case TYP_MULTI_SELECT: {
			let options = [];
			let parentValue = "";
			try {
				if (props.updatedOptions && props.updatedOptions.length) {
					options = orderBy(
						props.updatedOptions,
						[param => param.value.toLowerCase()],
						["asc"],
					);
				} else {
					options =
						props.field.parentId && !props.skipParentCheck
							? props.fetchDependentOptions(props.field.parentId, props.fieldId)
							: props.field.params;
				}
				parentValue =
					props.field.parentId && !props.skipParentCheck
						? props.fetchParentValue(props.field.parentId)
						: "";

				if (props.skipParentCheck && props.field.fieldId !== "members") {
					if (props.field.parentId) {
						const groupOptionsByParent = groupBy(options, "parentLabel");
						const newOptions = [];
						Object.keys(groupOptionsByParent).forEach(group => {
							newOptions.push({
								label: <p className="pc-filter-stage-pl-name">{group}</p>,
								options: groupOptionsByParent[group],
							});
						});
						options = newOptions;
					} else if (props.field.fieldName === "assignedTo") {
						options = filter(options, o => {
							if (props.userInfo.userType === "USER") return o.allowAssign;
							return true;
						});
					} else {
						options = filter(options, o => !o.isDisabled);
					}
				} else {
					options = filter(options, o => !o.isDisabled);
				}
				if (options && options.length) {
					each(options, (opt, i) => {
						// Check if it is Grouped Option
						if (!options[i].label) {
							options[i].label = props.intl.formatMessage({
								id: opt.key || opt.label,
							});
						} else {
							each(options[i].label.options, (o, j) => {
								options[i].label.options[j] = props.intl.formatMessage({
									id: o.key || o.label,
								});
							});
						}
					});
					if (
						props.field.fieldId &&
						props.field.fieldId === tagFields[props.field.moduleId]
					) {
						options = filter(options, o => o.status === "ACTIVE");
					}

					if (
						props.field.mappedWith === "F135" ||
						props.field.mappedWith === "F136"
					) {
						options = filter(options, o => o.status === "ACTIVE");
						options = orderBy(
							options,
							[param => param.value.toLowerCase()],
							["asc"],
						);
					} else if (props.alphabeticSort) {
						options = orderBy(options, [o => o.label.toLowerCase()], ["asc"]);
					}
					options = filter(options, o => !o.isDisabled);
				}
				field = props.field.isQuickFilter ? (
					<MultiSelectAll
						key={parentValue}
						{...props}
						options={options.length ? options : props.options}
						isClearable={props.isClearable}
					/>
				) : (
					<React.Fragment>
						{props.field.fieldId &&
						tagFields[props.field.moduleId] === props.field.fieldId ? (
							<TagsInput
								key={parentValue}
								{...props}
								options={options.length ? options : props.options}
								isClearable={props.isClearable}
								fieldId={props.fieldId || props.field.fieldId}
							/>
						) : (
							<MultiSelect
								key={parentValue}
								{...props}
								options={options.length ? options : props.options}
								isClearable={props.isClearable}
							/>
						)}
					</React.Fragment>
				);
			} catch (w) {
				// eslint-disable-next-line no-console
				console.log(w);
			}
			break;
		}
		case TYP_DATE: {
			if (props.field && (props.isRange || props.field.operator === "RG")) {
				field = <NewRangePicker {...props} />;
			} else {
				field = <DateNewInput {...props} />;
			}
			break;
		}
		case TYP_DATE_TIME: {
			let parentValue = "";

			try {
				parentValue = props.field.parentId
					? props.fetchParentValue(props.field.parentId)
					: "";
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
			if (props.field && (props.isRange || props.field.operator === "RG")) {
				field = <NewRangePicker parentValue={parentValue} {...props} />;
			} else {
				field = <NewDateTimeInput parentValue={parentValue} {...props} />;
			}
			break;
		}

		case TYP_EMAIL: {
			field = <EmailInput {...props} />;
			break;
		}

		case TYP_PHONE:
			field = <PhoneInput {...props} />;
			break;

		case TYP_CHECKBOX:
			field = <CheckBoxInput {...props} />;
			break;
		case TYP_TOGGLE:
			field = <ToggleInput {...props} />;
			break;
		case TYP_CURRENCY:
			field = <CurrencyInput {...props} />;
			break;
		case TYP_NUMBER:
			field = <NumberInput {...props} />;
			break;

		case TYP_RICHTEXT:
			field = <RichTextInput {...props} />;
			break;
		case TYP_PERCENT:
			field = <PercentInput {...props} />;
			break;
		case TYP_VIDEO_CONFERENCE:
			field = <VideoLinkInput {...props} />;
			break;
		case TYP_LOCATION:
			field = <LocationInput {...props} />;
			break;
		case TYP_NOTIFICATION_CONTROL: {
			let { disable } = props;
			let parentValue = "";
			let disableOptions = [];
			let options = [];

			try {
				if (props.field.parentId) {
					parentValue = props.fetchParentValue(props.field.parentId);
					if (props.field.parentId === "F26") {
						const dayDiff = moment(parentValue).diff(moment(), "days");
						if (!parentValue || dayDiff < 1) disable = true;
						if (!disable && dayDiff < 7) disableOptions = ["Weeks"];
					}
					if (props.field.parentId === "F109") {
						const minDiff = moment(parentValue).diff(moment(), "minutes");
						if (!parentValue && minDiff < 1) disable = true;
						if (!disable && minDiff < 60)
							disableOptions = ["Hours", "Days", "Weeks"];
						else if (!disable && minDiff < 1439)
							disableOptions = ["Days", "Weeks"];
						else if (!disable && minDiff < 10080) disableOptions = ["Weeks"];
					}
				}

				options = props.field.params;

				if (options && options.length && props.intl) {
					each(options, (o, i) => {
						options[i].label = props.intl.formatMessage({
							id: o.key || o.label,
						});
					});
					options = filter(options, o => !o.isDisabled);
					options = options.map(o => {
						if (disableOptions.includes(o.value)) {
							return {
								...o,
								isDisabled: true,
							};
						}
						return o;
					});
				}
				field = (
					<NotificationControl
						key={props.fieldId}
						{...props}
						options={options}
						isClearable={props.isClearable}
						disable={disable}
					/>
				);
			} catch (w) {
				// eslint-disable-next-line no-console
				console.log(w);
			}
			break;
		}
		case TYP_TAG: {
			field = <TagInline {...props} />;
			break;
		}
		default:
			field = <div />;
	}

	return (
		<div data-cy="field-input" className="field-input">
			{field}
			<span className="pc-error-message" data-cy="field-input-error">
				{props.errorText}
			</span>
		</div>
	);
}

FormElement.defaultProps = {
	handleBlur: () => {},
	fetchDependentOptions: () => {},
};

FormElement.propTypes = {
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	fetchDependentOptions: PropTypes.func,
};

export default injectIntl(FormElement);
