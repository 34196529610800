// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import LoginLayout from "@containers/PageLayout/LoginLayout";
import { makeSelect2FAFlag } from "@containers/App/selectors";

const mapStateToProps = createStructuredSelector({
	is2FA: makeSelect2FAFlag(),
});

function Auth2FARoute({ component: Component, is2FA, ...rest }) {
	return (
		<Route
			{...rest}
			render={props => {
				if (is2FA)
					return (
						<LoginLayout location={props.location}>
							<Component {...props} />
						</LoginLayout>
					);
				return <Redirect to="/login" />;
			}}
		/>
	);
}
export default connect(mapStateToProps)(Auth2FARoute);
