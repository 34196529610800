// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import { Route } from "react-router-dom";

import LoginLayout from "@containers/PageLayout/LoginLayout";

function PublicRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={props => (
				<LoginLayout location={props.location}>
					<Component {...props} />
				</LoginLayout>
			)}
		/>
	);
}

export default PublicRoute;
