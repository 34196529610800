/* eslint-disable react/react-in-jsx-scope */
import * as React from "react";
import Select, { OnChangeValue } from "react-select";

import { Option as CustomOption } from "@components/TinyComponents/FormField";
import { DropdownIndicator, themes } from "@components/TinyComponents/Dropdown";

import { CustomContainer, Option, selectStyles } from "./common";
import { FilterElementProps } from "./types";
import SelectDropdown from "./SelectDropDown";

const MultiSelectInput = (props: FilterElementProps): JSX.Element => {
	const [value, setValue] = React.useState<CustomOption[]>(
		props.value as CustomOption[],
	);

	const handleSelectChange = (
		opValue: OnChangeValue<CustomOption, true>,
	): void => {
		const selectValue = [...(opValue as CustomOption[])];

		setValue(selectValue as CustomOption[]);
	};

	const handleBlur = (): void => {
		props.handleChange({
			fieldId: props.field.fieldId,
			value: value.length
				? (value as CustomOption[])
				: [...(props.value as CustomOption[])],
			typeCode: props.field.typeCode,
		});
	};

	React.useEffect(() => {
		setValue(props.value as CustomOption[]);
	}, [props.value as CustomOption[]]);

	return (
		<SelectDropdown field={props.field} value={props.value as CustomOption[]}>
			<Select
				autoFocus
				backspaceRemovesValue={false}
				components={{
					DropdownIndicator,
					IndicatorSeparator: null,
					Option,
					SelectContainer: CustomContainer,
					IndicatorsContainer: () => null,
				}}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				menuIsOpen
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				onChange={handleSelectChange}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				options={props.field.params as CustomOption[]}
				placeholder="Search groups"
				styles={selectStyles}
				tabSelectsValue={false}
				value={value as CustomOption[]}
				isMulti
				theme={themes}
				onBlur={handleBlur}
				filterOption={props.filterOption}
			/>
		</SelectDropdown>
	);
};

export default MultiSelectInput;
