/*
 * AppReducer - GlobalReducer
 *
 * Keeps all the top level data like logged in user info
 */
import { fromJS } from "immutable";
import { User } from "@interfaces/user";
import { TypedMap } from "@interfaces/typed-map";
import {
	LOAD_MENU_RESPONSE,
	USER_ROLES_AND_PROFILES_LOADED,
	SENDING_REQUEST,
	SET_TOAST_MESSAGE,
	CLEAR_TOAST_MESSAGE,
	SET_USER_SECRET_FLAG,
	SET_LOGIN_DATA,
	SET_USER_SECRET_URL,
	SET_2FA_FLAG,
	SET_RESPONSE_CODE,
	UNSET_ANNONCEMENT,
} from "./constants";

export declare type AppInitialState = {
	loading: boolean;
	message: { [key: string]: string };
	userInfo: User;
	loginData: Record<string, string>;
	secretFlag: boolean;
	is2FA: boolean;
	responseCode: number;
	menus: Record<string, string>;
	menusState?: Record<string, string>[];
	secretURL?: string;
};

// The initial state of the App
const initialState = fromJS({
	loading: true,
	message: {},
	menusState: [],
	userInfo: {},
	loginData: {},
	secretFlag: false,
	is2FA: false,
	responseCode: 200,
	menus: {},
});

const appReducer = (
	state = initialState,
	action: {
		sending: boolean;
		type: string;
		message: Record<string, string>;
		menus: Record<string, string>;
		userInfo: User;
		secretFlag: string;
		loginData: Record<string, string>;
		secretURL: string;
		is2FA: string;
		code: number;
	},
): TypedMap<"global", AppInitialState> => {
	switch (action.type) {
		case CLEAR_TOAST_MESSAGE:
			return state.set("message", fromJS({}));
		case SENDING_REQUEST:
			return state.set("loading", fromJS(action.sending));
		case SET_TOAST_MESSAGE:
			return state.set("message", fromJS(action.message));
		case LOAD_MENU_RESPONSE:
			return state.set("menusState", fromJS(action.menus));
		case USER_ROLES_AND_PROFILES_LOADED:
			return state.set("userInfo", fromJS(action.userInfo));
		case UNSET_ANNONCEMENT: {
			const { userInfo } = state.toJS();
			if (userInfo) {
				(userInfo as User).announcement = false;
			}
			return state.set("userInfo", fromJS(userInfo));
		}
		case SET_USER_SECRET_FLAG:
			return state.set("secretFlag", action.secretFlag);
		case SET_LOGIN_DATA:
			return state.set("loginData", fromJS(action.loginData));
		case SET_USER_SECRET_URL:
			return state.set("secretURL", action.secretURL);
		case SET_2FA_FLAG:
			return state.set("is2FA", action.is2FA);
		case SET_RESPONSE_CODE:
			return state.set("responseCode", fromJS(action.code));
		default:
			return state;
	}
};

export default appReducer;
