import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

declare type LoaderProps = {
	loading?: boolean;
};

function Loader({ loading = true }: LoaderProps): JSX.Element | null {
	if (loading) {
		// ? Removed unwanted <div>
		return (
			<div
				id="loader"
				className="-loading -active"
				style={{
					position: "absolute",
					margin: "auto",
					top: "0",
					right: "0",
					bottom: "0",
					left: "0",
					width: "100px",
					height: "100px",
					textAlign: "center",
					lineHeight: "110px",
				}}
			>
				<BeatLoader
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					className="loader"
					size={20}
					color="#0291ae"
					loading
				/>
			</div>
		);
	}

	return null;
}

export default Loader;
