/* eslint-disable import/no-named-as-default */
/**
 *
 * PageLayout
 *
 */

import React from "react";
import { useDispatch, connect } from "react-redux";
import PropTypes from "prop-types";
import Mixpanel from "mixpanel-browser";
import { compose } from "redux";
import delay from "lodash/delay";
import { createStructuredSelector } from "reselect";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";

// Custom Components
import Header from "@components/Layout/Header";
import Loader from "@components/Loader";
import Toast from "@components/Toast";
import ClickAwayListener from "@components/Layout/ClickAwayListener";
import AnnouncementOverlay from "@components/AnnouncementOverlay";
import productUpdatesData from "@components/AnnouncementOverlay/announcements";
import ChatBot from "@components/ChatBot";
import DraggableChatButton from "@components/ChatBot/DraggableChatButton";
import Banner from "@components/Banner";

// Utilities
import AppLocalstorage from "@services/localStorage";
import { SocketContext } from "@context/socket";

// Redux helpers - Only saga injected in this file
import injectSaga from "@utils/injectSaga";
import { setLastActiveRoute } from "@utils/common";
import { loadMenus, clearToastMessage } from "@containers/App/actions";
import {
	makeSelectNavbar,
	makeSelectMessage,
	makeSelectResponseCode,
	makeSelectAnimation,
} from "@containers/App/selectors";

import useUser from "@hooks/useUser";
import GlobalSearchProvider from "@context/globalSearch";
import AircallProvider from "@context/aircallProvider";

import saga from "./saga";
import AccessDenied from "./AccessDenied";
import PageNotFound from "./PageNotFound";

import "@assets/styles/components/announcement.scss";
import "@assets/styles/components/styles.scss";
import "@assets/styles/chat-bot.scss";
import MaintenanceBar from "@components/MaintenanceBar";

const PageLayout = props => {
	const { connectSocket } = React.useContext(SocketContext);
	const [state, setState] = React.useState({
		menuClass: "",
		showAnnounmentOverlay: false,
		isOpenChatBot: false,
	});

	const dispatch = useDispatch();
	const userInfo = useUser();
	const history = useHistory();

	const handleChatBotToggle = (value = !state.isOpenChatBot) => {
		setState(st => ({ ...st, isOpenChatBot: value }));
	};

	React.useEffect(() => {
		connectSocket();
		dispatch(loadMenus());
		window.addEventListener("storage", reloadEvent);

		return () => {
			if (window) {
				window.removeEventListener("storage", reloadEvent);
			}
			const body = document.getElementsByTagName("body")[0];
			body.classList.remove("products-update-panel-overlay-open");
		};
	}, []);

	React.useEffect(() => {
		const body = document.getElementsByTagName("body")[0];
		if (state.showAnnounmentOverlay) {
			body.classList.add("products-update-panel-overlay-open");
		} else {
			body.classList.remove("products-update-panel-overlay-open");
		}
	}, [state.showAnnounmentOverlay]);

	React.useState(() => {
		dispatch(clearToastMessage());
	}, [props.location.pathname]);

	const handleAnnouncementOverlay = (closeOverlay = false) => {
		setState(prev => {
			if (closeOverlay || !prev.showAnnounmentOverlay === false) {
				const updateObj = {};
				productUpdatesData.forEach(e => {
					updateObj[e.id] = true;
				});
				localStorage.setItem("announcements", JSON.stringify(updateObj));
				AppLocalstorage.setItem(
					"announcements",
					productUpdatesData[0].id,
					true,
				);
			}

			return {
				...prev,
				showAnnounmentOverlay: closeOverlay
					? false
					: !prev.showAnnounmentOverlay,
			};
		});
	};

	const getActiveRoute = () => {
		const { pathname } = props.location;
		const moduleName = props.match.params.module;
		const screenName = props.match.params.screen;
		const logActionScreenNames = ["import", "export", "bulk_action"];
		if (pathname === "/stream") {
			return "stream";
		}

		if (pathname === "/dashboard") {
			return "dashboard";
		}

		if (pathname === "/messages") {
			return "MSG";
		}
		if (logActionScreenNames.includes(screenName)) return "LOG";

		if (moduleName) {
			switch (moduleName) {
				case "lead":
					return "LM";
				case "opportunity":
					return "OM";
				case "account":
					return "AM";
				case "contact":
					return "CM";
				case "task":
					return "ACTMT";
				case "event":
					return "ACTME";
				case "inbox":
					return "ACTMI";
				case "quotation":
				case "estimate":
					return "QTM";
				default:
					return "";
			}
		}
		if (pathname.split("/")[1] === "setting") {
			return "Setting";
		}
		return "Home";
	};

	const reloadEvent = event => {
		if (event.key === "logout-event") {
			window.location.reload();
		}
	};

	const handleClearToastMessage = () => {
		if (props.message && props.message.type) {
			dispatch(clearToastMessage());
		}
	};

	const navigate = url => {
		closeMenu();
		if (url && window.location.pathname !== url) {
			Mixpanel.track(`Navigation`, {
				url,
			});
			history.push(url);
		}
		delay(() => {
			setLastActiveRoute(getActiveRoute(), url);
		}, 500);
	};

	const closeMenu = () => {
		setState(st => ({ ...st, menuClass: "" }));
	};

	const toggleMenu = open => {
		const oldMenuClass = state.menuClass;
		const menuClass =
			// eslint-disable-next-line no-nested-ternary
			typeof open === "boolean"
				? open
					? "active"
					: ""
				: oldMenuClass === "active"
				? ""
				: "active";
		if (menuClass !== oldMenuClass) {
			setState(st => ({ ...st, menuClass }));
		}
	};

	const logout = () => {
		dispatch({ type: "LOGOUT" });
	};

	const onClickHeadingLink = () => {
		window.location.reload();
	};

	const { message, responseCode, location, match } = props;
	let { menus } = props;
	const { pathname } = location;

	if (userInfo?.integrations) {
		const hasQuickBooksIntegrated = !!userInfo.integrations.find(
			i => i.provider === "QUICK-BOOKS",
		);

		if (hasQuickBooksIntegrated) {
			menus = menus.map(item => {
				if (item.moduleId === "QTM") {
					return {
						iconName: "estimate",
						moduleId: "QTM",
						url: "/listing/activity/estimate",
						status: "ACTIVE",
						transKey: "module.estimate",
					};
				}
				return item;
			});
		}
	}

	let header = null;
	let childComp = <span />;

	const userProfile = userInfo.profile;

	if (message.type === "serverError") {
		return (
			<Toast
				clearToastMessage={handleClearToastMessage}
				variant="error"
				message={message.messageBody.title}
				details={message.messageBody.details}
				duration={3000}
			/>
		);
	}

	if (!userProfile) {
		return <Loader loading />;
	}

	const menuConent = [];
	if (userProfile && userProfile.permissions && menus instanceof Array) {
		menus.forEach(menu => {
			const permission = userProfile.permissions[menu.moduleId];
			if (permission && permission.READ) {
				menuConent.push(menu);
			}

			const importOrExportCheck = Object.keys(userProfile.permissions)
				// eslint-disable-next-line no-confusing-arrow
				.map(key => (userProfile.permissions[key][menu.moduleId] ? key : false))
				.filter(Boolean);
			if (
				importOrExportCheck.length &&
				(menu.moduleId === "IMPORT" || menu.moduleId === "EXPORT")
			)
				menuConent.push(menu);
		});
	}

	const moduleName = match.params.module;

	const headerProps = {
		userInfo,
		menus: menuConent,
		activeRoute: getActiveRoute(pathname),
		navigate,
		logout,
		menuClass: state.menuClass,
		toggleMenu,
		dispatch,
		handleAnnouncement: handleAnnouncementOverlay,
		pathname,
	};

	if (moduleName && pathname.split("/")[1] !== "setting") {
		let moduleId = "ACTM";
		switch (moduleName) {
			case "lead":
				moduleId = "LM";
				break;
			case "opportunity":
				moduleId = "OM";
				break;
			case "contact":
				moduleId = "CM";
				break;
			case "account":
				moduleId = "AM";
				break;
			case "task":
				moduleId = "ACTM";
				break;
			case "messages":
				moduleId = "MSG";
				break;
			default:
				break;
		}

		const permission = userProfile.permissions[moduleId];

		if (permission && permission.READ) {
			header = <Header {...headerProps} />;
			if (responseCode !== 404) childComp = props.children;
			else childComp = <PageNotFound />;
		} else if (
			(pathname.split("/")[1] === "import" && !permission.IMPORT) ||
			(pathname.split("/")[1] === "export" && !permission.EXPORT) ||
			(pathname.split("/")[1] === "bulk_action" && !permission.READ)
		) {
			header = <Header {...headerProps} />;
			childComp = props.children;
		} else {
			childComp = <AccessDenied />;
		}
	} else {
		header = <Header {...headerProps} />;
		childComp = props.children;
	}

	return (
		<AircallProvider>
			<GlobalSearchProvider>
				{/* <Helmet */}
				{/*	titleTemplate="%s - Pepper Cloud CRM" */}
				{/*	defaultTitle="Pepper Cloud CRM" */}
				{/* > */}
				{/*	<meta name="description" content="Simple CRM for Smart People" /> */}
				{/* </Helmet> */}
				<MaintenanceBar />
				{header}
				{message.type ? (
					<Toast
						clearToastMessage={handleClearToastMessage}
						onClickHeadingLink={onClickHeadingLink}
						variant={message.type}
						message={message.body.title}
						details={message.body.details}
						headingLink={message.body.headingLink}
						duration={message.body.duration || 3000}
					/>
				) : null}
				<div id="routesContainer">{childComp}</div>

				{state.showAnnounmentOverlay && (
					<ClickAwayListener
						onClickAway={() => handleAnnouncementOverlay(true)}
					>
						<div
							className="pc-announounment-overlay"
							style={{
								height: window.innerHeight,
							}}
						>
							<AnnouncementOverlay
								closeOverlay={() => {
									handleAnnouncementOverlay(true);
								}}
							/>
						</div>
					</ClickAwayListener>
				)}
				{state.isOpenChatBot && (
					<div
						className="pc-chatBot-overlay"
						style={{
							height: window.innerHeight,
						}}
					>
						<ChatBot handleChatBotToggle={handleChatBotToggle} />
					</div>
				)}
				<div>
					<DraggableChatButton handleChatBotToggle={handleChatBotToggle} />
				</div>
				<Banner />
			</GlobalSearchProvider>
		</AircallProvider>
	);
};

PageLayout.propTypes = {
	location: PropTypes.object,
	children: PropTypes.node,
	menus: PropTypes.array,
	match: PropTypes.object,
	message: PropTypes.object,
	responseCode: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
	menus: makeSelectNavbar(),
	message: makeSelectMessage(),
	responseCode: makeSelectResponseCode(),
	animation: makeSelectAnimation(),
});

const withConnect = connect(mapStateToProps, null);

injectSaga({ key: "pageLayout", saga });

export default compose(withConnect)(PageLayout);
