const generateErrorMessage = error => {
	// eslint-disable-next-line no-console
	console.log(error);

	const defaultError = {
		type: "error",
		transKey: "server.error",
		body: {
			title: "Server connection cannot be established",
			details: "",
			duration: 3000,
			utmTrackingUrl: "",
		},
	};
	if (error.response) {
		const { data } = error.response;
		if (data.message && data.message.body) {
			data.message.body.duration = 3000;
		}
		return data.message ? data.message : defaultError;
	}

	return defaultError;
};

export default generateErrorMessage;
