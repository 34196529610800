/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";

import Input from "@salesforce/design-system-react/components/input";
import Popover from "@salesforce/design-system-react/components/popover";
import { CirclePicker } from "react-color";
import colors from "../../utils/constants/colors";
// import ColorPicker from "@containers/Messages/MessageSetting/ColorPicker";

function TagInline(props) {
	const { inlineTagOption } = props;
	const { autoFocus } = props;
	const inputElementRef = useRef(null);
	const [toggleColorPicker, setToggleColorPicker] = useState(false);
	const [tagColor, setTagColor] = useState("");
	useEffect(() => {
		if (autoFocus && inputElementRef.current) {
			inputElementRef.current.focus();
		}
	}, []);

	const handleChange = event => {
		const { value } = event.target;
		const val = value.includes(",");
		if (!val) {
			return props.handleChange({
				typeCode: props.typeCode,
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				isRequired: props.isRequired,
				value,
				key: event.key,
			});
		}
		return null;
	};

	const handleBlur = event => {
		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value: event.target.value.trim(),
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleBlur(record);
	};

	const onChangeComplete = ({ hex }, option) => {
		setTagColor(hex);
		props.onChangeComplete(hex, option);
		setTimeout(() => setToggleColorPicker(false));
	};

	return (
		<React.Fragment>
			<div className="tags-inline-edit">
				<button
					className="popover-btn"
					type="button"
					onClick={() => setToggleColorPicker(true)}
				>
					<span
						style={{
							backgroundColor: `${tagColor || inlineTagOption.color}`,
							// backgroundColor: "black",
							width: "14px",
							height: "14px",
							borderRadius: "50%",
							display: "inline-block",
						}}
					></span>
				</button>
				<Input
					inputRef={inputElementRef}
					id={props.fieldId}
					disabled={props.disable}
					name={props.name || props.fieldName || props.fieldId}
					onChange={handleChange}
					onBlur={handleBlur}
					onKeyPress={handleChange}
					type="text"
					className={cx(props.className, "pc-modal-text-box")}
					value={props.value}
					placeholder={props.placeholder}
					autoComplete="off"
					label={props.label}
					minLength={props.minLength}
					maxLength={props.maxLength}
					readOnly={props.readOnly}
				/>
				{toggleColorPicker && (
					<Popover
						isOpen
						className="pc-calendarview-checkbox"
						onRequestClose={() => {
							setToggleColorPicker(false);
						}}
						body={
							<CirclePicker
								colors={colors}
								onChangeComplete={color => {
									onChangeComplete(color, inlineTagOption.uuid);
								}}
								circleSize={14}
								circleSpacing={14}
							/>
						}
						heading="Choose colour"
					></Popover>
				)}
			</div>
		</React.Fragment>
	);
}

export default TagInline;
