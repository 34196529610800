import React, { useRef } from "react";
// TinyMCE so the global var exists

import { Editor } from "@tinymce/tinymce-react";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/preview";
import "tinymce/plugins/emoticons";

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis";

/* content UI CSS is required */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import contentUiCss from "tinymce/skins/ui/oxide/content.min.css?raw";

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import contentCss from "tinymce/skins/content/default/content.min.css?raw";
import { RichTextInput } from "@custom-types/input-type";

import icons from "../../assets/icons/tinymce-icons";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
tinymce.IconManager.add("small", { icons });

const mimeTypes = [
	"image/jpeg",
	"image/jpg",
	"image/png",
	"image/tiff",
	"image/vnd.wap.wbmp",
	"image/x-icon",
	"image/x-jng",
	"image/x-ms-bmp",
	"image/svg+xml",
	"image/webp",
];

function TinyMceInput(props: RichTextInput): JSX.Element {
	const {
		value,
		customButtons = [],
		disable = false,
		inline = false,
		height = 150,
	} = props;
	const editorRef = useRef(null);

	const handleEditorChange = (): void => {
		const { attachments = [] } = value || {};
		if (editorRef.current) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const text = editorRef.current.getContent({ format: "text" });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const html = editorRef.current.getContent();
			const newValue = { html, text, attachments };
			const record = {
				fieldId: props.fieldId,
				fieldName: props.fieldName,
				sectionId: props.sectionId,
				value: newValue,
				typeCode: props.typeCode,
				isRequired: props.isRequired,
			};
			props.handleChange?.(record);
		}
	};

	const handleBlur = (): void => {
		const record = {
			fieldId: props.fieldId,
			fieldName: props.fieldName,
			sectionId: props.sectionId,
			value: props.value,
			typeCode: props.typeCode,
			isRequired: props.isRequired,
		};
		props.handleBlur(record);
	};

	const getCustomToolBarButtons = (): string =>
		customButtons.map(btn => btn.name).join(" ");

	const getToolBar = React.useMemo<string | boolean>((): string | boolean => {
		let toolbar = `font fontstyle allignment lists table link image hr removeformat preview emoticons ${getCustomToolBarButtons()}`;
		if (props.toolBar) {
			toolbar = props.toolBar.join(" ");

			if (!toolbar) return false;
		}
		return toolbar;
	}, []);

	return (
		<Editor
			onInit={(_, editor): void => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				editorRef.current = editor;
			}}
			disabled={disable}
			inline={inline}
			init={{
				height,
				skin: false,
				content_css: "/tinymce.css",
				statusbar: false,
				branding: false,
				menubar: false,
				image_advtab: true,
				icons: "small",
				max_height: 300,
				min_height: 200,
				content_style: [contentCss, contentUiCss].join("\n"),
				images_upload_handler: (blobInfo): Promise<string> =>
					new Promise((resolve, reject) => {
						const info = blobInfo.blob();
						if (mimeTypes.includes(info.type)) {
							const base64 = blobInfo.base64();
							const src = `data:${info.type};base64,${base64}`;
							resolve(src);
						} else {
							reject(new Error("Unsupported type"));
						}
					}),
				link_assume_external_targets: true,
				plugins:
					"link table lists autoresize preview image paste preview emoticons",
				noneditable_noneditable_class: "mceNonEditable",
				toolbar: getToolBar,
				toolbar_groups: {
					fontstyle: {
						icon: "italic",
						tooltip: "Font Style",
						items: "bold italic underline strikethrough",
					},
					allignment: {
						icon: "align-right",
						tooltip: "Alignment",
						items: "alignleft aligncenter alignright alignjustify",
					},
					lists: {
						icon: "unordered-list",
						tooltip: "Lists",
						items: "numlist bullist",
					},
					font: {
						icon: "text-color",
						tooltip: "Font",
						items: "forecolor fontsize blocks",
					},
				},
				setup: (editor): void => {
					if (customButtons.length) {
						customButtons.forEach(btn => {
							editor.ui.registry.addButton(btn.name, {
								icon: btn.iconName,
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								onAction: a => btn.action(editor, a),
								tooltip: btn.tooltip,
							});
						});
					}
				},
			}}
			onEditorChange={handleEditorChange}
			onBlur={handleBlur}
			value={value?.html || value?.text}
		/>
	);
}

export default TinyMceInput;
