import React, { Fragment, useEffect, useState } from "react";
import Tabs from "@salesforce/design-system-react/components/tabs";
import TabsPanel from "@salesforce/design-system-react/components/tabs/panel";
import Icon from "@salesforce/design-system-react/components/icon";
import Button from "@salesforce/design-system-react/components/button";
import PropTypes from "prop-types";
import cx from "classnames";
import sortBy from "lodash/sortBy";
import { getRequest, patchRequest } from "@utils/request";
import NofavPrompt from "../../assets/img/nofavprompt.svg";
import NoResult from "../../assets/img/noresult.svg";

function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
const list = [
	{
		prompt: `Create a Lead with first name "Wei", last name "Zhang", and email ID "zhangwei@gmail.com"`,
		type: "Leads",
		module: "Leads",
		id: 1,
	},
	{
		prompt: `Assign Lead with email ID "Lisa@gmail.com" to me`,
		type: "Leads",
		module: "Leads",
		id: 2,
	},
	{
		prompt: `Move the Lead "Lin Chang" to stage "Negotiation".`,
		type: "Leads",
		module: "Leads",
		id: 3,
	},
	{
		prompt: `Create a new Contact with first name "John", last name "Smith", and email ID "johnsmith@gmail.com""`,
		type: "Contacts",
		module: "Contacts",
		id: 4,
	},
	{
		prompt: `Reassign Contact "Ema" to user "Minh"`,
		type: "Contacts",
		module: "Contacts",
		id: 5,
	},
	{
		prompt: `Create a new Opportunity with name "George" worth amount "7000-USD"`,
		type: "Opportunity",
		module: "Opportunity",
		id: 6,
	},
	{
		prompt: `Move Opportunity name "Key Account Expansion" to the "Negotiation" stage`,
		type: "Opportunity",
		module: "Opportunity",
		id: 7,
	},
	{
		prompt: `Create a new Account with the name "Crystal Solutions"`,
		type: "Account",
		module: "Accounts",
		id: 8,
	},
	{
		prompt: `Reassign Account "Opal_Feest" to user "El Johnston"`,
		type: "Account",
		module: "Accounts",
		id: 9,
	},
	{
		prompt: `Add meeting log "Meeting postponed" with description "The client asked to postpone the demo meeting for next week" to contact name "Feng Bao"`,
		type: "Logs",
		module: "Activities",
		id: 10,
	},
	{
		prompt: `Add call log "Negotiation meeting" with description "Client is asking for customisable options for the chosen subscription" to the Contact name "Chang"`,
		type: "Logs",
		module: "Activities",
		id: 11,
	},
	{
		prompt: `Add a note "Call him after 10th June" with description "The client will be out of office due to the festive season until June 10th" to Contact name "Huang"`,
		type: "Note",
		module: "Activities",
		id: 12,
	},
];

const AllPrompt = ({ togglePromptModal, handleAddPrompt }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [tabIndex, setTabIndex] = useState(0);
	const [favPrompts, setFavPrompts] = useState({});
	const [filter, setFilter] = useState("All");

	const getFavPrompts = async () => {
		try {
			const {
				data: { data },
			} = await getRequest(`/api/v2/personalization`);
			if (data && data.favoritePrompts)
				setFavPrompts({ ...data.favoritePrompts });
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	useEffect(() => {
		getFavPrompts();
	}, []);

	const favAddRemove = async id => {
		const newPrompts = favPrompts;
		if (favPrompts[id]) {
			delete newPrompts[id];
		} else {
			newPrompts[id] = true;
		}
		setFavPrompts({ ...newPrompts });
		try {
			await patchRequest("/api/v2/personalization", {
				favoritePrompts: newPrompts,
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	const filteredList =
		searchTerm &&
		searchTerm.length &&
		list.filter(item => {
			if (filter !== "All" && item.module !== filter) return false;
			if (tabIndex && favPrompts && !favPrompts[item.id]) return false;
			const escapedTerm = escapeRegExp(searchTerm);
			const regex = new RegExp(escapedTerm, "i");
			return regex.test(item.prompt) || regex.test(item.type);
		});

	const promptsList = list.filter(item => {
		if (filter !== "All" && item.module !== filter) return false;
		return !(tabIndex && !favPrompts[item.id]);
	});

	const handleSearch = e => {
		setSearchTerm(e.target.value);
	};

	return (
		<div className="prompt-container">
			<div className="prompt-header">
				<div className="backbtn-title">
					<Button className="back-btn" onClick={togglePromptModal}>
						<Icon name="chevronleft" size="small" category="utility" />
					</Button>
					<div className="prompt-header-content">
						<h3>Prompts</h3>
						<p>AI-powered chatbot</p>
					</div>
				</div>
				<div className="close-btn">
					<Button
						iconCategory="custom"
						iconName="pc-glyph-close"
						onClick={togglePromptModal}
					/>
				</div>
			</div>
			<div className="tabs-block">
				<Tabs
					id="tabs-example-default"
					onSelect={i => setTabIndex(i)}
					selectedIndex={tabIndex}
				>
					{["All Prompts", "Favourites"].map(each => {
						return (
							<TabsPanel label={each}>
								<div className="search-block">
									<input
										type="text"
										placeholder="Search"
										value={searchTerm}
										onChange={handleSearch}
										id="searchright"
										data-toggle="tooltip"
										data-placement="top"
									/>
									<div className="search-icon">
										<Icon
											category="custom"
											size="x-small"
											name="pc-search-grey"
										/>
									</div>
								</div>

								<div className="bot-search-filters">
									{[
										"All",
										"Leads",
										"Contacts",
										"Opportunity",
										"Accounts",
										"Activities",
									].map(f => (
										// eslint-disable-next-line jsx-a11y/click-events-have-key-events
										<span
											key={f}
											role="button"
											tabIndex={-1}
											className={cx("filter", filter === f && "selected")}
											onClick={() => setFilter(f)}
										>
											{f}
										</span>
									))}
								</div>

								<ul>
									{searchTerm.trim() === "" ? (
										<Fragment>
											{promptsList && promptsList.length ? (
												<Fragment>
													{sortBy(promptsList, "prompt").map(item => (
														<PromptCard
															favAddRemove={favAddRemove}
															favPrompts={favPrompts}
															item={item}
															handleAddPrompt={handleAddPrompt}
														/>
													))}
												</Fragment>
											) : (
												<div className="empty-prompt-screen">
													<img src={NofavPrompt} alt="empty-promt" />
													<h3 className="empty-screen-title">
														No favourite prompts
													</h3>
													<p className="empty-screen-description">
														You have not added any favourite prompts. <br /> Add
														your favourite prompts from general prompts.
													</p>
													<Button
														className="pc-modal-primary-btn "
														onClick={() => setTabIndex(0)}
													>
														View prompts
													</Button>
												</div>
											)}
										</Fragment>
									) : (
										<Fragment>
											{filteredList && filteredList.length ? (
												<Fragment>
													{sortBy(filteredList, "prompt").map(item => (
														<PromptCard
															favAddRemove={favAddRemove}
															favPrompts={favPrompts}
															item={item}
															handleAddPrompt={handleAddPrompt}
														/>
													))}
												</Fragment>
											) : (
												<div className="empty-prompt-screen">
													<img src={NoResult} alt="No Result" />
													<h3 className="empty-screen-title">
														No result found
													</h3>
													<p className="empty-screen-description">
														There are no matching results. Check spelling errors
														and search again.
													</p>
												</div>
											)}
										</Fragment>
									)}
								</ul>
							</TabsPanel>
						);
					})}
				</Tabs>
			</div>
		</div>
	);
};

export default AllPrompt;

AllPrompt.propTypes = {
	togglePromptModal: PropTypes.func,
	handleAddPrompt: PropTypes.func,
};

const PromptCard = ({ favAddRemove, item, favPrompts, handleAddPrompt }) => {
	return (
		<li className="prompt-card" key={item.id}>
			<Button
				className="prompt-card-content"
				onClick={() => handleAddPrompt(item.prompt)}
			>
				<div className="prompt-icon-content">
					<Icon
						category="custom"
						name={`pc-prompt-${item.type}`}
						size="medium"
						className="pc-detail-page-header-image"
					/>
					<div className="prompt-content">
						<p className="prompt-name">{item.prompt}</p>
						<p className="prompt-type">{item.type}</p>
					</div>
				</div>
			</Button>
			<button type="button" onClick={() => favAddRemove(item.id)}>
				<Icon
					category="custom"
					name={
						favPrompts && favPrompts[item.id]
							? "pc-fav-prompt-red"
							: "pc-fav-prompt"
					}
					size="x-small"
					className="pc-detail-page-header-image"
				/>
			</button>
		</li>
	);
};
PromptCard.propTypes = {
	handleAddPrompt: PropTypes.func,
	favAddRemove: PropTypes.func,
	item: PropTypes.object,
	favPrompts: PropTypes.func,
};
