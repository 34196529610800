/* eslint-disable no-console */
/**
 * app.ts
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { BrowserRouter as Router } from "react-router-dom";

import * as Sentry from "@sentry/react"; // For Error Tracking
import { Integrations } from "@sentry/tracing"; // For Performance monitoring
import Mixpanel from "mixpanel-browser"; // For Product Analytics

// Utils
// Import root app

// Import Language Provider
import LanguageProvider from "@containers/LanguageProvider"; // eslint-disable-line

// Load the favicon
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!file-loader?name=[name].[ext]!./images/favicon.ico";

// Slds
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import actionSprite from "@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg";
import utilitySprite from "@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg";
import standardSprite from "@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg";
import doctypeSprite from "@salesforce-ux/design-system/assets/icons/doctype-sprite/svg/symbols.svg";
import App from "@containers/App";

// Other helpers files
import store from "./store";

// Styles and assets
import customSprite from "./assets/symbols.svg";
import "sanitize.css/sanitize.css";
import "./assets/styles/app.scss";

import * as ServiceWorker from "./utils/workbox-runtime";

// Import i18n messages
import { translationMessages } from "./i18n";

// Initializing the Mixpanel
Mixpanel.init(process.env.MIXPANEL_TOKEN || "64bfb075739", {
	persistence: "localStorage",
}); // Random string just in case to not get any error in DEVELOPMENT

// Initializing the Sentry browser SDK
if (
	process.env.SENTRY_ENV &&
	String(process.env.SENTRY_ENV).toLowerCase() !== "development"
) {
	// The below logic is for better garbage cleaning and lazy loading
	import("../package.json")
		.then(pkgJson => {
			const version = String(pkgJson.version);
			Sentry.init({
				dsn: process.env.SENTRY_DSN,
				environment: String(process.env.SENTRY_ENV).toUpperCase(),
				release: process.env.SENTRY_RELEASE,

				// https://docs.sentry.io/platforms/javascript/configuration/filtering/
				// sampleRate: "0.9", // ? By default it is "1"

				ignoreErrors: [
					"Failed to update a ServiceWorker",
					"ResizeObserver loop limit exceeded",
					"Non-Error promise rejection captured with keys: family, stretch, style, weight",
					"Unexpected token '<'",
					"InvalidStateError",
					"ServiceWorker script",
					"Script",
					"Network Error",
					"Request failed with status code 401",
					"Error: [IgnoreSentry]",
				],

				denyUrls: [/\*.mixpanel.com/i],

				integrations: [
					new Integrations.BrowserTracing({
						tracingOrigins: [
							"demo.peppercloud.com",
							"app.peppercloud.com",
							"demo-api.peppercloud.com",
							"backend.peppercloud.com",
							/^\//,
						],

						// routingInstrumentation:
						// 	Sentry.reactRouterV5Instrumentation(history),
					}),
				],
				// We recommend adjusting this value in production, or using tracesSampler

				// for finer control
				tracesSampleRate: process.env.SENTRY_ENV === "PROD" ? 0.4 : 0.6,
			});

			Sentry.configureScope(scope => {
				scope.setTag("version", `v${version}`);
			});
		})
		.catch(e => console.error(e));
} else {
	// If env is development then disable Mixpanel
	Mixpanel.disable();
}

// if (process.env.NODE_ENV !== "development" && process.env.MOUSEFLOW_TOKEN) {
// 	window._mfq = window._mfq || [];
// 	const mf = document.createElement("script");
// 	mf.type = "text/javascript";
// 	mf.defer = true;
// 	mf.src = `//cdn.mouseflow.com/projects/${process.env.MOUSEFLOW_TOKEN}.js`;
// 	document.getElementsByTagName("head")[0].appendChild(mf);
// }

const MOUNT_NODE = document.getElementById("app") as HTMLElement;

const root = createRoot(MOUNT_NODE);

const render = (messages: { [key: string]: string }): void => {
	root.render(
		<IconSettings
			actionSprite={actionSprite}
			customSprite={customSprite}
			doctypeSprite={doctypeSprite}
			standardSprite={standardSprite}
			utilitySprite={utilitySprite}
		>
			<Provider store={store}>
				<LanguageProvider messages={messages}>
					<Router>
						<ThemeProvider>
							<App />
						</ThemeProvider>
					</Router>
				</LanguageProvider>
			</Provider>
		</IconSettings>,
	);
};

// expose store when run in Cypress
if (window.Cypress) {
	window.store = store;
}

if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	console.log("Hot loading app......");
	module.hot.accept(["./i18n", "@containers/App"], () => {
		root.unmount();
		render(translationMessages);
	});
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
	new Promise(resolve => {
		resolve(import("intl"));
	})
		.then(() => render(translationMessages))
		.catch(err => {
			throw err;
		});
} else {
	render(translationMessages);
}

let reloading = false;
ServiceWorker.register({
	onSuccess: () => {
		console.log("Service Worker: registered");
	},
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	onUpdate: registration => {
		console.log("Service Worker: updated", registration);
		const registrationWaiting = registration.waiting;

		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: "SKIP_WAITING" });

			registrationWaiting.addEventListener(
				"statechange",
				(event: { target: { state: string } }) => {
					if (event.target.state === "activated" && !reloading) {
						reloading = true;
						window.location.reload();
					}
				},
			);
		}
	},
});
