export default {
	control: {
		fontSize: 14,
		fontWeight: "normal",
	},

	"&multiLine": {
		control: {
			fontFamily: "inherit",
			minHeight: 36,
		},
		highlighter: {
			padding: 9,
			border: "1px solid transparent",
		},
		input: {
			padding: 9,
			border: "1px solid silver",
		},
	},

	"&singleLine": {
		display: "inline-block",
		width: 180,

		highlighter: {
			padding: 1,
			border: "2px inset transparent",
		},
		input: {
			padding: 1,
			border: "2px inset",
		},
	},

	suggestions: {
		backgroundColor: "#fff",
		padding: "10px",
		list: {
			backgroundColor: "white",
			fontSize: 14,
			padding: "10px",
			minWidth: "300px",
			boxShadow: " 0 3px 13px #00000038",
			borderRadius: "8px",
			maxHeight: "200px",
			overFlow: "auto",
		},
		item: {
			padding: "5px 15px",
			alignItems: "flex-start",
		},
	},
};
