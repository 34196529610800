// ! Prop validation will be done on migrating this file
/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";

import PageLayout from "@containers/PageLayout";
import { getCookie } from "@services/getSetCookie";
import { SocketProvider } from "@context/socket";

function PrivateRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={props => {
				if (getCookie("_csrf")) {
					return (
						<SocketProvider>
							<PageLayout match={props.match} location={props.location}>
								{rest.withoutKey ? (
									<Component {...props} />
								) : (
									<Component key={props.location.pathname} {...props} />
								)}
							</PageLayout>
						</SocketProvider>
					);
				}

				return (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				);
			}}
		/>
	);
}

export default PrivateRoute;
