import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

// Custom Components
import Toast from "@salesforce/design-system-react/components/toast";
import ToastContainer from "@salesforce/design-system-react/components/toast/container";

// Redux Helpers
import { clearToastMessage } from "@containers/App/actions";
import { makeSelectMessage } from "@containers/App/selectors";

class LoginLayout extends React.Component {
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			this.props.dispatch(clearToastMessage());
		}
	}

	clearToastMessage = () => {
		if (this.props.message && this.props.message.type) {
			this.props.dispatch(clearToastMessage());
		}
	};

	render() {
		const { message } = this.props;
		const childComp = this.props.children;
		return (
			<React.Fragment>
				{message.type ? (
					<ToastContainer className="pc-login-toast-container">
						<div className="slds-grid slds-wrap">
							<div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_2-of-3"></div>
							<div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-3">
								<Toast
									variant={message.type}
									duration={3000}
									labels={{
										heading: message.body.title,
										details: message.body.details,
									}}
									onRequestClose={this.clearToastMessage}
								/>
							</div>
						</div>
					</ToastContainer>
				) : null}
				{childComp}
			</React.Fragment>
		);
	}
}
LoginLayout.propTypes = {
	dispatch: PropTypes.func.isRequired,
	location: PropTypes.object,
	message: PropTypes.object,
	children: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	message: makeSelectMessage(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LoginLayout);
