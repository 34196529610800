import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import get from "lodash/get";

import "./styles/dateStyles.scss";
import Button from "@salesforce/design-system-react/components/button";

function NewRangePicker(props) {
	const { timeZone } = props.userInfo.locale;
	const datePickerRef = useRef();
	const [dateFormat] = get(
		props,
		"userInfo.locale.dateTimeFormat",
		"DD/MM/YYYY HH:mm",
	).split(" ");

	const [start, setStart] = useState(
		props.value?.startDate
			? moment(props.value.startDate).tz(timeZone).format(dateFormat)
			: null,
	);

	const [end, setEnd] = useState(
		props.value?.endDate
			? moment(props.value.endDate).tz(timeZone).format(dateFormat)
			: null,
	);

	useEffect(() => {
		setEnd(
			props.value?.endDate
				? moment(props.value.endDate).tz(timeZone).format(dateFormat)
				: null,
		);
		setStart(
			props.value?.startDate
				? moment(props.value.startDate).tz(timeZone).format(dateFormat)
				: null,
		);
	}, [props.value]);

	const weekDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

	const handleChange = e => {
		let startDate = null;
		let endDate = null;
		if (e && e.length === 1) {
			startDate = moment(e[0].toDate()).tz(timeZone).startOf("day").format();
		} else if (e && e.length === 2) {
			startDate = moment(e[0].toDate()).tz(timeZone).startOf("day").format();
			setStart(startDate);
			endDate = moment(e[1].toDate()).tz(timeZone).endOf("day").format();
			setEnd(endDate);
			const value = { startDate, endDate };
			props.handleChange({
				fieldName: props.fieldName,
				fieldId: props.fieldId,
				typeCode: props.typeCode,
				value,
				isRequired: props.isRequired,
			});
		} else {
			props.handleChange({
				fieldName: props.fieldName,
				fieldId: props.fieldId,
				typeCode: props.typeCode,
				value: "",
				isRequired: props.isRequired,
			});
		}
	};

	const handleClear = () => {
		setStart([]);
		setEnd([]);
		props.handleChange({
			fieldName: props.fieldName,
			fieldId: props.fieldId,
			typeCode: props.typeCode,
			value: { startDate: null, endDate: null },
			isRequired: props.isRequired,
		});
	};
	const handleClick = () => {
		if (datePickerRef.current) {
			if (datePickerRef.current.isOpen) datePickerRef.current.closeCalendar();
			else datePickerRef.current.openCalendar();
		}
	};
	// eslint-disable-next-line react/react-in-jsx-scope
	return (
		<div className="pc-single-date-picker date-picker-container">
			<DatePicker
				id={props.fieldId}
				ref={props.datePickerRef || datePickerRef}
				range
				// eslint-disable-next-line react/prop-types
				placeholder={props?.placeholder ?? "Date range"}
				weekDays={weekDays}
				inputClass={`custom-input ${
					props.customClass ? props.customClass : "default-class"
				}`}
				value={[start, end]}
				onChange={handleChange}
				format={dateFormat}
				numberOfMonths={2}
				arrow={false}
				maxDate={props.maxDate || new Date().setHours(23, 59, 59, 999)}
				className="mobile-date-picker"
				position="bottom"
				markFocused
				// calendarPosition=""
			/>
			{props.value && props.value.startDate ? (
				<Button
					iconName="close"
					iconCategory="utility"
					iconVariant="border-filled"
					className="pc-listing-search-close"
					onClick={handleClear}
				/>
			) : (
				<Button
					iconName="chevrondown"
					iconCategory="utility"
					iconVariant="border-filled"
					className="pc-listing-search-close slds-p-right_small msg-datepicker-dropdown"
					onClick={handleClick}
				/>
			)}
		</div>
	);
}

NewRangePicker.propTypes = {
	handleChange: PropTypes.func,
	maxDate: PropTypes.string,
	fieldId: PropTypes.string,
	fieldName: PropTypes.string,
	typeCode: PropTypes.string,
	isRequired: PropTypes.bool,
	userInfo: PropTypes.object,
	value: PropTypes.object,
	customClass: PropTypes.string,
	datePickerRef: PropTypes.object,
};

export default NewRangePicker;
